import _ from "lodash";
import { formatInTimeZone } from "date-fns-tz";
import { compareAsc, parseISO, isBefore, isAfter, isEqual } from "date-fns";
import { eventTypeDefinitions } from "./api/trogdor/eventTypeDefinitions";
import { SatTypes } from "../redux/rtk/types/trogdorApiTypes";
import { EventSat } from "../redux/rtk/types/ThreeJSCommonTypes";
import {
  MultiScenarioResponse,
  MultiEventTypeResponse,
  MultiEventResponse,
} from "../redux/rtk/types/acmeApiTypes";
import { Scenario, Event } from "../redux/rtk/types/internalTypes";
import { EnumType } from "../redux/rtk/types/commonTypes";

class Common {
  static DISPLAY_TIME_FORMAT = "HH:mm:ss"; // No Timezones
  static DISPLAY_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
  static ISO_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSX"; // 2021-01-28T22:22:12.000Z
  static PLAYBACK_CONTROLS_FORMAT = "DDD  HH:mm:ss"; // 125 18:57:06
  static JULIAN_DATE = "yyyy-DDD"; // 2022-245
  static MILISEC_TIME_FORMAT = "HH:mm:ss.SSSX"; // 22:22:12.000Z
  static TOOTTIP_DATE_FORMAT = "DDD"; //308
  static LIST_ITEM_DATE = "dd MMM yyyy";
  static EPHEMERIS_FORMAT = "yyyyMMdd'T'HHmmssSSSS";

  static trogdor = process.env.REACT_APP_API === "trogdor";

  static parseDatesAndTimesFromEntity = (
    entity: Scenario | Event
  ): {
    dates: { start: string; end: string };
    times: { start: string; end: string };
  } => {
    const dates = {
      start: entity?.startTime?.slice(0, 10) || "",
      end: entity?.endTime?.slice(0, 10) || "",
    };
    const times = {
      start: entity?.startTime?.slice(11, 19) || "",
      end: entity?.endTime?.slice(11, 19) || "",
    };

    return { dates, times };
  };

  static convertToISOJSONDate = (
    dateFromInput: string,
    timeFromInput: string
  ): string => {
    const dateString = dateFromInput;
    const hours = timeFromInput.slice(0, 2);
    const minutes = timeFromInput.slice(3, 5);
    const seconds = timeFromInput.slice(6, 8);
    const formattedDate = `${dateString}T${hours}:${minutes}:${seconds}.000Z`;
    return formattedDate;
  };

  static formatDateTimeToJulianISO = (datetime: string | number | Date) => {
    const formattedDatetime = formatInTimeZone(
      datetime,
      "UTC",
      Common.PLAYBACK_CONTROLS_FORMAT
    );
    return formattedDatetime;
  };

  static formatDateJulianISO = (datetime: string | number | Date): string => {
    const formattedDatetime = formatInTimeZone(
      datetime,
      "UTC",
      Common.TOOTTIP_DATE_FORMAT
    );
    return formattedDatetime;
  };

  static formatDateTimeToISO = (datetime: string | number | Date) => {
    const formattedDatetime = formatInTimeZone(
      datetime,
      "UTC",
      Common.ISO_DATETIME_FORMAT
    );
    return formattedDatetime;
  };

  static formatDateTimeToListItemDate = (
    datetime: string | number | Date
  ): string => {
    const formattedDatetime = formatInTimeZone(
      datetime,
      "UTC",
      Common.LIST_ITEM_DATE
    ).toUpperCase();
    return formattedDatetime;
  };

  static formattedEphemDate = (datetime: string | number | Date) =>
    formatInTimeZone(new Date(datetime), "UTC", Common.EPHEMERIS_FORMAT);

  static formatTrogdorDateTime = (datetime: string) => {
    if (typeof datetime === "string") {
      return `${datetime.slice(0, 23)}Z`;
    } else {
      return datetime;
    }
  };
  static formatPlaybackControlsToDateObject = (
    playbackControlsDateTime: string,
    year: number
  ) => {
    const day = Number(playbackControlsDateTime.slice(0, 3));
    const hours = Number(playbackControlsDateTime.slice(-8, -6));
    const minutes = Number(playbackControlsDateTime.slice(-5, -3));
    const seconds = Number(playbackControlsDateTime.slice(-2));
    const dateObject = new Date(year, 0, day, hours, minutes, seconds);

    return dateObject;
  };

  // If no trigger is found or resolved will fall back to start time
  static eventIdsByTriggerTime = (eventsObj: { Event: any }) => {
    return Object.keys(eventsObj).sort((a, b) => {
      const eventTypeIdA = findEventTypeIdByName(eventsObj[a].eventType);
      const eventTypeIdB = findEventTypeIdByName(eventsObj[b].eventType);

      const eventTypeDef:
        | { [key: typeof eventTypeIdA]: any }
        | { [key: typeof eventTypeIdB]: any } = eventTypeDefinitions;

      let triggerValueA = findEventParamValueByDefId(
        eventsObj[a],
        eventTypeDef[eventTypeIdA].trigger
      );
      let triggerValueB = findEventParamValueByDefId(
        eventsObj[b],
        eventTypeDef[eventTypeIdB].trigger
      );

      // If trigger is not resolved, fall back to start time
      if (!triggerValueA) triggerValueA = eventsObj[a].startTime;
      if (!triggerValueB) triggerValueB = eventsObj[b].startTime;

      return compareAsc(parseISO(triggerValueA), parseISO(triggerValueB));
    });
  };

  // Deep clone keys to camel case
  static camelize = (obj: object) =>
    //acc is of type 'unknown'
    _.transform(obj, (acc: any, value, key, target) => {
      const camelKey = _.isArray(target) ? key : _.camelCase(key);

      acc[camelKey] = _.isObject(value) ? this.camelize(value) : value;
    });

  // Deep clone keys to snake case
  static snakeize = (obj: object) =>
    _.transform(obj, (acc: any, value, key, target) => {
      const snakeKey = _.isArray(target) ? key : _.snakeCase(key);

      acc[snakeKey] = _.isObject(value) ? this.snakeize(value) : value;
    });

  // Turn response data into camel case
  //'any' because the response can be any repsonse type- not always Axios
  static camelizeResponseData = (response: any) => {
    response.data = this.camelize(response.data);
    return response;
  };

  // return boolean if eventType is in supprotedEventTypes Array
  static isEventTypeSupported = (eventTypeId: number) => {
    return supportedEventTypes.includes(eventTypeId);
  };

  // return boolean if provider is in supproted for passed eventType
  static isProviderSupported = (eventTypeId: string, providerId: number) => {
    return supportedProviderTypesByEventTypeId[
      eventTypeId as keyof typeof supportedProviderTypesByEventTypeId
    ].includes(providerId);
  };
}

export const dateToStringWithMilliseconds = (date: Date | string) => {
  const date_str = date.toString();
  const date_without_milliseconds = new Date(date_str); // truncated date since milliseconds are not included
  const milliseconds_delta = Number(date) - Number(date_without_milliseconds);

  const date_str_with_milliseconds = date_str.replace(
    /(^.*:\d\d:\d\d)(.*$)/,
    `$1:${isNaN(milliseconds_delta) ? "000" : milliseconds_delta}$2`
  );
  return date_str_with_milliseconds;
};

export const isBetween = (
  date: number | Date,
  from: number | Date,
  to: number | Date,
  inclusivity = "()"
) => {
  if (!["()", "[]", "(]", "[)"].includes(inclusivity)) {
    throw new Error("Inclusivity parameter must be one of (), [], (], [)");
  }

  const isBeforeEqual = inclusivity[0] === "[",
    isAfterEqual = inclusivity[1] === "]";

  return (
    (isBeforeEqual
      ? isEqual(from, date) || isBefore(from, date)
      : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
};

export const supportedEventTypes = [1, 4, 5, 6];

export const supportedProviderTypesByEventTypeId = {
  "1": [1],
  "4": [1, 2],
  "5": [1, 2],
  "6": [1, 2],
};

export const findEventTypeIdByName = (eventTypeName: string): number => {
  const eventTypeId: number | undefined = Object.keys(
    eventTypeDefinitions
  ).find(
    (eventTypeId) =>
      eventTypeDefinitions[eventTypeId as keyof typeof eventTypeDefinitions]
        .eventName === eventTypeName
  );
  if (!eventTypeId) throw "unable to find event type id from event name";
  return eventTypeId;
};

export const findEventParamValueByDefId = (
  event: Event,
  paramDefId: number
): string => {
  const parameters = event?.parameters?.parameters || [];
  const matchingParam = parameters.find(
    (param) => param.paramDefID === paramDefId
  );
  return matchingParam?.paramValue || "";
};

export const findTleIdBySatNo = (
  tleArray: EventSat[],
  satNo: number | string
): string | null => {
  const tle = tleArray?.find((tle) => tle.satNo === Number(satNo));
  if (!tle) return null;
  return tle.id;
};

// Returns true when param values are equal
export const haveParamValuesChanged = (
  existingParams: string | any[],
  newParams: any[]
) => {
  if (existingParams.length !== newParams.length) return true;

  const result = newParams.every((param, index) => {
    if (param.paramValue === existingParams[index].paramValue) {
      return true;
    } else return false;
  });
  return !result;
};

export const getObjByIdAndArrayOfIds = (
  //any is here because it could be ids of any possible object
  array:
    | MultiScenarioResponse[]
    | MultiEventTypeResponse[]
    | MultiEventResponse[]
    | SatTypes[]
    | any,
  idProp = "id"
) => {
  const objectById = () => {
    return array.reduce((obj: any, value: any) => {
      obj[value[idProp]] = value;
      return obj;
    }, {});
  };

  const arrayOfIds = () => {
    return array.map((item: any) => item[idProp]);
  };
  return { objectById: objectById(), arrayOfIds: arrayOfIds() };
};

export const createEnum = (values: { [key: string]: EnumType }) => {
  const enumObject: { [key: string]: EnumType } = {};
  for (const key in values) {
    enumObject[key] = values[key];
  }
  return Object.freeze(enumObject);
};

export const currentScenarioStatusEnums = createEnum({
  initialState: "initialState",
  fetching: "fetching",
  fetched: "fetched",
  unsaved: "unsaved",
});

export default Common;
