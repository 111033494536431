import universalAxios, { Url, Data, Params, Headers, Method } from "../axios";

class TrogdorAxios {
  static get(url: Url, params?: Params) {
    return this.axios("get", url, params);
  }

  static delete(url: Url, params?: Params, data?: Data) {
    return this.axios("delete", url, data, params);
  }

  static post(url: Url, params?: Params, data?: Data, headers?: Headers) {
    return this.axios("post", url, data, params, headers);
  }

  static put(url: Url, params?: Params, data?: Data) {
    return this.axios("put", url, data, params);
  }

  static patch(url: Url, params?: Params, data?: Data) {
    return this.axios("patch", url, data, params);
  }

  static axios(
    method: Method,
    url: Url,
    params?: Params,
    data?: Data,
    headers?: Headers
  ) {
    const parameters = {
      api: "trogdor",
      url: url,
      ...params,
    };
    const trogdorHeaders = {
      "Content-Type": "application/json",
      ...headers,
    };
    return universalAxios(method, "/proxy/", parameters, data, trogdorHeaders);
  }
}

export default TrogdorAxios;
