import { useState, createContext, useContext, useMemo } from "react";

type PropTypes = {
  dateTime: Date;
  setDateTime: React.Dispatch<React.SetStateAction<Date>>;
};

const DateTimeContext = createContext<PropTypes>({
  dateTime: new Date(),
  setDateTime: () => Date,
});

export function useDateTimeContext() {
  return useContext(DateTimeContext);
}

export function DateTimeContextProvider({ children }: Children) {
  const [dateTime, setDateTime] = useState(new Date());
  const value: PropTypes = useMemo(
    () => ({ dateTime, setDateTime }),
    [dateTime]
  );

  return (
    <DateTimeContext.Provider value={value}>
      {children}
    </DateTimeContext.Provider>
  );
}
