import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { currentScenarioStatusEnums as statusEnums } from "../../utils/common";
import { useGetScenarioQuery } from "../../redux/rtk/endpoints/scenario";
import { useGetScenarioSVsQuery } from "../../redux/rtk/endpoints/svSats";
import {
  useGetScenarioEventsQuery,
  eventsInitialState,
} from "../../redux/rtk/endpoints/events";
import { useGetScenarioTLEsQuery } from "../../redux/rtk/endpoints/tleSats";
import {
  copyScenarioDetailsSuccess,
  updateStatus,
} from "../../redux/slices/scenarios/currentScenario/details/scenarioDetailsSlice";
import { copyScenarioEventsSuccess } from "../../redux/slices/scenarios/currentScenario/events/eventsSlice";
import { fetchTLEsSuccess } from "../../redux/slices/scenarios/currentScenario/objects/preEventTLEs";
import { getScenarioSVEphemerides } from "../../redux/thunks/eventObjectsThunks";
import { getCurrentScenarioStatus } from "../../redux/selectors/scenarioSelector";
import { currentScenarioStatusEnums } from "../../utils/common";
import { fetchSVsSuccess } from "../../redux/slices/scenarios/currentScenario/objects/postEventSVs";

type ReturnType = (scenarioId: number | undefined) => {
  scenario?: any;
  isLoadingScenario?: boolean;
  inProgress: boolean;
  isError?: boolean;
};

export const useFetchCurrentScenario: ReturnType = (scenarioId) => {
  const dispatch = useAppDispatch();
  const scenarioStatus = useAppSelector(getCurrentScenarioStatus);

  const setFetchingStatus = async () => {
    if (scenarioStatus === currentScenarioStatusEnums.initialState) {
      await dispatch(updateStatus(statusEnums.fetching));
    }
  };

  useEffect(() => {
    setFetchingStatus();
  }, []);

  const {
    currentData: scenario = {},
    isLoading: isLoadingScenario,
    isFetching: isFetchingScenario,
    isError: isScenarioError,
    isSuccess: isGetScenarioSuccess,
  } = useGetScenarioQuery(scenarioId);

  const {
    currentData: {
      ids: scenarioEventIds,
      entities: scenarioEventsById,
    } = eventsInitialState,
    isFetching: isFetchingScenarioEvents,
    isError: isScenarioEventsError,
    isSuccess: isGetScenarioEventsSuccess,
  } = useGetScenarioEventsQuery(scenarioId);

  const {
    currentData: scenarioTLEsByEventId = {},
    isFetching: isFetchingScenarioTLEs,
    isError: isScenarioTLEsError,
    isSuccess: isGetScenarioTLEsSuccess,
  } = useGetScenarioTLEsQuery(scenarioId);

  const {
    currentData: scenarioSVsByEventId = {},
    isFetching: isFetchingScenarioSVs,
    isError: isScenarioSVsError,
    isSuccess: isGetScenarioSVsSuccess,
  } = useGetScenarioSVsQuery(scenarioId);

  const copyScenarioAsCurrent = async () => {
    dispatch(copyScenarioDetailsSuccess(scenario));
    dispatch(copyScenarioEventsSuccess(scenarioEventsById));
    //copies sats to redux
    dispatch(fetchTLEsSuccess(scenarioTLEsByEventId));
    dispatch(fetchSVsSuccess(scenarioSVsByEventId));
    //fetches ephemeris
    dispatch(getScenarioSVEphemerides(scenarioEventIds));

    dispatch(updateStatus(statusEnums.fetched));
  };

  const allSuccess =
    isGetScenarioSuccess &&
    isGetScenarioEventsSuccess &&
    isGetScenarioTLEsSuccess &&
    isGetScenarioSVsSuccess;
  const anyFetching =
    isFetchingScenario ||
    isFetchingScenarioEvents ||
    isFetchingScenarioTLEs ||
    isFetchingScenarioSVs;

  useEffect(() => {
    if (
      scenarioStatus === currentScenarioStatusEnums.fetching &&
      allSuccess &&
      !anyFetching
    ) {
      copyScenarioAsCurrent();
    }
  }, [scenarioStatus, allSuccess, anyFetching]);

  if (!scenarioId)
    return {
      inProgress: false,
      error: "scenarioId invalid" as any,
    };

  return {
    scenario,
    isLoadingScenario,
    inProgress: (isFetchingScenario ||
      isFetchingScenarioEvents ||
      isFetchingScenarioTLEs ||
      isFetchingScenarioSVs) as boolean,
    isError: (isScenarioError ||
      isScenarioEventsError ||
      isScenarioTLEsError ||
      isScenarioSVsError) as boolean,
  };
};
