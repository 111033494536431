import { useEffect } from "react";
import { useAppSelector } from "../../../utils/Hooks/reduxHooks";
import { getEventTypesById } from "../../../redux/rtk/endpoints/initialization";
import "./ParametersForm.css";
import { Typography, Grid, Box } from "@mui/material";
import {
  generateJSXInputsArray,
  ParamEventValues,
} from "./parameterFieldFactory";
import { parseISO } from "date-fns";
import type { Event } from "../../../redux/rtk/types/internalTypes";
import type { EventFormValues } from "../../../redux/rtk/types/formTypes";

const styles = {
  eventPlaceholderTitle: {
    color: "#9FA3A7",
    marginTop: "5.75rem",
  },
  eventPlaceholderText: {
    color: "#9FA3A7",
    width: "15rem",
    textAlign: "center",
  },
  targetIcon: {
    width: ".25rem",
    marginTop: "2rem",
    marginRight: "1.55rem",
    marginLeft: "-1.25rem",
  },
};

type PropTypes = {
  currentEvent?: Event;
  values: EventFormValues;
  handleChange: (...args: any) => void;
  setInitialValues: (key: object) => void;
};

const ParametersForm = ({
  currentEvent,
  values,
  handleChange,
  setInitialValues,
}: PropTypes) => {
  const eventTypesById: any = useAppSelector(getEventTypesById);
  const { eventTypeId, providerId } = values;
  const eventTypeObj = eventTypesById[eventTypeId];
  const parameterDefs: number[] = eventTypeObj?.parameterDefs[providerId];
  const savedEventParams = currentEvent?.parameters.parameters;

  // Sets form field values from saved event
  useEffect(() => {
    if (!savedEventParams) return;
    const initialValues: ParamEventValues = {};

    savedEventParams.forEach(
      //paramValue needs any here because of the different strict assignments that can't include any other types (like Date)
      (param: { paramName: string; paramValue: any }) => {
        const { paramName, paramValue } = param;
        const paramValueParsed = parseISO(paramValue);
        if (isNaN(paramValue) && !isNaN(+paramValueParsed)) {
          const dateKey = `${paramName}_date`;
          const timeKey = `${paramName}_time`;
          initialValues[dateKey as keyof typeof initialValues] =
            paramValue.substring(0, 10);
          initialValues[timeKey as keyof typeof initialValues] =
            paramValue.substring(11, 22);
        } else {
          initialValues[paramName as keyof typeof initialValues] = paramValue;
        }
      }
    );

    setInitialValues(initialValues);
  }, [savedEventParams, setInitialValues]);

  return (
    <Box component={"div"} className="parameters-container">
      {parameterDefs ? (
        <>
          <Typography variant="h2">{eventTypeObj.name}</Typography>
          {generateJSXInputsArray(parameterDefs, values, handleChange)}
        </>
      ) : (
        <Grid
          container
          justifyContent="center"
          data-test="empty-parameters-form"
        >
          <Typography sx={styles.eventPlaceholderTitle} variant="h1">
            Event parameters
          </Typography>
          <Typography sx={styles.eventPlaceholderText} variant="h4">
            Select a provider and event type to continue with &nbsp; event
            creation
          </Typography>
        </Grid>
      )}
    </Box>
  );
};

export default ParametersForm;
