import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { RuxIndeterminateProgress } from "@astrouxds/react";
import { useSatelliteContext } from "../../utils/Hooks/contextHooks/useSatelliteContext";
import { useSatStateContext } from "../../utils/Hooks/contextHooks/useSatStateContext";

const styles = {
  collapse: {
    backgroundColor: "rgba(24, 38, 53, 0.8)", // --tertiaryDarken2 with opacity added
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    width: "100%",
    pointerEvents: "none",
  },
  bannerContentContainer: {
    width: "98%",
    height: "150px",
  },
  satelliteBannerLeft: {
    width: "30%",
    paddingLeft: "1rem",
  },
  satelliteBannerCenter: {
    width: "75%",
    padding: "0 2rem 0 2rem",
  },
  sectionDivider: {
    height: "50%",
    backgroundColor: "#7C8088", // --colorQuaternaryDarken2
    width: "3px",
  },
  dataHeader: {
    color: "#7C8088", // --colorQuaternaryDarken2
    paddingRight: "0.2rem",
    fontSize: "0.6rem",
  },
  dataValue: {
    color: "#CFD6E3", // --colorQuaternary
    fontSize: "0.6rem",
  },
  bannerImgDiv: {
    width: "6rem",
    height: "6rem",
    borderRadius: "3rem",
    border: "2px solid #19C7CA", // --colorTag1
    backgroundColor: "rgb(0, 23, 36)",
    position: "relative",
  },
  bannerImg: {
    position: "absolute",
    top: -20,
    left: -20,
    width: "140%",
  },
};

const setTitleCase = (str: string) => {
  if (typeof str !== "string") return;
  const newString = str.toLowerCase();
  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

const SatelliteBanner = () => {
  const { threeSatellitesById } = useSatelliteContext();
  const { hoveredSatId } = useSatStateContext();
  const activeSatellite = threeSatellitesById[hoveredSatId as string | number];

  const buildSatSubheader = () => {
    if (activeSatellite.origNetwork) {
      return `${activeSatellite.origNetwork} | ${setTitleCase(
        activeSatellite.dataMode
      )}`;
    } else if (activeSatellite.dataMode) return activeSatellite.dataMode;
    else {
      return `${activeSatellite.countryCode} | ${activeSatellite.objectType}`;
    }
  };

  return (
    <Collapse
      in={hoveredSatId !== null}
      mountOnEnter
      unmountOnExit
      sx={styles.collapse}
    >
      {activeSatellite ? (
        <>
          <Grid
            container
            justifyContent="space-between"
            wrap="nowrap"
            alignItems="center"
            sx={styles.bannerContentContainer}
          >
            <Grid
              container
              item
              sx={styles.satelliteBannerLeft}
              id="satellite-banner-left"
              alignItems="center"
              direction="row"
              wrap="nowrap"
              justifyContent="space-evenly"
            >
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                width="auto"
              >
                <Box component={"div"} sx={styles.bannerImgDiv}>
                  <Box
                    component="img"
                    sx={styles.bannerImg}
                    src={require("../../static/img/satelliteBannerImg.png")}
                    alt="satellite"
                  />
                </Box>
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                alignItems="flex-start"
                direction="column"
                width="auto"
              >
                <Grid item>
                  <Typography variant="h2">
                    {activeSatellite.name
                      ? setTitleCase(activeSatellite?.name)
                      : activeSatellite.objectName
                      ? setTitleCase(activeSatellite.objectName)
                      : `Sat No: ${activeSatellite.satNo}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography color="#CFD6E3">{buildSatSubheader()}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={styles.sectionDivider} />
            <Grid
              container
              item
              sx={styles.satelliteBannerCenter}
              id="satellite-banner-center"
              wrap="nowrap"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                container
                item
                width="auto"
                direction="row"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid
                  container
                  item
                  alignItems="flex-start"
                  direction="column"
                  justifyContent="center"
                  width="auto"
                  wrap="nowrap"
                >
                  <Typography sx={styles.dataHeader}>Elset ID:</Typography>
                  <Typography sx={styles.dataHeader}>NORAD ID:</Typography>
                  <Typography sx={styles.dataHeader}>
                    Classification:
                  </Typography>
                  <Typography sx={styles.dataHeader}>Source:</Typography>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  direction="column"
                  alignItems="flex-start"
                  width="auto"
                >
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.idElset}
                  </Typography>
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.idOnOrbit}
                  </Typography>
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.classificationMarking}
                  </Typography>
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.source}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                width="auto"
                direction="row"
                alignItems="flex-start"
                wrap="nowrap"
              >
                <Grid
                  container
                  item
                  alignItems="flex-start"
                  direction="column"
                  justifyContent="center"
                  width="auto"
                  wrap="nowrap"
                >
                  <Typography sx={styles.dataHeader}>Period:</Typography>
                  <Typography sx={styles.dataHeader}>Epoch:</Typography>
                  <Typography sx={styles.dataHeader}>Rev Number:</Typography>
                  <Typography sx={styles.dataHeader}>
                    Semimajor Axis:
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  direction="column"
                  alignItems="flex-start"
                  width="auto"
                >
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.period}
                  </Typography>
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.epoch}
                  </Typography>
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.revNo}
                  </Typography>
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.semiMajorAxis}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                width="auto"
                direction="row"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid
                  container
                  item
                  alignItems="flex-start"
                  direction="column"
                  justifyContent="center"
                  width="auto"
                  wrap="nowrap"
                >
                  <Typography sx={styles.dataHeader}>Mean Motion:</Typography>
                  <Typography sx={styles.dataHeader}>BStar:</Typography>
                  <Typography sx={styles.dataHeader}>Inclination:</Typography>
                  <Typography sx={styles.dataHeader}>
                    RA of Asc Node:
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  direction="column"
                  alignItems="flex-start"
                  width="auto"
                >
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.meanMotion}
                  </Typography>
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.bStar}
                  </Typography>
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.inclination}
                  </Typography>
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.raan}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                width="auto"
                direction="row"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid
                  container
                  item
                  alignItems="flex-start"
                  direction="column"
                  justifyContent="center"
                  width="auto"
                  wrap="nowrap"
                >
                  <Typography sx={styles.dataHeader}>Eccentricity:</Typography>
                  <Typography sx={styles.dataHeader}>
                    Arg of Perigee:
                  </Typography>
                  <Typography sx={styles.dataHeader}>Mean Anomoly:</Typography>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="center"
                  direction="column"
                  alignItems="flex-start"
                  width="auto"
                >
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.eccentricity}
                  </Typography>
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.argOfPerigee}
                  </Typography>
                  <Typography sx={styles.dataValue}>
                    {activeSatellite.meanAnomaly}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <RuxIndeterminateProgress />
          </Grid>
        </Grid>
      )}
    </Collapse>
  );
};

export default SatelliteBanner;
