import { createContext, useContext, useState } from "react";

type PropTypes = {
  zoomValue: number;
  setZoomValue: React.Dispatch<React.SetStateAction<number>>;
  sliderZoomActive: boolean;
  setSliderZoomActive: React.Dispatch<React.SetStateAction<boolean>>;
  zoomValueUpdateInterval: number;
  instanceScale: number | undefined;
};

export const ZoomContext = createContext<PropTypes>({
  zoomValue: -21213,
  setZoomValue: () => null,
  sliderZoomActive: false,
  setSliderZoomActive: () => null,
  zoomValueUpdateInterval: 2,
  instanceScale: undefined,
});

export function useZoomContext() {
  return useContext(ZoomContext);
}

export const ZoomContextProvider = ({ children }: Children) => {
  const [zoomValue, setZoomValue] = useState(-21213);
  // Whether or not zoomValue is being changed by the slider element
  const [sliderZoomActive, setSliderZoomActive] = useState(false);
  // Update zoomValue less often by increasing number. Better for higher perfomance when it comes to scaling satellites (i.e. sizeAttenuation), updateing the earth atmosphere, and updating zoomControls.
  const zoomValueUpdateInterval = 2;

  // code to control size attenuation of satellites so they grow if the camera is further away for increased visibility
  const scaleFactor = 120;
  const instanceScale = Math.floor(-zoomValue / scaleFactor);

  return (
    <ZoomContext.Provider
      value={{
        zoomValue,
        setZoomValue,
        sliderZoomActive,
        setSliderZoomActive,
        zoomValueUpdateInterval,
        instanceScale,
      }}
    >
      {children}
    </ZoomContext.Provider>
  );
};
