import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../utils/Hooks/reduxHooks";
import { useDrawerContext } from "../../../utils/Hooks/contextHooks/useDrawerContext";
import { Grid, List, Typography, Box } from "@mui/material";
import EventListItem from "./EventListItem/EventListItem";
import LeftPanelListHeaders from "../../Common/Layout/LeftPanel/LeftPanelListHeaders/LeftPanelListHeaders";
import {
  getCurrentScenarioEventIds,
  getCurrentScenarioEvents,
} from "../../../redux/selectors/scenarioSelector";
import {
  useGetScenarioEventsQuery,
  eventsInitialState,
} from "../../../redux/rtk/endpoints/events";
import ListSortBar from "../../Common/Layout/LeftPanel/ListSortBar/ListSortBar";
import EmptyListContent from "../../Common/Layout/LeftPanel/EmptyListContent/EmptyListContent";
import { RuxSelectCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";
import { RuxIndeterminateProgress } from "@astrouxds/react";
import _ from "lodash";

const styles = {
  listPanel: {
    height: "calc(100vh - 33.35rem )",
    overflowY: "auto",
  },
  listItems: {
    height: "100%",
    overflowY: "auto",
    width: "100%",
  },
  loadingScreen: {
    fontSize: "28px",
    textAlign: "center",
    justifyContent: "center",
  },
};

const sortOptions = [
  { label: "Start time", value: "startTime" },
  { label: "End time", value: "endTime" },
  { label: "Event Type", value: "eventType" },
  { label: "Status", value: "status" },
  { label: "Name", value: "name" },
];

const EventList = () => {
  const { scenarioId } = useParams();
  const { toggleCreateEvent, toggleAddObject } = useDrawerContext();
  const [sortProperty, setSortProperty] = useState("startTime");
  const currentScenarioEventIds = useAppSelector(getCurrentScenarioEventIds);
  const currentScenarioEventsById = useAppSelector(getCurrentScenarioEvents);
  const {
    data: {
      ids: trogdorScenarioEventIds,
      entities: trogdorScenarioEventsById,
    } = eventsInitialState,
    isLoading,
  } = useGetScenarioEventsQuery(scenarioId);

  const eventIds = _.isEmpty(currentScenarioEventIds)
    ? trogdorScenarioEventIds
    : currentScenarioEventIds;
  const eventsById = _.isEmpty(currentScenarioEventsById)
    ? trogdorScenarioEventsById
    : currentScenarioEventsById;

  const sortedEvents = useMemo(
    () =>
      [...eventIds].sort((a, b) =>
        eventsById[a][sortProperty].localeCompare(eventsById[b][sortProperty])
      ),
    [eventIds, sortProperty]
  );

  const handleSort = (
    event: RuxSelectCustomEvent<void> | React.BaseSyntheticEvent
  ) => setSortProperty(event.target.value);

  return (
    <>
      <Grid px="1.875rem" data-test="event-list-sort-bar-container">
        <ListSortBar
          type="events"
          options={sortOptions}
          sort={sortProperty}
          onSort={handleSort}
          onCreate={toggleCreateEvent}
          buttonName="Create Event"
        />
      </Grid>
      <LeftPanelListHeaders
        items={[
          { name: "Event" },
          { name: "Start", marginLeft: "1.95rem" },
          { name: "End", marginLeft: "-.5rem" },
        ]}
      />
      {isLoading ? (
        <Box component={"div"} sx={styles.loadingScreen}>
          <RuxIndeterminateProgress style={{ marginTop: "9rem" }} />
          <Box component={"div"}>
            <Typography variant="h2">Loading events...</Typography>
          </Box>
        </Box>
      ) : (
        <Grid sx={styles.listPanel}>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            {eventIds.length ? (
              <List disablePadding sx={styles.listItems} data-test="event-list">
                {sortedEvents.map((eventId) => {
                  return (
                    <EventListItem
                      event={eventsById[eventId]}
                      key={eventId}
                      data-test={eventId}
                    />
                  );
                })}
              </List>
            ) : (
              <EmptyListContent
                title="events"
                firstButtonOnClick={toggleCreateEvent}
                firstButtonName="Create event"
                text="Add events or objects to the scenario"
                secondButtonName="Add object"
                secondButtonOnClick={toggleAddObject}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default EventList;
