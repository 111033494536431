import { createSelector } from "@reduxjs/toolkit";

const liveEventDetails = (state) => state.liveEvent.eventDetails;
const liveEventParameters = (state) => state.liveEvent.eventParameters;
const liveEventTLEs = (state) => state.liveEvent.preEventTLEs;
const liveEventSVs = (state) => state.liveEvent.postEventSVs;
const liveEventPosting = (state) => state.liveEvent.isPosting;
const liveEvent = (state) => state.liveEvent;

export const getLiveEventDetails = createSelector(
  [liveEventDetails],
  (liveEventDetails) => liveEventDetails
);

export const getLiveEventParameters = createSelector(
  [liveEventParameters],
  (liveEventParameters) => liveEventParameters
);

export const getLiveEventTLEs = createSelector(
  [liveEventTLEs],
  (liveEventTLEs) => liveEventTLEs
);

export const getLiveEventSVs = createSelector(
  [liveEventSVs],
  (liveEventSVs) => liveEventSVs
);
export const getLiveEventPosting = createSelector(
  [liveEventPosting],
  (liveEventPosting) => liveEventPosting
);

export const getLiveEvent = createSelector(
  [liveEvent],
  (liveEvent) => liveEvent
);
