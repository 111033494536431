/* eslint-disable react/no-unknown-property */
import { useMemo } from "react";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";

const AmbientLight = () => {
  const { realTimeSunToggle, nightLightsToggle } = useCanvasContext();

  // __ Internal ________________________________________________________________
  const intensityHandler = useMemo(
    () =>
      nightLightsToggle
        ? 0.13
        : realTimeSunToggle && !nightLightsToggle
        ? 0.3
        : 0.9,
    [nightLightsToggle, realTimeSunToggle]
  );

  return (
    <ambientLight
      name="Ambient"
      color={"#e8e8e8"}
      intensity={intensityHandler}
    />
  );
};

export default AmbientLight;
