import { createSelector } from "@reduxjs/toolkit";
import { createCachedSelector } from "re-reselect";
import { currentScenarioStatusEnums } from "../../utils/common";

const currentScenarioData = (state) => state.currentScenario.details.details;
const currentScenarioEvents = (state) => state.currentScenario.events.events;
const currentScenarioEventIds = (state) =>
  state.currentScenario.events.eventIds;
const currentScenarioTLEsByEventId = (state) =>
  state.currentScenario.preEventTLEs.satsByEventId;
const currentScenarioPostEventSVs = (state) =>
  state.currentScenario.postEventSVs.stateVectorsByEventId;
const currentScenarioEphem = (state) =>
  state.currentScenario.postEventSVs.ephemByStateVectorId;
const currentScenarioStatus = (state) => state.currentScenario.details.status;
const currentScenarioPosting = (state) =>
  state.currentScenario.details.isPosting;
const eventId = (state, eventId) => eventId;
const satNo = (state, satNo) => satNo;

export const getCurrentScenario = createSelector(
  [currentScenarioData],
  (currentScenarioData) => currentScenarioData
);

export const getCurrentScenarioEventIds = createSelector(
  [currentScenarioEventIds],
  (currentScenarioEventIds) => currentScenarioEventIds
);

export const getCurrentScenarioEvents = createSelector(
  [currentScenarioEvents],
  (currentScenarioEvents) => currentScenarioEvents
);

export const getCurrentScenarioEventParams = createCachedSelector(
  currentScenarioEvents,
  eventId,

  (currentScenarioEvents, eventId) =>
    currentScenarioEvents[eventId].parameters.parameters
)(eventId);

export const getCurrentScenarioTLEs = createSelector(
  [currentScenarioTLEsByEventId],
  (currentScenarioTLEsByEventId) =>
    Object.values(currentScenarioTLEsByEventId).flat()
);

export const getCurrentScenarioTLEIds = createSelector(
  [currentScenarioTLEsByEventId],
  (currentScenarioTLEsByEventId) => {
    const allTLEs = Object.values(currentScenarioTLEsByEventId).flat();
    return allTLEs.map((sat) => sat.id);
  }
);

export const getCurrentScenarioTLESatNos = createSelector(
  [currentScenarioTLEsByEventId],
  (currentScenarioTLEsByEventId) => {
    const allTLEs = Object.values(currentScenarioTLEsByEventId).flat();
    return allTLEs.map((sat) => sat.satNo);
  }
);

export const isSatNoInCurrentScenario = createCachedSelector(
  [getCurrentScenarioTLESatNos, satNo],
  (currentScenarioSatNos, satNo) => {
    return currentScenarioSatNos.includes(satNo);
  }
)(satNo);

export const getCurrentScenarioTLEsByEventId = createSelector(
  [currentScenarioTLEsByEventId],
  (currentScenarioTLEsByEventId) => currentScenarioTLEsByEventId
);

export const getCurrentScenarioSVsByEventId = createSelector(
  [currentScenarioPostEventSVs],
  (currentScenarioPostEventSVs) => currentScenarioPostEventSVs
);

export const getCurrentScenarioSVs = createSelector(
  [currentScenarioPostEventSVs],
  (stateVectorsByEventId) => {
    const stateVectors = [];
    Object.keys(stateVectorsByEventId).forEach((eventId) => {
      if (stateVectorsByEventId[eventId].length) {
        stateVectorsByEventId[eventId].forEach((stateVector) =>
          stateVectors.push(stateVector)
        );
      }
    });
    return stateVectors;
  }
);

export const getCurrentScenarioEphemBySVId = createSelector(
  [currentScenarioEphem],
  (currentScenarioEphem) => currentScenarioEphem
);

export const getCurrentScenarioEventsChanged = createSelector(
  [getCurrentScenarioEventIds, currentScenarioEvents],
  (getCurrentScenarioEventIds, currentScenarioEvents) => {
    let pendingChanges = false;
    getCurrentScenarioEventIds.forEach((id) => {
      if (currentScenarioEvents[id].changed) pendingChanges = true;
    });

    return pendingChanges;
  }
);

export const getCurrentScenarioHasChanged = createSelector(
  [currentScenarioStatus],
  (currentScenarioStatus) =>
    currentScenarioStatus === currentScenarioStatusEnums.unsaved
);

export const getCurrentScenarioStatus = createSelector(
  [currentScenarioStatus],
  (currentScenarioStatus) => currentScenarioStatus
);

export const getCurrentScenarioPosting = createSelector(
  [currentScenarioPosting],
  (currentScenarioPosting) => currentScenarioPosting
);
