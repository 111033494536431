import { useNavigate } from "react-router-dom";
import { RuxButton } from "@astrouxds/react";
import { Typography, Box } from "@mui/material";
import { AcmeTheme } from "../../themes/acmeTheme";
import Modal from "@mui/material/Modal";
import { RuxIndeterminateProgress } from "@astrouxds/react";

const style = {
  container: {
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    alignItems: "center",
    paddingTop: (theme: AcmeTheme) => theme.astro.spacing(5),
    paddingBottom: "0.625rem",
    height: "4.25rem",
  },
  rightBtn: {
    justifySelf: "end",
  },
  modalDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

type PropTypes = {
  leftBtnText?: string;
  leftBtnType?: "button" | "submit";
  leftBtnFunction?: () => void;
  titleText?: string;
  rightBtnText?: string;
  rightBtnType?: "button" | "submit";
  rightBtnFunction?: () => void;
  postingStatus: boolean;
};

const PanelTitleBar = (props: PropTypes) => {
  const navigate = useNavigate();
  const {
    leftBtnText = "placeholder text",
    leftBtnType = "button",
    leftBtnFunction = () => navigate(-1),
    titleText = "placeholder text",
    rightBtnText = "",
    rightBtnType = "button",
    rightBtnFunction = () => navigate(-1),
    postingStatus = false,
  } = props;

  return (
    <Box component="div" sx={style.container}>
      <Box component="div">
        <Modal
          open={postingStatus}
          sx={style.modalDisplay}
          data-test="done-button-spinner"
        >
          <RuxIndeterminateProgress />
        </Modal>
        <RuxButton
          data-test="done-button"
          icon="arrow-back-ios"
          type={leftBtnType}
          onClick={
            leftBtnType !== "submit" ? () => leftBtnFunction() : () => null
          }
          borderless
        >
          {leftBtnText}
        </RuxButton>
      </Box>
      <Typography variant="h2">{titleText}</Typography>
      {rightBtnText && (
        <Box component="div" sx={style.rightBtn}>
          <RuxButton
            data-test="cancel-button"
            type={rightBtnType}
            onClick={
              rightBtnType !== "submit" ? () => rightBtnFunction() : () => null
            }
            borderless
          >
            {rightBtnText}
          </RuxButton>
        </Box>
      )}
    </Box>
  );
};

export default PanelTitleBar;
