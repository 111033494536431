// Hooks
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utils/Hooks/reduxHooks";
import { useSatelliteContext } from "../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useIsPresent } from "framer-motion";
// Redux
import { resetCurrentScenario } from "../../../redux/thunks/scenarioThunks";
import { getCurrentScenario } from "../../../redux/selectors/scenarioSelector";
import {
  useGetScenarioListQuery,
  scenarioListInitialState,
} from "../../../redux/rtk/endpoints/scenario";
import {
  useGetAllEventsQuery,
  buildEventsByScenarioId,
  eventsInitialState,
} from "../../../redux/rtk/endpoints/events";
// Components
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
// import { RuxSwitch } from "@astrouxds/react";
import List from "@mui/material/List";
import LeftPanelListHeaders from "../../Common/Layout/LeftPanel/LeftPanelListHeaders/LeftPanelListHeaders";
import ListSortBar from "../../Common/Layout/LeftPanel/ListSortBar/ListSortBar";
import ScenarioListItem from "./ScenarioListItem/ScenarioListItem";
import EmptyListContent from "../../Common/Layout/LeftPanel/EmptyListContent/EmptyListContent";
import { RuxSelectCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";
import LoadingGif from "../../Common/Layout/LeftPanel/EmptyListContent/LoadingScenarios.gif";

const styles = {
  panelContainer: {
    height: "10rem",
    justifyContent: "space-between",
    padding: "1.25rem 1.25rem 1.75rem",
  },
  activeSwitch: {
    marginLeft: "auto",
    spacing: ".625rem",
    minWidth: "7.75rem",
  },
  sortBar: {
    marginBottom: "3.8125rem",
  },
  listHeight: {
    height: "calc(100% - 10rem - 0.0625rem - 1.6875rem)",
  },
};

const sortOptions = [
  { label: "Start time", value: "startTime" },
  { label: "End time", value: "endTime" },
  { label: "Status", value: "status" },
  { label: "Name", value: "name" },
];

const ScenariosListPanel = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isPresent = useIsPresent();
  const { removeAllSatellites } = useSatelliteContext();
  const currentScenario = useAppSelector(getCurrentScenario);
  const [sortProperty, setSortProperty] = useState("startTime");
  const handleSort = (
    event: RuxSelectCustomEvent<void> | React.BaseSyntheticEvent
  ) => setSortProperty(event.target.value);
  const handleClick = () => navigate("/scenarios/create");
  const {
    data: {
      ids: scenarioIds,
      entities: scenariosById,
    } = scenarioListInitialState,
    isFetching,
  } = useGetScenarioListQuery(undefined, {
    pollingInterval: 60000,
  });

  const { eventsByScenarioId } = useGetAllEventsQuery(undefined, {
    selectFromResult: ({
      data: { entities: eventsByEventId } = eventsInitialState,
    }: any) => buildEventsByScenarioId(eventsByEventId, scenarioIds),
  });

  // Clear objects from visualization and close RenderWindow UI popover components

  useEffect(() => {
    if (isPresent) {
      dispatch(resetCurrentScenario());
      removeAllSatellites();
    }
  }, [isPresent]);

  const sortedScenarioIds = useMemo(() => {
    const newSortedScenarioIds = [...scenarioIds];
    newSortedScenarioIds.sort((a, b) => {
      return scenariosById[a][sortProperty].localeCompare(
        scenariosById[b][sortProperty]
      );
    });
    return newSortedScenarioIds;
  }, [scenarioIds, sortProperty]);

  return (
    <>
      <Stack sx={styles.panelContainer} data-test="scenario-list-panel">
        <Stack direction="row" alignItems="center" sx={styles.sortBar}>
          <Typography variant="h2">Scenarios</Typography>
          {/* TODO: Add functionality to "Show active only" feature */}
          {/* <Typography sx={styles.activeSwitch} data-test="show-active-switch">
            Show active only
          </Typography>
          <RuxSwitch /> */}
        </Stack>

        <ListSortBar
          options={sortOptions}
          buttonName="Create Scenario"
          onCreate={handleClick}
          onSort={handleSort}
          sort={sortProperty}
          type="scenarios"
        />
      </Stack>

      <LeftPanelListHeaders
        items={[
          { name: "Status", marginLeft: "-.65rem" },
          { name: "Scenario", marginLeft: "-6.5rem" },
          { name: "Start", marginLeft: "2.25rem" },
        ]}
      />

      {scenarioIds.length ? (
        <List sx={styles.listHeight} data-test="scenario-list">
          {sortedScenarioIds.map((scenarioId) => {
            return (
              <ScenarioListItem
                scenario={scenariosById[scenarioId]}
                NumOfEvents={eventsByScenarioId[scenarioId]?.length || 0}
                key={scenarioId}
                isCurrentScenario={currentScenario.id === scenarioId}
              />
            );
          })}
        </List>
      ) : isFetching ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          data-test="scenario-list-loading-screen"
        >
          <>
            <img
              src={LoadingGif}
              alt="Loading Scenarios"
              style={{ marginTop: "14.25rem" }}
            />
            <Typography variant="h2">Loading scenarios</Typography>
          </>
        </Grid>
      ) : (
        <EmptyListContent
          firstButtonOnClick={handleClick}
          title="scenarios"
          text="Create your first scenario"
          firstButtonName="Create scenario"
          type="scenarios"
        />
      )}
    </>
  );
};

export default ScenariosListPanel;
