import {
  createEntityAdapter,
  createSelector,
  EntityState,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { trogdorApi } from "../api";
import {
  formatCreateScenarioRequest,
  formatUpdateScenarioRequest,
} from "../formatting/requestFormatting";
import { formatScenarioResponse } from "../formatting/responseFormatting";
import { MutationResponse, ScenarioResponse } from "../types/responseTypes";
import type { Scenario, Event } from "../types/internalTypes";
import type { ScenarioFormValues } from "../types/formTypes";

export const scenarioListAdapter = createEntityAdapter<Scenario>({
  sortComparer: (a, b) => a.id - b.id,
});

export const scenarioEventsAdapter = createEntityAdapter<Event>({
  sortComparer: (a, b) => a.id - b.id,
});

export const scenarioListInitialState = scenarioListAdapter.getInitialState();

export const scenarioEndpoints = trogdorApi.injectEndpoints({
  endpoints: (builder) => ({
    getScenarioList: builder.query<EntityState<Scenario>, void>({
      query: () => ({
        url: "/db/get_all_scenarios",
        method: "get",
      }),
      keepUnusedDataFor: Infinity,
      transformResponse(response: { body: ScenarioResponse[] }) {
        const scenarioList = response.body.map((scenario) => {
          return formatScenarioResponse(scenario);
        });

        return scenarioListAdapter.setAll(
          scenarioListInitialState,
          scenarioList
        );
      },
      providesTags: (entityResponse: EntityState<Scenario> | undefined) => {
        const entities = entityResponse?.entities;
        const arrayOfEntities =
          entities && (Object.values(entities) as Scenario[]);
        return arrayOfEntities
          ? [
              ...arrayOfEntities.map(({ id }) => ({
                type: "Scenario" as const,
                id,
              })),
              "Scenario",
            ]
          : ["Scenario"];
      },
    }),
    getScenario: builder.query<Scenario, string | number>({
      query: (id) => ({
        url: "/db/get_scenario",
        method: "get",
        params: { scenario_id: id },
      }),
      transformResponse(response: { body: ScenarioResponse }) {
        return formatScenarioResponse(response.body);
      },
      providesTags: (_result, _error, id) => [{ type: "Scenario", id }],
    }),
    createScenario: builder.mutation<
      MutationResponse,
      Required<ScenarioFormValues>
    >({
      query: (formData) => {
        const params = formatCreateScenarioRequest(formData);
        return {
          url: "/db/create_scenario",
          method: "post",
          params: params,
        };
      },
      invalidatesTags: ["Scenario"],
    }),
    updateScenario: builder.mutation<
      MutationResponse,
      ScenarioFormValues & { id: string }
    >({
      query: (formData) => {
        const params = formatUpdateScenarioRequest(formData);
        return {
          url: "/db/update_scenario",
          method: "post",
          params: params,
        };
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: "Scenario", id: arg.id },
      ],
    }),
    deleteScenario: builder.mutation<MutationResponse, number>({
      query: (id) => {
        const params = { scenario_id: id };
        return {
          url: "/db/delete_scenario",
          method: "delete",
          params: params,
        };
      },
      invalidatesTags: ["Scenario"],
    }),
  }),
});
``;
export const {
  useGetScenarioListQuery,
  useGetScenarioQuery,
  useCreateScenarioMutation,
  useUpdateScenarioMutation,
  useDeleteScenarioMutation,
}: any = scenarioEndpoints;

// Build and export selectors
const selectScenarioListResult =
  scenarioEndpoints.endpoints.getScenarioList.select();
export const selectScenarioListData = createSelector(
  selectScenarioListResult,
  (scenarioListResult) => scenarioListResult.data
);

export const {
  selectIds: getScenarioIds,
  selectEntities: getScenariosById,
  selectAll: getAllScenarios,
  selectTotal: getScenariosTotal,
  selectById: getScenarioById,
} = scenarioListAdapter.getSelectors(
  (state: RootState) =>
    selectScenarioListData(state) ?? scenarioListInitialState
);
