/* eslint-disable react/no-unknown-property */
import { useRef } from "react";
import * as THREE from "three";
import { Trail, Instance } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { earthRadius } from "satellite.js/lib/constants";

type PropTypes = {
  speed: number;
  color: THREE.Color | string;
  radius: number;
  rotation: number[];
  center?: THREE.Vector3;
  groupRotation?: THREE.Vector3;
  position: number[];
  name: string;
};

const Satellite = ({
  speed,
  color,
  radius,
  rotation,
  center = new THREE.Vector3(0, 0, 0),
  groupRotation = new THREE.Vector3(0, 0, 0),
}: PropTypes) => {
  const ref = useRef<any>();
  const group = useRef<any>();

  radius = earthRadius * radius;
  groupRotation.set(rotation[0], rotation[1], rotation[2]);

  useFrame((state) => {
    const t = state.clock.getElapsedTime() * speed * 0.3;
    group.current.rotation.z = t;
    ref.current.position.set(
      (Math.cos(t) * radius * Math.atan(t)) / Math.PI / 0.5,
      Math.sin(t) * radius,
      0
    );
    ref.current.lookAt(center);
  });

  return (
    <group
      ref={group}
      rotation={[groupRotation.x, groupRotation.y, groupRotation.z]}
    >
      <Trail
        local={false}
        width={300}
        length={40}
        decay={1}
        color={color}
        attenuation={(t) => {
          return t * t * 2;
        }}
      >
        <Instance ref={ref} scale={[100, 100, 100]} />
      </Trail>
    </group>
  );
};

export default Satellite;
