import * as satellite from "satellite.js/lib/index";
import * as THREE from "three";
import { isEqual } from "lodash";
import { differenceInMilliseconds } from "date-fns";
import Common, { dateToStringWithMilliseconds } from "./common";
import { EventStateVector } from "../redux/rtk/types/trogdorApiTypes";
import { Ephemeris } from "../redux/rtk/types/internalTypes";
import { EventSat, SatPosition } from "../redux/rtk/types/ThreeJSCommonTypes";

const toThreeVec = (v: SatPosition, vec = new THREE.Vector3()) => {
  vec.set(v.x, v.z, -v.y);
  return vec;
};

const twoDigitTime = (time: number) => {
  return ("0" + time).slice(-2);
};

// Return a position and velocity vector for a given date and time.
const ephemerisPointToVec = (ephemPoint: { p: number[]; v: number[] }) => {
  if (!ephemPoint?.p || !ephemPoint?.v) return null;
  const { p, v } = ephemPoint;
  const pos = toThreeVec({ x: p[0], y: p[1], z: p[2] });
  const vel = toThreeVec({ x: v[0], y: v[1], z: v[2] });

  return { pos, vel };
};

// const rad2Deg = 180 / 3.141592654;

// __ Satellite locations _________________________________________________

const getEphemerisPropagate = (date: Date | string, ephemeris: Ephemeris) => {
  /*
    returns - Position and Velocity objects from points immediately before
    and after submitted date
  */
  const startDate = Date.parse(ephemeris.startDate);
  const currentDate = Date.parse(dateToStringWithMilliseconds(date));
  const timeRemainder = currentDate % startDate;
  const stepsFromStart = Math.floor(
    timeRemainder / (Number(ephemeris.step) * 1000)
  );

  const dateBeforeStep = new Date(
    startDate + stepsFromStart * (Number(ephemeris.step) * 1000)
  );
  const dateAfterStep = new Date(
    startDate + (stepsFromStart + 1) * (Number(ephemeris.step) * 1000)
  );

  const diffBetweenBeforeAndCurrent = differenceInMilliseconds(
    new Date(currentDate),
    dateBeforeStep
  );

  const diffBetweenBeforeAndAfter = differenceInMilliseconds(
    dateAfterStep,
    dateBeforeStep
  );

  // percentageComplete will never hit 100% because of the way the decimal increments
  // so it needs to be forced to 100 so the useFrame in SVSatelliteInstance know to build the next path
  const percentageComplete =
    (diffBetweenBeforeAndCurrent / diffBetweenBeforeAndAfter) * 100 >= 99.16
      ? 100
      : (diffBetweenBeforeAndCurrent / diffBetweenBeforeAndAfter) * 100;

  const ephemBefore = ephemeris.data[Common.formattedEphemDate(dateBeforeStep)];
  const ephemAfter = ephemeris.data[Common.formattedEphemDate(dateAfterStep)];

  if (ephemBefore) {
    const ephemBeforeVector = ephemerisPointToVec(ephemBefore);
    const ephemAfterVector = ephemerisPointToVec(ephemAfter);

    return {
      position: ephemBeforeVector?.pos,
      positionAfter: ephemAfterVector?.pos,
      velocity: ephemBeforeVector?.vel,
      velocityAfter: ephemAfterVector?.vel,
      beforeTime: `${twoDigitTime(dateBeforeStep.getMinutes())}:${twoDigitTime(
        dateBeforeStep.getSeconds()
      )}`,
      currentTime: `${twoDigitTime(
        new Date(currentDate).getMinutes()
      )}:${twoDigitTime(new Date(currentDate).getSeconds())}`,
      percentageComplete: percentageComplete,
    };
  } else return null;
};
//any because satrec doesn't exist on sat data
const getTLEPropagation = (station: EventSat | any, date: Date) => {
  if (!station.line1 || !station.line2) return null;
  const satrec =
    station.satrec ?? satellite.twoline2satrec(station.line1, station.line2);

  const convertedPropagation = satellite.propagate(satrec, date);

  if (convertedPropagation.position) {
    convertedPropagation.position = toThreeVec(convertedPropagation.position);
  } else {
    console.error(
      `Satellite number ${satrec.satnum} is not currently orbiting`
    );
  }

  return convertedPropagation;
};

// * Requires a Date Object
// type: 1 ECEF coordinates   2: ECI coordinates
export const getPositionFromTle = (station: EventSat, date: Date, type = 2) => {
  if (!station || !date) return null;

  const positionVelocity = getTLEPropagation(station, date);

  //! In case object is no longer orbiting, error in TLE
  if (
    !positionVelocity ||
    !positionVelocity.position ||
    isEqual(positionVelocity, [false, false])
  )
    return null;

  const positionEci = positionVelocity.position;

  if (type === 2) return positionEci;

  const gmst = satellite.gstime(date);

  if (!positionEci) return null; // Ignore

  const positionEcf = satellite.eciToEcf(positionEci, gmst);

  return toThreeVec(positionEcf);
};

export const getPositionFromSV = (
  date: Date | string,
  ephemeris: Ephemeris,
  type = 2
) => {
  if (!ephemeris || !date) return null;

  const ephemData = getEphemerisPropagate(date, ephemeris);

  //! In case object is no longer orbiting, error in SV
  if (!ephemData || !ephemData.position || isEqual(ephemData, [false, false]))
    return null;

  if (type === 2) return ephemData;

  // new Date(date) looses milliseconds, which in turn a looses a level of precision
  const gmst = satellite.gstime(
    typeof date === "string" ? new Date(date) : date
  );

  const positionEcf = satellite.eciToEcf(ephemData.position, gmst);

  const positionEcfObject = { position: toThreeVec(positionEcf) };

  return Object.assign({}, ephemData, positionEcfObject);
};

export const calculateMeanMotion = (
  sat: EventStateVector,
  positionVec3 = new THREE.Vector3(),
  velocityVec3 = new THREE.Vector3()
) => {
  // Standard Gravitational Parameter
  const scientificNotationSuffix = 10 ** 5;
  const gravitationalParameter = 3.986004418 * scientificNotationSuffix;

  if (!sat) {
    console.error(
      "Satellite passed to calculateMeanMotion function is not valid satellite"
    );
    return;
  }

  // re-use vector instaed of creating new object every render
  positionVec3.set(sat.xpos, sat.ypos, sat.zpos);
  velocityVec3.set(sat.xvel, sat.yvel, sat.zvel);

  // get Euclidean Norm
  const positionEN = positionVec3.length();
  const velocityEN = velocityVec3.length();

  // semi-major axis
  // https://downloads.rene-schwarz.com/download/M002-Cartesian_State_Vectors_to_Keplerian_Orbit_Elements.pdf
  const semiMajorAxis =
    1 / (2 / positionEN - Math.pow(velocityEN, 2) / gravitationalParameter);

  // Orbit period
  // http://www.castor2.ca/05_OD/01_Gauss/14_Kepler/index.html
  const period =
    2 *
    Math.PI *
    Math.pow(Math.pow(semiMajorAxis, 3) / gravitationalParameter, 1 / 2);

  // Mean Motion
  // 86400 is how many seconds are in a day
  const meanMotion = (1 / period) * 86400;

  return meanMotion;
};
