import { Drawer } from "@mui/material";
import { Theme } from "@mui/material";

const styles = {
  paperProps: {
    height: "calc(100vh - 6rem - 1.25rem)",
    width: "31.875rem",
    marginTop: "calc(6rem - 1.25rem)",
    borderWidth: ({ astro }: Theme) => astro.border.width.xs,
    borderColor: ({ astro }: Theme) => astro.color.text.inverse,
    borderStyle: "solid",
    overflow: "auto",
    bottom: "0.625rem",
    top: "initial",
    boxShadow: "2px 4px 10px 5px rgba(0, 0, 0, 0.5)",
  },
  modalProps: {
    width: "31.875rem",
  },
};

type DrawerWrapperProps = {
  children: React.ReactNode;
  open: boolean;
};

const DrawerWrapper = ({ children, open }: DrawerWrapperProps) => {
  return (
    <Drawer
      open={open}
      variant="temporary"
      hideBackdrop={true}
      PaperProps={{ sx: styles.paperProps }}
      ModalProps={{ sx: styles.modalProps }}
    >
      {children}
    </Drawer>
  );
};

export default DrawerWrapper;
