import { useRef, useEffect, useCallback } from "react";
import { useThree } from "@react-three/fiber";
import { earthRadius } from "satellite.js/lib/constants";
import {
  PerspectiveCamera,
  OrbitControls,
  AdaptiveDpr,
  AdaptiveEvents,
} from "@react-three/drei";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import AmbientLighting from "./AmbientLighting";
import ZoomControls from "../dom/controls/ZoomControls/ZoomControls";
import CanvasSettings from "../dom/CanvasSettings/CanvasSettings";
import PlaybackControls from "../dom/controls/PlaybackControls/PlaybackControls";

const Setup = () => {
  const {
    orbitIsDragging,
    setOrbitIsDragging,
    maxDistanceFactor,
    minDistance,
  } = useCanvasContext();

  const { size, gl } = useThree();
  const NEAR = 0.001,
    FAR = earthRadius * maxDistanceFactor * 2.4;

  const orbitConRef = useRef<any>();
  const orbitIsDraggingRef = useRef(orbitIsDragging);

  const onOrbitDrag = useCallback(
    () =>
      orbitIsDraggingRef.current === false
        ? setOrbitIsDragging(true)
        : (orbitIsDraggingRef.current = true),
    [setOrbitIsDragging]
  );

  useEffect(() => {
    if (orbitConRef.current) {
      gl.domElement.addEventListener("pointerdown", () => {
        setOrbitIsDragging(false);
        orbitIsDraggingRef.current = false;
        gl.domElement.addEventListener("pointermove", onOrbitDrag);
      });

      gl.domElement.addEventListener("pointerup", () => {
        // Dispose of mouseMove eventlistener when not being used
        gl.domElement.removeEventListener("pointermove", onOrbitDrag);
        setOrbitIsDragging(false);
        orbitIsDraggingRef.current = false;
      });
    }
  }, [gl.domElement, onOrbitDrag, setOrbitIsDragging]);

  return (
    <>
      <OrbitControls
        makeDefault
        ref={orbitConRef}
        minDistance={earthRadius + minDistance}
        maxDistance={earthRadius * maxDistanceFactor}
        enablePan={false}
        zoomSpeed={0.4}
      />
      <PerspectiveCamera
        makeDefault
        fov={50}
        aspect={size.width / size.height}
        near={NEAR}
        far={FAR}
        position={[15000, 0, -15000]}
      />

      <AmbientLighting />

      <ZoomControls />
      <CanvasSettings />
      <PlaybackControls />

      <AdaptiveDpr pixelated />
      <AdaptiveEvents />
    </>
  );
};

export default Setup;
