import { useRef } from "react";
import { Instances, useGLTF, useBVH } from "@react-three/drei";
import THREE from "three";
import { useSatelliteContext } from "../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import TLESatelliteInstance from "./TLESatelliteInstance";
import SVSatelliteInstance from "./SVSatelliteInstance";
import { getEventDataByTargetSatNo } from "../../../redux/selectors/eventsSelector";
import { useSelector } from "react-redux";
// import useManualOrbit from "../../../utils/Hooks/eventTypeHooks/useManualOrbit";
// import ManualOrbitSatelliteInstance from "../events/ManualOrbit/ManualOrbitSatelliteInstance.js";

const Satellites = () => {
  const { threeSatellites, threeSatelliteIds, ephemerisBySVId } =
    useSatelliteContext();
  const { ghostTLEToggle } = useCanvasContext();
  const eventDataBySatNo = useSelector(getEventDataByTargetSatNo);

  const { nodes, materials } = useGLTF(
    process.env.PUBLIC_URL +
      "/satellite-model/low-poly-satellite-baked-light-with-hitbox-transformed.glb"
  );

  const nodesObj = nodes["baked-v2001"] as THREE.Mesh;
  // const { manualOrbitTarget } = useManualOrbit();
  const modelRef = useRef<any>();
  useBVH(modelRef);

  return (
    <group name="All Satellites">
      <Instances
        ref={modelRef}
        geometry={nodesObj.geometry}
        material={materials["Material_0.001"]}
        material-transparent={true}
      >
        {threeSatelliteIds.length
          ? threeSatellites.map((sat) => {
              if (sat.type === "SV" && sat.visualizationState === "hidden")
                return null;
              if (sat.type === "SV") {
                const ephemerisPoints = ephemerisBySVId[sat.id];
                const eventData =
                  eventDataBySatNo[sat.satNo as keyof typeof eventDataBySatNo];
                if (typeof eventData === "undefined") return;
                return (
                  <SVSatelliteInstance
                    key={sat.id}
                    satellite={sat}
                    ephemerisPoints={ephemerisPoints}
                    eventData={eventData}
                  />
                );
              }
              if (
                (sat.type === "TLE" && sat.visualizationState === "visible") ||
                ghostTLEToggle
              ) {
                return <TLESatelliteInstance key={sat.id} satellite={sat} />;
              }
            })
          : null}
        {/* {manualOrbitTarget &&
          // eslint-disable-next-line array-callback-return
          satObjects.current.map((sat, i) => {
            if (sat.stateId === manualOrbitTarget.noradCatId.toString()) {
              return (
                <ManualOrbitSatelliteInstance
                  key={i}
                  satellite={sat}
                  instanceScale={sizeAttenuation}
                />
              );
            }
          })} */}
      </Instances>
    </group>
  );
};

export default Satellites;
