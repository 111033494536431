import { createSlice } from "@reduxjs/toolkit";
import { currentScenarioStatusEnums as statusEnums } from "../../../../../utils/common";

const initialState = {
  status: statusEnums.initialState,
  isPosting: false,
  isFetching: false,
  error: null,
  details: {},
};

export const currentScenarioDetailsSlice = createSlice({
  name: "currentScenario/details",
  initialState,
  reducers: {
    fetchScenarioFail: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
    copyScenarioDetailsSuccess: (state, action) => {
      state.details = action.payload;
    },
    saveToTrogdorStart: (state) => {
      state.isPosting = true;
    },
    saveToTrogdorEnd: (state) => {
      state.isPosting = false;
    },
    updateStatus: (state, action) => {
      switch (action.payload) {
        case statusEnums.initialState:
          if (state.status !== statusEnums.fetching)
            state.status = statusEnums.initialState;
          break;
        case statusEnums.fetching:
          if (state.status === statusEnums.initialState)
            state.status = statusEnums.fetching;
          break;
        case statusEnums.fetched:
          if (state.status === statusEnums.fetching)
            state.status = statusEnums.fetched;
          break;
        case statusEnums.unsaved:
          if (state.status === statusEnums.fetched)
            state.status = statusEnums.unsaved;
          break;
      }
    },
    resetScenarioDetails: () => initialState,
  },
});

export const {
  fetchScenarioFail,
  copyScenarioDetailsSuccess,
  saveToTrogdorStart,
  saveToTrogdorEnd,
  updateStatus,
  resetScenarioDetails,
} = currentScenarioDetailsSlice.actions;
