import { useRef, useCallback, useEffect } from "react";
import * as THREE from "three";
import { Instance } from "@react-three/drei";
import { ThreeEvent, useFrame } from "@react-three/fiber";
import { useSatStateContext } from "../../../utils/Hooks/contextHooks/useSatStateContext";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import { useZoomContext } from "../../../utils/Hooks/contextHooks/useZoomContext";
import { getPositionFromTle } from "../../../utils/propagation";
import { useDateTimeContext } from "../../../utils/Hooks/contextHooks/useDateTimeContext";
import useManualOrbit from "../../../utils/Hooks/eventTypeHooks/useManualOrbit";
import {
  EventSat,
  SatPosition,
} from "../../../redux/rtk/types/ThreeJSCommonTypes";
import AltitudeLine from "./AltitudeLine";
import SatelliteLabel from "./SatelliteLabel";

type PropTypes = {
  center?: THREE.Vector3;
  satellite: EventSat;
};

const TLESatelliteInstance = ({
  center = new THREE.Vector3(0, 0, 0),
  satellite,
}: PropTypes) => {
  const {
    selectedSatIds,
    hoveredSatId,
    setHovered,
    addSelected,
    removeSelected,
    hoveredSelectedSatId,
    setHoveredSelected,
    clearHoveredSelected,
  } = useSatStateContext();
  const {
    orbitIsDragging,
    timelineIsScrubbing,
    altitudeLinesToggle,
    satLabelToggle,
    ghostTLEToggle,
  } = useCanvasContext();
  const { instanceScale } = useZoomContext();
  const { dateTime } = useDateTimeContext();
  const { manualOrbitTarget } = useManualOrbit();

  const pos = useRef(new THREE.Vector3());
  const storedPos = useRef(new THREE.Vector3());
  const initialPosSet = useRef(false);
  const instanceRef = useRef<any>();

  const satId = satellite.id;
  const isGhostSat = satellite.visualizationState === "hidden";
  const sameIdAsManualOrbitTarget =
    manualOrbitTarget?.noradCatId &&
    satId === manualOrbitTarget.noradCatId.toString();

  const isHovered = hoveredSatId === satId;
  const isSelected = selectedSatIds.includes(satId);
  const isHoveredActive = hoveredSelectedSatId === satId;

  // __ Internal ________________________________________________________________
  const updateIfDiff = useCallback(
    (position: SatPosition) => {
      pos.current.set(position.x, position.y, position.z);

      const isDiffCheck = !pos.current.equals(storedPos.current);
      if (isDiffCheck) {
        instanceRef.current.lookAt(center);
        storedPos.current.set(pos.current.x, pos.current.y, pos.current.z);
      }
    },
    [center]
  );

  // __ Instance ______________________________________________________
  useFrame(() => {
    if (!instanceRef.current || sameIdAsManualOrbitTarget) return;

    if (!initialPosSet.current) {
      initialPosSet.current = true;
    }
    const getPos = getPositionFromTle(satellite, dateTime);
    if (!getPos) return;

    updateIfDiff(getPos);
  });

  // __ Update Instance Color _______________________________________________________________________
  useEffect(() => {
    if (!instanceRef.current) return;

    const currentColor = instanceRef.current.color;
    const origColor = new THREE.Color("#fff0f0");
    const ghostColor = new THREE.Color("#054652");

    if (!ghostTLEToggle && !currentColor.equals(origColor)) {
      currentColor.set(origColor);
      return;
    }
    if (isGhostSat && !currentColor.equals(ghostColor)) {
      currentColor.set(ghostColor);
    } else if (!currentColor.equals(origColor)) {
      currentColor.set(origColor);
    }
  }, [instanceRef, isGhostSat, ghostTLEToggle]);

  // __ Instance Pointer Interaction ________________________________________________________________
  const onClickHandler = (e: ThreeEvent<MouseEvent>) => {
    if (isGhostSat) return;
    e.stopPropagation();

    if (selectedSatIds.includes(satId)) {
      removeSelected(satId);
      setHovered(null);
      setHoveredSelected(null);
    } else if (!selectedSatIds.includes(satId)) {
      addSelected(satId);
      setHovered(null);
      setHoveredSelected(satId);
    }
  };

  const onPointerOverHandler = (e: ThreeEvent<PointerEvent>) => {
    if (orbitIsDragging || timelineIsScrubbing || isGhostSat) return;
    e.stopPropagation();

    selectedSatIds.includes(satId)
      ? setHoveredSelected(satId)
      : setHovered(satId);
  };

  const onPointerOutHandler = (e: ThreeEvent<PointerEvent>) => {
    if (isGhostSat) return;
    e.stopPropagation();

    if (hoveredSelectedSatId) clearHoveredSelected();
    setHovered(null);
  };

  return (
    <group name={satId}>
      <Instance
        ref={instanceRef}
        onClick={(e) => onClickHandler(e)}
        onPointerOver={(e) => onPointerOverHandler(e)}
        onPointerOut={(e) => onPointerOutHandler(e)}
        position={[pos.current.x, pos.current.y, pos.current.z]}
        scale={instanceScale}
      >
        {initialPosSet.current && satLabelToggle ? (
          <SatelliteLabel
            isSelected={isSelected}
            isHoveredActive={isHoveredActive}
            isGhostSat={isGhostSat}
            satellite={satellite}
          />
        ) : null}
      </Instance>

      {(isSelected || isHovered) && altitudeLinesToggle ? (
        <AltitudeLine
          isSelected={isSelected}
          hasManualOrbit={sameIdAsManualOrbitTarget}
          satId={satId}
          satPos={pos.current}
        />
      ) : null}
    </group>
  );
};

export default TLESatelliteInstance;
