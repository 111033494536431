import {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useReducer,
  useMemo,
} from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  getTimelineEphemBySVId,
  getTimelineTLEs,
  getTimelineSVs,
} from "../../../redux/selectors/timelineSelector.js";

import { useDateTimeContext } from "./useDateTimeContext";
import { useSatStateContext } from "./useSatStateContext";
import { useDrawerContext } from "./useDrawerContext";
import useEventTimeline from "../useEventTimeline";
import {
  getEventDataByTargetSatNo,
  getEventDataByEventId,
} from "../../../redux/selectors/eventsSelector";
import { getObjByIdAndArrayOfIds } from "../../common";
import { SatTypes } from "../../../redux/rtk/types/trogdorApiTypes.js";
import { EventSat } from "../../../redux/rtk/types/ThreeJSCommonTypes.js";
import { Ephemeris } from "../../../redux/rtk/types/internalTypes.js";

type PropTypes = {
  setToCurrentSatellites: () => void;
  removeAllSatellites: () => void;
  threeSatellites: SatTypes[] | any[];
  setThreeSatellites: (satellites: any) => void;
  threeSatelliteIds: Array<string | number>;
  threeSatellitesById: { [id: string | number]: any };
  manualOrbitIds: Array<string | number>;
  setManualOrbitIds: React.Dispatch<React.SetStateAction<any>>;
  manualOrbitsById: object;
  setManualOrbitsById: React.Dispatch<React.SetStateAction<object>>;
  manualOrbitTarget: any;
  setManualOrbitTarget: React.Dispatch<React.SetStateAction<any>>;
  ephemerisBySVId: { [key: string | number]: Ephemeris };
  renderWindowPortal: any;
  setRenderWindowPortal: React.Dispatch<React.SetStateAction<any>>;
  sceneIsLoaded: boolean;
  setSceneIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  visibleSatIds: Array<string | number>;
  getVisibleSatByEventId: any;
};

const SatelliteContext = createContext<PropTypes>({
  setToCurrentSatellites: () => null,
  removeAllSatellites: () => null,
  threeSatellites: [],
  setThreeSatellites: () => null,
  threeSatelliteIds: [],
  threeSatellitesById: {},
  manualOrbitIds: [],
  setManualOrbitIds: () => null,
  manualOrbitsById: {},
  setManualOrbitsById: () => null,
  manualOrbitTarget: undefined,
  setManualOrbitTarget: () => null,
  ephemerisBySVId: {},
  renderWindowPortal: undefined,
  setRenderWindowPortal: () => null,
  sceneIsLoaded: false,
  setSceneIsLoaded: () => null,
  visibleSatIds: [],
  getVisibleSatByEventId: () => null,
});

export function useSatelliteContext() {
  return useContext(SatelliteContext);
}

const initialState = {
  threeSatellites: [],
  threeSatelliteIds: [],
  threeSatellitesById: {},
  visibleSatIds: [],
};

const SET_SATELLITES = "ADD_SELECTED";
const CLEAR_SATELLITES = "CLEAR_SELECTED";

//TODO empty arrays in initialState are assigned `never` regardless of type assignment. Find solution to remove `any`'s
const satellitesReducer = (
  state: {
    threeSatelliteIds: string[] | any;
    threeSatellites: SatTypes[] | any;
    threeSatellitesById: { [id: string | number]: SatTypes };
    visibleSatIds: string[];
  },
  action: { type: string; payload?: any[] }
) => {
  switch (action.type) {
    case SET_SATELLITES: {
      const prevSatsOmitSatrec = state.threeSatellites.map((sat: any) =>
        _.omit(sat, "satrec")
      );
      const currentSatsOmitSatrec = action.payload?.map((sat) =>
        _.omit(sat, "satrec")
      );

      if (_.isEqual(prevSatsOmitSatrec, currentSatsOmitSatrec)) return state;

      const organizedSatellites = getObjByIdAndArrayOfIds(action.payload);

      const visibleSats = action.payload?.filter(
        (sat) => sat.visualizationState === "visible"
      );
      const organizedVisibleSats = getObjByIdAndArrayOfIds(visibleSats);

      return {
        threeSatellites: action.payload,
        threeSatelliteIds: organizedSatellites.arrayOfIds,
        threeSatellitesById: organizedSatellites.objectById,
        visibleSatIds: organizedVisibleSats.arrayOfIds,
      };
    }

    case CLEAR_SATELLITES: {
      return initialState;
    }
  }
  return state;
};

export const SatelliteContextProvider = ({ children }: Children) => {
  const [
    { threeSatellites, threeSatelliteIds, threeSatellitesById, visibleSatIds },
    dispatch,
  ] = useReducer(satellitesReducer, initialState);

  const { dateTime } = useDateTimeContext();
  const {
    selectedSatIds,
    removeSelected,
    addSelected,
    clearHovered,
    clearSatState,
  } = useSatStateContext();
  const { addObjectOpen } = useDrawerContext();
  const ephemerisBySVId = useSelector(getTimelineEphemBySVId);
  const timelineTLEs = useSelector(getTimelineTLEs);
  const timelineSVs = useSelector(getTimelineSVs);
  const eventDataBySatNo: any = useSelector(getEventDataByTargetSatNo);
  const eventDataByEventId: any = useSelector(getEventDataByEventId);

  const { getCurrentSats } = useEventTimeline();

  const [sceneIsLoaded, setSceneIsLoaded] = useState(false);

  const [renderWindowPortal, setRenderWindowPortal] = useState();

  const [manualOrbitIds, setManualOrbitIds] = useState([]);
  const [manualOrbitsById, setManualOrbitsById] = useState({});
  const [manualOrbitTarget, setManualOrbitTarget] = useState();

  // __ SATELLITE SELECTED/HOVERED STATE _______________________________________________
  const setThreeSatellites = useCallback(
    (satellites: SatTypes[]) => {
      dispatch({
        type: SET_SATELLITES,
        payload: satellites,
      });
    },
    [dispatch]
  );

  const clearSatellites = useCallback(() => {
    dispatch({
      type: CLEAR_SATELLITES,
    });
  }, [dispatch]);

  const removeAllSatellites = useCallback(() => {
    clearSatState();
    clearSatellites();
  }, [clearSatState, clearSatellites]);

  const setToCurrentSatellites = useCallback(() => {
    clearSatState();
    setThreeSatellites(getCurrentSats());
  }, [clearSatState, getCurrentSats, setThreeSatellites]);

  const transitionOrbitStates = useCallback(
    (currentSats: SatTypes[]) => {
      return currentSats.map((sat) => {
        const eventData = eventDataBySatNo[sat.satNo];
        const eventSat: EventSat = sat as EventSat;

        if (selectedSatIds.includes(sat.id) && eventData) {
          if (sat.type === "TLE" && eventSat.visualizationState === "hidden") {
            if (eventData.eventType !== "Suppression") {
              removeSelected(sat.id);
              addSelected(eventData.svId);
              clearHovered();
            } else {
              // If Suppression event there is no eventData.svId to add
              removeSelected(sat.id);
              clearHovered();
            }
          }
          if (sat.type === "SV" && eventSat.visualizationState === "hidden") {
            removeSelected(sat.id);
            addSelected(eventData.tleId);
            clearHovered();
          }
        }
        return null;
      });
    },
    [eventDataBySatNo, selectedSatIds]
  );

  const getVisibleSatByEventId = useCallback(
    (eventId: number) => {
      const eventData = eventDataByEventId[eventId];
      if (typeof eventData === "undefined") return;

      const visibleSatId = visibleSatIds.find(
        (satId: number) =>
          satId === eventData?.svId || satId === eventData?.tleId
      );

      if (typeof visibleSatId === "undefined") return;

      return threeSatellitesById[visibleSatId];
    },
    [visibleSatIds, threeSatellitesById, eventDataByEventId]
  );

  // REBUILD SAT ARRAY CODE

  // Derive current 3D state from timeline
  useEffect(() => {
    if (addObjectOpen) return;
    const currentSats = getCurrentSats(dateTime, timelineTLEs, timelineSVs);
    transitionOrbitStates(currentSats);
    // Rebuild current sats
    if (!_.isEqual(threeSatellites, currentSats))
      setThreeSatellites(currentSats);
  }, [
    dateTime,
    getCurrentSats,
    location,
    timelineSVs,
    timelineTLEs,
    transitionOrbitStates,
  ]);

  const value = useMemo(
    () => ({
      setToCurrentSatellites,
      removeAllSatellites,
      threeSatellites,
      setThreeSatellites,
      threeSatelliteIds,
      threeSatellitesById,
      manualOrbitIds,
      setManualOrbitIds,
      manualOrbitsById,
      setManualOrbitsById,
      manualOrbitTarget,
      setManualOrbitTarget,
      ephemerisBySVId,
      renderWindowPortal,
      setRenderWindowPortal,
      sceneIsLoaded,
      setSceneIsLoaded,
      visibleSatIds,
      getVisibleSatByEventId,
    }),
    [
      sceneIsLoaded,
      threeSatellites,
      threeSatellitesById,
      ephemerisBySVId,
      getVisibleSatByEventId,
      renderWindowPortal,
    ]
  );

  return (
    <SatelliteContext.Provider value={value}>
      {children}
    </SatelliteContext.Provider>
  );
};
