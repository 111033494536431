import { GetSat } from "../../../redux/rtk/types/trogdorApiTypes";

export const formatUDLTLEResponse = (eventSatData: GetSat) => {
  const constructedName = `Sat No: ${eventSatData.satNo}`;
  return {
    ...eventSatData,
    name: constructedName,
    id: eventSatData.idElset,
    type: "TLE",
  };
};
