import { useAppDispatch } from "../../utils/Hooks/reduxHooks";
import { RuxDialog } from "@astrouxds/react";
import { deleteEvent } from "../../redux/thunks/eventThunks";
import { RuxDialogCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";

type EventForDeletionType = {
  id: string | number;
  scenarioId: number;
};

type PropTypes = {
  isOpen?: boolean;
  item: EventForDeletionType | null;
  resetDialog?: () => void;
};

const DeleteEventDialog = ({
  isOpen = false,
  item = null,
  resetDialog,
}: PropTypes) => {
  const dispatch = useAppDispatch();
  const { id } = item || {};
  const handleDialogClose = (e: RuxDialogCustomEvent<boolean | null>) => {
    if (e.detail)
      dispatch(deleteEvent(id)).then(() => {
        resetDialog?.();
      });
    else {
      resetDialog?.();
    }
  };

  return (
    <RuxDialog
      data-test="delete-event-dialog"
      open={isOpen}
      message="Please confirm you would like to delete this event"
      header="Delete Event Confirmation"
      onRuxdialogclosed={handleDialogClose}
    />
  );
};

export default DeleteEventDialog;
