import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetching: false,
  isPosting: false,
  error: null,
  events: {},
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    fetchEventStart: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    fetchEventSuccess: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.events[action.payload.id] = action.payload;
    },
    fetchEventFail: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
    fetchEventEqual: (state) => {
      state.isFetching = false;
    },
    createEventStart: (state) => {
      state.isPosting = true;
      state.error = null;
    },
    createEventSuccess: (state, action) => {
      state.isPosting = false;
      state.error = null;
      state.events[action.payload.id] = action.payload;
    },
    createEventFail: (state, action) => {
      state.isPosting = false;
      state.error = action.payload;
    },
    editEventStart: (state) => {
      state.isPosting = true;
      state.error = null;
    },
    editEventSuccess: (state, action) => {
      state.isPosting = false;
      state.events[action.payload.id] = action.payload;
    },
    editEventFail: (state, action) => {
      state.isPosting = false;
      state.error = action.payload;
    },
    deleteEventStart: (state) => {
      state.isPosting = true;
      state.error = null;
    },
    deleteEventSuccess: (state) => {
      state.isPosting = false;
    },
    deleteEventFail: (state, action) => {
      state.isPosting = false;
      state.error = action.payload;
    },
    createEventObjectStart: (state) => {
      state.isPosting = true;
      state.error = null;
    },
    createEventObjectSuccess: (state, action) => {
      state.isPosting = false;
      state.events[action.payload.eventId].eventObjects.push(
        action.payload.data
      );
    },
    createEventObjectFail: (state, action) => {
      state.isPosting = false;
      state.error = action.payload;
    },
    fetchEventParamsStart: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    fetchEventParamsSuccess: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.events[action.payload.id] = action.payload;
    },
    fetchEventParamsFail: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
    resetEvents: () => initialState,
  },
});

export const {
  fetchEventStart,
  fetchEventSuccess,
  fetchEventFail,
  fetchEventEqual,
  createEventStart,
  createEventSuccess,
  createEventFail,
  editEventStart,
  editEventSuccess,
  editEventFail,
  deleteEventStart,
  deleteEventSuccess,
  deleteEventFail,
  resetEvents,
  createEventObjectStart,
  createEventObjectSuccess,
  createEventObjectFail,
  fetchEventParamsStart,
  fetchEventParamsSuccess,
  fetchEventParamsFail,
} = eventsSlice.actions;
