// Hooks
import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utils/Hooks/reduxHooks";
import useForm from "../../../utils/Hooks/useForm";

// Redux
import { getCurrentScenario } from "../../../redux/selectors/scenarioSelector";
import {
  copyLiveEventToCurrentScenario,
  persistNewEvent,
  visualizeManeuverEvent,
} from "../../../redux/thunks/eventThunks";
import {
  getLiveEventDetails,
  getLiveEventParameters,
} from "../../../redux/selectors/liveEventSelector";

// Components
import Grid from "@mui/material/Grid";
import ParametersForm from "../EventTypeParameters/ParametersForm";
import { RuxInput } from "@astrouxds/react";
import EventTypeSelect from "../../Common/EventTypeSelect";
import EventProviderSelect from "../../Common/EventProviderSelect";
import UpdateVisualizationFooter from "./UpdateVisualizationFooter";
import PanelTitleBar from "../../Common/PanelTitleBar";
import JumpToTimeButton from "../../Common/JumpToTimeButton";
import type { EventFormValues } from "../../../redux/rtk/types/formTypes";

// Utilities
import Common, { haveParamValuesChanged } from "../../../utils/common";
import {
  validateEventStartDateTime,
  validateEventEndDateTime,
  validateRequiredField,
} from "../../../utils/formFieldValidation";
import {
  formatEventParametersForRedux,
  formatEventDetailsForRedux,
} from "../../../utils/Hooks/eventTypeHooks/reduxFormattingFunctions";
import _ from "lodash";

const styles = {
  inputStyles: {
    paddingInline: "1.25rem",
    marginBottom: "1.25rem",
  },
  formInputs: {
    flexGrow: 1,
    flewWrap: "nowrap",
  },
  date: {
    paddingInline: "1.25rem",
  },
  lowerFormInputs: {
    flexBasis: "calc(50% - 1.25rem)",
    marginBottom: "1.25rem",
    paddingLeft: "1.25rem",
  },
  eventAndProvider: {
    marginBottom: "1.25rem",
  },
  startTimeTargetIcon: {
    width: ".25rem",
    marginTop: "3.25rem",
  },
  endTimeTargetIcon: {
    width: ".25rem",
    marginTop: "2rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
  },
  startTime: {
    width: "11.1875rem",
    marginLeft: ".625rem",
    marginRight: "1.625rem",
  },
  endTime: {
    width: "11.1875rem",
    marginRight: "1.625rem",
  },
  dates: {
    width: "11.5rem",
  },
} as const;

export interface UseFormEventType extends ReturnType<typeof useForm> {
  values: Partial<EventFormValues>;
  errors: Partial<EventFormValues>;
}

type PropTypes = {
  closeCreateEvent: () => void;
};

const CreateEventPanel = ({ closeCreateEvent }: PropTypes) => {
  const dispatch = useAppDispatch();
  const currentScenario = useAppSelector(getCurrentScenario);
  const existingEventDetails = useAppSelector(getLiveEventDetails);
  const existingEventParameters = useAppSelector(getLiveEventParameters);

  const {
    values,
    errors,
    setInitialValues,
    handleChange,
    handleSubmit,
    getFormValues,
  }: UseFormEventType = useForm(() =>
    createNewEvent(values as EventFormValues)?.then(() => closeCreateEvent())
  );

  useEffect(() => {
    if (currentScenario) {
      const { dates, times } =
        Common.parseDatesAndTimesFromEntity(currentScenario);

      const formattedValues = {
        startDate: dates?.start,
        endDate: dates?.end,
        startTime: times?.start,
        endTime: times?.end,
        scenarioId: currentScenario.id,
      };

      setInitialValues(formattedValues);
    }
  }, [currentScenario]);

  // Event creation function
  const createNewEvent = (values: EventFormValues) => {
    const eventDetails = formatEventDetailsForRedux({
      ...values,
    });
    const eventParameters = formatEventParametersForRedux(values);
    const eventTypeId = Number(values.eventTypeId);

    const existingEventDetailsUpdated = _.omit(existingEventDetails, [
      "id",
      "editedEventId",
    ]);

    if (
      !_.isEqual(existingEventDetailsUpdated, eventDetails) &&
      haveParamValuesChanged(existingEventParameters, eventParameters) === true
    ) {
      switch (eventTypeId) {
        case 1:
          return dispatch(persistNewEvent(eventDetails, eventParameters));
        case 4:
        case 5:
        case 6:
          return dispatch(
            visualizeManeuverEvent(eventDetails, eventParameters)
          );
        default:
          console.log("Event Creation for event type not supported");
      }
    } else return dispatch(copyLiveEventToCurrentScenario());
  };

  return (
    <>
      <form
        style={styles.form}
        onSubmit={(e) => handleSubmit(e)}
        name="createEventForm"
        data-test="create-event-form"
      >
        <PanelTitleBar
          leftBtnText="Done"
          leftBtnType="submit"
          titleText="Create event"
          rightBtnType="button"
          rightBtnText="Cancel"
          rightBtnFunction={closeCreateEvent}
          postingStatus={false}
        />
        <Grid container item direction="column" sx={styles.formInputs}>
          <Grid container item direction="column" sx={styles.inputStyles}>
            <RuxInput
              required
              label="Event name"
              name="name"
              placeholder="Add event name"
              value={values.name}
              onRuxinput={(e) => handleChange(e, [validateRequiredField])}
              invalid={!!errors.name}
              errorText={errors.name}
              size="small"
              data-test="create-event-name"
            />
          </Grid>
          <Grid container spacing={2} item direction="row" sx={styles.date}>
            <Grid container item direction="row" sx={styles.lowerFormInputs}>
              <RuxInput
                size="small"
                type="date"
                required
                label="Start date"
                name="startDate"
                value={values.startDate}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateEventStartDateTime,
                    validateRequiredField,
                  ])
                }
                invalid={!!errors.startDate}
                errorText={errors.startDate}
                style={styles.dates}
                data-test="create-event-start-date"
              />
            </Grid>

            <Grid container item direction="row" sx={styles.lowerFormInputs}>
              <RuxInput
                size="small"
                type="time"
                required
                label="Start time"
                name="startTime"
                step="1"
                value={values.startTime}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateEventStartDateTime,
                    validateRequiredField,
                  ])
                }
                invalid={!!errors.startTime}
                errorText={errors.startTime}
                style={styles.startTime}
                data-test="create-event-start-time"
              />
            </Grid>
            <Grid sx={styles.startTimeTargetIcon}>
              <JumpToTimeButton
                date={values.startDate}
                time={values.startTime}
              />
            </Grid>
          </Grid>
          <Grid container item direction="row">
            <Grid container item direction="row" sx={styles.lowerFormInputs}>
              <RuxInput
                size="small"
                type="date"
                required
                label="End date"
                name="endDate"
                value={values.endDate}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateEventEndDateTime,
                    validateRequiredField,
                  ])
                }
                invalid={!!errors.endDate}
                errorText={errors.endDate}
                style={styles.dates}
                data-test="create-event-end-date"
              />
            </Grid>
            <Grid container item direction="row" sx={styles.lowerFormInputs}>
              <RuxInput
                size="small"
                type="time"
                required
                label="End time"
                name="endTime"
                step="1"
                value={values.endTime}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateEventEndDateTime,
                    validateRequiredField,
                  ])
                }
                invalid={!!errors.endTime}
                errorText={errors.endTime}
                style={styles.endTime}
                data-test="create-event-end-time"
              />
              <Grid sx={styles.endTimeTargetIcon}>
                <JumpToTimeButton date={values.endDate} time={values.endTime} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            sx={styles.eventAndProvider}
          >
            <EventTypeSelect
              eventTypeId={values.eventTypeId}
              handleChange={handleChange}
            />
            <EventProviderSelect
              values={values}
              handleChange={handleChange}
              setInitialValues={setInitialValues}
            />
          </Grid>
          <ParametersForm
            values={values as EventFormValues}
            handleChange={handleChange}
            setInitialValues={setInitialValues}
          />
          <UpdateVisualizationFooter getFormValues={getFormValues} />
        </Grid>
      </form>
    </>
  );
};

export default CreateEventPanel;
