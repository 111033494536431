import { useNavigate } from "react-router-dom";
import Common from "../../../../utils/common";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Status from "../../../Common/Rux/Status";
import ListDateTime from "../../../Common/Layout/LeftPanel/ListDateTime/ListDateTime";
import { RuxIcon } from "@astrouxds/react";
import { MouseEvent } from "react";
import { Scenario } from "../../../../redux/rtk/types/internalTypes";
import { RuxIndeterminateProgress } from "@astrouxds/react";
import { currentScenarioStatusEnums } from "../../../../utils/common";
import { getCurrentScenarioStatus } from "../../../../redux/selectors/scenarioSelector";
import { useAppSelector } from "../../../../utils/Hooks/reduxHooks";
import { useDrawerContext } from "../../../../utils/Hooks/contextHooks/useDrawerContext";
import { EnumType } from "../../../../redux/rtk/types/commonTypes";

type PropTypes = {
  scenario: Scenario;
  NumOfEvents: number;
  isCurrentScenario: boolean;
};

const ScenarioListItem = ({
  scenario,
  NumOfEvents,
  isCurrentScenario,
}: PropTypes) => {
  const navigate = useNavigate();
  const { openEditScenario } = useDrawerContext();
  const currentScenarioStatus = useAppSelector(getCurrentScenarioStatus);
  const normal = "normal";

  const { dates, times } = Common.parseDatesAndTimesFromEntity(scenario);

  const handleEditClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    openEditScenario(scenario.id);
  };

  const handleScenarioClick = () => navigate(`/scenarios/${scenario.id}`);

  const enumStatus: Readonly<{ [key: string]: EnumType }> =
    currentScenarioStatusEnums;

  const stillFetching = currentScenarioStatus !== enumStatus.initialState;

  return (
    <>
      <ListItemButton
        onClick={handleScenarioClick}
        data-test="scenario-item-button"
        id={`scenario-list-item-${scenario.id}`}
        disabled={stillFetching && !isCurrentScenario}
      >
        {stillFetching && isCurrentScenario ? (
          <RuxIndeterminateProgress
            style={{
              transform: "scale(0.4) translate(-1.9rem, 0)",
              width: "7px",
            }}
          />
        ) : (
          <Status
            status={normal}
            size="0.5rem"
            pt="0.375rem"
            alignSelf="flex-start"
            data-test="scenario-status"
          />
        )}
        <ListItemText
          data-test="scenario-name-id-events"
          primary={scenario.name}
          secondary={`ID#${scenario.id} \u2022 ${NumOfEvents} events`}
        />

        <ListDateTime date={dates.start} time={times.start} />

        <RuxIcon
          data-test="scenario-list-edit"
          icon="edit"
          size="extra-small"
          onClick={handleEditClick}
        />
      </ListItemButton>
    </>
  );
};

export default ScenarioListItem;
