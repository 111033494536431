import { Grid, Typography } from "@mui/material";
import { RuxButton } from "@astrouxds/react";
import { ListTypes } from "../../../../../types/utils";

const styles = {
  title: {
    marginTop: "5.8125rem",
  },
  addDescription: {
    marginTop: "0.625rem",
    marginBottom: "2rem",
    lineHeight: "23.44px",
  },
  ifSecondButton: {
    marginBlock: "0.625rem",
  },
  text: {
    color: "rgba(255, 255, 255, 0.6)",
  },
};

type EmptyListProps = {
  firstButtonOnClick: () => void;
  secondButtonOnClick?: () => void;
  text: string;
  title: string;
  firstButtonName: string;
  secondButtonName?: string;
  type?: ListTypes;
};

const EmptyListContent = (props: EmptyListProps) => {
  const {
    firstButtonOnClick,
    secondButtonOnClick,
    text,
    title,
    firstButtonName,
    secondButtonName,
    type,
  } = props;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      sx={styles.text}
      data-test="empty-list-component"
    >
      {type === "scenarios" ? (
        <>
          <Typography variant="h1" sx={styles.title}>
            No {title}
          </Typography>
          <Typography variant="h4" sx={styles.addDescription}>
            {text}
          </Typography>
          <RuxButton
            data-test="empty-list-first-button"
            onClick={firstButtonOnClick}
          >
            {firstButtonName}
          </RuxButton>
        </>
      ) : (
        <>
          <Typography variant="h1" sx={styles.title}>
            No {title}
          </Typography>
          <Typography variant="h4" sx={styles.addDescription}>
            {text}
          </Typography>
          <RuxButton
            onClick={firstButtonOnClick}
            data-test="empty-list-first-button"
          >
            {firstButtonName}
          </RuxButton>
          <Typography sx={styles.ifSecondButton} variant="h4">
            -or-
          </Typography>
          <RuxButton
            onClick={secondButtonOnClick}
            data-test="empty-list-second-button"
          >
            {secondButtonName}
          </RuxButton>
        </>
      )}
    </Grid>
  );
};
export default EmptyListContent;
