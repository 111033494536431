import { Popover, Typography, Box } from "@mui/material";
import { parseISO } from "date-fns";
import { isBetween } from "../../../utils/common";
import { useDateTimeContext } from "../../../utils/Hooks/contextHooks/useDateTimeContext";
import { useDialog } from "../../../utils/Hooks/useDialog";
import DeleteEventDialog from "../../Dialogs/DeleteEventDialog";
import { Event } from "../../../redux/rtk/types/internalTypes";

const styles = {
  kabobFontSX: {
    color: "#4dacff",
    cursor: "pointer",
    padding: 1,
    "&:hover": {
      color: "#92cbff",
      backgroundColor: "#142435",
    },
  },
  kebabBackground: {
    backgroundColor: "#101923",
    borderRadius: 1,
    border: 1,
    borderColor: "#2b659b",
  },
  popover: {
    position: "relative",
    "&::before": {
      backgroundColor: "#2B659B",
      content: '""',
      display: "block",
      position: "absolute",
      width: 12,
      height: 12,
      top: 14,
      left: -5,
      transform: "rotate(45deg)",
      zIndex: -1,
    },
  },
};

type PropTypes = {
  item: Event;
  id: string | undefined;
  open: boolean;
  anchorEl: ((e: Element) => Element) | null;
  onClose: (e: React.MouseEvent) => void;
};

const ListItemKabobMenu = ({
  item,
  id,
  open,
  anchorEl,
  onClose,
}: PropTypes) => {
  const { setDateTime } = useDateTimeContext();
  const { openDialog, WithCurrentDialog } = useDialog();

  const handleGoToStartTimeClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    onClose(e);
    setDateTime(new Date(item.startTime));
  };

  const handleGoToEventTriggerClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    const EventTrigger = parseISO(item.parameters?.parameters[2]?.paramValue);

    const EventTriggerValidator = isBetween(
      EventTrigger,
      new Date(item.startTime),
      new Date(item.endTime),
      "[]"
    );

    if (!EventTriggerValidator)
      console.warn("Event Trigger is not within this events start/end times");

    onClose(e);
    setDateTime(
      EventTriggerValidator ? EventTrigger : new Date(item.startTime)
    );
  };

  const handleGoToEndTimeClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose(e);
    setDateTime(new Date(item.endTime));
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    onClose(e);
    openDialog(<DeleteEventDialog item={item} />);
  };

  return WithCurrentDialog(
    <Popover
      data-test="kebab-menu-popover"
      className="popover"
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          borderRadius: 0,
          paddingLeft: "8px",
          marginLeft: "6px",
          marginTop: "-20px",
        },
      }}
    >
      <Box component="div" sx={styles.popover} />
      <Typography component={"div"} sx={styles.kebabBackground}>
        <Typography
          data-test="kebab-start-time"
          sx={styles.kabobFontSX}
          onClick={(e) => handleGoToStartTimeClick(e)}
        >
          Go to start time
        </Typography>
        <Typography
          data-test="kebab-trigger-time"
          sx={styles.kabobFontSX}
          onClick={(e) => handleGoToEventTriggerClick(e)}
        >
          Go to event trigger time
        </Typography>
        <Typography
          sx={styles.kabobFontSX}
          data-test="kebab-end-time"
          onClick={(e) => handleGoToEndTimeClick(e)}
        >
          Go to end time
        </Typography>
        <Typography
          sx={styles.kabobFontSX}
          data-test="kebab-delete-button"
          onClick={(e) => handleDeleteClick(e)}
        >
          Delete
        </Typography>
      </Typography>
    </Popover>
  );
};
export default ListItemKabobMenu;
