/* eslint-disable react/no-unknown-property */
import * as THREE from "three";
import { earthRadius } from "satellite.js/lib/constants";
import { MutableRefObject } from "react";
import { ThreeEvent } from "@react-three/fiber";

type PropTypes = {
  earthRef: MutableRefObject<any>;
  quality: string;
  segments: number;
  map: THREE.Texture;
  bumpMap: THREE.Texture;
  displacementMap?: THREE.Texture;
  displacementScale?: number | any; //any because of undefined and null conditional
  specularMap: THREE.Texture;
  color: THREE.Color | string;
};

const EarthTemplate = ({
  earthRef,
  quality,
  segments,
  map,
  bumpMap,
  displacementMap,
  displacementScale,
  specularMap,
  color,
}: PropTypes) => {
  const earthPointerEventsOcclusionHandler = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <mesh
      ref={earthRef}
      name={`Earth ${quality} Quality`}
      onPointerOver={(e) => earthPointerEventsOcclusionHandler(e)}
      onClick={(e) => earthPointerEventsOcclusionHandler(e)}
    >
      <sphereGeometry
        args={[earthRadius, segments, segments]}
        name={`Earth Geometry ${quality} Quality`}
      />
      <meshPhongMaterial
        name={`Earth Material ${quality} Quality`}
        map={map}
        shininess={8}
        bumpMap={bumpMap}
        bumpScale={earthRadius / 8}
        displacementMap={
          typeof displacementMap !== "undefined" ? displacementMap : null
        }
        displacementScale={
          typeof displacementScale !== "undefined" ? displacementScale : null
        }
        specularMap={specularMap}
        side={THREE.FrontSide}
        color={color}
      />
    </mesh>
  );
};

export default EarthTemplate;
