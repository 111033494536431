import { Navigate } from "react-router-dom";
import { isAuthenticatedUser } from "../../utils/authenticatedUser";

type PropTypes = {
  children: React.ReactElement;
};

export const PrivateRoute = ({ children }: PropTypes) => {
  const auth = isAuthenticatedUser();
  return auth ? children : <Navigate to="/login" />;
};
