import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Ruler from "@scena/react-ruler";
import { getMinutes, getHours, set, format } from "date-fns";

const styles = {
  ruler: {
    display: "flex",
    width: "30rem",
    height: "80%",
    overflow: "hidden",
    cursor: "pointer",
  },
};

type PropTypes = {
  displayDateTime: Date;
  handleDisplayDateTimeChange: (args: any) => void;
  setIsPaused: (args: boolean) => void;
  pauseButtonState: string;
  timelineIsScrubbing: boolean;
  setTimelineIsScrubbing: (args: boolean) => void;
};

const timeToRulerPosition = (date: Date) => {
  const timezoneOffset = date.getTimezoneOffset();
  const hours = getHours(date) * 60;
  const minutes = getMinutes(date);
  return hours + minutes + timezoneOffset - 240;
};

const TimeRuler = ({
  displayDateTime,
  handleDisplayDateTimeChange,
  setIsPaused,
  pauseButtonState,
  timelineIsScrubbing,
  setTimelineIsScrubbing,
}: PropTypes) => {
  const [rulerPosition, setRulerPosition] = useState(
    timeToRulerPosition(displayDateTime)
  );

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsPaused(true);
    setTimelineIsScrubbing(true);
    document.addEventListener("mouseup", stopRulerDrag);
    document.addEventListener("mousemove", dragRuler);
  };

  useEffect(() => {
    setRulerPosition(timeToRulerPosition(displayDateTime));
  }, [displayDateTime]);

  useEffect(() => {
    if (timelineIsScrubbing) {
      window.addEventListener("pointerup", () => {
        setTimelineIsScrubbing(false);
      });
    }
    return () => {
      window.removeEventListener("pointerup", () => {
        setTimelineIsScrubbing(false);
      });
    };
  }, [timelineIsScrubbing, setTimelineIsScrubbing]);

  const dragRuler = (e: MouseEvent) => {
    e.preventDefault();
    setRulerPosition((prevPosition) => prevPosition - e.movementX);
    handleDisplayDateTimeChange(-e.movementX);
  };

  const stopRulerDrag = () => {
    if (pauseButtonState === "playing") setIsPaused(false);
    document.removeEventListener("mouseup", stopRulerDrag);
    document.removeEventListener("mousemove", dragRuler);
  };

  const formatTimeStringForRuler = (time: number) => {
    let date = displayDateTime;
    date = set(date, {
      minutes: time % 60,
      hours: time / 60,
    });

    if (time % (60 * 24) === 0) {
      const includesDay = format(date, "MMM-d HH:mm");
      return includesDay;
    } else {
      const hoursAndMinutes = format(date, "HH:mm");
      return hoursAndMinutes;
    }
  };

  return (
    <Box component={"div"} onMouseDown={handleMouseDown}>
      <Ruler
        type="horizontal"
        height={30}
        backgroundColor={"transparent"}
        lineColor={"#4DACFF"}
        mainLineSize="57%"
        longLineSize="35%"
        shortLineSize="20%"
        unit={60}
        segment={4}
        font={"9px Roboto"}
        textAlign="center"
        textFormat={formatTimeStringForRuler}
        textOffset={[0, -3]}
        scrollPos={rulerPosition}
        negativeRuler={true}
        style={styles.ruler}
      />
    </Box>
  );
};

export default TimeRuler;
