import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { getPersistConfig } from "redux-deep-persist";

import rootReducer from "../redux/slices/rootReducer";
import { trogdorApi, acmeApi } from "../redux/rtk/api";

// Tells redux-persist what state not to persist
const persistedBlacklist: string[] = [
  // "currentScenario.postEventSVs.ephemByStateVectorId",
];

const persistConfig = getPersistConfig({
  key: "persistedStore",
  version: 6,
  storage,
  blacklist: persistedBlacklist,
  migrate: (state, version): any => {
    if (state && version > state._persist.version) {
      localStorage.removeItem("persist:persistedStore");
      return Promise.resolve(rootReducer);
    } else {
      return Promise.resolve(state);
    }
  },
  rootReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      // TODO: remove later, used to hide warning and make console easier to read
      immutableCheck: false,
    })
      .concat(trogdorApi.middleware)
      .concat(acmeApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
