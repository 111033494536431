import { useState } from "react";
import { Html } from "@react-three/drei";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import { useZoomContext } from "../../../utils/Hooks/contextHooks/useZoomContext";
import { Box } from "@mui/material";
import { SatTypes } from "../../../redux/rtk/types/trogdorApiTypes";
//Leave CSS file due to complex sat label styling
import "./SatelliteLabel.css";

type PropTypes = {
  isSelected: boolean;
  isHoveredActive: boolean;
  isGhostSat?: boolean;
  satellite: SatTypes | any; //any because of objectName on sat
  isStateVector?: boolean;
};

const SatelliteLabel = ({
  isSelected,
  isHoveredActive,
  isGhostSat = false,
  satellite,
  isStateVector = false,
}: PropTypes) => {
  const [isOccluded, setIsOccluded] = useState(false);
  const { earthRef, timelineIsScrubbing } = useCanvasContext();
  const { instanceScale } = useZoomContext();
  const occlusionHandler: (args: boolean) => any = (hide) => {
    setIsOccluded(hide);
  };

  return (
    <Html
      wrapperClass="sat-label-wrapper-class"
      className="sat-label-html"
      occlude={timelineIsScrubbing ? false : [earthRef as any]} //overlap error in types
      onOcclude={(hide) => occlusionHandler(hide)}
      style={{
        transition: "all 0.25s",
        opacity: isOccluded ? 0.4 : 1,
      }}
      transform
      sprite
      zIndexRange={[100, 0]}
      scale={instanceScale}
    >
      <Box
        component={"div"}
        className={`sat-circle ${isSelected ? "is-selected" : ""} ${
          isHoveredActive && isSelected ? "is-hovered-active" : ""
        } ${isStateVector ? "is-state-vector" : ""} ${
          isGhostSat ? "is-ghost-sat" : ""
        }`}
      >
        <Box component={"div"} className="sat-label">
          <Box component={"span"} className="sat-name-pointer"></Box>
          <Box component={"div"} className="sat-name">
            {satellite.objectName || `Sat No: ${satellite.satNo}`}
          </Box>
        </Box>
      </Box>
    </Html>
  );
};

export default SatelliteLabel;
