import { useAppSelector } from "../../../utils/Hooks/reduxHooks";
import { useSatelliteContext } from "../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useSatStateContext } from "../../../utils/Hooks/contextHooks/useSatStateContext";
import { getEventDataByTargetSatNo } from "../../../redux/selectors/eventsSelector";
import TLEOrbit from "./TLEOrbit";
import SVOrbit from "./SVOrbit";

const HoveredSats = () => {
  const { threeSatellitesById, ephemerisBySVId } = useSatelliteContext();
  const { selectedSatIds, hoveredSatId } = useSatStateContext();
  const eventDataBySatNo = useAppSelector(getEventDataByTargetSatNo);

  if (hoveredSatId && !selectedSatIds.includes(hoveredSatId.toString())) {
    const satelliteFromId = threeSatellitesById[hoveredSatId];
    const eventData =
      eventDataBySatNo[satelliteFromId.satNo as keyof typeof eventDataBySatNo];

    if (satelliteFromId.type === "SV") {
      const ephemerisPoints = ephemerisBySVId[satelliteFromId.id];
      return (
        <SVOrbit
          isHovered
          isSelected={false}
          satellite={satelliteFromId}
          ephemerisPoints={ephemerisPoints}
          eventData={eventData}
        />
      );
    }
    if (satelliteFromId.type === "TLE") {
      return (
        <TLEOrbit
          isHovered
          isSelected={false}
          satellite={satelliteFromId}
          eventData={eventData}
        />
      );
    }
  }
  return null;
};

export default HoveredSats;
