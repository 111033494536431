import _ from "lodash";
import { useEffect } from "react";
import { RuxInput, RuxSelect, RuxButton, RuxOption } from "@astrouxds/react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { AcmeTheme } from "../../../themes/acmeTheme";
import { useSatelliteContext } from "../../../utils/Hooks/contextHooks/useSatelliteContext";
import { RuxSelectCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";
import { EntityId } from "@reduxjs/toolkit";

const style = {
  searchFormWrapperSX: {
    backgroundColor: "var(--color-surface)",
    padding: "1.25rem",
    paddingTop: (theme: AcmeTheme) => theme.astro.spacing(3),
  },
  searchFormButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

const initialFormValues = {
  name: "",
  id: "",
  type: "",
  country: "",
  source: "st",
};

type PropTypes = {
  values: Partial<ObjectSearchFormValues>;
  handleChange: (e: CustomEvent) => void;
  handleSubmit: (e: React.FormEvent) => void;
  setInitialValues: (a: object) => void;
  setSearchedSatIds: React.Dispatch<React.SetStateAction<EntityId[] | null>>;
};

export type ObjectSearchFormValues = {
  source: string;
  name: string;
  country: string;
  id: string;
  type: string;
};

const ObjectSearchForm = ({
  values,
  handleChange,
  handleSubmit,
  setInitialValues,
  setSearchedSatIds,
}: PropTypes) => {
  const { setToCurrentSatellites } = useSatelliteContext();

  const handleResetSearch = () => {
    setSearchedSatIds(null);
    setToCurrentSatellites();
    setInitialValues(initialFormValues);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<any>) => {
    if (e.key === "13") {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    setInitialValues(initialFormValues);
  }, []);

  return (
    <Grid sx={style.searchFormWrapperSX} data-test="object-search-form">
      <form
        onSubmit={(e) => handleSubmit(e)}
        onKeyDown={onKeyDownHandler}
        data-test="add-object-form"
      >
        <Grid item sm={7} sx={{ mb: 1.5 }}>
          <RuxSelect
            required
            value={values.source}
            name="source"
            label="Data source"
            size="small"
            onRuxchange={(e: RuxSelectCustomEvent<void>) => handleChange(e)}
          >
            <RuxOption label="Space Track" value="st" />
          </RuxSelect>
        </Grid>
        <Box component={"div"} sx={{ mb: 1.5 }}>
          <Grid container spacing={3.5}>
            <Grid item sm={8}>
              <RuxInput
                data-test="name-input"
                name="name"
                value={values.name}
                label="Object name"
                type="text"
                size="small"
                placeholder="Enter Name"
                onRuxinput={(e) => handleChange(e)}
              ></RuxInput>
            </Grid>
            <Grid item sm={4}>
              <RuxInput
                name="id"
                value={values.id}
                label="Object ID"
                type="text"
                size="small"
                placeholder="Enter ID"
                onRuxinput={(e) => handleChange(e)}
                // disabled //TBD Remove when functional
              ></RuxInput>
            </Grid>
          </Grid>
        </Box>
        <Box component={"div"} sx={{ mb: 2 }}>
          <Grid container spacing={3.5}>
            <Grid item sm={5.5}>
              <RuxSelect
                name="type"
                value={values.type}
                label="Object type"
                size="small"
                onRuxchange={handleChange}
                disabled //TBD Remove when functional
              ></RuxSelect>
            </Grid>
            <Grid item sm={6.5}>
              <RuxSelect
                name="country"
                value={values.country}
                label="Object country"
                size="small"
                onRuxchange={handleChange}
                disabled //TBD Remove when functional
              ></RuxSelect>
            </Grid>
          </Grid>
        </Box>
        <Box component={"div"} sx={style.searchFormButtons}>
          <RuxButton
            data-test="reset-button"
            borderless
            type="button"
            disabled={_.isEqual(initialFormValues, values)}
            onClick={handleResetSearch}
          >
            Reset
          </RuxButton>
          <RuxButton
            type="submit"
            disabled={_.isEqual(initialFormValues, values)}
            data-test="search-button"
          >
            Search
          </RuxButton>
        </Box>
      </form>
    </Grid>
  );
};

export default ObjectSearchForm;
