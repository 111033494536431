import {
  CreateScenarioRequestParams,
  UpdateScenarioRequestParams,
  SpaceTrackFilterRequest,
  CreateEventRequestParams,
  EventParamsRequestIntersection,
  UpdateEventRequestParams,
  CreateEventParamRequestParams,
  CreateTLESatRequestBody,
  CreateSVSatRequestBody,
} from "../types/requestTypes";
import Common from "../../../utils/common";
import { ObjectSearchFormValues } from "../../../Components/Objects/AddObjectPanel/ObjectSearchForm";
import type { ScenarioFormValues } from "../types/formTypes";
import {
  Event,
  CreateEventParamValues,
  TLESat,
  SVSat,
} from "../types/internalTypes";
import { ParamValueResponse } from "../types/responseTypes";
import { eventTypeDefinitions } from "../../../utils/api/trogdor/eventTypeDefinitions";

export const formatCreateScenarioRequest = (
  scenarioData: Required<ScenarioFormValues>
): CreateScenarioRequestParams => {
  const { name, description, endDate, endTime, startTime, startDate } =
    scenarioData;
  const combinedStartTime =
    startDate && startTime && Common.convertToISOJSONDate(startDate, startTime);
  const combinedEndTime =
    endDate && endTime && Common.convertToISOJSONDate(endDate, endTime);
  return {
    scenario_name: name,
    scenario_active: false,
    scenario_start: Common.formatTrogdorDateTime(combinedStartTime),
    scenario_end: Common.formatTrogdorDateTime(combinedEndTime),
    scenario_desc: description,
    scenario_status: "Deactivated" as const,
  };
};

export const formatUpdateScenarioRequest = (
  scenarioData: ScenarioFormValues & { id: string }
): UpdateScenarioRequestParams => {
  const {
    name,
    active,
    description,
    endTime,
    startTime,
    startDate,
    endDate,
    id,
  } = scenarioData;
  const combinedStartTime =
    startDate && startTime && Common.convertToISOJSONDate(startDate, startTime);
  const combinedEndTime =
    endDate && endTime && Common.convertToISOJSONDate(endDate, endTime);

  return {
    scenario_id: Number(id),
    scenario_status: "Deactivated" as const,
    ...(name && { scenario_name: name }),
    ...(description && { scenario_description: description }),
    ...(active && { scenario_active: false }),
    ...(combinedStartTime && {
      scenario_start: Common.formatTrogdorDateTime(combinedStartTime),
    }),
    ...(combinedEndTime && {
      scenario_end: Common.formatTrogdorDateTime(combinedEndTime),
    }),
  };
};

export const formatGetFilteredSpaceTrackSatRequest = (
  searchFormValues: ObjectSearchFormValues
): SpaceTrackFilterRequest => {
  // Format form value data for API submission
  const filters: SpaceTrackFilterRequest = {};
  for (const [key, filterValue] of Object.entries(searchFormValues)) {
    if (!filterValue) continue;
    switch (key) {
      case "source":
        break;
      case "id":
        filters.id = Number(filterValue);
        break;
      default: {
        filters[key] = filterValue.toUpperCase();
      }
    }
  }
  return filters;
};

// Conditionally reformats data object for query param submission on POST
export const formatCreateEventRequest = (
  event: Event
): CreateEventRequestParams => {
  const { eventTypeId, scenarioId, startTime, endTime, name, provider } = event;

  const eventParams = eventParamRequestFormatFunction(
    eventTypeId,
    event.parameters.parameters
  );
  return {
    event_type:
      eventTypeDefinitions[eventTypeId as keyof typeof eventTypeDefinitions]
        .eventName,
    // hard coded event status value for new events
    event_status: "Queued",
    scenario_id: scenarioId,
    event_start: `${startTime}`,
    event_end: `${endTime}`,
    event_name: name,
    mitreMode: provider === "MITRE",
    // adds additional request formatting for specific event type parameters
    ...eventParams,
  };
};

export const eventParamRequestFormatFunction = (
  eventTypeId: number,
  paramsArray: ParamValueResponse[]
): EventParamsRequestIntersection => {
  const paramValuesByDefId: { [key: string]: string } = {};
  paramsArray.forEach((param) => {
    paramValuesByDefId[param.paramDefID] = param.paramValue;
  });
  const eventParamsByEventTypeId = {
    1: {
      satellite_id: paramValuesByDefId[1],
    },
    4: {
      change_amount: paramValuesByDefId[11],
      burn_time: `${paramValuesByDefId[14]}`,
      sat_number: paramValuesByDefId[13],
      time_of_flight: paramValuesByDefId[12],
    },
    5: {
      change_amount: paramValuesByDefId[5],
      burn_time: `${paramValuesByDefId[7]}`,
      sat_number: paramValuesByDefId[6],
      time_of_flight: paramValuesByDefId[105],
    },
    6: {
      change_amount: paramValuesByDefId[8],
      burn_time: `${paramValuesByDefId[10]}`,
      sat_number: paramValuesByDefId[9],
      time_of_flight: paramValuesByDefId[106],
    },
  };
  return eventParamsByEventTypeId[
    eventTypeId as keyof typeof eventParamsByEventTypeId
  ];
};

export const formatUpdateEventRequest = (
  event: Event
): UpdateEventRequestParams => {
  const { id, eventTypeId, scenarioId, startTime, endTime, name, provider } =
    event;

  const eventParams = eventParamRequestFormatFunction(
    eventTypeId,
    event.parameters.parameters
  );
  return {
    event_id: id,
    event_type:
      eventTypeDefinitions[eventTypeId as keyof typeof eventTypeDefinitions]
        .eventName,
    // hard coded event status value for new events
    event_status: "Queued",
    scenario_id: scenarioId,
    event_start: `${startTime}`,
    event_end: `${endTime}`,
    event_name: name,
    mitreMode: provider === "MITRE",
    // adds additional request formatting for specific event type parameters
    ...eventParams,
  };
};

export const formatCreateEventParameterRequest = (
  values: CreateEventParamValues
): CreateEventParamRequestParams => {
  return {
    event_id: values.eventId,
    param_def_id: values.paramDefID,
    param_value: values.paramValue,
  };
};

export const formatCreateTLESatRequest = (
  values: TLESat
): CreateTLESatRequestBody => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { eventId, id, name, type, visualizationState, ...requiredProps } =
    values;

  return {
    ...requiredProps,
  };
};

export const formatCreateSVSatRequest = (
  values: SVSat
): CreateSVSatRequestBody => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, eventId, type, visualizationState, ...requiredProps } = values;
  return { ...requiredProps };
};
