import {
  RuxButton,
  RuxTextarea,
  RuxInput,
  RuxSwitch,
  // RuxSelect,
  // RuxOption,
  //RuxSlider,
} from "@astrouxds/react";

import { Grid } from "@mui/material";
import JumpToTimeButton from "../../Common/JumpToTimeButton";
import { parameterFieldLookup } from "./parameterFieldsLookup";
import {
  RuxInputCustomEvent,
  RuxTextareaCustomEvent,
  RuxSelectCustomEvent,
  RuxSwitchCustomEvent,
} from "@astrouxds/astro-web-components/dist/types/components";
import {
  ParamFieldPropTypes,
  ParamDefNames,
} from "../../../redux/rtk/types/parameterTypes";

const styles = {
  targetIcon: {
    width: ".25rem",
    marginTop: "2rem",
    marginRight: "1.55rem",
    marginLeft: "-1.25rem",
  },
};

type HandleChangeArgs = (
  e:
    | RuxInputCustomEvent<void>
    | RuxTextareaCustomEvent<any>
    | RuxSelectCustomEvent<void>
    | RuxSwitchCustomEvent<any>
) => void;

export type BaseEventValues = {
  endDate: string;
  endTime: string;
  eventTypeId: string | number;
  name: string;
  providerId: string;
  startDate: string;
  startTime: string;
  scenarioId?: number | string;
  id?: number | string;
};

export type ParamEventValues = {
  [key in ParamDefNames]?: string;
};

export type EventValues = BaseEventValues & ParamEventValues;
type ParamFieldPropTypesWithKey = ParamFieldPropTypes & { key: number };

export const buildTextInput = (
  props: ParamFieldPropTypesWithKey,
  values: EventValues,
  handleChange: HandleChangeArgs
) => {
  const input = (
    <RuxInput
      className="parameter-input"
      label={props.label}
      placeholder={props.placeholder}
      name={props.name}
      value={values[props.name]}
      type="text"
      size="small"
      onRuxinput={(e) => handleChange(e)}
      key={`${props.key} - input`}
      data-param-def={props.key}
    />
  );
  return input;
};

export const buildNumberInput = (
  props: ParamFieldPropTypesWithKey,
  values: EventValues,
  handleChange: HandleChangeArgs
) => {
  const input = (
    <RuxInput
      className="parameter-input"
      // disabled={!values[props.name]}
      name={props.name}
      value={values[props.name]}
      type="number"
      size="small"
      label={props.label}
      placeholder={props.placeholder}
      min={props.min}
      max={props.max}
      step={props.step}
      key={`${props.key} - input`}
      onRuxinput={(e) => handleChange(e)}
      data-param-def={props.key}
    />
  );
  //TODO: add slider back in when scale and min max are provided by trogdor
  // const slider = (
  //   <RuxSlider
  //     className="parameter-slider"
  //     // disabled={!values[props.name]}
  //     value={values[props.name]}
  //     size="small"
  //     {...props}
  //     label={null}
  //     min={newMin}
  //     max={newMax}
  //     key={`${props.key} - slider`}
  //     onRuxinput={(e) => handleChange(e, null, () => handlerFunction(e))}
  //   />
  // );
  // return [input, slider];
  return [input];
};

export const buildDateInput = (
  props: ParamFieldPropTypesWithKey,
  values: EventValues,
  handleChange: HandleChangeArgs
) => {
  let min = null;
  const uniqueName = `${props.name}_date`;

  if (!values[uniqueName as ParamDefNames] && values["startDate"]) {
    values[uniqueName as ParamDefNames] = values["startDate"];
    min = values["startDate"];
  }
  const input = (
    <RuxInput
      className="parameter-input"
      type="date"
      name={uniqueName}
      label={`${props.label} date`}
      placeholder="00:00:00:000Z"
      key={`${props.key}-date`}
      value={values[uniqueName as ParamDefNames]}
      min={`${min}`}
      style={{ width: "11.75rem" }}
      size="small"
      onRuxinput={(e) => handleChange(e)}
      data-param-def={props.key}
    />
  );
  return input;
};

export const buildTimeInput = (
  props: ParamFieldPropTypesWithKey,
  values: EventValues,
  handleChange: HandleChangeArgs
) => {
  let min = null;
  const uniqueName = `${props.name}_time`;

  if (!values[uniqueName as ParamDefNames] && values["startTime"]) {
    values[uniqueName as ParamDefNames] = values["startTime"];
    min = values["startTime"];
  }

  if (props.name.includes("time")) {
    values[uniqueName as ParamDefNames] ==
      values[uniqueName as ParamDefNames]?.replace("Z", "");
  }

  const input = (
    <RuxInput
      className="parameter-input"
      type="time"
      name={uniqueName}
      label={`${props.label} time`}
      placeholder="YYY-DDD"
      step={"0.001"}
      key={`${props.key}-time`}
      value={values[uniqueName as ParamDefNames]}
      min={`${min}`}
      style={{ width: "10.75rem" }}
      size="small"
      onRuxinput={(e) => handleChange(e)}
      data-param-def={props.key}
    />
  );
  return input;
};
// ! not necessary right now since we have no select
// export const buildSelect = (props: ParamFieldPropTypes, values: BaseEventValues, handleChange: HandleChangeArgs) => {
//   const select = (
//     <RuxSelect
//       className="parameter-select"
//       {...props}
//       value={values[props.name]}
//       size="small"
//       onRuxchange={(e) => handleChange(e)}
//       data-param-def={props.key}
//     >
//       <RuxOption selected value="" label={props.placeholder}></RuxOption>
//       {props.options.split(",").map((option, index) => {
//         return <RuxOption key={index} value={option} label={option} />;
//       })}
//     </RuxSelect>
//   );
//   return select;
// };

export const buildSwitch = (
  props: ParamFieldPropTypesWithKey,
  values: EventValues,
  handleChange: HandleChangeArgs
) => (
  <RuxSwitch
    // {...props}
    name={props.name}
    value={values[props.name]}
    onRuxchange={(e) => handleChange(e)}
    data-param-def={props.key}
  ></RuxSwitch>
);
export const buildTextarea = (
  props: ParamFieldPropTypesWithKey,
  values: EventValues,
  handleChange: HandleChangeArgs
) => (
  <RuxTextarea
    // {...props}
    name={props.name}
    value={values[props.name]}
    size="small"
    onRuxchange={(e) => handleChange(e)}
    data-param-def={props.key}
  ></RuxTextarea>
);
export const buildButton = (
  props: ParamFieldPropTypesWithKey,
  handlerFunction: (e: React.MouseEvent) => void
) => (
  <RuxButton
    // {...props}
    icon-only
    size="small"
    icon="add-circle-outline"
    className="event-parameter-add-section-button"
    onClick={(e) => () => handlerFunction(e)}
    data-param-def={props.key}
  />
);

// Creates array of JSX inputs based on parameter definition ids
export const generateJSXInputsArray = (
  paramDefIds: number[] | undefined,
  values: EventValues,
  handleChange: () => void
) => {
  if (!paramDefIds) return;
  const arrayOfInputs: (JSX.Element | null)[] = [];
  paramDefIds.forEach((id) => {
    const matchedInput = matchInputTypeToParameter(id, values, handleChange);
    if (Array.isArray(matchedInput)) {
      matchedInput.forEach((input) => arrayOfInputs.push(input));
    } else {
      arrayOfInputs.push(matchedInput);
    }
  });
  return arrayOfInputs;
};

// Looks up form input data based on parameter definition and returns input jsx
const matchInputTypeToParameter = (
  parameterDefId: number,
  values: EventValues,
  handleChange: () => void
) => {
  if (!parameterFieldLookup[parameterDefId]) return null;
  const props = {
    ...parameterFieldLookup[parameterDefId],
    key: parameterDefId,
  };

  switch (props.type) {
    case "text":
      return buildTextInput(props, values, handleChange);
    case "number":
      return buildNumberInput(props, values, handleChange);
    case "datetime": {
      const dateInput = buildDateInput(props, values, handleChange);
      const timeInput = buildTimeInput(props, values, handleChange);
      return (
        <div className="datetime-container" key={parameterDefId}>
          {dateInput}
          {timeInput}
          <Grid sx={styles.targetIcon}>
            <JumpToTimeButton
              date={dateInput.props.value}
              time={timeInput.props.value}
            />
          </Grid>
        </div>
      );
    }
    // case "select":
    //   return buildSelect(props, values, handleChange);
    case "textarea":
      return buildTextarea(props, values, handleChange);
    case "switch":
      return buildSwitch(props, values, handleChange);
    case "button":
      return buildButton(props, handleChange);
    default:
      return null;
  }
};
