import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  isCommunicating: false,
  error: null,
  stateVectorsByEventId: {},
  ephemByStateVectorId: {},
};

export const postEventSVsSlice = createSlice({
  name: "currentScenario/postEventSVs",
  initialState,
  reducers: {
    fetchSVsStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    fetchSVsSuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      state.stateVectorsByEventId = action.payload;
    },
    copySVsSuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      state.stateVectorsByEventId[action.payload[0].eventId] = action.payload;
    },
    fetchSVsFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    fetchEphemeridesStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    fetchEphemeridesSuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      state.ephemByStateVectorId = action.payload;
    },
    fetchEphemeridesFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    fetchEphemStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    fetchEphemSuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      state.ephemByStateVectorId[action.payload.id] =
        action.payload.ephemerisPoints;
    },
    fetchEphemFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    addVisualizedSVs: (state, action) => {
      const eventId = action.payload.eventId;
      state.stateVectorsByEventId[eventId] = action.payload.stateVector;
    },
    //TODO: create removeEventSV (singular)
    removeEventSVsSuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      const eventId = action.payload;
      // Delete ephemeris
      if (!_.isEmpty(state.stateVectorsByEventId[eventId])) {
        state.stateVectorsByEventId[eventId].forEach((sv) => {
          delete state.ephemByStateVectorId[sv.idStateVector];
        });
        // Delete SV
        delete state.stateVectorsByEventId[eventId];
      }
    },
    createTrogdorSVStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    createTrogdorSVSuccess: (state) => {
      state.isCommunicating = false;
      state.error = null;
    },
    createTrogdorSVFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    resetSVSlice: () => initialState,
  },
});

export const {
  fetchSVsStart,
  fetchSVsSuccess,
  copySVsSuccess,
  fetchSVsFail,
  removeEventSVsSuccess,
  fetchEphemeridesStart,
  fetchEphemeridesSuccess,
  fetchEphemeridesFail,
  fetchEphemStart,
  fetchEphemSuccess,
  fetchEphemFail,
  addVisualizedSVs,
  createTrogdorSVStart,
  createTrogdorSVSuccess,
  createTrogdorSVFail,
  resetSVSlice,
} = postEventSVsSlice.actions;
