/* eslint-disable react/no-unknown-property */
import { useMemo } from "react";
import * as THREE from "three";
import { useTexture } from "@react-three/drei";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import { earthRadius } from "satellite.js/lib/constants";
import CustomShaderMaterial from "three-custom-shader-material";

const NightLights = () => {
  const { sunLightRef, nightLightsToggle } = useCanvasContext();
  const sunPos = sunLightRef?.current?.position;

  const [night, transparentTetxure] = useTexture([
    process.env.PUBLIC_URL + "/earth-assets/night-lights/8k_earth_nightmap.png",
    process.env.PUBLIC_URL +
      "/earth-assets/night-lights/transparent_texture.png",
  ]);

  const uniforms = useMemo(
    () => ({
      uDay: { value: transparentTetxure },
      uNight: { value: night },
      uLight: {
        value: sunPos,
      },
    }),
    [transparentTetxure, night, sunPos]
  );

  return (
    <>
      {nightLightsToggle && sunLightRef && (
        <mesh name="City Lights" scale={earthRadius / 4} rotation-y={Math.PI}>
          <icosahedronGeometry args={[4, 20]} />
          <CustomShaderMaterial
            baseMaterial={THREE.MeshLambertMaterial}
            vertexShader={`
                uniform vec3 uLight;
                varying vec2 vUv2;
                varying float vDist;
                float map(float value, float min1, float max1, float min2, float max2) {
                  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
                }
                float normalizeFunc(float v) { return map(v, -1.0, 3.0, 0.0, 3.4); }

                void main() {
                  vUv2 = uv;
                  vDist = clamp(pow(normalizeFunc(dot(normalize(uLight) * vec3(-1.0,-1.0,-1.0) , position) * 0.26), 0.9), 0.0, 1.0);
                }
              `}
            fragmentShader={`
                uniform sampler2D uDay;
                uniform sampler2D uNight;
                uniform vec3 uLight;
                varying vec2 vUv2;
                varying float vDist;

                void main() {
                  vec4 texDay = texture2D(uDay, vUv2);
                  vec4 texNight = texture2D(uNight, vUv2);
                  float c = vDist;
                  vec4 d = mix(texNight,texDay,vDist);
                  csm_DiffuseColor = d;
                }
              `}
            uniforms={uniforms}
            emissive={"#ffa812"}
            side={THREE.FrontSide}
            emissiveIntensity={20}
            emissiveMap={night}
            transparent={true}
            depthTest={false}
          />
        </mesh>
      )}
    </>
  );
};

export default NightLights;
