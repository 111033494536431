import TrogdorAxios from "./trogdorAxios";
import {
  formatEventRequest,
  formatSingleEventResponse,
  formatMultiEventParamResponse,
  formatSingleEventParamResponse,
  formatEventParamCreationRequest,
  formatCreatedEventResponse,
} from "./formattingFunctions";
import { EventDataForPost } from "../../../redux/rtk/types/trogdorApiTypes";
import { UpdateEventParamsRequest } from "../../../redux/rtk/types/requestTypes";
import { ParamValueResponse } from "../../../redux/rtk/types/responseTypes";

const eventURLs = {
  1: "/db/create_event",
  4: "/maneuver/change_altitude",
  5: "/maneuver/change_inclination",
  6: "/maneuver/change_period",
  7: "/maneuver/close_approach",
  // 12: "/maneuver/rpo",
  // 12: "/maneuver/rpo-scenario",
  13: "/breakup/breakup",
  14: "/breakup/shedding",
};

export const getEvent = (eventId: number) => {
  const params = { event_id: eventId };
  return TrogdorAxios.get("/db/get_event", params).then((response) =>
    formatSingleEventResponse(response)
  );
};

export const createEvent = (data: EventDataForPost, visualize = false) => {
  const params = formatEventRequest(data);
  if (visualize) params.forVisualization = true;
  const eventTypeID = data.eventTypeId;
  return TrogdorAxios.post(
    eventURLs[eventTypeID as keyof typeof eventURLs],
    null,
    params
  ).then((response) => {
    return formatCreatedEventResponse(
      response,
      //@ts-expect-error eventId does not exist on data
      data.eventId,
      visualize,
      data
    );
  });
};

export const editEvent = (data: EventDataForPost, eventId: number) => {
  const params = { ...formatEventRequest(data), event_id: eventId };
  return TrogdorAxios.post("/db/update_event", null, params).then((response) =>
    formatSingleEventResponse(response)
  );
};

export const deleteEvent = (eventId: number) => {
  const params = {
    event_id: eventId,
  };
  return TrogdorAxios.delete("/db/delete_event", null, params);
};

export const getEventParams = (eventId: number) => {
  const params = { event_id: eventId };
  return TrogdorAxios.get("/db/get_event_parameters", params).then((response) =>
    formatMultiEventParamResponse(response)
  );
};

export const createEventParam = (
  values: ParamValueResponse,
  eventId: number
) => {
  const params = formatEventParamCreationRequest(values, eventId);
  return TrogdorAxios.post("/db/create_event_parameter", null, params).then(
    (response) => formatSingleEventParamResponse(response, eventId)
  );
};

export const editEventParam = (
  paramValue: UpdateEventParamsRequest,
  eventParamId: number
) => {
  const params = {
    event_param_id: eventParamId,
    param_value: paramValue,
  };
  return TrogdorAxios.post("/db/update_event_parameter", params).then(
    (response) => formatMultiEventParamResponse(response)
  );
};

export const updateEventParam = (
  value: UpdateEventParamsRequest,
  paramId: number
) => {
  const params = {
    event_param_id: paramId,
    param_value: value,
  };
  return TrogdorAxios.post("/db/update_event_parameter", params);
};
