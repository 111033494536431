import { useState, useCallback, SyntheticEvent } from "react";
import { validateFormRequiredFieldsOnSubmit } from "../formFieldValidation";

const useForm = (submitCallback: (...args: any) => void) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const setInitialValues = useCallback((newValues: object) => {
    setValues((prevValues) => ({
      ...prevValues,
      ...newValues,
    }));
  }, []);

  const handleErrors = useCallback(
    (newErrorMessages: any) => {
      setErrors((errors) => ({
        ...errors,
        ...newErrorMessages,
      }));
      return { ...errors, ...newErrorMessages };
    },
    [errors]
  );

  // TODO: consider changing handleChange to use named arguments pattern
  const handleChange = useCallback(
    (
      event: CustomEvent,
      validationCallbacks?: {
        (e: CustomEvent<any>, values: any, errors: any): any;
      }[],
      eventHookCallback?: ((e?: any) => void) | null
    ) => {
      //removes error message text on change
      const eventTarget = event?.target as HTMLInputElement;
      const errorsObj: any = errors;
      if (errorsObj[eventTarget.name]) delete errorsObj[eventTarget.name];
      setValues((prevValues) => ({
        ...prevValues,
        [eventTarget.name]: eventTarget.value,
      }));
      validationCallbacks &&
        validationCallbacks.forEach((cb) => {
          handleErrors(cb(event, values, errorsObj));
        });
      eventHookCallback && eventHookCallback(eventTarget.value);
    },
    [errors, handleErrors, values]
  );

  const handleSubmit = (event: SyntheticEvent) => {
    if (event) event.preventDefault();
    // automatic required fields validation
    const eventTarget = event.target as HTMLFormElement;
    const errors = handleErrors(
      validateFormRequiredFieldsOnSubmit(eventTarget)
    );

    const errorValues = Object.values(errors);
    const hasNoErrors = errorValues.every((value) => value === null);
    if (hasNoErrors) submitCallback(values);
  };

  const getFormValues = useCallback(() => values, [values]);

  return {
    values,
    errors,
    setInitialValues,
    handleChange,
    handleSubmit,
    getFormValues,
  };
};

export default useForm;
