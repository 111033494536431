import { useState } from "react";
import { Html } from "@react-three/drei";
import { Box } from "@mui/material";
import { RuxCheckboxGroup, RuxCheckbox, RuxIcon } from "@astrouxds/react";
import { useSatelliteContext } from "../../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useCanvasContext } from "../../../../utils/Hooks/contextHooks/useCanvasContext";
import PerformanceTool from "../PerformanceTools/PerformanceTool";

const styles = {
  canvasSettingsHTML: {
    height: 0,
    width: 0,
    top: "auto",
    zIndex: 0,
    transform: "none",
    pointerEvents: "none",
    animationDelay: "1s",
  },
  canvasSettingsPanel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "8px",
  },
  canvasSettings: {
    display: "flex",
    flexDirection: "column",
  },
  settingsCheckbox: {
    pointerEvents: "auto",
    width: "max-content",
    marginTop: "15px",
    transition: "transform 0.5s ease-in-out",
  },
  icon: {
    position: "relative",
    zIndex: 1,
    pointerEvents: "auto",
    color: "var(--color-background-interactive-default, #4dacff)",
    cursor: "pointer",
    transition: " transform 0.5s ease-in-out",
  },
  canvasSettingsContainer: {
    display: "flex",
    bottom: 0,
    height: "calc(100vh - 6.25rem - 2.5rem)",
    overflow: "hidden",
  },
};

const CanvasSettings = () => {
  const { threeSatellites, renderWindowPortal } = useSatelliteContext();
  const {
    lightHelperToggle,
    setLightHelperToggle,
    realTimeSunToggle,
    setRealTimeSunToggle,
    lensflareToggle,
    setLensflareToggle,
    altitudeLinesToggle,
    setAltitudeLinesToggle,
    ephemHelperToggle,
    setEphemHelperToggle,
    satLabelToggle,
    setSatLabelToggle,
    nightLightsToggle,
    setNightLightsToggle,
    ghostTLEToggle,
    setGhostTLEToggle,
  } = useCanvasContext();

  const [settingsToggle, setSettingsToggle] = useState(false);

  const handleSettingsToggle = () => {
    setSettingsToggle(!settingsToggle);
  };

  const handleLightHelperToggle = () => {
    setLightHelperToggle(!lightHelperToggle);
  };

  const handleAltitudeLinesToggle = () => {
    setAltitudeLinesToggle(!altitudeLinesToggle);
  };

  const handleEphemHelperToggle = () => {
    setEphemHelperToggle(!ephemHelperToggle);
  };

  const handleRealTimeSunToggle = () => {
    setRealTimeSunToggle(!realTimeSunToggle);
  };

  const handleLensflareToggle = () => {
    setLensflareToggle(!lensflareToggle);
  };

  const handleSatLabelToggle = () => {
    setSatLabelToggle(!satLabelToggle);
  };

  const handleNightLightsToggle = () => {
    setNightLightsToggle(!nightLightsToggle);
  };

  const handleGhostTLEToggle = () => {
    setGhostTLEToggle(!ghostTLEToggle);
  };

  const canvasSetWrapperClassDiv = document.querySelector(
    ".canvas-settings-html"
  ) as HTMLDivElement;

  renderWindowPortal && canvasSetWrapperClassDiv !== null
    ? Object.assign(canvasSetWrapperClassDiv.style, styles.canvasSettingsHTML)
    : null;

  return (
    <Html
      as="Box"
      wrapperClass="canvas-settings-html"
      style={styles.canvasSettingsContainer}
      className="canvas-settings-container"
      portal={renderWindowPortal}
    >
      <Box component={"div"} sx={styles.canvasSettingsPanel}>
        <Box component={"div"} sx={styles.canvasSettings}>
          <RuxIcon
            style={styles.icon}
            size="small"
            icon="settings"
            onClick={() => handleSettingsToggle()}
          />
          <Box
            component={"div"}
            sx={styles.settingsCheckbox}
            style={
              settingsToggle
                ? { transform: "translateX(0px)" }
                : { transform: "translateX(-500px)" }
            }
          >
            <RuxCheckboxGroup>
              <RuxCheckbox
                value="LightHelperToggle"
                name="checkboxGroup"
                id="checkbox4c"
                checked={lightHelperToggle}
                onRuxchange={handleLightHelperToggle}
              >
                Light Helper
              </RuxCheckbox>
              <RuxCheckbox
                value="RealTimeSunToggle"
                name="checkboxGroup"
                id="checkbox4c"
                checked={realTimeSunToggle}
                onRuxchange={handleRealTimeSunToggle}
              >
                Real-Time Sun Position
              </RuxCheckbox>
              <RuxCheckbox
                value="LensflareToggle"
                name="checkboxGroup"
                id="checkbox4c"
                checked={lensflareToggle}
                onRuxchange={handleLensflareToggle}
              >
                Lensflare
              </RuxCheckbox>
              <RuxCheckbox
                value="NightLightsToggle"
                name="checkboxGroup"
                id="checkbox4c"
                checked={nightLightsToggle}
                onRuxchange={handleNightLightsToggle}
              >
                City LIghts Texture
              </RuxCheckbox>
              <RuxCheckbox
                value="SatLabelToggle"
                name="checkboxGroup"
                id="checkbox4c"
                checked={satLabelToggle}
                onRuxchange={handleSatLabelToggle}
              >
                Satalite Labels
              </RuxCheckbox>
              <RuxCheckbox
                value="GhostTLEToggle"
                name="checkboxGroup"
                id="checkbox4c"
                checked={ghostTLEToggle}
                onRuxchange={handleGhostTLEToggle}
              >
                Ghost TLE Visible
              </RuxCheckbox>
              <RuxCheckbox
                value="AltitudeLinesToggle"
                name="checkboxGroup"
                id="checkbox4c"
                checked={altitudeLinesToggle}
                onRuxchange={handleAltitudeLinesToggle}
                disabled={!threeSatellites.length}
              >
                Altitude Lines
              </RuxCheckbox>
              <RuxCheckbox
                value="EphemHelperToggle"
                name="checkboxGroup"
                id="checkbox4c"
                checked={ephemHelperToggle}
                onRuxchange={handleEphemHelperToggle}
              >
                State Vector Interpolation Helper
              </RuxCheckbox>
            </RuxCheckboxGroup>
          </Box>
        </Box>
        <PerformanceTool />
      </Box>
    </Html>
  );
};

export default CanvasSettings;
