import { Html } from "@react-three/drei";
import { Box } from "@mui/material";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import { useZoomContext } from "../../../utils/Hooks/contextHooks/useZoomContext";
import { ReactNode } from "react";

type PropTypes = {
  date: ReactNode;
  isSatellite?: boolean;
};

const SVPointHelper = ({ date, isSatellite = false }: PropTypes) => {
  const { instanceScale } = useZoomContext();
  const { timelineIsScrubbing } = useCanvasContext();

  const styles = {
    pointHelperLabel: {
      pointerEvents: "none",
      userSelect: "none",
      height: 0,
      width: 0,
    },
    pointHelperNamePointer: {
      position: "absolute",
      zIndex: 1,
      display: "block",
      border: "solid #0177fe",
      borderWidth: "0 15px 15px 0",
      padding: "15px",
      transform: "translate(-50%, calc(-50% + 75px)) rotate(-135deg)",
      ...(isSatellite && {
        borderColor: "red",
        transform: "translate(-50%, calc(-50% - 175px)) rotate(45deg)",
      }),
    },
    pointHelperName: {
      whiteSpace: "nowrap",
      transform: "translate(-50%, calc(-50% + 120px))",
      width: "max-content",
      fontSize: "42px",
      color: "#0177fe",
      ...(isSatellite && {
        color: "red",
        transform: "translate(-50%, calc(-50% - 220px))",
      }),
    },
  };

  return (
    <Html
      transform
      sprite
      zIndexRange={[100, 0]}
      scale={instanceScale}
      style={{
        visibility: timelineIsScrubbing ? "hidden" : "visible",
        pointerEvents: "none",
        userSelect: "none",
        height: 0,
        width: 0,
      }}
    >
      <Box component={"span"} sx={styles.pointHelperNamePointer} />
      <Box component={"div"} sx={styles.pointHelperName}>
        {date}
      </Box>
    </Html>
  );
};

export default SVPointHelper;
