import store from "./store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { AstroThemeProvider } from "astro-mui-theme";
import { acmeTheme } from "../themes/acmeTheme";
import { DrawerContextProvider } from "../utils/Hooks/contextHooks/useDrawerContext";

export const persistor = persistStore(store);

export const AppProvider = ({ children }: Children) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StyledEngineProvider injectFirst>
        <AstroThemeProvider theme={acmeTheme}>
          <Router>
            <DrawerContextProvider>{children}</DrawerContextProvider>
          </Router>
        </AstroThemeProvider>
      </StyledEngineProvider>
    </PersistGate>
  </Provider>
);
