import { createSelector } from "@reduxjs/toolkit";

import {
  getTimelineEventIds,
  getTimelineEventsById,
  getTimelineSVsByEventId,
  getTimelineTLEsByEventId,
} from "./timelineSelector";
import { parseISO } from "date-fns";
import {
  findEventTypeIdByName,
  findEventParamValueByDefId,
} from "../../utils/common";
import { eventTypeDefinitions } from "../../utils/api/trogdor/eventTypeDefinitions";

export const getEventData = createSelector(
  getTimelineEventIds,
  getTimelineEventsById,
  getTimelineSVsByEventId,
  getTimelineTLEsByEventId,
  (
    timelineEventIds,
    timelineEventsById,
    timelineSVsByEventId,
    timelineTLEsByEventId
  ) => {
    return timelineEventIds.map((id) => {
      const event = timelineEventsById[id];
      const eventTypeId = findEventTypeIdByName(event.eventType);
      const triggerValue = findEventParamValueByDefId(
        event,
        eventTypeDefinitions[eventTypeId].trigger
      );
      const targetSatNo = findEventParamValueByDefId(
        event,
        eventTypeDefinitions[eventTypeId].target
      );

      const targetTLE = timelineTLEsByEventId[id]?.find(
        (sat) => sat.satNo?.toString() === targetSatNo
      );
      const targetSV = timelineSVsByEventId[id]?.find(
        (sat) => sat.satNo?.toString() === targetSatNo
      );

      return {
        eventId: event.id,
        name: event.name,
        eventType: event.eventType,
        scenarioId: event.scenarioId,
        startTime: parseISO(event.startTime),
        eventTrigger: parseISO(triggerValue),
        endTime: parseISO(event.endTime),
        satNo: targetSatNo,
        tleId: targetTLE ? targetTLE.id : null,
        svId: targetSV ? targetSV.id : null,
      };
    });
  }
);

export const getEventDataByTargetSatNo = createSelector(
  getEventData,
  (eventData) => {
    const eventDataBySatNo = {};
    eventData.forEach(
      (eventDataObj) => (eventDataBySatNo[eventDataObj.satNo] = eventDataObj)
    );
    return eventDataBySatNo;
  }
);

export const getEventDataByEventId = createSelector(
  getEventData,
  (eventData) => {
    const eventDataByEventId = {};
    eventData.forEach(
      (eventDataObj) =>
        (eventDataByEventId[eventDataObj.eventId] = eventDataObj)
    );
    return eventDataByEventId;
  }
);
