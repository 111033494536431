import { useRef, useState, useEffect, useMemo } from "react";
import * as THREE from "three";
import { Line } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { isAfter, isBefore, subMinutes, addMinutes } from "date-fns";
import { isBetween } from "../../../utils/common";
import { getPositionFromTle } from "../../../utils/propagation";
import { useSatelliteContext } from "../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useSatStateContext } from "../../../utils/Hooks/contextHooks/useSatStateContext";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import { useDateTimeContext } from "../../../utils/Hooks/contextHooks/useDateTimeContext";
import SVGhostOrbit from "./SVGhostOrbit";
import {
  EventSat,
  EventDataForObjects,
} from "../../../redux/rtk/types/ThreeJSCommonTypes";

type PropTypes = {
  isSelected: boolean;
  isHovered: boolean;
  hasManualOrbit?: boolean;
  satellite: EventSat;
  eventData: EventDataForObjects;
};

const TLEOrbit = ({
  isSelected,
  isHovered,
  hasManualOrbit,
  satellite,
  eventData,
}: PropTypes) => {
  const { threeSatellitesById } = useSatelliteContext();
  const {
    removeSelected,
    hoveredSelectedSatId,
    setHoveredSelected,
    clearHoveredSelected,
  } = useSatStateContext();
  const { orbitIsDragging, timelineIsScrubbing } = useCanvasContext();
  const { dateTime } = useDateTimeContext();

  const [startDate, setStartDate] = useState<Date>(dateTime);
  const [endDate, setEndDate] = useState<Date>();
  const [TLEOrbitPointsArr, setTLEOrbitPointsArr] = useState<THREE.Vector3[]>(
    []
  );
  const [TLEGhostOrbitPointsArr, setTLEGhostOrbitPointsArr] = useState<
    THREE.Vector3[]
  >([]);

  const lineSegmentInterval = useRef(isHovered ? 2 : 1);
  const ghostPathInterval = useRef(0);
  const revsPerDay = useMemo(() => satellite.meanMotion, [satellite]);
  const minutesPerRev = useMemo(() => 1440 / revsPerDay, [revsPerDay]);

  const satId = satellite.id;
  const isSuppressed = eventData?.eventType === "Suppression";
  const lastOrbitOfTLEStart = eventData
    ? subMinutes(eventData.eventTrigger, minutesPerRev)
    : null;
  const isHoveredActive = hoveredSelectedSatId === satId;

  // __ Create Path ______________________________________________
  useEffect(() => {
    const TLEOrbitPoints = [];
    const TLEGhostOrbitPoints = [];

    for (let i = 0; i <= minutesPerRev; i += lineSegmentInterval.current) {
      const date = addMinutes(startDate, i);
      const getPos = getPositionFromTle(satellite, date);

      if (!getPos) return;
      const thisDateIsLastTLEOrbit = eventData
        ? isAfter(date, eventData.eventTrigger)
        : false;

      thisDateIsLastTLEOrbit
        ? TLEGhostOrbitPoints.push(getPos)
        : TLEOrbitPoints.push(getPos);

      if (i + lineSegmentInterval.current >= minutesPerRev) {
        setEndDate(date);
      }
    }

    setTLEOrbitPointsArr(TLEOrbitPoints);
    setTLEGhostOrbitPointsArr(TLEGhostOrbitPoints);
  }, [startDate, satId]);

  // __ Update Path (if theres no ghost orbit) __________________________
  useFrame(() => {
    if (typeof endDate === "undefined" || isLastTLEOrbit) return;
    let timeReset = false;
    // set orbit only once per revolution
    const timeIsBefore = isBefore(dateTime, startDate);
    const timeIsAfter = isAfter(dateTime, endDate);

    if (timeIsBefore && !timeReset) {
      const startOfPreviousOrbitDate = subMinutes(startDate, minutesPerRev);
      const isTimeJump = isAfter(startOfPreviousOrbitDate, dateTime);

      if (isTimeJump) {
        setStartDate(dateTime);
        timeReset = true;
        return;
      }
      setStartDate(startOfPreviousOrbitDate);
      timeReset = true;
    } else if (!timeIsBefore && timeReset) {
      timeReset = false;
    }

    if (timeIsAfter && !timeReset) {
      const startOfNextOrbitDate = addMinutes(endDate, minutesPerRev);
      const isTimeJump = isBefore(startOfNextOrbitDate, dateTime);
      if (isTimeJump) {
        setStartDate(dateTime);
        timeReset = true;
        return;
      }
      setStartDate(endDate);
      timeReset = true;
    } else if (!timeIsAfter && timeReset) {
      timeReset = false;
    }
  });

  // __ Update Ghost Orbit Path _________________________________
  useEffect(() => {
    if (!timelineIsScrubbing) return;
    if (isLastTLEOrbit && ghostPathInterval.current % 2) {
      setStartDate(dateTime);
    }
    ghostPathInterval.current++;
  }, [dateTime, timelineIsScrubbing]);

  // __ Internal ____________________________________________
  const TLEColorHandler = useMemo(
    () => (isHovered ? "#008FFF" : hasManualOrbit ? "grey" : "#12f3ff"),
    [isHovered, hasManualOrbit]
  );

  const isLastTLEOrbit = useMemo(() => {
    if (!eventData || !lastOrbitOfTLEStart) return false;

    return isBetween(dateTime, lastOrbitOfTLEStart, eventData.eventTrigger);
  }, [dateTime, eventData]);

  // _ Remove Selected by clicking on orbit _________________________
  const onClickHandler = () => {
    if (isSelected && !hasManualOrbit) {
      removeSelected(satId);
      clearHoveredSelected();
    }
  };

  const onPointerOverHandler = () => {
    if (orbitIsDragging) return;
    if (isSelected && !hasManualOrbit) {
      setHoveredSelected(satId);
    }
  };

  const onPointerOutHandler = () => {
    if (isSelected && !hasManualOrbit) {
      clearHoveredSelected();
    }
  };

  return (
    <>
      {TLEOrbitPointsArr.length ? (
        <>
          {/* This first Line is not visible but acts as a hitbox for the visible line */}
          <Line
            name={"TLEOrbit Hitbox"}
            onClick={onClickHandler}
            onPointerOver={onPointerOverHandler}
            onPointerOut={onPointerOutHandler}
            linewidth={30}
            points={TLEOrbitPointsArr}
            visible={false}
            depthFunc={THREE.LessDepth}
          />
          <Line
            name={"TLEOrbit"}
            points={TLEOrbitPointsArr}
            color={TLEColorHandler}
            opacity={isHovered || hasManualOrbit ? 0.6 : 1.0}
            transparent
            linewidth={isHoveredActive ? 5 : 3} // px
            blending={isHovered ? THREE.AdditiveBlending : THREE.NormalBlending}
            dashed={isHovered || hasManualOrbit}
            depthFunc={THREE.LessDepth}
            dashSize={300}
            gapSize={150}
            dashOffset={40}
          />

          {TLEGhostOrbitPointsArr?.length &&
          !isSuppressed &&
          threeSatellitesById[eventData?.svId] ? (
            <SVGhostOrbit
              isSelected={isSelected}
              isHovered={isHovered}
              isHoveredActive={isHoveredActive}
              eventData={eventData}
              satellite={threeSatellitesById[eventData.svId]}
              endOfRev={endDate}
              eventStart={eventData.eventTrigger}
              TLEGhostOrbitPoints={TLEGhostOrbitPointsArr}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default TLEOrbit;
