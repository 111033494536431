// React Libraries
import { useEffect } from "react";
import { useAppSelector } from "../../../utils/Hooks/reduxHooks";
// Redux & Utilties
import { getScenarioById } from "../../../redux/rtk/endpoints/scenario";
import useForm from "../../../utils/Hooks/useForm";
import { useUpdateScenarioMutation } from "../../../redux/rtk/endpoints/scenario";
import {
  validateRequiredField,
  validateScenarioStartDateTime,
  validateScenarioEndDateTime,
} from "../../../utils/formFieldValidation";
// Component Libraries and Styling
import { RuxTextarea, RuxInput } from "@astrouxds/react";
import PanelTitleBar from "../../Common/PanelTitleBar";
import Grid from "@mui/material/Grid";

// Types
import type { Scenario } from "../../../redux/rtk/types/internalTypes";
import type { ScenarioFormValues } from "../../../redux/rtk/types/formTypes";

const styles = {
  createBtn: {
    margin: "1.25rem, auto",
  },
  scenarioWrapper: {
    paddingInline: "1.25rem",
    marginBottom: "1rem",
  },
  descriptionBox: {
    paddingInline: "1.25rem",
    marginBottom: "2.5rem",
  },
  dates: {
    width: "15.6875rem",
    marginRight: "1.875rem",
  },
};

interface useFormScenarioType extends ReturnType<typeof useForm> {
  values: Partial<ScenarioFormValues>;
  errors: Partial<ScenarioFormValues>;
}

type EditScenarioPanelProps = {
  onClose: () => void;
  scenarioId?: number;
};

const EditScenarioPanel = ({ onClose, scenarioId }: EditScenarioPanelProps) => {
  const scenario = useAppSelector((state) =>
    getScenarioById(state, `${scenarioId}`)
  );

  const {
    values,
    errors,
    setInitialValues,
    handleChange,
    handleSubmit,
  }: useFormScenarioType = useForm(editCurrentScenario);

  const [updateScenario, { isLoading: isUpdating }] =
    useUpdateScenarioMutation();

  function editCurrentScenario() {
    if (!scenarioId) return;
    updateScenario({ id: scenarioId, ...values })
      .unwrap()
      .then(() => onClose())
      .catch((error: any) => console.log("error", error));
  }

  const formatValuesForInitialFormState = (
    scenario: Scenario
  ): ScenarioFormValues => {
    const { name, description, startTime, endTime, active } = scenario;
    const formattedValues = {
      name,
      description,
      startDate: startTime.substring(0, 10),
      endDate: endTime.substring(0, 10),
      startTime: startTime.substring(11, 19),
      endTime: endTime.substring(11, 19),
      active,
    };
    return formattedValues;
  };

  //Use Effects
  useEffect(() => {
    if (!scenario) return;
    const initialFormValues = formatValuesForInitialFormState(scenario);
    if (scenario) setInitialValues(initialFormValues);
  }, [scenario]);

  return (
    <>
      <form
        onSubmit={(e) => handleSubmit(e)}
        name="createScenario"
        data-test="edit-scenario-form"
      >
        <PanelTitleBar
          leftBtnText="Done"
          leftBtnType="submit"
          titleText="Scenario details"
          rightBtnText="Cancel"
          rightBtnFunction={onClose}
          postingStatus={isUpdating}
        />
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid container item direction="column" sx={styles.scenarioWrapper}>
            <RuxInput
              required
              label="Scenario name"
              name="name"
              placeholder="Add a name"
              value={values.name}
              errorText={errors.name}
              onRuxinput={(e) => handleChange(e, [validateRequiredField])}
              size="large"
              data-test="scenario-name-input"
              invalid={!!errors.name}
            />
          </Grid>
          <Grid
            container
            item
            wrap="nowrap"
            direction="row"
            sx={styles.scenarioWrapper}
          >
            <Grid container item direction="row">
              <RuxInput
                size="small"
                type="date"
                required
                label="Start date"
                name="startDate"
                placeholder="YYY-DDD"
                value={values.startDate}
                errorText={errors.startDate}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateRequiredField,
                    validateScenarioStartDateTime,
                  ])
                }
                style={styles.dates}
                data-test="scenario-start-date-input"
                invalid={!!errors.startDate}
              />
            </Grid>
            <Grid container item direction="row">
              <RuxInput
                size="small"
                type="time"
                required
                label="Start time"
                name="startTime"
                step={"0.001"}
                placeholder="00: 00: 00: 000Z"
                value={values.startTime}
                errorText={errors.startTime}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateRequiredField,
                    validateScenarioStartDateTime,
                  ])
                }
                data-test="scenario-start-time-input"
                invalid={!!errors.startTime}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            wrap="nowrap"
            direction="row"
            justifyContent="space-between"
            sx={styles.scenarioWrapper}
          >
            <Grid container item direction="row">
              <RuxInput
                size="small"
                type="date"
                required
                label="End date"
                name="endDate"
                placeholder="YYY-DDD"
                value={values.endDate}
                errorText={errors.endDate}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateRequiredField,
                    validateScenarioEndDateTime,
                  ])
                }
                style={styles.dates}
                data-test="scenario-end-date-input"
                invalid={!!errors.endDate}
              />
            </Grid>
            <Grid container item direction="row">
              <RuxInput
                size="small"
                type="time"
                required
                label="End time"
                name="endTime"
                step={"0.001"}
                placeholder="00: 00: 00: 000Z"
                value={values.endTime}
                errorText={errors.endTime}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateRequiredField,
                    validateScenarioEndDateTime,
                  ])
                }
                data-test="scenario-end-time-input"
                invalid={!!errors.endTime}
              />
            </Grid>
          </Grid>
          <Grid container item direction="column" sx={styles.descriptionBox}>
            <RuxTextarea
              required
              label="Description"
              name="description"
              placeholder="Add a description"
              value={values.description}
              errorText={errors.description}
              onRuxinput={(e) => handleChange(e, [validateRequiredField])}
              data-test="scenario-description-input"
              invalid={!!errors.description}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EditScenarioPanel;
