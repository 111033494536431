export const eventTypeDefinitions = {
  //--Supression--//
  1: {
    eventName: "Suppression",
    category: 0,
    target: 1,
    trigger: null,
    paramDefs: () => [1],
  },
  //--User Event--//
  2: {
    eventName: "Custom",
    category: 1,
    paramDefs: () => [4],
  },
  //--Impulse Maneuver--//
  //missing param defs for X Velocity, Y Velocity, and Z Velocity
  3: {
    eventName: "Impulse",
    category: 1,
    target: 59,
    paramDefs: () => [59, 60, 61, null, null, null],
  },
  //--Change Altitude Maneuver--//
  4: {
    eventName: "Change_Altitude",
    category: 1,
    target: 13,
    trigger: 14,
    paramDefs: (providerName: string) => [
      13,
      11,
      14,
      ...(providerName === "MITRE" ? [12] : []),
    ],
  },
  //--Change Inclination Maneuver--//
  5: {
    eventName: "Change_Inclination",
    category: 1,
    target: 6,
    trigger: 7,
    paramDefs: (providerName: string) => [
      6,
      5,
      7,
      ...(providerName === "MITRE" ? [105] : []),
    ],
  },
  //--Change Period Maneuver--//
  6: {
    eventName: "Change_Period",
    category: 1,
    target: 9,
    trigger: 10,
    paramDefs: (providerName: string) => [
      9,
      8,
      10,
      ...(providerName === "MITRE" ? [106] : []),
    ],
  },
  //--Close Approach--//
  7: {
    eventName: "Close_Approach",
    category: 1,
    target: 17,
    paramDefs: () => [17, 16, 18, 20, 19],
  },
  //--Rendezvous--//
  // no param defs provided for Rendezvous event type
  8: {
    eventName: "Rendezvous",
    category: 1,
    target: null,
    paramDefs: () => [null],
  },
  //--Way Point Maneuver--//
  // no param defs provided for Way Point Maneuver event type
  9: {
    eventName: "Way_Point",
    category: 1,
    target: null,
    paramDefs: () => [null],
  },
  //--Pivot Maneuver--//
  // no param defs provided for pivot maneuver
  10: {
    eventName: "Pivot",
    category: 1,
    target: null,
    paramDefs: () => [null],
  },
  //--Squeeze Maneuver--//
  // no param defs provided for squeeze maneuver
  11: {
    eventName: "Squeeze",
    category: 1,
    target: null,
    paramDefs: () => [null],
  },
  //--RPO--//
  // TODO: need to figure out how to represent different types of RPOs
  12: {
    eventName: "RPO",
    category: 2,
    target: null,
    paramDefs: () => [null],
  },
  //--Break Up--//
  13: {
    eventName: "Breakup",
    category: 2,
    target: 52,
    paramDefs: () => [52, 23, 24, 25, 21, 22],
  },
  //--Shedding--//
  // missing param def for Satellite Mass, Min Area to Mass and Max Area to Mass
  14: {
    eventName: "Shedding",
    category: 2,
    target: 53,
    paramDefs: () => [53, 28, 27, 26],
  },
  //--Launch--//
  // Launch event not yet supported on trogdor
  15: {
    eventName: "Launch",
    category: 2,
    target: 70,
    trigger: 68,
    paramDefs: () => [68, 69, 70],
  },
};
