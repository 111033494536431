// React Libraries
import { useNavigate } from "react-router-dom";
// Redux & Utilties
import useForm from "../../../utils/Hooks/useForm";
import { useCreateScenarioMutation } from "../../../redux/rtk/endpoints/scenario";
import {
  validateRequiredField,
  validateScenarioStartDateTime,
  validateScenarioEndDateTime,
} from "../../../utils/formFieldValidation";
// Component Libraries and Styling
import { RuxButton, RuxTextarea, RuxInput } from "@astrouxds/react";
import PanelTitleBar from "../../Common/PanelTitleBar";
import Grid from "@mui/material/Grid";
// Types
import type { ScenarioBaseResponse } from "../../../redux/rtk/types/responseTypes";
import type { ScenarioFormValues } from "../../../redux/rtk/types/formTypes";

const styles = {
  createBtn: {
    margin: "1.25rem, auto",
  },
  scenarioWrapper: {
    paddingInline: "1.25rem",
    marginBottom: "1rem",
  },
  descriptionBox: {
    paddingInline: "1.25rem",
    marginBottom: "2.5rem",
  },
  dates: {
    width: "15.6875rem",
    marginRight: "1.875rem",
  },
};

interface useFormScenarioType extends ReturnType<typeof useForm> {
  values: Partial<ScenarioFormValues>;
  errors: Partial<ScenarioFormValues>;
}

const CreateScenarioPanel = () => {
  const navigate = useNavigate();
  const { values, errors, handleChange, handleSubmit }: useFormScenarioType =
    useForm(createNewScenario);

  const [addScenario, { isLoading: isAdding }] = useCreateScenarioMutation();

  function createNewScenario() {
    addScenario(values)
      .unwrap()
      .then((data: { body: { Scenario: ScenarioBaseResponse } }) => {
        navigate(`/scenarios/${data.body.Scenario.scenarioID}`);
      })
      .catch((error: string) => console.log("error", error));
  }

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      name="createScenario"
      data-test="create-scenario-form"
    >
      <PanelTitleBar
        leftBtnText="Cancel"
        titleText="Create scenario"
        postingStatus={isAdding}
      />
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid container item direction="column" sx={styles.scenarioWrapper}>
          <RuxInput
            required
            label="Scenario name"
            name="name"
            placeholder="Add a name"
            value={values.name}
            errorText={errors.name}
            onRuxinput={(e) => handleChange(e, [validateRequiredField])}
            size="large"
            data-test="scenario-name-input"
            invalid={!!errors.name}
          />
        </Grid>
        <Grid
          container
          item
          wrap="nowrap"
          direction="row"
          sx={styles.scenarioWrapper}
        >
          <Grid container item direction="row">
            <RuxInput
              size="small"
              type="date"
              required
              label="Start date"
              name="startDate"
              placeholder="YYY-DDD"
              value={values.startDate}
              errorText={errors.startDate}
              onRuxinput={(e) =>
                handleChange(e, [
                  validateRequiredField,
                  validateScenarioStartDateTime,
                ])
              }
              style={styles.dates}
              data-test="scenario-start-date-input"
              invalid={!!errors.startDate}
            />
          </Grid>
          <Grid container item direction="row">
            <RuxInput
              size="small"
              type="time"
              required
              label="Start time"
              name="startTime"
              step="0.001"
              placeholder="00: 00: 00: 000Z"
              value={values.startTime}
              errorText={errors.startTime}
              onRuxinput={(e) =>
                handleChange(e, [
                  validateRequiredField,
                  validateScenarioStartDateTime,
                ])
              }
              data-test="scenario-start-time-input"
              invalid={!!errors.startTime}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          wrap="nowrap"
          direction="row"
          justifyContent="space-between"
          sx={styles.scenarioWrapper}
        >
          <Grid container item direction="row">
            <RuxInput
              size="small"
              type="date"
              required
              label="End date"
              name="endDate"
              placeholder="YYY-DDD"
              value={values.endDate}
              errorText={errors.endDate}
              onRuxinput={(e) =>
                handleChange(e, [
                  validateRequiredField,
                  validateScenarioEndDateTime,
                ])
              }
              style={styles.dates}
              data-test="scenario-end-date-input"
              invalid={!!errors.endDate}
            />
          </Grid>
          <Grid container item direction="row">
            <RuxInput
              size="small"
              type="time"
              required
              label="End time"
              name="endTime"
              step="0.001"
              placeholder="00: 00: 00: 000Z"
              value={values.endTime}
              errorText={errors.endTime}
              onRuxinput={(e) =>
                handleChange(e, [
                  validateRequiredField,
                  validateScenarioEndDateTime,
                ])
              }
              data-test="scenario-end-time-input"
              invalid={!!errors.endTime}
            />
          </Grid>
        </Grid>
        <Grid container item direction="column" sx={styles.descriptionBox}>
          <RuxTextarea
            required
            label="Description"
            name="description"
            placeholder="Add a description"
            value={values.description}
            errorText={errors.description}
            onRuxinput={(e) => handleChange(e, [validateRequiredField])}
            data-test="scenario-description-input"
            invalid={!!errors.description}
          />
        </Grid>
        <RuxButton
          data-test="create-scenario-button"
          style={styles.createBtn}
          type="submit"
          size="large"
          disabled={isAdding}
        >
          Create
        </RuxButton>
      </Grid>
    </form>
  );
};

export default CreateScenarioPanel;
