import { createSlice } from "@reduxjs/toolkit";
import Common from "../../../../../utils/common";

const initialState = {
  isPosting: false,
  isCommunicating: false,
  error: null,
  events: {},
  eventIds: [],
};

export const currentScenarioEventsSlice = createSlice({
  name: "currentScenario/events",
  initialState,
  reducers: {
    fetchEventStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    fetchEventSuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      state.events[action.payload.id] = action.payload;
      state.eventIds = Common.eventIdsByTriggerTime(state.events);
    },
    fetchEventFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    createEventStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    createEventSuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      if (action.payload.shouldUpdate) {
        delete action.payload.shouldUpdate;
        state.events[action.payload.id] = action.payload;
        state.eventIds = Common.eventIdsByTriggerTime(state.events);
      }
    },
    createEventFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    editEventStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    editEventSuccess: (state, action) => {
      state.isCommunicating = false;
      state.events[action.payload.id] = action.payload;
      state.eventIds = Common.eventIdsByTriggerTime(state.events);
    },
    editEventFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    deleteEventStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    deleteEventSuccess: (state, action) => {
      state.isCommunicating = false;
      delete state.events[action.payload];
      state.eventIds = Common.eventIdsByTriggerTime(state.events);
    },
    deleteEventFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    copyScenarioEventsSuccess: (state, action) => {
      state.events = action.payload;
      state.eventIds = Common.eventIdsByTriggerTime(state.events);
    },
    editEventParamStart: (state) => {
      state.isPosting = true;
      state.error = null;
    },
    createEventParamStart: (state) => {
      state.isPosting = true;
      state.error = null;
    },
    createEventParamSuccess: (state, action) => {
      state.isPosting = false;
      state.error = null;
      if (action.payload.shouldUpdate) {
        const newParameter = action.payload;
        const currentEventParameters =
          state.events[action.payload.eventId]?.parameters.parameters;
        if (currentEventParameters) {
          currentEventParameters.push(newParameter);
        } else {
          state.events[action.payload.eventId].parameters.parameters = [
            newParameter,
          ];
        }
      }
    },
    createEventParamFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    editEventParamSuccess: (state, action) => {
      state.isPosting = false;
      state.error = null;
      state.events[action.payload.eventId].parameters.parameters.forEach(
        (paramObj) => {
          if (action.payload.paramID === paramObj.paramID) {
            paramObj.paramValue = action.payload.paramValue;
          }
        }
      );
    },
    editEventParamFail: (state, action) => {
      state.isPosting = false;
      state.error = action.payload;
    },
    fetchEventsDetailsStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    fetchEventsDetailsSuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      state.events = action.payload;
      state.eventIds = Common.eventIdsByTriggerTime(state.events);
    },
    fetchEventsDetailsFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    resetEvents: () => initialState,
  },
});

export const {
  fetchEventStart,
  fetchEventSuccess,
  fetchEventFail,
  createEventStart,
  createEventSuccess,
  createEventFail,
  editEventStart,
  editEventSuccess,
  editEventFail,
  deleteEventStart,
  deleteEventSuccess,
  deleteEventFail,
  resetEvents,
  copyScenarioEventsSuccess,
  createEventParamSuccess,
  createEventParamFail,
  createEventParamStart,
  editEventParamStart,
  editEventParamSuccess,
  editEventParamFail,
  fetchEventsDetailsStart,
  fetchEventsDetailsSuccess,
  fetchEventsDetailsFail,
} = currentScenarioEventsSlice.actions;
