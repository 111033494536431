/* eslint-disable react/no-unknown-property */
import { useRef, useCallback, useEffect } from "react";
import * as THREE from "three";
import { useTexture } from "@react-three/drei";
import { earthRadius } from "satellite.js/lib/constants";
import { useThree, useFrame } from "@react-three/fiber";
import { useLoginCanvasContext } from "../../../utils/Hooks/contextHooks/useLoginCanvasContext";
import { scaleFactorEq, opacityEq } from "../../Scene/aux/Atmosphere";

type PropTypes = {
  vec?: THREE.Vector3;
};

const Atmosphere = ({ vec = new THREE.Vector3() }: PropTypes) => {
  const { setSceneIsLoaded, introAnimationDone, atmosphereScaleToggleAlpha } =
    useLoginCanvasContext();
  const { camera } = useThree();
  const earthAuxRef = useRef<any>();
  const storedCameraDis = useRef(0);
  const earthAux = earthAuxRef.current;
  const camPos = camera.position;

  const [earthAtmosphere] = useTexture([
    process.env.PUBLIC_URL + "/earth-assets/earth-atmosphere-glow.png",
  ]);

  const earthAuxGlowHandler = useCallback(() => {
    if (!earthAux) return;
    let camDistFromEarth: number | undefined;

    const cameraDis = Math.floor(camPos.distanceTo(earthAux.position));
    if (cameraDis !== storedCameraDis.current) {
      camDistFromEarth = cameraDis;
      storedCameraDis.current = camDistFromEarth;
    }
    if (typeof camDistFromEarth === "undefined") return;

    if (camDistFromEarth > 8200 && camDistFromEarth < 26000) {
      earthAux.scale.set(
        earthRadius * scaleFactorEq(camDistFromEarth),
        earthRadius * scaleFactorEq(camDistFromEarth),
        1
      );
      earthAux.material.opacity = opacityEq(camDistFromEarth);
    } else {
      earthAux.scale.lerp(
        vec.set(
          earthRadius * scaleFactorEq(camDistFromEarth),
          earthRadius * scaleFactorEq(camDistFromEarth),
          1
        ),
        atmosphereScaleToggleAlpha ? 0.25 : 0.6
      );
    }
  }, [earthAux, camPos, introAnimationDone, atmosphereScaleToggleAlpha]);

  useFrame(() => {
    if (!earthAux) return;
    earthAuxGlowHandler();
  });

  useEffect(() => {
    if (!earthAux) return;
    setSceneIsLoaded(true);
  }, [earthAux]);

  return (
    <sprite
      name="Atmosphere"
      ref={earthAuxRef}
      scale={[earthRadius * 2.46, earthRadius * 2.46, 1]}
    >
      <spriteMaterial
        name="Atmosphere Mat"
        map={earthAtmosphere}
        side={THREE.FrontSide}
        sizeAttenuation={true}
        depthTest={false}
        blending={THREE.AdditiveBlending}
        opacity={0.38}
      />
    </sprite>
  );
};

export default Atmosphere;
