import Common from "../../common";
import { eventTypeDefinitions } from "../../api/trogdor/eventTypeDefinitions";
import store from "../../../app/store";
import type { EventFormValues } from "../../../redux/rtk/types/formTypes";

// Formats values for saving to redux/trogdor
export const formatEventDetailsForRedux = (
  values: EventFormValues,
  state = store.getState()
) => {
  const {
    eventTypeId,
    name,
    startDate,
    startTime,
    endDate,
    endTime,
    scenarioId,
    providerId,
  } = values;

  const getProviderObj = () => {
    const queries: any = state.trogdorApi?.queries;
    const eventTypes = queries[`getEventTypes(undefined)`].data.entities;

    const provider = eventTypes[eventTypeId].providers.find(
      (provider: { providerID: number }) =>
        provider.providerID === Number(providerId)
    );
    return provider;
  };

  const providerName = getProviderObj().providerName;

  const eventDetails = {
    name: name,
    active: false,
    status: "Pending",
    provider: providerName,
    scenarioId: scenarioId,
    startTime: Common.convertToISOJSONDate(
      startDate as string,
      startTime as string
    ),
    endTime: Common.convertToISOJSONDate(endDate as string, endTime as string),
    eventType:
      eventTypeDefinitions[
        // TODO: Update eventTypeDefinitions with string keys)
        eventTypeId as unknown as keyof typeof eventTypeDefinitions
      ].eventName,
    eventTypeId: eventTypeId,
    mitreMode: providerName === "MITRE",
    parameters: {
      parameters: formatEventParametersForRedux(values),
    },
  };
  return eventDetails;
};

// Formats parameters obejcts for attaching to events in redux
//TODO replace any- types are already made for params
export const formatEventParametersForRedux = (values: any) => {
  switch (values.eventTypeId) {
    // Suppression Event
    case "1":
      return [
        {
          paramType: "Integer",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 1,
          paramName: "satellite_id",
          paramValue: values.satellite_id,
          paramDescription: "The ID of the satellite to suppress.",
        },
      ];
    // Change Altitude Event
    case "4": {
      const params = [
        {
          paramType: "Integer",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 13,
          paramName: "altitude_sat",
          paramValue: values.altitude_sat,
          paramDescription: "Satellite number of target.",
        },
        {
          paramType: "Double",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 11,
          paramName: "change_in_altitude",
          paramValue: values.change_in_altitude,
          paramDescription: "The change in altitude. (km)",
        },
        {
          paramType: "String",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 14,
          paramName: "altitude_maneuver_time",
          paramValue: Common.convertToISOJSONDate(
            values.altitude_maneuver_time_date,
            values.altitude_maneuver_time_time
          ),
          paramDescription:
            "The time the change in altitude occurs. (UTC Date String)",
        },
      ];
      if (Object.hasOwn(values, "alt_time_of_flight"))
        params.push({
          paramType: "String",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 12,
          paramName: "alt_time_of_flight",
          paramValue: values.alt_time_of_flight,
          paramDescription:
            "The time of flight for a altitude change maneuver. (seconds)",
        });
      return params;
    }
    // Change Inclination Event
    case "5": {
      const params = [
        {
          paramType: "Integer",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 6,
          paramName: "inclination_sat",
          paramValue: values.inclination_sat,
          paramDescription: "Satellite number of target.",
        },
        {
          paramType: "Double",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 5,
          paramName: "change_in_inclination",
          paramValue: values.change_in_inclination,
          paramDescription: "The change in inclination. (deg)",
        },
        {
          paramType: "String",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 7,
          paramName: "inclination_maneuver_time",
          paramValue: Common.convertToISOJSONDate(
            values.inclination_maneuver_time_date,
            values.inclination_maneuver_time_time
          ),
          paramDescription:
            "The time the change in inclination occurs. (UTC Date String)",
        },
      ];
      if (Object.hasOwn(values, "inclination_time_of_flight"))
        params.push({
          paramType: "String",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 105,
          paramName: "inclination_time_of_flight",
          paramValue: values.inclination_time_of_flight,
          paramDescription:
            "The time of flight for a inclination change maneuver. (seconds)",
        });
      return params;
    }
    // Change Period Event
    case "6": {
      const params = [
        {
          paramType: "Integer",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 9,
          paramName: "period_sat",
          paramValue: values.period_sat,
          paramDescription: "Satellite number of target.",
        },
        {
          paramType: "Double",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 8,
          paramName: "change_in_period",
          paramValue: values.change_in_period,
          paramDescription: "The change in period. (min)",
        },
        {
          paramType: "String",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 10,
          paramName: "period_maneuver_time",
          paramValue: Common.convertToISOJSONDate(
            values.period_maneuver_time_date,
            values.period_maneuver_time_time
          ),
          paramDescription:
            "The time the change in period occurs. (UTC Date String)",
        },
      ];
      if (Object.hasOwn(values, "period_time_of_flight"))
        params.push({
          paramType: "String",
          paramID: `tempParamId-${crypto.randomUUID()}`,
          paramDefID: 106,
          paramName: "period_time_of_flight",
          paramValue: values.period_time_of_flight,
          paramDescription:
            "The time of flight for a period change maneuver. (seconds)",
        });
      return params;
    }
    default:
      return [];
  }
};
