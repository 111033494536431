import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSatelliteContext } from "../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useSatStateContext } from "../../../utils/Hooks/contextHooks/useSatStateContext";
import { useScrollToListItem } from "../../../utils/Hooks/useScrollToListItem";
import { useDrawerContext } from "../../../utils/Hooks/contextHooks/useDrawerContext";
import { Grid, List } from "@mui/material";
import ObjectItem from "./ObjectListItem/ObjectItem";
import LeftPanelListHeaders from "../../Common/Layout/LeftPanel/LeftPanelListHeaders/LeftPanelListHeaders";
import ListSortBar from "../../Common/Layout/LeftPanel/ListSortBar/ListSortBar";
import EmptyListContent from "../../Common/Layout/LeftPanel/EmptyListContent/EmptyListContent";
import { RuxSelectCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";
import { ObjectProps } from "./ObjectListItem/ObjectItem";

const styles = {
  objectListSX: {
    height: "100%",
    overflowY: "auto",
    width: "100%",
  },
  listHeight: {
    height: "calc(100vh - 33.35rem  )",
    overflowY: "auto",
  },
};

const sortOptions = [{ label: "ID", value: "satNo" }];

const ObjectList = () => {
  const { scenarioId } = useParams();
  if (!scenarioId) return null;
  const { threeSatellites } = useSatelliteContext();
  const { selectedSatIds } = useSatStateContext();
  const { toggleAddObject, toggleCreateEvent } = useDrawerContext();
  const { listItemRefs } = useScrollToListItem(selectedSatIds);

  const [sortProperty, setSortProperty] = useState("satNo");
  const [visibleSats, setVisibleSats] = useState([]);

  const handleSort = (
    event: RuxSelectCustomEvent<void> | React.BaseSyntheticEvent
  ) => setSortProperty(event.target.value);

  useEffect(() => {
    const getVisibleSortedSatsArr = (allSats: any) =>
      allSats
        .filter(
          (sat: { visualizationState: string }) =>
            sat.visualizationState !== "hidden"
        )
        .sort(
          (a: { [x: string]: number }, b: { [x: string]: number }) =>
            a[sortProperty] - b[sortProperty]
        );

    setVisibleSats(getVisibleSortedSatsArr(threeSatellites));
  }, [threeSatellites]);

  return (
    <>
      <Grid px="1.875rem">
        <ListSortBar
          type="objects"
          options={sortOptions}
          sort={sortProperty}
          onSort={handleSort}
          onCreate={toggleAddObject}
          buttonName="Add Object"
        />
      </Grid>
      <LeftPanelListHeaders items={[{ name: "Object" }]} />
      <Grid sx={styles.listHeight} data-test="objects-list-container">
        {visibleSats.length ? (
          <List
            disablePadding
            sx={styles.objectListSX}
            data-test="objects-list"
          >
            {visibleSats.map((satellite: ObjectProps, index: number) => {
              return (
                <ObjectItem
                  listItemRefs={listItemRefs}
                  object={satellite}
                  key={satellite.id + `${index}`}
                  scenarioId={Number(scenarioId)}
                />
              );
            })}
          </List>
        ) : (
          <EmptyListContent
            title="objects"
            firstButtonOnClick={toggleAddObject}
            firstButtonName="Add object"
            text="Add objects or events to the scenario"
            secondButtonName="Create event"
            secondButtonOnClick={toggleCreateEvent}
          />
        )}
      </Grid>
    </>
  );
};

export default ObjectList;
