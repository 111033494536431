import { useRef, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import Engine from "../Scene/Engine";
import SatelliteBanner from "./SatelliteBanner";
import { useSatelliteContext } from "../../utils/Hooks/contextHooks/useSatelliteContext";
import Box from "@mui/material/Box";

const styles = {
  renderParent: {
    position: "relative",
    overflow: "hidden",
    // set to insure sliding behaviors go behind render window
    zIndex: 200,
  },
  renderWindow: {
    /* screen height - global status bar - top and bottom padding */
    height: "calc(100vh - 6rem - 2.5rem)",
    width: "100%",
    overflow: "hidden",
  },
};

const RenderWindow = () => {
  const { setRenderWindowPortal } = useSatelliteContext();

  //_ The renderWindowPortal ref is passed into the drei Html components inside the canvas, ultimately so pointer-events doesn't pass through them and move the camera around __________________________________________________________________________
  const renderWindowPortal = useRef();

  useEffect(() => {
    if (renderWindowPortal.current) {
      setRenderWindowPortal(renderWindowPortal);
    }
  }, [renderWindowPortal, setRenderWindowPortal]);

  return (
    <Box component={"div"} sx={styles.renderParent}>
      <Box
        component={"div"}
        id="render-window"
        sx={styles.renderWindow}
        ref={renderWindowPortal}
      >
        <Canvas
          frameloop="demand"
          performance={{ min: 0.1 }}
          style={{ position: "initial" }}
          gl={{
            logarithmicDepthBuffer: true,
            physicallyCorrectLights: true,
          }}
        >
          <Engine />
        </Canvas>

        <SatelliteBanner />
      </Box>
    </Box>
  );
};

export default RenderWindow;
