import universalAxios from "../axios";
import { formatUDLTLEResponse } from "./formattingFunctions";

export const getUDLTLE = (satNo: number) => {
  const params = {
    api: "udl",
    url: "/elset/current",
    satNo: satNo,
  };
  return universalAxios("get", "/proxy/", params).then((response: any) => {
    if (response.data.length) {
      response.data = response.data.map((sat: any) => {
        return formatUDLTLEResponse(sat);
      });
    }
    return response;
  });
};
