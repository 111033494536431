import { createTheme } from "@mui/material";
import { astroTokens } from "astro-mui-theme";

const astro = astroTokens();

export const acmeTheme = createTheme({
  shape: {
    borderRadius: parseInt(astro.radius.base.charAt(0)),
  },
  spacing: (...values: number[]): string => {
    // each value equals 0.625rem or 10px
    const rems = values.map((value) => `${0.625 * value}rem`);
    return rems.slice(0, 4).join().replace(/[,]/g, " ");
  },
  components: {
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
      styleOverrides: {
        root: {
          overflow: "auto",
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
        divider: true,
      },
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: astro.color.background.surface.hover,
          },
          "&.Mui-selected": {
            backgroundColor: astro.color.background.surface.selected,
            borderLeft: `4px solid ${astro.color.border.interactive.default}`,
            paddingLeft: "0.75rem",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#214b6f",
          },
          "&.MuiListItemButton-divider": {
            borderBottom: `1px solid ${astro.color.text.inverse}`,
          },
          gap: "1rem",
          padding: "0.1875rem 0.75rem 0.1875rem 1rem",
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          noWrap: true,
          ...astro.typography.h3,
        },
        secondaryTypographyProps: {
          noWrap: true,
          ...astro.typography.body2,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        underlineNone: {
          "&:hover": {
            textDecoration: "none",
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: astro.color.background.base.default,
          borderWidth: astro.border.width.xs,
          borderColor: astro.color.border.interactive.default,
          borderStyle: "solid",
          padding: "0.125rem 0.625rem 0.0625rem",
          fontSize: astro.typography.body3.fontSize,
          marginTop: "8px !important",
          borderRadius: astro.radius.base,
        },
      },
    },
  },
});

export type AcmeTheme = typeof acmeTheme;
