import { createApi } from "@reduxjs/toolkit/query/react";
import { acmeQuery, trogdorQuery } from "./baseQueries";

import { LoginRequest, UserResponse } from "./types/acmeApiTypes";
import { EndpointDefinitions } from "@reduxjs/toolkit/query/react";

export const trogdorApi = createApi({
  reducerPath: "trogdorApi",
  tagTypes: ["Scenario", "Event", "TLESat", "SVSat"],
  baseQuery: trogdorQuery(),
  endpoints: (): EndpointDefinitions => ({} as EndpointDefinitions),
});

export const acmeApi = createApi({
  reducerPath: "acmeApi",
  baseQuery: acmeQuery(),
  endpoints: (builder) => ({
    // Adding login here due to circular imports
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: "/auth/login",
        method: "post",
        body: credentials,
      }),
    }),
  }),
});

export const { useLoginMutation } = acmeApi;
