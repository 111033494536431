import { useState, createContext, useContext, MutableRefObject } from "react";

type PropTypes = {
  maxDistanceFactor: number;
  minDistance: number;
  orbitIsDragging: boolean;
  setOrbitIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  timelineIsScrubbing: boolean;
  setTimelineIsScrubbing: React.Dispatch<React.SetStateAction<boolean>>;
  earthRef: MutableRefObject<any> | undefined;
  setEarthRef: React.Dispatch<React.SetStateAction<any>>;
  sunLightRef: MutableRefObject<any> | undefined;
  setSunLightRef: React.Dispatch<React.SetStateAction<any>>;
  lightHelperToggle: boolean;
  setLightHelperToggle: React.Dispatch<React.SetStateAction<boolean>>;
  realTimeSunToggle: boolean;
  setRealTimeSunToggle: React.Dispatch<React.SetStateAction<boolean>>;
  lensflareToggle: boolean;
  setLensflareToggle: React.Dispatch<React.SetStateAction<boolean>>;
  altitudeLinesToggle: boolean;
  setAltitudeLinesToggle: React.Dispatch<React.SetStateAction<boolean>>;
  satLabelToggle: boolean;
  setSatLabelToggle: React.Dispatch<React.SetStateAction<boolean>>;
  ephemHelperToggle: boolean;
  setEphemHelperToggle: React.Dispatch<React.SetStateAction<boolean>>;
  nightLightsToggle: boolean;
  setNightLightsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  ghostTLEToggle: boolean;
  setGhostTLEToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CanvasContext = createContext<PropTypes>({
  maxDistanceFactor: 45,
  minDistance: 2000,
  orbitIsDragging: false,
  setOrbitIsDragging: () => null,
  timelineIsScrubbing: false,
  setTimelineIsScrubbing: () => null,
  earthRef: undefined,
  setEarthRef: () => null,
  sunLightRef: undefined,
  setSunLightRef: () => null,
  lightHelperToggle: false,
  setLightHelperToggle: () => null,
  realTimeSunToggle: false,
  setRealTimeSunToggle: () => null,
  lensflareToggle: true,
  setLensflareToggle: () => null,
  altitudeLinesToggle: false,
  setAltitudeLinesToggle: () => null,
  satLabelToggle: true,
  setSatLabelToggle: () => null,
  ephemHelperToggle: false,
  setEphemHelperToggle: () => null,
  nightLightsToggle: false,
  setNightLightsToggle: () => null,
  ghostTLEToggle: false,
  setGhostTLEToggle: () => null,
});

export function useCanvasContext() {
  return useContext(CanvasContext);
}

export const CanvasContextProvider = ({ children }: Children) => {
  const maxDistanceFactor = 45;
  const minDistance = 2000;

  // Added to disable pointer events on satellites during camera movements to avoid performance drop
  const [orbitIsDragging, setOrbitIsDragging] = useState(false);

  // Added to disable pointer events on satellites during timeline scrubbing to avoid performance drop
  const [timelineIsScrubbing, setTimelineIsScrubbing] = useState(false);

  const [earthRef, setEarthRef] = useState();
  const [sunLightRef, setSunLightRef] = useState();
  const [lightHelperToggle, setLightHelperToggle] = useState(false);
  const [realTimeSunToggle, setRealTimeSunToggle] = useState(true);
  const [lensflareToggle, setLensflareToggle] = useState(true);
  const [altitudeLinesToggle, setAltitudeLinesToggle] = useState(false);
  const [satLabelToggle, setSatLabelToggle] = useState(true);
  const [ephemHelperToggle, setEphemHelperToggle] = useState(false);
  const [nightLightsToggle, setNightLightsToggle] = useState(false);
  const [ghostTLEToggle, setGhostTLEToggle] = useState(false);

  return (
    <CanvasContext.Provider
      value={{
        maxDistanceFactor,
        minDistance,
        orbitIsDragging,
        setOrbitIsDragging,
        timelineIsScrubbing,
        setTimelineIsScrubbing,
        earthRef,
        setEarthRef,
        sunLightRef,
        setSunLightRef,
        lightHelperToggle,
        setLightHelperToggle,
        realTimeSunToggle,
        setRealTimeSunToggle,
        lensflareToggle,
        setLensflareToggle,
        altitudeLinesToggle,
        setAltitudeLinesToggle,
        satLabelToggle,
        setSatLabelToggle,
        ephemHelperToggle,
        setEphemHelperToggle,
        nightLightsToggle,
        setNightLightsToggle,
        ghostTLEToggle,
        setGhostTLEToggle,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};
