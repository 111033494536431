import { RuxButton } from "@astrouxds/react";
import Common from "../../utils/common";
import { useDateTimeContext } from "../../utils/Hooks/contextHooks/useDateTimeContext";

type PropTypes = {
  date: string | undefined;
  time: string | undefined;
};

const JumpToTimeButton = (props: PropTypes) => {
  const { date, time } = props;
  const { setDateTime } = useDateTimeContext();
  const handleGoToTimeClick = (e: React.MouseEvent) => {
    if (!date || !time) return;
    const formattedTimeAndDate = Common.convertToISOJSONDate(date, time);
    e.stopPropagation();
    if (!formattedTimeAndDate) return;
    setDateTime(new Date(formattedTimeAndDate));
  };

  return (
    <RuxButton
      data-test="jump-time-button"
      icon="my-location"
      secondary
      iconOnly
      size="small"
      onClick={(e) => handleGoToTimeClick(e)}
      disabled={!time}
    />
  );
};

export default JumpToTimeButton;
