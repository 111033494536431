import { batch } from "react-redux";

// Redux Actions and Thunks
import * as curScenarioDetails from "../slices/scenarios/currentScenario/details/scenarioDetailsSlice";
import * as curScenarioPreEventTLEs from "../slices/scenarios/currentScenario/objects/preEventTLEs";
import * as curScenarioEvents from "../slices/scenarios/currentScenario/events/eventsSlice";
import * as curScenarioPostEventSVs from "../slices/scenarios/currentScenario/objects/postEventSVs";
// import {
//   copyScenarioEventsToCurrent,
//   fetchScenarioEventsDetails,
// } from "./eventThunks";
// import { getPlotScenarioSVs } from "./eventObjectsThunks";

// API Functions
import * as acmeScenarios from "../../utils/api/acme/scenarios";
import * as trogdorEventSats from "../../utils/api/trogdor/eventSats";

// Utilities
import Common from "../../utils/common";
import {
  formatSTPreEventTLERequest,
  getPromiseAllData,
} from "../../utils/api/trogdor/formattingFunctions";
import { trogdorPromiseAllErrorCheck } from "../../utils/api/trogdor/utils";

// export const fetchCurrentScenario = (scenarioId, scenarioEventIds) => {
//   return async (dispatch) => {
//     const onSuccess = async () => {
//       // Set current scenario status after successful fetches
//       await dispatch(curScenarioDetails.updateStatus(statusEnums.fetched));
//       return Promise.resolve("fetch successful");
//     };

//     const onError = async (error) => {
//       console.error(error);
//       await dispatch(curScenarioDetails.fetchScenarioFail(error));
//       await dispatch(curScenarioDetails.updateStatus(statusEnums.fetched));
//       return error;
//     };

//     try {
//       await dispatch(curScenarioDetails.updateStatus(statusEnums.fetching));
//       await dispatch(copyScenarioEventsToCurrent(scenarioEventIds));
//       await dispatch(fetchScenarioTLEs(scenarioId, scenarioEventIds));
//       await dispatch(fetchScenarioEventsDetails(scenarioEventIds));
//       await dispatch(getPlotScenarioSVs(scenarioEventIds));
//       return onSuccess();
//     } catch (error) {
//       return onError("Unable to fetch event Details");
//     }
//   };
// };

export const fetchScenarioTLEs = (scenarioId, scenarioEventIds) => {
  return async (dispatch) => {
    const onTrogdorSuccess = async (response, eventIds) => {
      const eventTLEs = {};
      eventIds.forEach(
        (eventId, index) => (eventTLEs[eventId] = response[index])
      );
      await dispatch(curScenarioPreEventTLEs.fetchTLEsSuccess(eventTLEs));
      return Promise.resolve(response);
    };

    const onAcmeSuccess = async (response) => {
      dispatch(curScenarioPreEventTLEs.fetchTLEsSuccess(response.data));
      return response;
    };

    const onError = async (error) => {
      console.error(error);
      await dispatch(curScenarioPreEventTLEs.fetchTLEsFail(error.response));
      return error;
    };

    try {
      dispatch(curScenarioPreEventTLEs.fetchTLEsStart());
      if (Common.trogdor) {
        const requests = scenarioEventIds.map((eventId) =>
          trogdorEventSats.getAllPreEventTLEs(eventId)
        );

        const responses = await Promise.all(requests);
        // Check for errors
        const errorCheck = trogdorPromiseAllErrorCheck(responses);
        if (errorCheck.error) return onError(errorCheck.errorMessage);

        return onTrogdorSuccess(getPromiseAllData(responses), scenarioEventIds);
      } else {
        const response = await acmeScenarios.getScenarioObjects(scenarioId);
        return onAcmeSuccess(response);
      }
    } catch (error) {
      return onError(error);
    }
  };
};

// must use eventId with dispatch with trogdor due to all sats being tied to events and not scenarios
// * With Trogdor this is only used to add SpaceTrack sats to redux
export const addScenarioTLE = (TLE, scenarioId) => {
  return async (dispatch) => {
    const onTrogdorSuccess = async () => {
      await dispatch(
        curScenarioPreEventTLEs.saveLocalTLESuccess({
          ...formatSTPreEventTLERequest(TLE),
        })
      );
    };

    const onAcmeSuccess = (response) => {
      batch(async () => {
        await dispatch(curScenarioPreEventTLEs.saveLocalTLESuccess());
        await dispatch(fetchScenarioTLEs(scenarioId));
      });
      return response;
    };

    const onError = async (error) => {
      console.error(error);
      await dispatch(curScenarioPreEventTLEs.saveLocalTLEFail(error.response));
      return error;
    };

    try {
      dispatch(curScenarioPreEventTLEs.saveLocalTLEStart());
      if (Common.trogdor) {
        return onTrogdorSuccess();
      } else {
        const response = await acmeScenarios.addScenarioObject(
          TLE.id,
          scenarioId
        );
        return onAcmeSuccess(response);
      }
    } catch (error) {
      return onError(error);
    }
  };
};

// TODO write thunk for editScenarioObject after determining if editing will take place in trogdor for sats table
export const removeScenarioTLE = (objectId, scenarioId) => {
  return async (dispatch) => {
    const onAcmeSuccess = (reponse) => {
      batch(async () => {
        await dispatch(curScenarioPreEventTLEs.removeTLESuccess());
        await dispatch(fetchScenarioTLEs(scenarioId));
      });
      return reponse;
    };

    const onTrogdorSuccess = async () => {
      await dispatch(
        curScenarioPreEventTLEs.removeTLESuccess({ id: objectId })
      );
      return Promise.resolve("Successfully deleted");
    };

    const onError = async (error) => {
      console.error(error);
      await dispatch(curScenarioPreEventTLEs.removeTLEFail(error.response));
      return error;
    };

    try {
      dispatch(curScenarioPreEventTLEs.removeTLEStart());
      if (Common.trogdor) {
        return onTrogdorSuccess();
      } else {
        const response = await acmeScenarios.removeScenarioObject(
          objectId,
          scenarioId
        );
        return onAcmeSuccess(response);
      }
    } catch (error) {
      return onError(error);
    }
  };
};

export const resetCurrentScenario = () => {
  return async (dispatch) => {
    await dispatch(curScenarioEvents.resetEvents());
    await dispatch(curScenarioPreEventTLEs.resetTLEs());
    await dispatch(curScenarioPostEventSVs.resetSVSlice());
    await dispatch(curScenarioDetails.resetScenarioDetails());
  };
};

export const copyScenarioDetailsToCurrent = (scenario) => {
  return async (dispatch) => {
    await dispatch(curScenarioDetails.copyScenarioDetailsSuccess(scenario));
  };
};
