import { motion } from "framer-motion";
import Box from "@mui/material/Box";
import LeftPanel from "../../LeftPanel";

type LeftBehaviorProps = Children & {
  duration: number;
  isInitialPageLoad: boolean;
  isAnimate: boolean;
};

const LeftBehavior = ({
  children,
  duration,
  isInitialPageLoad,
  isAnimate,
}: LeftBehaviorProps) => (
  <Box
    width={500}
    position="fixed"
    component={motion.div}
    initial={{ x: isInitialPageLoad ? 0 : isAnimate ? -520 : 0 }}
    animate={{ x: 0 }}
    exit={{ x: -520 }}
    transition={{ duration }}
    overflow="hidden"
  >
    <LeftPanel>{children}</LeftPanel>
  </Box>
);

export default LeftBehavior;
