import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { trogdorApi } from "../api";
import {
  formatSVSatResponse,
  formatEventResponse,
} from "../formatting/responseFormatting";
import { SVSatResponse, EventResponse } from "../types/responseTypes";
import { SVSat } from "../types/internalTypes";
import { formatCreateSVSatRequest } from "../formatting/requestFormatting";

export const svSatsAdapter = createEntityAdapter<SVSat>();
export const svSatsInitialState = svSatsAdapter.getInitialState();

export const svSatsByEventIdAdapter = createEntityAdapter<SVSat>({
  selectId: (svSat) => svSat.eventId,
  sortComparer: (a, b) => a.eventId - b.eventId,
});
export const svSatsByEventIdInitialState =
  svSatsByEventIdAdapter.getInitialState();

export const svSatsEndpoints = trogdorApi.injectEndpoints({
  endpoints: (builder) => ({
    getScenarioSVs: builder.query<any, number>({
      async queryFn(scenarioId, { dispatch }, _extraOptions, baseQuery) {
        const scenarioEvents: any = await baseQuery({
          url: "/db/get_scenario_events",
          method: "get",
          params: { scenario_id: scenarioId },
        });
        const formattedEvents =
          scenarioEvents?.data?.body?.message ===
          "There are no events for the given Scenario."
            ? []
            : scenarioEvents?.data?.body?.map((event: EventResponse) => {
                return formatEventResponse(event);
              });
        const eventIds = formattedEvents.map((event: any) => event.id);
        const svsByEventId = {} as any;

        try {
          const svRequests = eventIds.map(async (eventId: number) => {
            await dispatch(
              svSatsEndpoints.endpoints.getEventSVs.initiate(eventId)
            ).then((response) => {
              svsByEventId[eventId] =
                response?.data?.entities &&
                Object.values(response.data.entities);
            });
          });
          await Promise.all(svRequests);
          return { data: svsByEventId };
        } catch (error) {
          return { error: error as FetchBaseQueryError };
        }
      },
      providesTags: (_result, _error, scenarioId) => [
        { type: "Scenario", id: scenarioId },
      ],
    }),
    getEventSVs: builder.query<EntityState<SVSat>, number>({
      query: (eventId) => ({
        url: "/db/get_event_state_vectors",
        method: "get",
        params: { event_id: eventId },
      }),
      keepUnusedDataFor: Infinity,
      transformResponse(response: { body: SVSatResponse[] }, _meta, eventId) {
        const eventSVs = response.body.map((svSat) => {
          return formatSVSatResponse(svSat, eventId);
        });

        return svSatsAdapter.setAll(svSatsInitialState, eventSVs);
      },
      providesTags: (entityResponse: EntityState<SVSat> | undefined) => {
        const entities = entityResponse?.entities;
        const arrayOfEntities =
          entities && (Object.values(entities) as SVSat[]);
        return arrayOfEntities
          ? [
              ...arrayOfEntities.map(({ id }) => ({
                type: "SVSat" as const,
                id,
              })),
              "SVSat",
            ]
          : ["SVSat"];
      },
    }),
    createEventSV: builder.mutation<SVSat, { eventId: number; svData: SVSat }>({
      query: ({ eventId, svData }) => {
        return {
          url: "/db/create_state_vector",
          method: "post",
          params: { event_id: eventId },
          body: formatCreateSVSatRequest(svData),
        };
      },
      transformResponse(response: { body: SVSatResponse }, _meta, { eventId }) {
        return formatSVSatResponse(response.body, eventId);
      },
      invalidatesTags: (result) =>
        result ? [{ type: "SVSat", id: result.id }] : ["SVSat"],
    }),
  }),
});

export const {
  useGetEventSVsQuery,
  useGetScenarioSVsQuery,
  useCreateEventSVMutation,
}: any = svSatsEndpoints;
