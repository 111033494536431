import { batch } from "react-redux";
import { logOut, setAccessToken } from "../slices/auth/authSlice";
import { resetCurrentScenario } from "./scenarioThunks";
import { resetEvents } from "../slices/events/eventsSlice";

import * as acmeAuth from "../../utils/api/acme/authentication";
import { persistor } from "../../app/AppProvider";

import { trogdorApi, acmeApi } from "../../redux/rtk/api";

export const logOff = () => async (dispatch) => {
  try {
    batch(() => {
      dispatch(resetCurrentScenario());
      dispatch(trogdorApi.util.resetApiState());
      dispatch(acmeApi.util.resetApiState());
      dispatch(resetEvents());
      dispatch(logOut());
      acmeAuth.logoff();
      persistor.purge();
    });
  } catch (error) {
    console.error("Log off error", error.response);
  }
};

export const refreshAccessToken = (refreshToken) => {
  return async (dispatch) => {
    const onSuccess = async (response) => {
      await dispatch(setAccessToken(response.data));
      return response;
    };
    const onError = (error) => {
      console.error("accessToken refresh error", error.response);
      return error;
    };
    try {
      const response = await acmeAuth.renewAccessToken(refreshToken);
      return onSuccess(response);
    } catch (error) {
      onError(error);
    }
  };
};
