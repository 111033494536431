import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "../utils/Hooks/reduxHooks";
import { getUsername } from "../redux/selectors/authSelector";
import { PrivateRoute } from "../Components/Common/PrivateRoute";
import Login from "../Components/Auth/Login";
import ScenarioRouter from "../Components/Scenarios/ScenarioRouter";
import { useLazyGetEventTypesQuery } from "../redux/rtk/endpoints/initialization";

const App = () => {
  const username = useAppSelector(getUsername);
  const [eventTypesTrigger] = useLazyGetEventTypesQuery();

  useEffect(() => {
    if (username) {
      eventTypesTrigger();
    }
  }, [username]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/scenarios/*"
        element={
          <PrivateRoute>
            <ScenarioRouter />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default App;
