import { motion } from "framer-motion";
import Box from "@mui/material/Box";
import LeftPanel from "../../LeftPanel";

type RightBehaviorProps = Children & {
  duration: number;
  isAnimate: boolean;
};

const RigthBehavior = ({
  children,
  duration,
  isAnimate,
}: RightBehaviorProps) => (
  <Box
    display="grid"
    gridTemplateColumns="10px 500px"
    width={510}
    position="fixed"
    component={motion.div}
    initial={{ x: isAnimate ? 520 : -10 }}
    animate={{ x: -10 }}
    exit={{ x: isAnimate ? 520 : -10 }}
    transition={{ duration }}
    // needs to be set under render window z-index of 200
    zIndex={100}
  >
    <Box
      component="span"
      bgcolor={({ astro }) => astro.color.background.base.default}
    />
    <LeftPanel>{children}</LeftPanel>
  </Box>
);

export default RigthBehavior;
