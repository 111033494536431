import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import Common from "../../utils/common";

const currentScenarioEvents = (state) => state.currentScenario.events.events;
const currentScenarioTLEsByEventId = (state) =>
  state.currentScenario.preEventTLEs.satsByEventId;
const currentScenarioSVsByEventId = (state) =>
  state.currentScenario.postEventSVs.stateVectorsByEventId;
const currentScenarioEphem = (state) =>
  state.currentScenario.postEventSVs.ephemByStateVectorId;

const liveEventDetails = (state) => state.liveEvent.eventDetails;
const liveEventTLEs = (state) => state.liveEvent.preEventTLEs;
const liveEventSVs = (state) => state.liveEvent.postEventSVs;
const liveEventEphemeris = (state) => state.liveEvent.ephemeris;

export const getTimelineEventIds = createSelector(
  [currentScenarioEvents, liveEventDetails],
  (currentScenarioEvents, liveEventDetails) => {
    const timelineEvents = { ...currentScenarioEvents };
    if (liveEventDetails?.id) {
      const editedEventId = liveEventDetails.editedEventId;
      delete timelineEvents[editedEventId];
      timelineEvents[liveEventDetails.id] = liveEventDetails;
    }
    return Common.eventIdsByTriggerTime(timelineEvents);
  }
);

export const getTimelineEventsById = createSelector(
  [currentScenarioEvents, liveEventDetails],
  (currentScenarioEvents, liveEventDetails) => {
    const timelineEventsById = { ...currentScenarioEvents };
    if (liveEventDetails?.id) {
      const editedEventId = liveEventDetails.editedEventId;
      timelineEventsById[liveEventDetails.id] = liveEventDetails;
      delete timelineEventsById[editedEventId];
    }
    return timelineEventsById;
  }
);

export const getTimelineTLEs = createSelector(
  [currentScenarioTLEsByEventId, liveEventTLEs, liveEventDetails],
  (currentScenarioTLEsByEventId, liveEventTLEs, liveEventDetails) => {
    let timelineTLEs = [];
    let timelineTLEsByEventId = currentScenarioTLEsByEventId;
    // If populated
    if (liveEventTLEs[0]) {
      // If Creating
      if (liveEventDetails.editedEventId === undefined) {
        timelineTLEs = Object.values(timelineTLEsByEventId).flat();
      } else {
        const editedEventId = liveEventDetails.editedEventId;
        timelineTLEs = _.omit(timelineTLEsByEventId, editedEventId);
        timelineTLEs = Object.values(timelineTLEs).flat();
      }
      timelineTLEs.push(liveEventTLEs[0]);
    } else {
      timelineTLEs = Object.values(timelineTLEsByEventId).flat();
    }
    // Remove scenario space track sat if event targets same sat number
    const scenarioSatsSatNos =
      currentScenarioTLEsByEventId["temp"]?.map((sat) =>
        sat.satNo.toString()
      ) || [];
    const eventSatsIdOnOrbits = Object.values(
      _.omit(currentScenarioTLEsByEventId, "temp")
    )
      .concat(liveEventTLEs)
      .flat()
      .map((sat) => sat.idOnOrbit);

    const filteredForDuplicateSats = timelineTLEs.flat().filter((sat) => {
      const satNo = sat?.satNo?.toString();
      if (
        !(
          sat.idElset === undefined &&
          eventSatsIdOnOrbits.includes(satNo) &&
          scenarioSatsSatNos.includes(satNo)
        )
      )
        return true;
    });

    timelineTLEs = filteredForDuplicateSats;
    // Adding check for valid TLE values to prevent bad TLEs from making it to 3JS
    return timelineTLEs.filter((TLE) => {
      if ("line1" in TLE && "line2" in TLE) return TLE;
    });
    // return timelineTLEs.flat()
  }
);

export const getTimelineTLEsByEventId = createSelector(
  [currentScenarioTLEsByEventId, liveEventTLEs, liveEventDetails],
  (currentScenarioTLEsByEventId, liveEventTLEs, liveEventDetails) => {
    const timelineTLEsByEventId = { ...currentScenarioTLEsByEventId };
    if (liveEventTLEs.length && liveEventDetails?.id) {
      const editedEventId = liveEventDetails.editedEventId;
      delete timelineTLEsByEventId[editedEventId];
      timelineTLEsByEventId[liveEventDetails.id] = liveEventTLEs;
    }
    return timelineTLEsByEventId;
  }
);

export const getTimelineSVs = createSelector(
  [
    currentScenarioSVsByEventId,
    liveEventSVs,
    currentScenarioEphem,
    liveEventEphemeris,
    liveEventDetails,
  ],
  (
    currentScenarioSVsByEventId,
    liveEventSVs,
    currentScenarioEphem,
    liveEventEphemeris,
    liveEventDetails
  ) => {
    let timelineSVs = [];
    let timelineSVsByEventId = currentScenarioSVsByEventId;
    // If Populated
    if (liveEventSVs[0]) {
      // If Creating
      if (liveEventDetails.editedEventId === undefined) {
        timelineSVs = Object.values(timelineSVsByEventId).flat();
      } else {
        const editedEventId = liveEventDetails.editedEventId;
        timelineSVs = _.omit(timelineSVsByEventId, editedEventId);
        timelineSVs = Object.values(timelineSVs).flat();
      }
      timelineSVs.push(liveEventSVs[0]);
    } else {
      timelineSVs = Object.values(timelineSVsByEventId).flat();
    }
    const unfilteredSVs = timelineSVs;
    const filteredForEphemerisSVs = unfilteredSVs.filter(
      (sat) =>
        sat.id in { ...currentScenarioEphem } ||
        sat.id === liveEventEphemeris.id
    );
    return filteredForEphemerisSVs;
  }
);

export const getTimelineSVsByEventId = createSelector(
  [currentScenarioSVsByEventId, liveEventSVs, liveEventDetails],
  (currentScenarioSVsByEventId, liveEventSVs, liveEventDetails) => {
    const timelineSVsByEventId = { ...currentScenarioSVsByEventId };
    if (liveEventSVs.length && liveEventDetails?.id) {
      const editedEventId = liveEventDetails.editedEventId;
      delete timelineSVsByEventId[editedEventId];
      timelineSVsByEventId[liveEventDetails.id] = liveEventSVs;
    }
    return timelineSVsByEventId;
  }
);

export const getTimelineEphemBySVId = createSelector(
  [currentScenarioEphem, liveEventEphemeris],
  (currentScenarioEphem, liveEventEphemeris) => {
    const timelineEphemerisBySVId = { ...currentScenarioEphem };
    if (liveEventEphemeris?.id) {
      timelineEphemerisBySVId[liveEventEphemeris?.id] =
        liveEventEphemeris.ephemerisPoints;
    }
    return timelineEphemerisBySVId;
  }
);
