/* eslint-disable react/no-unknown-property */
import { useRef, useMemo } from "react";
import * as THREE from "three";
import { addMinutes, differenceInMinutes } from "date-fns";
import { Line } from "@react-three/drei";
import {
  getPositionFromSV,
  calculateMeanMotion,
} from "../../../utils/propagation";
import { useSatelliteContext } from "../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useSatStateContext } from "../../../utils/Hooks/contextHooks/useSatStateContext";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import { EventStateVector } from "../../../redux/rtk/types/trogdorApiTypes";
import { EventDataForObjects } from "../../../redux/rtk/types/ThreeJSCommonTypes";

type PropTypes = {
  isSelected: boolean;
  isHovered: boolean;
  isHoveredActive: boolean;
  hasManualOrbit?: boolean;
  eventData: EventDataForObjects;
  satellite: EventStateVector;
  eventStart: Date;
  endOfRev: any;
  TLEGhostOrbitPoints: THREE.Vector3[];
};

const SVGhostOrbit = ({
  isSelected,
  isHovered,
  isHoveredActive,
  hasManualOrbit,
  eventData,
  satellite,
  eventStart,
  endOfRev,
  TLEGhostOrbitPoints,
}: PropTypes) => {
  const { ephemerisBySVId } = useSatelliteContext();
  const { removeSelected, setHoveredSelected, clearHoveredSelected } =
    useSatStateContext();
  const { orbitIsDragging } = useCanvasContext();

  const SVSatId = satellite?.id;
  const TLESatId = eventData?.tleId;

  const revsPerDay = useMemo(() => calculateMeanMotion(satellite), [satellite]);
  const minutesPerRev = useMemo(() => 1440 / Number(revsPerDay), [revsPerDay]);
  const lineSegmentInterval = useRef(isHovered ? 2 : 1);

  const allSVGhostOrbitPoints = useMemo(() => {
    if (typeof eventStart === "undefined") return [];

    const SVOrbitPoints = [];

    for (let i = 0; i <= minutesPerRev; i += lineSegmentInterval.current) {
      const date = addMinutes(eventStart, i);
      const getPos = getPositionFromSV(
        date,
        ephemerisBySVId[SVSatId]
      )?.position;

      if (!getPos) continue;

      SVOrbitPoints.push(getPos);
    }

    return SVOrbitPoints;
  }, [eventStart, ephemerisBySVId, minutesPerRev]);

  const SVGhostOrbitPointsArr = useMemo(() => {
    const orbitEnd = addMinutes(eventStart, minutesPerRev);
    const minutesToCut = differenceInMinutes(orbitEnd, endOfRev);
    const indexesToCut = minutesToCut / lineSegmentInterval.current;

    return allSVGhostOrbitPoints.slice(
      0,
      allSVGhostOrbitPoints.length - indexesToCut
    );
  }, [endOfRev, minutesPerRev, lineSegmentInterval.current]);

  // __ Internal _______________________________________

  const SVcolorHandler = useMemo(
    () => (isHovered ? "#40ff40" : hasManualOrbit ? "grey" : "#9fff9f"),
    [isHovered, hasManualOrbit]
  );

  const onClickHandler = () => {
    if (isSelected && !hasManualOrbit) {
      removeSelected(TLESatId);
      clearHoveredSelected();
    }
  };

  const onPointerOverHandler = () => {
    if (orbitIsDragging) return;
    if (isSelected && !hasManualOrbit) {
      setHoveredSelected(TLESatId);
    }
  };

  const onPointerOutHandler = () => {
    if (isSelected && !hasManualOrbit) {
      clearHoveredSelected();
    }
  };

  return (
    <>
      {TLEGhostOrbitPoints.length ? (
        <>
          <Line
            name={"TLEGhostOrbitPoints"}
            onClick={onClickHandler}
            onPointerOver={onPointerOverHandler}
            onPointerOut={onPointerOutHandler}
            points={TLEGhostOrbitPoints}
            color={"#525252"}
            linewidth={isHoveredActive ? 5 : 3} // px
            blending={isHovered ? THREE.AdditiveBlending : THREE.NormalBlending}
            dashed={isHovered || hasManualOrbit}
            depthFunc={THREE.LessDepth}
            dashSize={150}
            gapSize={150}
            dashOffset={40}
          />
        </>
      ) : null}
      {SVGhostOrbitPointsArr?.length ? (
        <>
          <Line
            name={"SVGhostOrbitPointsArr"}
            onClick={onClickHandler}
            onPointerOver={onPointerOverHandler}
            onPointerOut={onPointerOutHandler}
            points={SVGhostOrbitPointsArr}
            color={SVcolorHandler}
            opacity={isHovered || hasManualOrbit ? 0.6 : 1.0}
            transparent
            linewidth={isHoveredActive ? 5 : 3} // px
            blending={isHovered ? THREE.AdditiveBlending : THREE.NormalBlending}
            dashed={isHovered || hasManualOrbit}
            depthFunc={THREE.LessDepth}
            dashSize={150}
            gapSize={150}
            dashOffset={40}
          />

          <mesh position={SVGhostOrbitPointsArr[0]}>
            <sphereGeometry args={[100, 16, 16]} />
            <meshBasicMaterial
              color={SVcolorHandler}
              opacity={isHovered || hasManualOrbit ? 0.6 : 1.0}
            />
          </mesh>
        </>
      ) : null}
    </>
  );
};

export default SVGhostOrbit;
