import { PerspectiveCamera } from "@react-three/drei";
import { SheetProvider, editable as e } from "@theatre/r3f";

import { useLoginCanvasContext } from "../../utils/Hooks/contextHooks/useLoginCanvasContext";

import Engine from "./Engine";

type PropTypes = {
  loginContainerRef: React.RefObject<HTMLDivElement>;
};

const LoginScene = ({ loginContainerRef }: PropTypes) => {
  const { earthRadius, maxDistanceFactor, demoSheet } = useLoginCanvasContext();

  const NEAR = 0.001,
    FAR = earthRadius * maxDistanceFactor * 2.4;

  const EditableCamera = e(PerspectiveCamera, "perspectiveCamera");

  return (
    <SheetProvider sheet={demoSheet}>
      <EditableCamera
        theatreKey="Camera"
        makeDefault
        near={NEAR}
        far={FAR}
        position={[30000, 10000, -30000]}
        ref={loginContainerRef}
      />
      <Engine />
    </SheetProvider>
  );
};

export default LoginScene;
