// Hooks
import { useEffect } from "react";
import {
  useParams,
  useNavigate,
  useLocation,
  Outlet,
  Params,
} from "react-router-dom";
import { useDateTimeContext } from "../../../utils/Hooks/contextHooks/useDateTimeContext";
import { useDrawerContext } from "../../../utils/Hooks/contextHooks/useDrawerContext";

// Components
import { Grid, Stack, Typography, Box } from "@mui/material";
import SaveFooter from "../../Common/Layout/SaveFooter/SaveFooter";
import { RuxTab, RuxTabs, RuxButton, RuxIcon } from "@astrouxds/react";
import { AcmeTheme } from "../../../themes/acmeTheme";
import Tooltip from "@mui/material/Tooltip";

// Utilities
import { formatInTimeZone } from "date-fns-tz";
import Common from "../../../utils/common";
import _ from "lodash";
import { RuxIndeterminateProgress } from "@astrouxds/react";
import { useFetchCurrentScenario } from "../../../utils/Hooks/useFetchCurrentScenario";

const styles = {
  scenarioName: {
    marginRight: "0.5rem",
    textAlign: "left",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  scenarioID: {
    marginTop: "auto",
    color: (theme: AcmeTheme) => theme.astro.palette.grey["500"],
    display: "inline",
  },
  descriptionText: {
    color: (theme: AcmeTheme) => theme.astro.color.text.secondary,
    textOverflow: "ellipsis",
    height: "14px",
  },
  dateSX: {
    fontSize: (theme: AcmeTheme) => theme.astro.typography.fontSize.xs,
    fontWeight: (theme: AcmeTheme) => theme.astro.typography.fontWeight.bold,
    lineHeight: "14px",
  },
  timeSX: {
    fontSize: "10px",
    lineHeight: "12px",
  },
  dataAndTimeContainer: {
    backgroundColor: (theme: AcmeTheme) =>
      theme.astro.color.background.surface.header,
    border: "1px solid #101923",
    borderRadius: (theme: AcmeTheme) => theme.astro.radius.base,
    justifyContent: "space-between",
    width: "18.875rem",
    height: "2.5625rem",
    gap: "0.1875rem",
    padding: ".3125rem 0.9375rem .3125rem 0.9375rem",
    marginTop: "1.25rem",
  },
  scenarioPanelContainerSX: {
    height: "100%",
    backgroundColor: (theme: AcmeTheme) =>
      theme.astro.color.background.surface.default,
    position: "relative",
  },
  scenarioDetailWrapperSX: {
    height: "13.625rem",
    borderBottom: "1px solid black",
  },
  scenarioInfoSX: {
    textAlign: "left",
    height: "2.875rem",
    textOverflow: "ellipsis",
  },
  scenarioDetailTitleWrapperSX: {
    padding: "1.25rem 1.25rem 0 1.25rem",
    height: "100%",
  },
  dateTimeArrowWrapperSX: {
    margin: "0",
    display: "flex",
    justifyContent: "center",
  },
  datesContainer: {
    textAlign: "left",
    gap: "3px",
  },
  arrowIcon: {
    color: "#374E65",
    margin: "auto",
  },
  loadingScreen: {
    fontSize: "28px",
    textAlign: "center",
    justifyContent: "center",
    marginTop: "17rem",
  },
};

const ScenarioDetailsPanel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { scenarioId }: Readonly<Params<string>> = useParams();
  const { setDateTime } = useDateTimeContext();
  const { openEditScenario } = useDrawerContext();

  const { scenario, isLoadingScenario, isError } = useFetchCurrentScenario(
    Number(scenarioId)
  );

  const endOfPath = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    // Set engine date time to scenario start on initial load
    if (scenario.startTime) setDateTime(new Date(scenario.startTime));
  }, [scenario]);

  if (isLoadingScenario || _.isEmpty(scenario))
    return (
      <Box component={"div"} sx={styles.loadingScreen}>
        <RuxIndeterminateProgress style={{ marginTop: "3.5rem" }} />
        <Box component={"div"}>
          <Typography variant="h2">Loading...</Typography>
        </Box>
      </Box>
    );
  if (!scenario)
    return (
      <>
        <RuxButton
          icon="arrow-back-ios"
          onClick={() => navigate(`/scenarios`)}
          style={{ marginTop: "1rem" }}
          borderless
        >
          Back to Scenarios
        </RuxButton>
        <Box component={"div"} sx={styles.loadingScreen}>
          <RuxIcon icon="warning" size="4.5rem" />
          <Typography variant="h2">No scenario matches that id.</Typography>
        </Box>
      </>
    );
  if (isError)
    return (
      <>
        <RuxButton
          icon="arrow-back-ios"
          onClick={() => navigate(`/scenarios`)}
          style={{ marginTop: "1rem" }}
          borderless
        >
          Back to Scenarios
        </RuxButton>
        <Box component={"div"} sx={styles.loadingScreen}>
          <RuxIcon icon="error-outline" size="4.5rem" />
          <Typography variant="h2">
            Error retrieving scenario data. <br /> Please try again.
          </Typography>
        </Box>
      </>
    );

  return (
    <Grid
      sx={styles.scenarioPanelContainerSX}
      data-test="scenario-details-container"
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        sx={styles.scenarioDetailWrapperSX}
        wrap="nowrap"
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          sx={styles.scenarioDetailTitleWrapperSX}
          wrap="nowrap"
        >
          <Grid container item direction="row-reverse" wrap="nowrap">
            <RuxButton
              borderless
              size="small"
              icon="create"
              className="edit-scenario-button"
              data-test="edit-scenario-button"
              onClick={() => openEditScenario(scenario.id)}
            >
              Edit details
            </RuxButton>
          </Grid>
          <Stack sx={styles.scenarioInfoSX}>
            <Box component={"div"} display="flex">
              <Typography
                variant="h2"
                data-test="scenario-details-name"
                sx={styles.scenarioName}
              >
                {scenario.name}
              </Typography>
              <Typography variant="h6" sx={styles.scenarioID}>
                {` ID# ${scenario.id} `}
              </Typography>
            </Box>
            <Typography
              variant="body3"
              data-test="scenario-details-description"
              sx={styles.descriptionText}
            >
              {scenario.description}
            </Typography>
          </Stack>
          <Stack sx={styles.dataAndTimeContainer} direction="row" spacing={2}>
            <Grid container wrap="nowrap" sx={{ width: "fit-content" }}>
              <Box component={"div"} sx={{ marginRight: "0.625rem" }}>
                <RuxIcon
                  icon="calendar-today"
                  size="1rem"
                  style={{ color: "#FFFFFF" }}
                />
              </Box>
              <Tooltip
                title={formatInTimeZone(
                  scenario.startTime,
                  "UTC",
                  Common.DISPLAY_DATETIME_FORMAT
                )}
              >
                <Stack direction="column" sx={styles.datesContainer}>
                  <Typography
                    data-test="scenario-details-start-date"
                    sx={styles.dateSX}
                  >
                    {formatInTimeZone(
                      scenario.startTime,
                      "UTC",
                      Common.JULIAN_DATE
                    )}
                  </Typography>
                  <Typography
                    data-test="scenario-details-start-time"
                    sx={styles.timeSX}
                  >
                    {formatInTimeZone(
                      scenario.startTime,
                      "UTC",
                      Common.MILISEC_TIME_FORMAT
                    )}
                  </Typography>
                </Stack>
              </Tooltip>
            </Grid>
            <Box component={"div"} sx={styles.dateTimeArrowWrapperSX}>
              <RuxIcon
                icon="arrow-forward"
                size="1.5rem"
                style={styles.arrowIcon}
              />
            </Box>
            <Grid container wrap="nowrap" sx={{ width: "fit-content" }}>
              <Box component={"div"} sx={{ marginRight: "0.625rem" }}>
                <RuxIcon
                  icon="calendar-today"
                  size="1rem"
                  style={{ color: "#FFFFFF" }}
                />
              </Box>
              <Tooltip
                title={formatInTimeZone(
                  scenario.endTime,
                  "UTC",
                  Common.DISPLAY_DATETIME_FORMAT
                )}
              >
                <Stack direction="column" sx={styles.datesContainer}>
                  <Typography
                    data-test="scenario-details-end-date"
                    sx={styles.dateSX}
                  >
                    {formatInTimeZone(
                      scenario.endTime,
                      "UTC",
                      Common.JULIAN_DATE
                    )}
                  </Typography>
                  <Typography
                    data-test="scenario-details-end-time"
                    sx={styles.timeSX}
                  >
                    {formatInTimeZone(
                      scenario.endTime,
                      "UTC",
                      Common.MILISEC_TIME_FORMAT
                    )}
                  </Typography>
                </Stack>
              </Tooltip>
            </Grid>
          </Stack>
          <Grid container justifyContent="center" item>
            <RuxTabs small>
              <RuxTab
                data-test="scenario-details-events-tab"
                selected={endOfPath !== "objects"}
                onClick={() =>
                  endOfPath === "events"
                    ? null
                    : navigate(`/scenarios/${scenarioId}/events`)
                }
              >
                Events
              </RuxTab>
              <RuxTab
                data-test="scenario-details-objects-tab"
                selected={endOfPath === "objects"}
                onClick={() =>
                  endOfPath === "objects"
                    ? null
                    : navigate(`/scenarios/${scenarioId}/objects`)
                }
              >
                Objects
              </RuxTab>
            </RuxTabs>
          </Grid>
        </Grid>
      </Grid>
      {/* Outlet for the events and objects routes */}
      <Outlet />
      <SaveFooter />
    </Grid>
  );
};

export default ScenarioDetailsPanel;
