import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

type HeaderListItem = {
  name: string;
  marginLeft?: string | null;
};

type HeaderListProps = {
  columnStyle?: string;
  items: HeaderListItem[];
};

const LeftPanelListHeaders = ({
  columnStyle,
  items = [{ name: "One" }, { name: "Two" }, { name: "Three" }],
}: HeaderListProps) => {
  const style = {
    container: {
      display: "grid",
      gridTemplateColumns: columnStyle
        ? columnStyle
        : `repeat(${items.length}, 1fr)`,
      alignItems: "start",
      width: "100%",
      padding: "5px 0 5px 1rem",
      borderTop: "1px solid black",
      boxShadow: "0px 3px 7px 0px rgb(0 0 0 / 50%)",
      color: (theme: any) => theme.astro.color.text.placeholder,
    },
    text: {
      textAlign: "left",
    },
  };

  return (
    <Stack sx={style.container} data-test="list-panel-headers">
      {items.map((item: HeaderListItem, index) => {
        if (item?.name) {
          return (
            <Typography
              key={index}
              variant="body3"
              sx={
                item.marginLeft
                  ? {
                      marginLeft: item.marginLeft,
                    }
                  : style.text
              }
            >
              {item.name}
            </Typography>
          );
        } else {
          return null;
        }
      })}
    </Stack>
  );
};

export default LeftPanelListHeaders;
