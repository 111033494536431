import _ from "lodash";
import { ListItemButton, ListItemText, Box, Typography } from "@mui/material";
import { RuxButton } from "@astrouxds/react";
import { AcmeTheme } from "../../../../themes/acmeTheme";
import { useSatStateContext } from "../../../../utils/Hooks/contextHooks/useSatStateContext";
import { useDialog } from "../../../../utils/Hooks/useDialog";
import { getCurrentScenarioTLEsByEventId } from "../../../../redux/selectors/scenarioSelector";
import { removeScenarioTLE } from "../../../../redux/thunks/scenarioThunks";
import RemoveObjectDialog from "../../../Dialogs/RemoveObjectDialog";
import { MutableRefObject } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../utils/Hooks/reduxHooks";
import React from "react";

export type ObjectProps = {
  id: string;
  name: string;
  idOnOrbit: string;
  origNetwork: string;
  idStateVector: string;
  type: string;
};

type PropTypes = {
  object: ObjectProps;
  scenarioId: number;
  listItemRefs: MutableRefObject<{
    [key: string]: HTMLElement | null;
  }>;
};

const ObjectItem = ({ object, scenarioId, listItemRefs }: PropTypes) => {
  const dispatch = useAppDispatch();
  const { openDialog, WithCurrentDialog } = useDialog();
  const {
    selectedSatIds,
    addSelected,
    removeSelected,
    hoveredSatId,
    setHovered,
    hoveredSelectedSatId,
    setHoveredSelected,
    clearHoveredSelected,
  } = useSatStateContext();
  const { id, name, idOnOrbit, origNetwork, idStateVector, type } = object;

  const currentScenarioTLEsByEventId = useAppSelector(
    getCurrentScenarioTLEsByEventId
  );
  const isSelected = selectedSatIds.includes(id);

  //removes 'temp' event from currentScenarioTLEsByEvetId for event associated sats check
  const onlyEventTLEIds = Object.values(
    _.omit(currentScenarioTLEsByEventId, "temp")
  )
    .flat()
    .map((sat) => sat.id);

  const removeObjHandler = async (e: React.MouseEvent) => {
    // * Stop event from bubbling to onClickHandler
    e.stopPropagation();

    if (hoveredSatId === id) setHovered(null);
    if (isSelected) removeSelected(id);
    if (hoveredSelectedSatId) clearHoveredSelected();

    // shows dialog only if satellite is associated with an event
    const satForRemoval = { id, scenarioId, type };
    if (onlyEventTLEIds.includes(id) || idStateVector) {
      openDialog(<RemoveObjectDialog item={satForRemoval} />);
    } else {
      dispatch(removeScenarioTLE(id, scenarioId));
    }
  };

  const onClickHandler = () => {
    // if (isSatSupressed()) return;
    if (isSelected) {
      removeSelected(id);
    } else if (!isSelected) {
      addSelected(id);
    }
  };

  // const isSatSupressed = useCallback(() => {
  //   return !threeSatelliteIds.includes(id.toString());
  // }, [id, threeSatelliteIds]);

  const onPointerEnterHandler = () => {
    // if (isSatSupressed()) return;
    if (isSelected) {
      setHoveredSelected(id);
    } else {
      setHovered(id);
    }
  };

  const onPointerLeaveHandler = () => {
    if (!isSelected) setHovered(null);
    if (hoveredSelectedSatId) clearHoveredSelected();
  };

  const isHovered = hoveredSatId === id;

  const styles = {
    objectListItem: (theme: AcmeTheme) => ({
      ...(isHovered && {
        backgroundColor: theme.astro.color.background.surface.selected,
      }),
      borderLeft: "3px solid transparent",
      ...(isSelected && {
        backgroundColor: "#1C3F5E",
        borderLeft: "3px solid #4DACFF",
      }),
      ...(hoveredSelectedSatId === id && { backgroundColor: "#214B6F" }),
    }),
    objectID: {
      color: (theme: AcmeTheme) => theme.astro.palette.grey["500"],
      display: "inline-block",
    },
    listItemSecondary: {
      width: "40%",
      marginRight: "5px",
    },
  };

  return WithCurrentDialog(
    <ListItemButton
      selected={isSelected}
      sx={styles.objectListItem}
      data-test="objects-list-item"
      id={id}
      onPointerEnter={onPointerEnterHandler}
      onClick={onClickHandler}
      onPointerLeave={onPointerLeaveHandler}
      ref={(el) => (listItemRefs.current[object.id] = el)}
    >
      <ListItemText
        primary={
          <>
            <Box component="span">{name} </Box>
            <Box component="span" sx={styles.objectID}>
              <Typography variant="body1">{` ID# ${idOnOrbit}`}</Typography>
            </Box>
          </>
        }
        secondary={
          type === "TLE"
            ? `${type} \u2022 ${origNetwork}`
            : `State Vector ID: ${idStateVector}`
        }
        sx={styles.listItemSecondary}
      />
      <RuxButton
        secondary
        size="small"
        onClick={removeObjHandler}
        data-test="remove-object-button"
      >
        Remove
      </RuxButton>
    </ListItemButton>
  );
};

export default ObjectItem;
