import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Grid from "@mui/material/Grid";
import GlobalStatusBar from "../GlobalStatusBar/GlobalStatusBar";
import SlideInPanel from "../Common/Layout/LeftPanel/SlideInPanel/SlideInPanel";
import RenderWindow from "../RenderWindow/RenderWindow";
import { SatelliteContextProvider } from "../../utils/Hooks/contextHooks/useSatelliteContext";
import { SatStateContextProvider } from "../../utils/Hooks/contextHooks/useSatStateContext";
import { DateTimeContextProvider } from "../../utils/Hooks/contextHooks/useDateTimeContext";
import { AnimationProvider } from "../../utils/Hooks/contextHooks/useAnimationContext";
import { CanvasContextProvider } from "../../utils/Hooks/contextHooks/useCanvasContext";
import EventList from "../../Components/Events/EventList/EventList";
import ObjectList from "../../Components/Objects/ObjectList/ObjectList";
import PlaybackControls from "../Scene/dom/controls/PlaybackControls/PlaybackControls";
import Drawers from "../Dialogs/Drawers";

const styles = {
  grid: {
    // 2 column grid at 500px for left panel and fill the rest with render window
    display: "grid",
    gridTemplateColumns: "31.25rem 1fr",
    p: "1.25rem",
    gap: "0.625rem",
    overflow: "hidden",
  },
  playbackControlWrapper: {
    position: "relative",
    height: "calc(50vh - 2.5rem)",
  },
};

const ScenarioRouter = () => {
  const location = useLocation();
  const isCypress = !!process.env.REACT_APP_CYPRESS;

  return (
    <DateTimeContextProvider>
      <SatStateContextProvider>
        <SatelliteContextProvider>
          <GlobalStatusBar />
          <Grid sx={styles.grid} id="grid">
            <Grid>
              <AnimationProvider location={location}>
                <AnimatePresence>
                  <Routes location={location} key={location.key}>
                    <Route
                      path="/"
                      element={<SlideInPanel type="scenarios-list" />}
                    />
                    <Route
                      path="create"
                      element={<SlideInPanel type="create-scenario" />}
                    />
                    <Route
                      path=":scenarioId"
                      element={<SlideInPanel type="scenario-details" />}
                    >
                      <Route index element={<EventList />} />
                      <Route path="events" element={<EventList />} />
                      <Route path="objects" element={<ObjectList />} />
                    </Route>
                  </Routes>
                </AnimatePresence>
              </AnimationProvider>
            </Grid>
            {!isCypress ? (
              <RenderWindow />
            ) : (
              <CanvasContextProvider>
                <Grid sx={styles.playbackControlWrapper}>
                  <PlaybackControls />
                </Grid>
              </CanvasContextProvider>
            )}
          </Grid>
          <Drawers />
        </SatelliteContextProvider>
      </SatStateContextProvider>
    </DateTimeContextProvider>
  );
};

export default ScenarioRouter;
