import { createContext, useContext, useRef, useEffect, useState } from "react";
import { Location } from "react-router-dom";

type AnimationContextProps = {
  prevLocation: Location | null;
  isInitialPageLoad: boolean;
  isAnimateLeftBehavior: boolean;
  isAnimateRightBehavior: boolean;
  setIsScenarioDetailsAnimation: React.Dispatch<React.SetStateAction<boolean>>;
};

const AnimationContext = createContext<AnimationContextProps>({
  isInitialPageLoad: true,
  isAnimateLeftBehavior: false,
  isAnimateRightBehavior: false,
  prevLocation: null,
  setIsScenarioDetailsAnimation: () => null,
});

export const useAnimationContext = () => useContext(AnimationContext);

type AnimationProviderProps = Children & {
  location: Location;
};

export const AnimationProvider = (props: AnimationProviderProps) => {
  const { location, children } = props;
  const prevLocation = useRef<Location | null>(null);
  // prettier-ignore
  const [isScenarioDetailsAnimation, setIsScenarioDetailsAnimation] = useState(false);

  useEffect(() => {
    prevLocation.current = location;
  }, [location]);

  const prevPathname = prevLocation.current?.pathname;
  const isInitialPageLoad = !prevPathname;

  const isAnimateRightBehavior =
    isScenarioDetailsAnimation ||
    prevPathname === "/scenarios" ||
    prevPathname === "/scenarios/create";

  const isAnimateLeftBehavior = prevPathname?.split("/")[3] !== "edit";

  const value: AnimationContextProps = {
    isAnimateLeftBehavior,
    isAnimateRightBehavior,
    isInitialPageLoad,
    prevLocation: prevLocation.current,
    setIsScenarioDetailsAnimation,
  };

  return (
    <AnimationContext.Provider value={value}>
      {children}
    </AnimationContext.Provider>
  );
};
