import { TrogdorResponse } from "./types";

export type TrogdorPromiseAllErrorCheck = {
  error: boolean;
  errorMessage: string;
};

export const trogdorPromiseAllErrorCheck = (
  promiseArr: TrogdorResponse[]
): TrogdorPromiseAllErrorCheck => {
  let errorMessage = "";
  const hasError = !promiseArr.every((response) => {
    if (response.data.errorMessage) {
      errorMessage = response.data.body.errorMessage;
      return false;
    } else return true;
  });
  return { error: hasError, errorMessage };
};
