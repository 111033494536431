// * Helper functions

import { EventSatsResponseParams } from "../redux/rtk/types/trogdorApiTypes";

// Uses slice so endIndex is NOT included
export const subStringReplace = (
  str: string,
  startIndex: number,
  endIndex: number,
  newValue: string
) => {
  if (endIndex - startIndex !== newValue.length) {
    return str;
  }
  return str.slice(0, startIndex) + newValue + str.slice(endIndex);
};

// * Change number to string and add leading blank if < 100
//! NOTE: Do not use if number will not cross 100
export const numToStringForTle = (num: number, length: number) => {
  if (typeof num === "string") num = Number(num);
  if (num < 100) {
    return " " + num.toFixed(length);
  } else {
    return num.toFixed(length);
  }
};

export const defaultSatelliteOptions = {
  orbitMinutes: 0,
  satelliteSize: 150,
};

export const createNewTle2 = (
  dataString: "inclination" | "raOfAscNode" | "meanMotion",
  target: EventSatsResponseParams
) => {
  const newTle2 = {
    inclination: subStringReplace(
      target.tleLine2,
      8,
      16,
      numToStringForTle(target.inclination, 4)
    ),
    raOfAscNode: subStringReplace(
      target.tleLine2,
      17,
      25,
      numToStringForTle(target.raOfAscNode, 4)
    ),
    meanMotion: subStringReplace(
      target.tleLine2,
      52,
      63,
      target.meanMotion.toFixed(8)
    ),
  };
  return newTle2[dataString];
};

export const tle2SliceToNum = (
  dataString: "inclination" | "raOfAscNode" | "meanMotion",
  target: EventSatsResponseParams
) => {
  const tle2NumSlices = {
    inclination: Number(target.tleLine2.slice(8, 16)),
    raOfAscNode: Number(target.tleLine2.slice(17, 25)),
    meanMotion: Number(target.tleLine2.slice(52, 63)),
  };
  return tle2NumSlices[dataString];
};

// For taking changes in OrbitControls, generate a new TLE and feed to the Engine
export const generateNewTLE = (
  target: EventSatsResponseParams,
  updateTargetFunc: (...args: any) => void
) => {
  if (target.inclination !== tle2SliceToNum("inclination", target)) {
    const newTle2 = createNewTle2("inclination", target);
    updateTargetFunc({
      ...target,
      tleLine2: newTle2,
    });
  } else if (target.raOfAscNode !== tle2SliceToNum("raOfAscNode", target)) {
    const newTle2 = createNewTle2("raOfAscNode", target);
    updateTargetFunc({
      ...target,
      tleLine2: newTle2,
    });
  } else if (target.meanMotion !== tle2SliceToNum("meanMotion", target)) {
    const newTle2 = createNewTle2("meanMotion", target);
    updateTargetFunc({
      ...target,
      tleLine2: newTle2,
    });
  }
};
