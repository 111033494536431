import { useAppSelector, useAppDispatch } from "./reduxHooks";
import { trogdorApi } from "../../redux/rtk/api";
import {
  getCurrentScenarioEvents,
  getCurrentScenarioEventIds,
  getCurrentScenarioTLEsByEventId,
  getCurrentScenarioSVsByEventId,
  getCurrentScenario,
} from "../../redux/selectors/scenarioSelector";
import {
  useCreateEventMutation,
  useDeleteEventMutation,
  getEventsById,
  getEventIdsByScenarioId,
} from "../../redux/rtk/endpoints/events";
import { useCreateEventTLEMutation } from "../../redux/rtk/endpoints/tleSats";
import { useCreateEventSVMutation } from "../../redux/rtk/endpoints/svSats";
import { useCreateEventParameterMutation } from "../../redux/rtk/endpoints/eventParameters";
import { Event, TLESat } from "../../redux/rtk/types/internalTypes";
import { updateStatus } from "../../redux/slices/scenarios/currentScenario/details/scenarioDetailsSlice";
import { currentScenarioStatusEnums as statusEnums } from "../../utils/common";

type ReturnType = () => {
  saveCurrentScenario: () => void;
  inProgress: boolean;
  isError: boolean;
};

export const useSaveCurrentScenario: ReturnType = () => {
  const dispatch = useAppDispatch();
  const currentScenario = useAppSelector(getCurrentScenario);
  const trogdorScenarioEventIds: number[] = useAppSelector((state) =>
    getEventIdsByScenarioId(state, currentScenario.id)
  );
  const trogdorEventsById = useAppSelector(getEventsById);
  const currentEvents = useAppSelector(getCurrentScenarioEvents);
  const currentEventIds: string[] = useAppSelector(getCurrentScenarioEventIds);
  const currentTLEs = useAppSelector(getCurrentScenarioTLEsByEventId);
  const currentSVs = useAppSelector(getCurrentScenarioSVsByEventId);

  const [
    createEvent,
    { isLoading: isCreatingEvent, isError: isCreateEventError },
  ]: any = useCreateEventMutation();
  const [
    deleteEvent,
    { isLoading: isDeletingEvent, isError: isDeleteEventError },
  ]: any = useDeleteEventMutation();
  const [
    createEventParameter,
    {
      isLoading: isCreatingEventParameter,
      isError: isCreateEventParameterError,
    },
  ]: any = useCreateEventParameterMutation();
  const [
    createEventTLE,
    { isLoading: isCreatingEventTLE, error: isCreateEventTLEError },
  ]: any = useCreateEventTLEMutation();
  const [
    createEventSVSat,
    { isLoading: isCreatingEventSVSat, isError: isCreateEventSVSatError },
  ]: any = useCreateEventSVMutation();

  const saveCurrentScenario = () => {
    // Delete removed events
    trogdorScenarioEventIds.forEach((eventId: number) => {
      if (!currentEventIds.includes(eventId.toString())) {
        deleteEvent(eventId);
      }
    });

    // Create new events
    currentEventIds.forEach((eventId) => {
      const localEvent = currentEvents[eventId];
      if (!localEvent.changed) return null;

      const eventInList = trogdorEventsById[eventId];

      if (!eventInList) {
        createEvent(localEvent).then(async (response: { data: Event }) => {
          const newEvent = response.data;

          //New event param only needed for suppression events
          if (newEvent.eventType === "Suppression") {
            const [newParam] = localEvent.parameters.parameters;
            createEventParameter({ ...newParam, eventId: newEvent.id });
          }

          // If sat, create satq
          if (currentTLEs[eventId].length) {
            currentTLEs[eventId].forEach((tle: TLESat) => {
              createEventTLE({ eventId: newEvent.id, tleData: tle });
            });
          }

          // If SV, create SV
          if (currentSVs[eventId].length) {
            currentSVs[eventId].forEach(async (sv: any) => {
              createEventSVSat({ eventId: newEvent.id, svData: sv });
            });
          }
        });
      } else {
        // ! Commenting out until we can edit/delete Sats/SVs
        console.log(`edit event fired, eventId: ${eventId}`);
        // const trogdorEvent = await trogdorEvents
        //   .getEvent(eventId)
        //   .then((response) => response.data);
        // const currentEvent = currentEvents[eventId];

        // // Check for event detail changes
        // // TODO: Move Array Definition
        // let detailChanges = false;
        // const eventProps = ["name", "startTime", "endTime", "status"];
        // eventProps.forEach((property) => {
        //   if (currentEvent[property] !== trogdorEvent[property])
        //     detailChanges = true;
        // });
        // if (detailChanges)
        //   await trogdorEvents.editEvent(currentEvent, eventId);

        // // Check for param changes
        // // ? Params are only changed, not created or deleted if type does not change
        // const currentEventParams = currentEvent.parameters.parameters;
        // const trogdorEventParams = trogdorEvent.parameters.parameters;
        // currentEventParams.forEach(async (param) => {
        //   const trogdorParam = trogdorEventParams.find((trogdorParam) => {
        //     return param.paramID === trogdorParam.paramID;
        //   });
        //   if (
        //     trogdorParam &&
        //     param.paramValue !== trogdorParam.paramValue
        //   ) {
        //     await trogdorEvents.updateEventParam(param.paramValue, eventId);
        //   }
        // });
      }
    });
    // invalidate cache for saved scenario
    dispatch(
      trogdorApi.util.invalidateTags([
        { type: "Scenario", id: Number(currentScenario.id) },
      ])
    );
    dispatch(updateStatus(statusEnums.initialState));
  };

  return {
    saveCurrentScenario,
    inProgress: (isCreatingEvent ||
      isDeletingEvent ||
      isCreatingEventParameter ||
      isCreatingEventTLE ||
      isCreatingEventSVSat) as boolean,
    isError: (isCreateEventError ||
      isDeleteEventError ||
      isCreateEventParameterError ||
      isCreateEventTLEError ||
      isCreateEventSVSatError) as boolean,
  };
};
