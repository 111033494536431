/* eslint-disable react/no-unknown-property */
import { useRef, useCallback, useEffect, useState, useMemo } from "react";
import * as THREE from "three";
import { SpotLightHelper } from "three";
import { useHelper, Sphere, useTexture } from "@react-three/drei";
import { earthRadius } from "satellite.js/lib/constants";
import { Lensflare, LensflareElement } from "../aux/Lensflare";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import { useDateTimeContext } from "../../../utils/Hooks/contextHooks/useDateTimeContext";

type PropTypes = {
  center?: THREE.Vector3;
};

const Sun = ({ center = new THREE.Vector3(0, 0, 0) }: PropTypes) => {
  const { dateTime } = useDateTimeContext();
  const {
    lightHelperToggle,
    realTimeSunToggle,
    lensflareToggle,
    maxDistanceFactor,
    setSunLightRef,
  } = useCanvasContext();

  const sunRef = useRef<any>();
  const distanceFromEarth = useRef<any>();
  const storedDistance = useRef(0);

  const [lightHelperIsReady, setLightHelperIsReady] = useState(false);

  const [textureFlare0, textureFlare3] = useTexture([
    process.env.PUBLIC_URL + "/lens-flare/lensflare0.png",
    process.env.PUBLIC_URL + "/lens-flare/lensflare3.png",
  ]);

  const lensFlare = useMemo(() => new Lensflare(), []);
  lensFlare.name = "lensFlare";

  useEffect(() => {
    if (sunRef.current) {
      setSunLightRef(sunRef);
    }
  }, [sunRef, setSunLightRef]);

  // Solution provided by https://stackoverflow.com/questions/28365948/javascript-latitude-longitude-to-xyz-position-on-earth-threejs
  const setSunPos = useCallback(
    (lat: number, lon: number) => {
      const altitude = earthRadius * maxDistanceFactor;
      const phi = (90 - lat) * (Math.PI / 180);
      const theta = (lon + 180) * (Math.PI / 180);

      const x = -(altitude * Math.sin(phi) * Math.cos(theta));
      const z = altitude * Math.sin(phi) * Math.sin(theta);
      const y = altitude * Math.cos(phi);

      sunRef.current.position.set(x, y, z);

      // Set only once
      if (sunRef.current.position.x !== 0) {
        if (lightHelperIsReady === false) {
          setLightHelperIsReady(true);
        }
      }
    },
    [lightHelperIsReady, maxDistanceFactor]
  );

  // code provided by http://www.ne.jp/asahi/hamradio/je9pel/sunpstle.htm
  const getSunCoordinates = useCallback(
    (date: Date) => {
      const rad = 0.017453292519943295;
      // based on NOAA solar calculations
      const mins_past_midnight =
        (date.getUTCHours() * 60 + date.getUTCMinutes()) / 1440;
      const jc = (date.getTime() / 86400000.0 + 2440587.5 - 2451545) / 36525;
      const mean_long_sun =
        (280.46646 + jc * (36000.76983 + jc * 0.0003032)) % 360;
      const mean_anom_sun = 357.52911 + jc * (35999.05029 - 0.0001537 * jc);
      const sun_eq =
        Math.sin(rad * mean_anom_sun) *
          (1.914602 - jc * (0.004817 + 0.000014 * jc)) +
        Math.sin(rad * 2 * mean_anom_sun) * (0.019993 - 0.000101 * jc) +
        Math.sin(rad * 3 * mean_anom_sun) * 0.000289;
      const sun_true_long = mean_long_sun + sun_eq;
      const sun_app_long =
        sun_true_long -
        0.00569 -
        0.00478 * Math.sin(rad * 125.04 - 1934.136 * jc);
      const mean_obliq_ecliptic =
        23 +
        (26 + (21.448 - jc * (46.815 + jc * (0.00059 - jc * 0.001813))) / 60) /
          60;
      const obliq_corr =
        mean_obliq_ecliptic + 0.00256 * Math.cos(rad * 125.04 - 1934.136 * jc);
      const lat =
        Math.asin(Math.sin(rad * obliq_corr) * Math.sin(rad * sun_app_long)) /
        rad;
      const eccent = 0.016708634 - jc * (0.000042037 + 0.0000001267 * jc);
      const y =
        Math.tan(rad * (obliq_corr / 2)) * Math.tan(rad * (obliq_corr / 2));
      const rq_of_time =
        4 *
        ((y * Math.sin(2 * rad * mean_long_sun) -
          2 * eccent * Math.sin(rad * mean_anom_sun) +
          4 *
            eccent *
            y *
            Math.sin(rad * mean_anom_sun) *
            Math.cos(2 * rad * mean_long_sun) -
          0.5 * y * y * Math.sin(4 * rad * mean_long_sun) -
          1.25 * eccent * eccent * Math.sin(2 * rad * mean_anom_sun)) /
          rad);
      const true_solar_time = (mins_past_midnight * 1440 + rq_of_time) % 1440;
      const lng = -(true_solar_time / 4 < 0
        ? true_solar_time / 4 + 180
        : true_solar_time / 4 - 180);

      setSunPos(lat, lng);
    },
    [setSunPos]
  );

  useEffect(() => {
    if (!sunRef.current) return;
    if (realTimeSunToggle) {
      getSunCoordinates(dateTime);
    }
  }, [dateTime, getSunCoordinates, realTimeSunToggle]);

  // Add lensflare
  useEffect(() => {
    if (!sunRef.current) return;
    const lenseFlareExist = sunRef.current.getObjectByName("lensFlare");
    if (typeof lenseFlareExist !== "undefined") return;

    sunRef.current.add(lensFlare);

    lensFlare.addElement(
      new LensflareElement(
        textureFlare0,
        200 * 1.9,
        0.0,
        new THREE.Color("white")
      )
    );
    lensFlare.addElement(
      new LensflareElement(
        textureFlare3,
        30 * 1.9,
        0.06,
        new THREE.Color("#f5c651")
      )
    );
    lensFlare.addElement(
      new LensflareElement(
        textureFlare3,
        70 * 1.9,
        0.1,
        new THREE.Color("#f5c651")
      )
    );
    lensFlare.addElement(
      new LensflareElement(
        textureFlare3,
        110 * 1.9,
        0.15,
        new THREE.Color("#f5c651")
      )
    );
    lensFlare.addElement(
      new LensflareElement(
        textureFlare3,
        60 * 1.9,
        0.19,
        new THREE.Color("#f5c651")
      )
    );
  }, [lensFlare, textureFlare0, textureFlare3]);

  // ____ Toggles _____________________________________

  useEffect(() => {
    if (!sunRef.current) return;
    if (!realTimeSunToggle) {
      sunRef.current.position.set(673, 127930, -294297);
    }
    if (!lightHelperToggle) return;
    // update spotLight distance. Needed for lightHelper _____________
    distanceFromEarth.current = sunRef.current.position.distanceTo(center);

    const isDiffCheck = distanceFromEarth.current !== storedDistance.current;

    if (isDiffCheck) {
      sunRef.current.distance = distanceFromEarth.current * 1.5;
      storedDistance.current = distanceFromEarth.current;
    }
  }, [realTimeSunToggle, lightHelperToggle, center]);

  // lensflareToggle
  useEffect(() => {
    lightHelperToggle
      ? (lensFlare.visible = false)
      : (lensFlare.visible = lensflareToggle);
  }, [lensflareToggle, lensFlare, lightHelperToggle]);

  useHelper(lightHelperToggle && sunRef, SpotLightHelper, "yellow");

  return (
    <>
      <spotLight
        name="Sun"
        ref={sunRef}
        color={"#BFC1D4"}
        intensity={4.5}
        angle={Math.PI / 80}
        penumbra={0.6}
        decay={0}
      >
        {lightHelperToggle && (
          <Sphere args={[5000, 32, 32]} name={"SunLightHelperSphere"}>
            <meshBasicMaterial color="yellow" name={"SunLightHelperMat"} />
          </Sphere>
        )}
      </spotLight>
    </>
  );
};

export default Sun;
