import { createSlice } from "@reduxjs/toolkit";
import Common from "../../../../../utils/common";

const initialState = {
  isCommunicating: false,
  error: null,
  satsByEventId: {},
};

export const preEventTLEsSlice = createSlice({
  name: "currentScenario/preEventTLEs",
  initialState,
  reducers: {
    fetchTLEsStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    fetchTLEsSuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      state.satsByEventId = action.payload;
    },
    copyTLEsSuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      state.satsByEventId[action.payload[0].liveEventId] = action.payload;
    },
    fetchTLEsFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    fetchUdlTLEStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    fetchUdlTLESuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      const preEventSats = state.satsByEventId[action.payload.eventId];
      const replaceIndex = action.payload.replaceIndex;
      if (preEventSats) {
        if (replaceIndex === -1) {
          state.satsByEventId[action.payload.eventId].push(action.payload);
        } else {
          preEventSats[replaceIndex] = action.payload;
        }
      } else {
        state.satsByEventId[action.payload.eventId] = [action.payload];
      }
    },
    replaceEventUdlTLEsSuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      state.satsByEventId[action.payload.eventId] = [action.payload];
    },
    addUdlTLE: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      const preEventSats = state.satsByEventId[action.payload.eventId];
      if (preEventSats) {
        state.satsByEventId[action.payload.eventId].push(action.payload);
      } else {
        state.satsByEventId[action.payload.eventId] = [action.payload];
      }
    },
    fetchUdlTLEFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    saveLocalTLEStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    saveLocalTLESuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      if (action.payload) {
        if (state.satsByEventId["temp"]) {
          state.satsByEventId["temp"].push(action.payload);
        } else {
          state.satsByEventId["temp"] = [action.payload];
        }
      }
    },
    saveAllLocalTLEs: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      if (action.payload) {
        if (state.satsByEventId["temp"]) {
          const merged = [...state.satsByEventId["temp"], ...action.payload];
          state.satsByEventId["temp"] = merged;
        } else {
          state.satsByEventId["temp"] = action.payload;
        }
      }
    },
    saveLocalTLEFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    removeTLEStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    removeEventPreEventTLEs: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      const eventId = action.payload;
      delete state.satsByEventId[eventId];
    },
    removeTLESuccess: (state, action) => {
      state.isCommunicating = false;
      state.error = null;
      if (action.payload && Common.trogdor) {
        state.satsByEventId["temp"] = state.satsByEventId["temp"].filter(
          (sat) => sat.id !== action.payload.id
        );
      }
    },
    removeTLEFail: (state, action) => {
      state.isCommunicating = false;
      state.error = action.payload;
    },
    createTrogdorTLEStart: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    createTrogdorTLESuccess: (state) => {
      state.isCommunicating = true;
      state.error = null;
    },
    createTrogdorTLEFail: (state, action) => {
      state.isCommunicating = true;
      state.error = action.payload;
    },
    copyScenarioTLEsSuccess: (state, action) => {
      state.satsByEventId = action.payload;
    },
    resetTLEs: () => initialState,
  },
});

export const {
  fetchTLEsStart,
  fetchTLEsSuccess,
  copyTLEsSuccess,
  fetchTLEsFail,
  fetchUdlTLEStart,
  fetchUdlTLESuccess,
  replaceEventUdlTLEsSuccess,
  addUdlTLE,
  fetchUdlTLEFail,
  saveLocalTLEStart,
  saveLocalTLESuccess,
  saveAllLocalTLEs,
  saveLocalTLEFail,
  removeTLEStart,
  removeEventPreEventTLEs,
  removeTLESuccess,
  removeTLEFail,
  createTrogdorTLEStart,
  createTrogdorTLESuccess,
  createTrogdorTLEFail,
  copyScenarioTLEsSuccess,
  resetTLEs,
} = preEventTLEsSlice.actions;
