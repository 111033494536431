import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  getAuth,
} from "../../redux/selectors/outsideSelectors";
import { refreshAccessToken, logOff } from "../../redux/thunks/authThunks";
import store from "../../app/store";

import type { AxiosRequestConfig } from "axios";
export type Url = AxiosRequestConfig["url"];
export type Data = AxiosRequestConfig["data"];
export type Params = AxiosRequestConfig["params"];
export type Headers = AxiosRequestConfig["headers"];
export type Method = AxiosRequestConfig["method"];

let isRefreshing = false;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequestConfig = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      originalRequestConfig.url !== "/auth/refresh" &&
      getAuth()
    ) {
      // If expiration has passed log off user
      if (
        isRefreshExpired() ||
        tokenErrorMessageCheck(error?.response?.data?.msg)
      ) {
        logOffAndNavigateToLogin();
        error = new Error("Refresh Token Expired");
        return Promise.reject(error);
      }

      // If expiration has not passed request new access token
      if (!isRefreshing) {
        isRefreshing = true;
        const refreshToken = getRefreshToken();
        const refreshResponse = await store.dispatch(
          refreshAccessToken(refreshToken)
        );

        //! If invalid refresh token
        if (refreshResponse.response.status === 401)
          return Promise.reject(error);
        isRefreshing = false;
        // Submit original request again with new access token
        const rerequestResponse = await axios({
          ...originalRequestConfig,
          headers: {
            ...originalRequestConfig.headers,
            "X-CSRF-TOKEN": getAccessToken(),
          },
          // must parse data otherwise snakeize function will fail
          data: JSON.parse(originalRequestConfig.data),
        });
        return Promise.resolve(rerequestResponse);
      } else {
        // do nothing because still refereshing
      }
    } else {
      return Promise.reject(error);
    }
  }
);

const tokenErrorMessageCheck = (message: string) =>
  message === "CSRF double submit tokens do not match";

const isRefreshExpired = () => {
  const refreshExpiration = getAuth()?.tokens?.refresh?.expires;
  if (refreshExpiration) {
    const expiresDate = new Date(refreshExpiration);
    return expiresDate < new Date();
  } else {
    return true;
  }
};

const logOffAndNavigateToLogin = async () => {
  const host = window.location.host;
  await store.dispatch(logOff()).then(() => {
    window.location.replace(`http://${host}/login`);
  });
  // TODO: display warning notification that refresh token was expired
};

export const universalAxios = (
  method: Method,
  url: Url,
  params?: Params,
  data?: Data,
  headers?: Headers
) => {
  return axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method,
    url,
    headers: {
      "X-CSRF-TOKEN": getAccessToken() || "",
      ...headers,
    },
    data,
    params,
    withCredentials: true,
  });
};

export default universalAxios;
