import React, { useState } from "react";
import {
  Stack,
  ListItemText,
  ListItemButton,
  Box,
  Tooltip,
} from "@mui/material";
import { RuxButton } from "@astrouxds/react";
import ListItemKabobMenu from "../../../Common/ListItemKabobMenu/ListItemKabobMenu";
import Common, {
  supportedEventTypes,
  findEventTypeIdByName,
} from "../../../../utils/common";
import { useSatelliteContext } from "../../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useSatStateContext } from "../../../../utils/Hooks/contextHooks/useSatStateContext";
import { useDrawerContext } from "../../../../utils/Hooks/contextHooks/useDrawerContext";
import ListDateTime from "../../../Common/Layout/LeftPanel/ListDateTime/ListDateTime";
import { ConditionalWrapper } from "../../../Misc/ConditionalWrapper";
import { Event } from "../../../../redux/rtk/types/internalTypes";

const isTrogdorSavedEvent = (id: number | string) => {
  return typeof id === "number";
};

type PropTypes = {
  event: Event;
};

const EventListItem = ({ event }: PropTypes) => {
  const {
    selectedSatIds,
    addSelected,
    removeSelected,
    hoveredSatId,
    setHovered,
    hoveredSelectedSatId,
    setHoveredSelected,
    clearHoveredSelected,
    clearSatState,
  } = useSatStateContext();
  const { getVisibleSatByEventId } = useSatelliteContext();
  const { openEditEvent } = useDrawerContext();
  const { dates, times } = Common.parseDatesAndTimesFromEntity(event);
  const [anchorEl, setAnchorEl] = useState(null);
  const visibleEventSat = getVisibleSatByEventId(event.id);
  const isSelected = selectedSatIds.includes(visibleEventSat?.id);
  const isHovered = hoveredSatId === visibleEventSat?.id;
  const isHoveredActive = hoveredSelectedSatId === visibleEventSat?.id;
  const isSupportedEventType = supportedEventTypes.includes(
    Number(findEventTypeIdByName(event.eventType))
  );

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    clearSatState();

    // TODO: Make all event Id's strings
    // Event ID's can be numbers or strings
    openEditEvent(event.id.toString());
  };

  const handleMenuClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (anchorEl === null) setAnchorEl(e.currentTarget);
    else {
      setAnchorEl(null);
    }
  };

  const onClickHandler = () => {
    if (typeof visibleEventSat === "undefined") return;

    if (isSelected) {
      removeSelected(visibleEventSat.id);
    } else {
      addSelected(visibleEventSat.id);
      setHovered(null);
    }
  };

  const onPointerEnterHandler = () => {
    if (typeof visibleEventSat === "undefined") return;

    if (isSelected) {
      setHoveredSelected(visibleEventSat.id);
    } else {
      setHovered(visibleEventSat.id);
    }
  };

  const onPointerLeaveHandler = () => {
    if (typeof visibleEventSat === "undefined") return;

    if (!isSelected) {
      setHovered(null);
    }
    if (hoveredSelectedSatId) {
      clearHoveredSelected();
    }
  };

  const styles = {
    eventItem: () => ({
      ...(isHovered && {
        backgroundColor: "#1c3f5e",
      }),
      ...(isHoveredActive && {
        "&.Mui-selected": {
          backgroundColor: "#214b6f",
        },
      }),
    }),
  };

  const open = Boolean(anchorEl);
  const kabobId = open ? `event-${event.id}-popover` : undefined;

  return (
    <>
      <ListItemButton
        selected={isSelected}
        sx={styles.eventItem}
        onClick={onClickHandler}
        onPointerEnter={onPointerEnterHandler}
        onPointerLeave={onPointerLeaveHandler}
        data-test="event-list-item"
      >
        <Tooltip title={event.name}>
          <ListItemText
            sx={{ maxWidth: "10.1875rem" }}
            data-test="event-item-name-type-provider"
            primary={event.name}
            secondary={`${event.eventType} \u2022 ${event.provider}`}
          />
        </Tooltip>
        <ListDateTime date={dates.start} time={times.start} />
        <ListDateTime date={dates.end} time={times.end} />

        <Stack direction="row" alignItems="center">
          <ConditionalWrapper
            condition={!isSupportedEventType || isTrogdorSavedEvent(event.id)}
            wrapper={(children: React.ReactNode) => (
              <Tooltip
                title={
                  !isSupportedEventType
                    ? "Unsupported event type"
                    : isTrogdorSavedEvent(event.id)
                    ? "Trogdor events can not be edited"
                    : null
                }
              >
                <span>{children}</span>
              </Tooltip>
            )}
          >
            <Box
              component={RuxButton}
              icon="edit"
              pr="0.25rem"
              size="small"
              onClick={handleEditClick}
              disabled={!isSupportedEventType || isTrogdorSavedEvent(event.id)}
              data-test="event-list-item-edit-icon"
              icon-only
              borderless
            />
          </ConditionalWrapper>
          <Box
            component={RuxButton}
            icon="more-vert"
            size="small"
            aria-describedby={kabobId}
            onClick={handleMenuClick}
            icon-only
            borderless
            data-test="event-list-item-kabab-icon"
            sx={{
              "::part(icon)": {
                transform: "scale(1.3)",
              },
            }}
          />
        </Stack>
      </ListItemButton>
      <ListItemKabobMenu
        item={event}
        id={kabobId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClick}
      />
    </>
  );
};

export default EventListItem;
