import { useEffect } from "react";
import { RuxButton } from "@astrouxds/react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../utils/Hooks/reduxHooks";
import { updateLiveEvent } from "../../../redux/thunks/liveEventThunks";
import {
  formatEventDetailsForRedux,
  formatEventParametersForRedux,
} from "../../../utils/Hooks/eventTypeHooks/reduxFormattingFunctions";
import { resetLiveEvent } from "../../../redux/slices/liveEvent/liveEventSlice";
import { RuxIndeterminateProgress } from "@astrouxds/react";
import { getLiveEventPosting } from "../../../redux/selectors/liveEventSelector";
import type { EventFormValues } from "../../../redux/rtk/types/formTypes";

const styles = {
  updateVisFooter: {
    display: "flex",
    maxHeight: "70px",
    padding: "20px",
    marginTop: "auto",
  },
  modalDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

type PropTypes = {
  getFormValues: (...args: any[]) => Partial<EventFormValues>;
  eventId?: string;
};

const UpdateVisualizationFooter = ({ getFormValues, eventId }: PropTypes) => {
  const dispatch = useAppDispatch();
  const postingStatus = useAppSelector(getLiveEventPosting);

  const handleUpdateVisualization = () => {
    const values = getFormValues();
    const eventDetails = formatEventDetailsForRedux(values as EventFormValues);
    const eventParameters = formatEventParametersForRedux(values);
    dispatch(updateLiveEvent(eventDetails, eventParameters, eventId));
  };

  useEffect(
    () => () => {
      dispatch(resetLiveEvent());
    },
    []
  );

  return (
    <Grid>
      <Modal open={postingStatus} sx={styles.modalDisplay}>
        <RuxIndeterminateProgress data-test="update-vis-spinner" />
      </Modal>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={styles.updateVisFooter}
      >
        <RuxButton
          data-test="update-visualization-button"
          onClick={handleUpdateVisualization}
        >
          Update Visualization
        </RuxButton>
      </Grid>
    </Grid>
  );
};

export default UpdateVisualizationFooter;
