import { useAppSelector } from "../../../utils/Hooks/reduxHooks";
import { useSatelliteContext } from "../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useSatStateContext } from "../../../utils/Hooks/contextHooks/useSatStateContext";
import { getEventDataByTargetSatNo } from "../../../redux/selectors/eventsSelector";
import TLEOrbit from "./TLEOrbit";
import SVOrbit from "./SVOrbit";
import { EventDataForObjects } from "../../../redux/rtk/types/ThreeJSCommonTypes";

const SelectedSats = () => {
  const { threeSatellitesById, ephemerisBySVId } = useSatelliteContext();
  const { selectedSatIds } = useSatStateContext();
  const eventDataBySatNo: { [key: number]: EventDataForObjects } =
    useAppSelector(getEventDataByTargetSatNo) as {
      [key: number]: EventDataForObjects;
    };

  return (
    <>
      {selectedSatIds.map((satId) => {
        const satelliteFromId = threeSatellitesById[satId];
        if (!satelliteFromId) return;
        const eventData = eventDataBySatNo[satelliteFromId.satNo];

        if (satelliteFromId?.type === "SV") {
          const ephemerisPoints = ephemerisBySVId[satelliteFromId.id];
          return (
            <SVOrbit
              isSelected
              isHovered={false}
              key={satId}
              satellite={satelliteFromId}
              ephemerisPoints={ephemerisPoints}
              eventData={eventData}
            />
          );
        }
        if (satelliteFromId?.type === "TLE") {
          return (
            <TLEOrbit
              isSelected
              isHovered={false}
              key={satId}
              satellite={satelliteFromId}
              eventData={eventData}
            />
          );
        }
        return null;
      })}
    </>
  );
};

export default SelectedSats;
