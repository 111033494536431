import AcmeAxios from "./acmeAxios";
import { formatTLEResponse } from "./formattingFunctions";

export const createScenario = (data) => {
  return AcmeAxios.post("/scenarios/", data).then((response) => {
    response.data = response.data.item;
    return response;
  });
};

export const editScenario = (data) => {
  return AcmeAxios.put(`/scenarios/${data.id}`, data).then((response) => {
    response.data = response.data.item;
    return response;
  });
};

export const deleteScenario = (id) => {
  return AcmeAxios.delete(`/scenarios/${id}`);
};

export const getScenarioObjects = (scenarioId) => {
  return AcmeAxios.get(`/scenarios/${scenarioId}/objects`).then((response) => {
    response.data = response.data.map((object) => formatTLEResponse(object));
    return response;
  });
};

export const addScenarioObject = (objectId, scenarioId) => {
  const data = {
    scenario_id: scenarioId,
  };
  return AcmeAxios.post(`/scenarios/objects/${objectId}`, data).then(
    (response) => {
      response.data = formatTLEResponse(response.data);
      return response;
    }
  );
};

export const removeScenarioObject = (objectId, scenarioId) => {
  const data = {
    scenario_id: scenarioId,
  };
  return AcmeAxios.delete(`/scenarios/objects/${objectId}`, data).then(
    (response) => {
      response.data = formatTLEResponse(response.data);
      return response;
    }
  );
};
