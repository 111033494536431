/* eslint-disable react/no-unknown-property */
import { useState, useRef, useMemo } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";

type PropTypes = {
  pos?: THREE.Vector3;
  storedPos?: THREE.Vector3;
  center?: THREE.Vector3;
  hasManualOrbit?: boolean;
  isSelected: boolean;
  satPos: THREE.Vector3;
  isStateVector?: boolean;
  satId?: string;
};

const AltitudeLine = ({
  pos = new THREE.Vector3(),
  storedPos = new THREE.Vector3(),
  center = new THREE.Vector3(0, 0, 0),
  hasManualOrbit,
  isSelected,
  satPos,
  isStateVector = false,
}: PropTypes) => {
  const altLineGroupRef: React.MutableRefObject<any> = useRef();
  const [depth, setDepth] = useState(0);
  const width = 50;

  const extrudeSettings = {
    depth: depth,
  };

  useFrame(() => {
    if (!altLineGroupRef.current) return;
    pos = satPos;

    if (!pos) return;
    const isDiffCheck = !pos.equals(storedPos);

    if (isDiffCheck) {
      altLineGroupRef.current.position.set(pos.x, pos.y, pos.z);
      altLineGroupRef.current.lookAt(center);
      setDepth(altLineGroupRef.current.position.distanceTo(center));
      storedPos = pos;
    }
  });

  const shape = useMemo(() => {
    const start: [number, number] = [0, -width];
    const paths: [[number, number], [number, number]] = [
      [-width * 1.5, width],
      [width * 1.5, width],
    ];

    const shape = new THREE.Shape();
    shape.moveTo(...start);
    paths.forEach((path) => shape.lineTo(...path));

    return shape;
  }, []);

  const colorHandler = useMemo(
    () =>
      isSelected
        ? "#9fccff"
        : isStateVector
        ? "#9fff9f"
        : hasManualOrbit
        ? "grey"
        : "#0177fe",
    [isSelected, isStateVector, hasManualOrbit]
  );

  return (
    <group ref={altLineGroupRef}>
      <mesh>
        <extrudeGeometry
          args={[shape, extrudeSettings]}
          name="Altitude Line Geometry"
        />
        <meshBasicMaterial color={colorHandler} name="Altitude Line Material" />
      </mesh>
    </group>
  );
};

export default AltitudeLine;
