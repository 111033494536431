import { useNavigate, useParams } from "react-router-dom";
import { RuxDialog } from "@astrouxds/react";
import { RuxDialogCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";

import { useDeleteScenarioMutation } from "../../redux/rtk/endpoints/scenario";

type PropTypes = {
  isOpen?: boolean;
  resetDialog?: () => void;
};

const DeleteScenarioDialog = ({ isOpen = false, resetDialog }: PropTypes) => {
  const { scenarioId } = useParams();
  const navigate = useNavigate();

  const [deleteScenario] = useDeleteScenarioMutation();

  const handleDialogClose = (e: RuxDialogCustomEvent<boolean | null>) => {
    if (e.detail) {
      deleteScenario(Number(scenarioId))
        .unwrap()
        .then(() => navigate("/scenarios"))
        .catch((error: any) => console.log("error", error));
    }
    resetDialog?.();
  };

  return (
    <RuxDialog
      open={isOpen}
      header="Delete Scenario"
      message="Are you sure you want to delete the scenario and all related events?"
      onRuxdialogclosed={handleDialogClose}
      data-test="scenario-delete-popup"
    />
  );
};

export default DeleteScenarioDialog;
