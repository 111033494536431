/* eslint-disable react/no-unknown-property */
import { OrbitControls, AdaptiveDpr } from "@react-three/drei";
import { useLoginCanvasContext } from "../../../utils/Hooks/contextHooks/useLoginCanvasContext";

const Setup = () => {
  const { introAnimationDone, earthRadius, minDistance } =
    useLoginCanvasContext();

  return (
    <>
      <OrbitControls
        makeDefault
        minDistance={earthRadius + minDistance}
        maxDistance={earthRadius * 5}
        enablePan={false}
        zoomSpeed={0.4}
        autoRotate={introAnimationDone}
        autoRotateSpeed={0.3}
      />
      <ambientLight name="Ambient" color={"#e8e8e8"} intensity={0.13} />
      <AdaptiveDpr pixelated />
    </>
  );
};

export default Setup;
