import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPosting: false,
  isFetching: false,
  error: null,
  eventDetails: {},
  eventParameters: [],
  preEventTLEs: [],
  postEventSVs: [],
  ephemeris: [],
};

export const liveEventSlice = createSlice({
  name: "liveEvent",
  initialState,
  reducers: {
    setEventDetails: (state, action) => {
      state.eventDetails = action.payload;
    },
    setEventParameters: (state, action) => {
      state.eventParameters = action.payload;
    },
    startPosting: (state) => {
      state.isPosting = true;
    },
    endPosting: (state) => {
      state.isPosting = false;
    },
    addPreEventTLEStart: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    addPreEventTLESuccess: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.preEventTLEs = [action.payload];
    },
    addPreEventTLEFail: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
    removePreEventTLEs: (state) => {
      state.preEventTLEs = [];
    },
    addPostEventSVStart: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    addPostEventSVSuccess: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.postEventSVs = [action.payload];
    },
    addPostEventSVFail: (state, action) => {
      state.isFetching = true;
      state.error = action.payload;
    },
    removePostEventSVs: (state) => {
      state.postEventSVs = [];
    },
    addEphemerisStart: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    addEphemerisSuccess: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.ephemeris = action.payload;
    },
    addEphemerisFail: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
    removeEphemeris: (state) => {
      state.ephemeris = [];
    },
    updateLiveEventFail: (state, action) => {
      state.error = action.payload;
    },
    resetLiveEvent: () => initialState,
  },
});

export const {
  setEventDetails,
  setEventParameters,
  startPosting,
  endPosting,
  addPreEventTLEStart,
  addPreEventTLESuccess,
  addPreEventTLEFail,
  removePreEventTLEs,
  addPostEventSVStart,
  addPostEventSVSuccess,
  addPostEventSVFail,
  removePostEventSVs,
  addEphemerisStart,
  addEphemerisSuccess,
  addEphemerisFail,
  updateLiveEventFail,
  resetLiveEvent,
} = liveEventSlice.actions;
