import { useRef } from "react";
import { Canvas } from "@react-three/fiber";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import LoginScene from "../LoginScene/LoginScene";
import LoginForm from "./LoginForm";

import { LoginCanvasContextProvider } from "../../utils/Hooks/contextHooks/useLoginCanvasContext";
import logo from "../../static/img/space-acme-logo.svg";
import starfield from "../../static/img/galaxy_starfield.png";

const styles = {
  container: {
    position: "fixed",
    height: "100vh",
    width: "100vw",
    backgroundImage: `url(${starfield})`,
  },
  leftPanel: {
    height: "calc(100% - 5rem)",
    width: "32.5rem",
    position: "fixed",
    zIndex: 1,
    margin: "2.5rem",
    top: 0,
    left: 0,
    backgroundColor: "#1b2d3e60",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  canvas: {
    width: "calc(100% + 45vw)",
  },
};

const Login = () => {
  //_ The loginContainerPortal ref is passed into the drei Html components inside the canvas, ultimately so pointer-events doesn't pass through them and move the camera around __________________________________________________________________________
  const loginContainerPortal = useRef<any>();
  const isCypress = !!process.env.REACT_APP_CYPRESS;

  return (
    <Grid component={"div"} sx={styles.container} ref={loginContainerPortal}>
      {/* left panel */}

      <Paper sx={styles.leftPanel}>
        <img width={305} src={logo} alt="Space ACME Logo" />
        <LoginForm />
        <Stack justifyContent="space-between" alignItems="center" height={44}>
          {/* Registration and forgot Password links for future use */}
          {/* <Typography display="flex" gap="0.25rem" variant="body2">
            Forgot
            <Link component={RouterLink} to="#">
              Username
            </Link>
            or
            <Link component={RouterLink} to="#">
              Password?
            </Link>
          </Typography>
          <Box component="span">
            <Link component={RouterLink} to="#" fontSize="0.875rem">
              Register for access
            </Link>
          </Box> */}
        </Stack>
      </Paper>
      {!isCypress ? (
        <LoginCanvasContextProvider>
          <Canvas
            frameloop="always"
            performance={{ min: 0.1 }}
            gl={{
              logarithmicDepthBuffer: true,
              physicallyCorrectLights: true,
              preserveDrawingBuffer: true,
            }}
            style={styles.canvas}
          >
            <LoginScene loginContainerRef={loginContainerPortal} />
          </Canvas>
        </LoginCanvasContextProvider>
      ) : null}
    </Grid>
  );
};

export default Login;
