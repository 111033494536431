// Hooks
import { useEffect, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utils/Hooks/reduxHooks";
import useForm from "../../../utils/Hooks/useForm";
import { useDateTimeContext } from "../../../utils/Hooks/contextHooks/useDateTimeContext";

// Redux
import { editPersistedEvent } from "../../../redux/thunks/eventThunks";
import {
  getCurrentScenarioEvents,
  getCurrentScenario,
} from "../../../redux/selectors/scenarioSelector";
import { getEventTypesById } from "../../../redux/rtk/endpoints/initialization";

// Components
import Grid from "@mui/material/Grid";
import ParametersForm from "../EventTypeParameters/ParametersForm";
import { RuxInput } from "@astrouxds/react";
import EventTypeSelect from "../../Common/EventTypeSelect";
import EventProviderSelect from "../../Common/EventProviderSelect";
import UpdateVisualizationFooter from "./UpdateVisualizationFooter";
import PanelTitleBar from "../../Common/PanelTitleBar";
import JumpToTimeButton from "../../Common/JumpToTimeButton";

// Utilities
import {
  validateEventStartDateTime,
  validateEventEndDateTime,
  validateRequiredField,
} from "../../../utils/formFieldValidation";
import Common from "../../../utils/common";
import { eventTypeDefinitions } from "../../../utils/api/trogdor/eventTypeDefinitions";
import { findEventTypeIdByName } from "../../../utils/common";
import {
  formatEventDetailsForRedux,
  formatEventParametersForRedux,
} from "../../../utils/Hooks/eventTypeHooks/reduxFormattingFunctions";
import type {
  EventProvider,
  Scenario,
} from "../../../redux/rtk/types/internalTypes";
import type { EventFormValues } from "../../../redux/rtk/types/formTypes";
import type { UseFormEventType } from "./CreateEventPanel";

const styles = {
  inputStyles: {
    paddingInline: "1.25rem",
    marginBottom: "1.25rem",
  },
  formInputs: {
    flexGrow: 1,
    flewWrap: "nowrap",
  },
  date: {
    paddingInline: "1.25rem",
  },
  lowerFormInputs: {
    flexBasis: "calc(50% - 1.25rem)",
    marginBottom: "1.25rem",
    paddingLeft: "1.25rem",
  },
  eventAndProvider: {
    marginBottom: "1.25rem",
  },
  startTimeTargetIcon: {
    width: ".25rem",
    marginTop: "3.25rem",
  },
  endTimeTargetIcon: {
    width: ".25rem",
    marginTop: "2rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
  },
  startTime: {
    width: "11.1875rem",
    marginLeft: ".625rem",
    marginRight: "1.625rem",
  },
  endTime: {
    width: "11.1875rem",
    marginRight: "1.625rem",
  },
  dates: {
    width: "11.5rem",
  },
} as const;

type PropTypes = {
  eventId: string;
  closeEditEvent: () => void;
};

const EditEventPanel = ({ eventId, closeEditEvent }: PropTypes) => {
  const dispatch = useAppDispatch();
  const currentScenario = useAppSelector(getCurrentScenario);
  const currentEvent = useAppSelector(getCurrentScenarioEvents)[eventId];
  const eventTypesById = useAppSelector(getEventTypesById);
  const { dates, times } = currentEvent
    ? Common.parseDatesAndTimesFromEntity(currentEvent)
    : Common.parseDatesAndTimesFromEntity(currentScenario as Scenario);

  const { setDateTime } = useDateTimeContext();
  const {
    values,
    errors,
    setInitialValues,
    handleChange,
    handleSubmit,
    getFormValues,
  }: UseFormEventType = useForm(() =>
    editCurrentEvent(values as EventFormValues)?.then(() => closeEditEvent())
  );

  const getFormattedInitialValues = useCallback(() => {
    const eventTypeId: number = currentEvent?.eventTypeId;
    // Below if branch due to trogdor saving event types as name strings instead of ids
    if (Common.trogdor) {
      eventTypeId ===
        Object.keys(eventTypeDefinitions).find(
          () => findEventTypeIdByName === currentEvent?.eventType
        );
    } else {
      currentEvent?.eventType.id;
    }

    const assignProviderId = () => {
      const providers = eventTypesById[eventTypeId]?.providers;
      if (providers?.length && currentEvent.provider) {
        return providers.find(
          (provider: EventProvider) =>
            provider.providerName === currentEvent.provider
        )?.providerID;
      } else {
        return "";
      }
    };

    const formattedValues = {
      name: currentEvent?.name,
      startDate: dates?.start,
      endDate: dates?.end,
      startTime: times?.start,
      endTime: times?.end,
      eventTypeId: eventTypeId || "",
      scenarioId: currentEvent?.scenarioId,
      // TODO: remove when provider property attached to events in ACME, this hard codes the first provider in the list
      providerId: assignProviderId(),
    };
    return formattedValues;
  }, [
    currentEvent,
    dates?.end,
    dates?.start,
    eventTypesById,
    times?.end,
    times?.start,
  ]);

  useEffect(() => {
    setInitialValues(getFormattedInitialValues());
  }, [setInitialValues, currentEvent, getFormattedInitialValues, setDateTime]);

  // Event edit function
  const editCurrentEvent = (values: EventFormValues) => {
    const eventDetails = formatEventDetailsForRedux({
      ...values,
    });
    const eventParameters = formatEventParametersForRedux(values);
    const eventTypeId = Number(values.eventTypeId);

    // chooses correct edit thunk depending on eventType
    switch (eventTypeId) {
      case 1:
        return dispatch(
          editPersistedEvent(eventDetails, eventParameters, currentEvent.id)
        );
      case 4:
      case 5:
      case 6:
        return dispatch(
          editPersistedEvent(eventDetails, eventParameters, currentEvent.id)
        );
      default:
        console.log("Event Creation for event type not supported");
    }
  };

  return (
    <>
      <form
        style={styles.form}
        onSubmit={(e) => handleSubmit(e)}
        name="editEventForm"
        data-test="edit-event-form"
      >
        <PanelTitleBar
          leftBtnText="Done"
          leftBtnType="submit"
          titleText="Event details"
          rightBtnText="Cancel"
          rightBtnType="button"
          rightBtnFunction={closeEditEvent}
          postingStatus={false}
        />

        <Grid container item direction="column" sx={styles.formInputs}>
          <Grid container item direction="column" sx={styles.inputStyles}>
            <RuxInput
              required
              label="Event name"
              name="name"
              placeholder="Add event name"
              value={values.name}
              onRuxinput={(e) => handleChange(e, [validateRequiredField])}
              invalid={!!errors.name}
              errorText={errors.name}
              size="small"
              data-test="edit-event-name"
            />
          </Grid>
          <Grid container spacing={2} item direction="row" sx={styles.date}>
            <Grid container item direction="row" sx={styles.lowerFormInputs}>
              <RuxInput
                size="small"
                type="date"
                required
                label="Start date"
                name="startDate"
                value={values.startDate}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateEventStartDateTime,
                    validateRequiredField,
                  ])
                }
                invalid={!!errors.startDate}
                errorText={errors.startDate}
                style={styles.dates}
                data-test="edit-event-start-date"
              />
            </Grid>

            <Grid container item direction="row" sx={styles.lowerFormInputs}>
              <RuxInput
                size="small"
                type="time"
                required
                label="Start time"
                name="startTime"
                step="1"
                value={values.startTime}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateEventStartDateTime,
                    validateRequiredField,
                  ])
                }
                invalid={!!errors.startTime}
                errorText={errors.startTime}
                style={styles.startTime}
                data-test="edit-event-start-time"
              />
            </Grid>
            <Grid sx={styles.startTimeTargetIcon}>
              <JumpToTimeButton
                date={values.startDate}
                time={values.startTime}
              />
            </Grid>
          </Grid>
          <Grid container item direction="row">
            <Grid container item direction="row" sx={styles.lowerFormInputs}>
              <RuxInput
                size="small"
                type="date"
                required
                label="End date"
                name="endDate"
                value={values.endDate}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateEventEndDateTime,
                    validateRequiredField,
                  ])
                }
                invalid={!!errors.endDate}
                errorText={errors.endDate}
                style={styles.dates}
                data-test="edit-event-end-date"
              />
            </Grid>
            <Grid container item direction="row" sx={styles.lowerFormInputs}>
              <RuxInput
                size="small"
                type="time"
                required
                label="End time"
                name="endTime"
                step="1"
                value={values.endTime}
                onRuxinput={(e) =>
                  handleChange(e, [
                    validateEventEndDateTime,
                    validateRequiredField,
                  ])
                }
                invalid={!!errors.endTime}
                errorText={errors.endTime}
                style={styles.endTime}
                data-test="edit-event-end-time"
              />
              <Grid sx={styles.endTimeTargetIcon}>
                <JumpToTimeButton date={values.endDate} time={values.endTime} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            sx={styles.eventAndProvider}
          >
            <EventTypeSelect
              eventTypeId={values.eventTypeId}
              handleChange={handleChange}
            />
            <EventProviderSelect
              values={values}
              handleChange={handleChange}
              setInitialValues={setInitialValues}
            />
          </Grid>
          <ParametersForm
            currentEvent={currentEvent}
            values={values as EventFormValues}
            handleChange={handleChange}
            setInitialValues={setInitialValues}
          />
          <UpdateVisualizationFooter
            getFormValues={getFormValues}
            eventId={eventId}
          />
        </Grid>
      </form>
    </>
  );
};

export default EditEventPanel;
