/* eslint-disable react/no-unknown-property */
import { useRef } from "react";
import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { Text } from "@react-three/drei";

function Loading() {
  const { invalidate } = useThree();

  const loadingGroup = useRef<any>();
  const loader = useRef<any>();

  useFrame(() => {
    if (loader.current) {
      loader.current.rotation.z += 0.05;
      loadingGroup.current.rotation.y -= 0.001;
      invalidate();
    }
  });

  return (
    <group
      ref={loadingGroup}
      position={[0, 0, 0]}
      rotation={[0, Math.PI / 2, 0]}
      scale={150}
    >
      <mesh ref={loader}>
        <torusGeometry
          attach="geometry"
          args={[20, 2, 30, 70, (Math.PI * 2) / 2]}
        />
        <meshBasicMaterial
          attach="material"
          side={THREE.DoubleSide}
          color={"#172635"}
        />
      </mesh>
      <Text
        color={"#172635"}
        anchorX={"center"}
        anchorY={"middle"}
        fontSize={6}
      >
        Loading...
      </Text>
    </group>
  );
}

export default Loading;
