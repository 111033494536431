import { RuxButton, RuxOption, RuxSelect } from "@astrouxds/react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ListTypes } from "../../../../../types/utils";
import { RuxSelectCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";

type OptionsType = {
  label: string;
  value: string;
  selected?: boolean;
};

type ListSortBarProps = {
  onCreate: () => void;
  onSort: (event: RuxSelectCustomEvent<void>) => void;
  options: OptionsType[];
  sort: string;
  type: ListTypes;
  buttonName: string;
};

const listSortBarSX = {
  height: "5rem",
};

const ListSortBar = (props: ListSortBarProps) => {
  const { onCreate, onSort, sort, type, buttonName, options } = props;
  let icon: undefined | "add" = undefined;

  if (type === "events" || type === "objects") {
    icon = "add";
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={listSortBarSX}
      data-test="list-sort-bar"
    >
      <RuxButton
        data-test="list-sort-bar-create-btn"
        icon={icon}
        secondary
        size="small"
        onClick={onCreate}
      >
        {buttonName}
      </RuxButton>
      <Stack direction="row" alignItems="center" spacing="0.625rem">
        <Typography>Sort</Typography>
        <Box component="span" width="8.5rem">
          <RuxSelect
            data-test="list-sort-bar-select"
            size="small"
            value={sort}
            onRuxchange={onSort}
          >
            {options.map((option, index) => (
              <RuxOption {...option} label={option.label} key={index} />
            ))}
          </RuxSelect>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ListSortBar;
