import { useEffect, useRef } from "react";
import { usePrevious } from "../Hooks/debuggerHooks/usePrevious";

export const useScrollToListItem = (triggerArray: Array<any>) => {
  const listItemRefs = useRef<any>({});

  const prevTriggerArray: Array<any> = usePrevious(triggerArray || []);

  useEffect(() => {
    const scrollToListItem = (satId: number | string) => {
      listItemRefs.current[satId].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    };

    if (triggerArray?.length && triggerArray?.length > prevTriggerArray?.length)
      scrollToListItem(triggerArray[triggerArray.length - 1]);
  }, [prevTriggerArray, triggerArray]);

  return { listItemRefs };
};
