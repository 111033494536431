import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

const useKeyPress = (keys: any, callback: (key: any) => void, node = null) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: { key: string; ctrlKey: any }) => {
      // check if key is part of passed keys object and ctrlKey press is true.
      if (event.key in keys && event.ctrlKey) {
        callbackRef.current(event.key);
      }
    },
    [keys]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    targetNode && targetNode.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () =>
      targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node]);
};

export default useKeyPress;
