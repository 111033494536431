import { useRef, useMemo } from "react";
import * as THREE from "three";
import { addMinutes, differenceInMinutes } from "date-fns";
import { Line } from "@react-three/drei";
import { getPositionFromTle } from "../../../utils/propagation";
import { useSatStateContext } from "../../../utils/Hooks/contextHooks/useSatStateContext";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import {
  EventDataForObjects,
  EventSat,
} from "../../../redux/rtk/types/ThreeJSCommonTypes";

type PropTypes = {
  isSelected: boolean;
  isHovered: boolean;
  isHoveredActive: boolean;
  hasManualOrbit?: boolean;
  eventData: EventDataForObjects;
  satellite: EventSat;
  eventStart: Date;
  endOfRev: number | Date | null;
};

const TLEGhostOrbit = ({
  isSelected,
  isHovered,
  isHoveredActive,
  hasManualOrbit,
  eventData,
  satellite,
  eventStart,
  endOfRev,
}: PropTypes) => {
  const { removeSelected, setHoveredSelected, clearHoveredSelected } =
    useSatStateContext();
  const { orbitIsDragging } = useCanvasContext();

  const SVSatId = eventData.svId;
  const lineSegmentInterval = useRef(isHovered ? 2 : 1);

  const revsPerDay = useMemo(() => satellite.meanMotion, [satellite]);
  const minutesPerRev = useMemo(() => 1440 / revsPerDay, [revsPerDay]);

  const allTLEGhostOrbitPoints = useMemo(() => {
    if (typeof eventStart === "undefined") return [];
    const TLEGhostOrbitPoints = [];

    for (let i = 0; i <= minutesPerRev; i += lineSegmentInterval.current) {
      const date = addMinutes(eventStart, i);
      const getPos = getPositionFromTle(satellite, date);

      if (!getPos) continue;
      TLEGhostOrbitPoints.push(getPos);
    }

    return TLEGhostOrbitPoints;
  }, [eventStart, minutesPerRev]);

  // Cuts down the all orbit points array to only show orbit until the current time
  const TLEGhostOrbitPointsArr = useMemo(() => {
    const minutesToShow = differenceInMinutes(Number(endOfRev), eventStart);
    const indexesToShow = minutesToShow / lineSegmentInterval.current;

    return allTLEGhostOrbitPoints.slice(0, indexesToShow);
  }, [endOfRev, lineSegmentInterval.current]);

  // __ Internal _________________________________________

  const TLEGhostColorHandler = useMemo(
    () => (isHovered ? "#008FFF" : hasManualOrbit ? "grey" : "#054652"),
    [isHovered, hasManualOrbit]
  );

  const onClickHandler = () => {
    if (isSelected && !hasManualOrbit) {
      removeSelected(SVSatId);
      clearHoveredSelected();
    }
  };

  const onPointerOverHandler = () => {
    if (orbitIsDragging) return;
    if (isSelected && !hasManualOrbit) {
      setHoveredSelected(SVSatId);
    }
  };

  const onPointerOutHandler = () => {
    if (isSelected && !hasManualOrbit) {
      clearHoveredSelected();
    }
  };

  return (
    <>
      {TLEGhostOrbitPointsArr?.length ? (
        <>
          <Line
            name={"TLEGhostOrbitPointsArr"}
            onClick={onClickHandler}
            onPointerOver={onPointerOverHandler}
            onPointerOut={onPointerOutHandler}
            points={TLEGhostOrbitPointsArr} // Array of points
            color={TLEGhostColorHandler}
            opacity={isHovered || hasManualOrbit ? 0.6 : 1.0}
            transparent
            linewidth={isHoveredActive ? 5 : 3} // px
            blending={isHovered ? THREE.AdditiveBlending : THREE.NormalBlending}
            dashed={isHovered || hasManualOrbit}
            depthFunc={THREE.LessDepth}
            dashSize={150}
            gapSize={150}
            dashOffset={40}
          />
        </>
      ) : null}
    </>
  );
};

export default TLEGhostOrbit;
