import { useEffect, useCallback, useRef } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import { RuxSlider, RuxIcon } from "@astrouxds/react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { earthRadius } from "satellite.js/lib/constants";
import { useZoomContext } from "../../../../../utils/Hooks/contextHooks/useZoomContext";
import { useCanvasContext } from "../../../../../utils/Hooks/contextHooks/useCanvasContext";
import { useSatelliteContext } from "../../../../../utils/Hooks/contextHooks/useSatelliteContext";
import { RuxSliderCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";

const styles = {
  zoomControlsHTMLHandle: {
    height: 0,
    width: 0,
    top: "50%",
    right: "9.375rem",
    left: "auto",
    zIndex: 0,
    transform: "none",
    pointerEvents: "none",
    animationDelay: "1s",
  },
  icon: {
    pointerEvents: "auto",
    cursor: "pointer",
    mr: "0.75rem",
    color: "white",
  },
  zoomControlsContainer: {
    backgroundColor: "transparent",
    height: "21.875rem",
    position: "absolute",
    right: "1.5625rem",
    top: "calc(50% - 11rem)",
    pointerEvents: " none",
  },
  slider: {
    pointerEvents: "auto",
    transform: "rotate(-90deg) translateY(45%)",
    width: "225%",
  },
  zoomControls: {
    height: 0,
    width: "10rem",
  },
};

const sliderStyle = Object.assign(styles.slider, {
  "--slider-track-before-thumb-height": "1px",
});

type PropTypes = {
  center?: THREE.Vector3;
  currentCamera?: THREE.Vector3;
  newCamera?: THREE.Vector3;
};

const ZoomControls = ({
  center = new THREE.Vector3(0, 0, 0),
  currentCamera = new THREE.Vector3(),
  newCamera = new THREE.Vector3(),
}: PropTypes) => {
  const {
    zoomValue,
    setZoomValue,
    zoomValueUpdateInterval,
    sliderZoomActive,
    setSliderZoomActive,
  } = useZoomContext();
  const { maxDistanceFactor, minDistance } = useCanvasContext();
  const { renderWindowPortal } = useSatelliteContext();
  const { camera, invalidate, gl } = useThree();

  const storedCameraDis = useRef<any>();
  const callCount = useRef(0);

  // TODO: convert step to state when ready to incorporate exponential step increases
  const step = 2000;
  const maxZoomDistance = -earthRadius - minDistance;
  const minZoomDistance = -earthRadius * maxDistanceFactor;

  useEffect(() => {
    if (sliderZoomActive) {
      window.addEventListener("pointerup", () => {
        setSliderZoomActive(false);
      });
    }
    return () => {
      window.removeEventListener("pointerup", () => {
        setSliderZoomActive(false);
      });
    };
  }, [sliderZoomActive, setSliderZoomActive]);

  const handleSliderZoomActive = (bool: boolean) => {
    setSliderZoomActive(bool);
  };

  const handleOnInput = (e: RuxSliderCustomEvent<any>) => {
    if (sliderZoomActive) {
      setZoomValue(e.target.value);
    }
  };

  const zoomOut = (num: number) => {
    num - step <= minZoomDistance
      ? setZoomValue(minZoomDistance)
      : setZoomValue(num - step);
  };

  const zoomIn = (num: number) => {
    num + step >= maxZoomDistance
      ? setZoomValue(maxZoomDistance)
      : setZoomValue(num + step);
  };

  const handleSliderZoomChange = useCallback(
    (zoomLevel: number) => {
      if (sliderZoomActive) {
        const { x, y, z } = camera.position;

        newCamera
          .subVectors(currentCamera.set(x, y, z), center)
          .setLength(-zoomLevel)
          .add(center);

        camera.position.set(newCamera.x, newCamera.y, newCamera.z);
        invalidate();
      }
    },
    [camera, invalidate, sliderZoomActive, center, currentCamera, newCamera]
  );

  useEffect(() => {
    handleSliderZoomChange(zoomValue);
  }, [zoomValue, handleSliderZoomChange]);

  const setManualZoomChangeHandler: (
    e: RuxSliderCustomEvent<any> | any
  ) => void = useCallback(() => {
    const cameraDistance = camera.position.distanceTo(center);
    const cameraDistanceRounded = Math.floor(-cameraDistance);

    if (cameraDistanceRounded === -5) return;

    if (cameraDistanceRounded !== storedCameraDis.current) {
      ++callCount.current;
      storedCameraDis.current = cameraDistanceRounded;

      if (callCount.current % zoomValueUpdateInterval === 0) {
        setZoomValue(cameraDistanceRounded);
      }
    }
  }, [camera, setZoomValue, center, zoomValueUpdateInterval]);

  useEffect(() => {
    gl.domElement.addEventListener(
      "wheel",
      (e) => setManualZoomChangeHandler(e),
      { passive: true }
    );

    return () =>
      gl.domElement.removeEventListener("wheel", setManualZoomChangeHandler);
  }, [setManualZoomChangeHandler, gl.domElement]);

  const zoomWrapperClassDiv = document.querySelector(
    ".zoom-controls-html-handle"
  ) as HTMLDivElement;

  renderWindowPortal && zoomWrapperClassDiv !== null
    ? Object.assign(zoomWrapperClassDiv.style, styles.zoomControlsHTMLHandle)
    : null;

  return (
    <Html
      wrapperClass="zoom-controls-html-handle"
      className="zoom-controls-html"
      style={styles.zoomControls}
      portal={renderWindowPortal}
    >
      <Stack
        justifyContent="space-around"
        alignItems="center"
        sx={styles.zoomControlsContainer}
        onPointerDown={() => handleSliderZoomActive(true)}
        onPointerUp={() => handleSliderZoomActive(false)}
      >
        <Box
          component={RuxIcon}
          onClick={() => zoomIn(zoomValue)}
          icon="add"
          size="extra-small"
          sx={styles.icon}
        />

        <RuxSlider
          aria-label="zoom level"
          max={maxZoomDistance}
          min={minZoomDistance}
          step={step}
          value={zoomValue}
          onRuxinput={handleOnInput}
          style={sliderStyle}
        />

        <Box
          component={RuxIcon}
          onClick={() => zoomOut(zoomValue)}
          icon="remove"
          size="extra-small"
          sx={styles.icon}
        />
      </Stack>
    </Html>
  );
};

export default ZoomControls;
