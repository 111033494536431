import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Common from "../../../../../utils/common";

const listTimeStyles = {
  alignItems: "center",
  minWidth: "6.5rem",
};

type ListDateTimeProps = {
  date: string;
  time: string;
};

const ListDateTime = ({ date, time }: ListDateTimeProps) => {
  const titleDate = Common.formatDateJulianISO(date);
  const dateAndTime = titleDate + " " + time + "z";

  return (
    <Tooltip title={dateAndTime}>
      <Stack sx={listTimeStyles} data-test="list-item-date-and-time">
        <Typography data-test="list-item-date">
          {Common.formatDateTimeToListItemDate(date)}
        </Typography>
        <Typography variant="body2" data-test="list-item-time">
          {time}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default ListDateTime;
