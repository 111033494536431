import { useDrawerContext } from "../../utils/Hooks/contextHooks/useDrawerContext";
import DrawerWrapper from "../Common/Layout/DrawerWrapper";
import EditScenarioPanel from "../Scenarios/CreateEditScenarioPanel/EditScenarioPanel";
import ObjectPanelSearch from "../Objects/AddObjectPanel/ObjectPanelSearch";
import CreateEventPanel from "../Events/CreateEditEventPanel/CreateEventPanel";
import EditEventPanel from "../Events/CreateEditEventPanel/EditEventPanel";

const Drawers = () => {
  const {
    editScenarioOpen,
    createEventOpen,
    editEventOpen,
    addObjectOpen,
    scenarioId,
    eventId,
    closeEditScenario,
    closeEditEvent,
    toggleCreateEvent,
    toggleAddObject,
  } = useDrawerContext();

  return (
    <>
      <DrawerWrapper open={editScenarioOpen}>
        <EditScenarioPanel
          onClose={closeEditScenario}
          scenarioId={scenarioId}
        />
      </DrawerWrapper>
      <DrawerWrapper open={createEventOpen}>
        <CreateEventPanel closeCreateEvent={toggleCreateEvent} />
      </DrawerWrapper>
      <DrawerWrapper open={editEventOpen}>
        <EditEventPanel eventId={eventId} closeEditEvent={closeEditEvent} />
      </DrawerWrapper>
      <DrawerWrapper open={addObjectOpen}>
        <ObjectPanelSearch onClose={toggleAddObject} />
      </DrawerWrapper>
    </>
  );
};

export default Drawers;
