import { createContext, useContext, useCallback, useReducer } from "react";

type PropTypes = {
  selectedSatIds: string[] | any[];
  addSelected: (satId: string | number) => void;
  removeSelected: (satId: string | number) => void;
  clearSelected: () => void;
  hoveredSatId: string | number | null;
  setHovered: (satId: string | number | null) => void;
  clearHovered: () => void;
  hoveredSelectedSatId: string | number | null;
  setHoveredSelected: (satId: string | number | null) => void;
  clearHoveredSelected: () => void;
  clearSatState: () => void;
};

export const SatStateContext = createContext<PropTypes>({
  selectedSatIds: [],
  addSelected: () => null,
  removeSelected: () => null,
  clearSelected: () => null,
  hoveredSatId: null,
  setHovered: () => null,
  clearHovered: () => null,
  hoveredSelectedSatId: null,
  setHoveredSelected: () => null,
  clearHoveredSelected: () => null,
  clearSatState: () => null,
});

export function useSatStateContext() {
  return useContext(SatStateContext);
}

const initialState = {
  selectedSatIds: [],
  hoveredSatId: null,
  hoveredSelectedSatId: null,
};

const ADD_SELECTED = "ADD_SELECTED";
const REMOVE_SELECTED = "REMOVE_SELECTED";
const CLEAR_SELECTED = "CLEAR_SELECTED";
const SET_HOVERED = "SET_HOVERED";
const CLEAR_HOVERED = "CLEAR_HOVERED";
const SET_HOVEREDSELECTED = "SET_ACTIVE";
const CLEAR_HOVEREDSELECTED = "CLEAR_ACTIVE";

//TODO empty arrays in initialState are assigned `never` regardless of type assignment. Find solution to remove `any`'s
const satStateReducer = (
  state: {
    hoveredSatId: string | number | any;
    hoveredSelectedSatId: string | number | any;
    selectedSatIds: string[] | any[];
  },
  action: { type: string; payload?: number | string | null }
) => {
  switch (action.type) {
    case ADD_SELECTED:
      return {
        ...state,
        selectedSatIds: [...state.selectedSatIds, action.payload],
      };
    case REMOVE_SELECTED: {
      const filteredSatIds = state.selectedSatIds.filter(
        (id: string | number) => id !== action.payload
      );
      return {
        ...state,
        selectedSatIds: [...filteredSatIds],
      };
    }
    case CLEAR_SELECTED:
      return {
        ...state,
        selectedSatIds: [],
      };
    case SET_HOVERED:
      return {
        ...state,
        hoveredSatId: action.payload,
      };
    case CLEAR_HOVERED:
      return {
        ...state,
        hoveredSatId: null,
      };
    case SET_HOVEREDSELECTED:
      return {
        ...state,
        hoveredSelectedSatId: action.payload,
      };
    case CLEAR_HOVEREDSELECTED:
      return {
        ...state,
        hoveredSelectedSatId: null,
      };
  }
  return state;
};

export const SatStateContextProvider = ({ children }: Children) => {
  const [{ selectedSatIds, hoveredSatId, hoveredSelectedSatId }, dispatch] =
    useReducer(satStateReducer, initialState);

  const addSelected = useCallback(
    (satId: string | number) => {
      dispatch({
        type: ADD_SELECTED,
        payload: satId,
      });
    },
    [dispatch]
  );

  const removeSelected = useCallback(
    (satId: string | number) => {
      dispatch({
        type: REMOVE_SELECTED,
        payload: satId,
      });
    },
    [dispatch]
  );

  const clearSelected = useCallback(() => {
    dispatch({
      type: CLEAR_SELECTED,
    });
  }, []);

  const setHovered = useCallback(
    (satId: string | number | null) => {
      dispatch({
        type: SET_HOVERED,
        payload: satId,
      });
    },
    [dispatch]
  );

  const clearHovered = useCallback(() => {
    dispatch({
      type: CLEAR_HOVERED,
    });
  }, [dispatch]);

  const setHoveredSelected = useCallback(
    (satId: string | number | null) => {
      dispatch({
        type: SET_HOVEREDSELECTED,
        payload: satId,
      });
    },
    [dispatch]
  );

  const clearHoveredSelected = useCallback(() => {
    dispatch({
      type: CLEAR_HOVEREDSELECTED,
    });
  }, [dispatch]);

  const clearSatState = useCallback(() => {
    clearHoveredSelected();
    clearHovered();
    clearSelected();
  }, [clearHoveredSelected, clearHovered, clearSelected]);

  const value = {
    selectedSatIds,
    addSelected,
    removeSelected,
    clearSelected,
    hoveredSatId,
    setHovered,
    clearHovered,
    hoveredSelectedSatId,
    setHoveredSelected,
    clearHoveredSelected,
    clearSatState,
  };

  return (
    <SatStateContext.Provider value={value}>
      {children}
    </SatStateContext.Provider>
  );
};
