import Paper from "@mui/material/Paper";
import { SxProps, Theme } from "@mui/material";

const leftPanelSX: SxProps<Theme> = {
  /* screen height - global status bar - top and bottom padding */
  height: "calc(100vh - 6rem - 2.5rem)",
  borderWidth: ({ astro }) => astro.border.width.xs,
  borderColor: ({ astro }) => astro.color.text.inverse,
  borderStyle: "solid",
  overflow: "auto",
};

const LeftPanel = ({ children }: Children) => (
  <Paper sx={leftPanelSX}>{children}</Paper>
);

export default LeftPanel;
