import { trogdorApi } from "../api";
import { formatCreateEventParameterRequest } from "../formatting/requestFormatting";
import { MutationResponse } from "../types/responseTypes";
import { CreateEventParamValues } from "../types/internalTypes";

export const eventParameterEndpoints = trogdorApi.injectEndpoints({
  endpoints: (builder) => ({
    createEventParameter: builder.mutation<
      MutationResponse,
      CreateEventParamValues
    >({
      query: (values) => {
        const params = formatCreateEventParameterRequest(values);
        return {
          url: "/db/create_event_parameter",
          method: "post",
          params: params,
        };
      },
      invalidatesTags: (_result, _error, { eventId }) => [
        { type: "Event", id: eventId },
      ],
    }),
  }),
});

export const { useCreateEventParameterMutation }: any = eventParameterEndpoints;
