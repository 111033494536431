import { eventTypeDefinitions } from "../../../utils/api/trogdor/eventTypeDefinitions";
import {
  EventTypeResponse,
  ScenarioResponse,
  SpaceTrackSatResponse,
  EventResponse,
  TLESatResponse,
  SVSatResponse,
} from "../types/responseTypes";
import {
  EventType,
  Scenario,
  SpaceTrackSat,
  Event,
  TLESat,
  SVSat,
} from "../types/internalTypes";
import Common, { findEventTypeIdByName } from "../../../utils/common";

export const formatEventTypeResponse = (
  eventTypeData: EventTypeResponse
): EventType => {
  // Optionally remove class object layer.
  if ("Type" in eventTypeData) eventTypeData = eventTypeData.Type;

  const eventTypeId = eventTypeData.typeID;
  const eventTypeDef: { [key: typeof eventTypeId]: any } = eventTypeDefinitions;

  const eventTypeObj = {
    category: eventTypeDef[eventTypeId].category,
    description: eventTypeData.typeDesc,
    id: eventTypeData.typeID,
    name: eventTypeData.typeName,
    providers: eventTypeData.providers.providers,
    parameterDefs: {},
  };

  eventTypeObj.providers.forEach(({ providerName, providerID }) => {
    const eventTypeObjId = eventTypeObj.id;
    const eventTypeDef: { [key: typeof eventTypeObjId]: any } =
      eventTypeDefinitions;
    const paramDefsForProvider =
      eventTypeDef[eventTypeObjId].paramDefs(providerName);
    const paramDefType: { [key: string]: object } = eventTypeObj.parameterDefs;
    paramDefType[providerID] = paramDefsForProvider;
  });

  return eventTypeObj;
};

export const formatScenarioResponse = (
  scenarioData: ScenarioResponse
): Scenario => {
  // Optionally remove class object layer.
  if ("Scenario" in scenarioData) scenarioData = scenarioData.Scenario;
  const scenarioEvents = scenarioData.events.events || scenarioData.events;

  return {
    id: scenarioData.scenarioID,
    name: scenarioData.scenarioName,
    description: scenarioData.scenarioDescription,
    active: scenarioData.active,
    startTime: scenarioData.startTime,
    endTime: scenarioData.endTime,
    events: scenarioEvents.map((event: any) => formatEventResponse(event)),
    status: scenarioData.status,
  };
};

export const formatSpaceTrackSatResponse = (
  satellite: SpaceTrackSatResponse
): SpaceTrackSat => {
  const camelizedSat = Common.camelize(satellite);
  return {
    ...camelizedSat,
    id: camelizedSat.noradCatId.toString(),
    satNo: camelizedSat.noradCatId,
    line1: camelizedSat.tleLine1,
    line2: camelizedSat.tleLine2,
    type: "TLE",
    eventId: null,
    visualizationState: "visible",
  };
};

export const formatEventResponse = (eventData: EventResponse): Event => {
  // Optionally remove class object layer.
  if ("Event" in eventData) eventData = eventData.Event;
  if ("ManeuverEvent" in eventData) eventData = eventData.ManeuverEvent;

  return {
    id: eventData.eventID,
    name: eventData.eventName,
    startTime: eventData.startTime,
    endTime: eventData.endTime,
    scenarioId: eventData.scenarioID,
    active: eventData.active,
    status: eventData.status,
    eventType: eventData.eventType,
    eventTypeId: findEventTypeIdByName(eventData.eventType),
    provider: eventData.provider,
    parameters: eventData.parameters,
    changed: false,
  };
};

export const formatTLESatResponse = (
  tleData: TLESatResponse | { UDLTle: TLESatResponse },
  eventId: number
): TLESat => {
  // Optionally remove class object layer.
  if ("UDLTle" in tleData) tleData = tleData.UDLTle;
  const satNo = tleData?.satNo;
  const constructedName = `Sat No: ${satNo}`;
  return {
    ...tleData,
    id: tleData.idElset,
    eventId,
    name: constructedName,
    type: "TLE",
    visualizationState: "visible",
  };
};

export const formatSVSatResponse = (
  svData: SVSatResponse | { UDLStateVector: SVSatResponse },
  eventId: number
): SVSat => {
  // Optionally remove class object layer.
  if ("UDLStateVector" in svData) svData = svData.UDLStateVector;

  return {
    ...svData,
    id: svData.idStateVector,
    type: "SV",
    visualizationState: "hidden",
    eventId: eventId,
  };
};
