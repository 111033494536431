import CreateScenarioPanel from "../../../../Scenarios/CreateEditScenarioPanel/CreateScenarioPanel";
import ScenarioDetailsPanel from "../../../../Scenarios/ScenarioDetailsPanel/ScenarioDetailsPanel";
import ScenariosListPanel from "../../../../Scenarios/ScenarioListPanel/ScenariosListPanel";
import { useAnimationContext } from "../../../../../utils/Hooks/contextHooks/useAnimationContext";
import LeftBehavior from "./LeftBehavior/LeftBehavior";
import RigthBehavior from "./RightBehavior/RightBehavior";

type SlideInPanelProps = {
  type: "scenarios-list" | "create-scenario" | "scenario-details";
};

const duration = 0.3;

const SlideInPanel = ({ type }: SlideInPanelProps) => {
  const { isInitialPageLoad, isAnimateLeftBehavior, isAnimateRightBehavior } =
    useAnimationContext();

  switch (type) {
    case "create-scenario": {
      return (
        <RigthBehavior
          {...{
            duration,
            isAnimate: isAnimateRightBehavior,
          }}
        >
          <CreateScenarioPanel />
        </RigthBehavior>
      );
    }

    case "scenario-details": {
      return (
        <RigthBehavior {...{ duration, isAnimate: isAnimateRightBehavior }}>
          <ScenarioDetailsPanel />
        </RigthBehavior>
      );
    }

    case "scenarios-list": {
      return (
        <LeftBehavior
          {...{ duration, isInitialPageLoad, isAnimate: isAnimateLeftBehavior }}
        >
          <ScenariosListPanel />
        </LeftBehavior>
      );
    }

    default:
      return null;
  }
};

export default SlideInPanel;
