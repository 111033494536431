import { RuxStatus } from "@astrouxds/react";
import Grid from "@mui/material/Grid";
import { BoxProps } from "@mui/material/Box";
import { StatusTypes } from "../../../types/utils";

type RuxStatusProps = BoxProps & {
  status: StatusTypes;
  size: string;
};

const Status = ({ size, status = "normal", ...boxProps }: RuxStatusProps) => (
  <Grid
    component={RuxStatus}
    status={status}
    {...boxProps}
    sx={{
      "::part(status)": {
        width: size,
        height: size,
      },
      ...boxProps.sx,
    }}
  />
);

export default Status;
