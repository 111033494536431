import { useState, createContext, useContext, useEffect } from "react";
import { earthRadius } from "satellite.js/lib/constants";
import { getProject, ISheet, onChange } from "@theatre/core";

import demoProjectState from "../../../Components/LoginScene/login-scene-state.json";

type PropTypes = {
  demoSheet: ISheet | any;
  maxDistanceFactor: number;
  minDistance: number;
  sunLightRef: React.RefObject<any> | undefined;
  setSunLightRef: React.Dispatch<React.SetStateAction<any>>;
  lensflareToggle: boolean;
  earthRadius: number | any;
  sceneIsLoaded: boolean;
  setSceneIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  introAnimationDone: boolean;
  atmosphereScaleToggleAlpha: boolean;
};

export const LoginCanvasContext = createContext<PropTypes>({
  demoSheet: undefined,
  maxDistanceFactor: 45,
  minDistance: 2000,
  sunLightRef: undefined,
  setSunLightRef: () => null,
  lensflareToggle: false,
  earthRadius,
  sceneIsLoaded: false,
  setSceneIsLoaded: () => null,
  introAnimationDone: false,
  atmosphereScaleToggleAlpha: false,
});

export function useLoginCanvasContext() {
  return useContext(LoginCanvasContext);
}

export const LoginCanvasContextProvider = ({ children }: Children) => {
  const maxDistanceFactor = 45;
  const minDistance = 2000;

  const [sceneIsLoaded, setSceneIsLoaded] = useState(false);
  const [introAnimationDone, setIntroAnimationDone] = useState(false);
  const [atmosphereScaleToggleAlpha, setAtmosphereScaleToggleAlpha] =
    useState(false);

  const [sunLightRef, setSunLightRef] = useState();
  const [lensflareToggle, setLensflareToggle] = useState(false);

  const demoSheet = getProject("Demo Project", {
    state: demoProjectState,
  }).sheet("Demo Sheet");

  // __ Initialize Animation ______________________________________________________
  useEffect(() => {
    if (!sceneIsLoaded) return;

    demoSheet.project.ready.then(() => {
      demoSheet.sequence.play({ iterationCount: 1 }).then(() => {
        setIntroAnimationDone(true);
      });
    });
  }, [sceneIsLoaded]);

  // __ Animation Timeline toggles ______________________________________________________
  onChange(demoSheet.sequence.pointer.position, (position) => {
    // Hide Lensflare while camera is animating in
    if (position >= 2.7 && !lensflareToggle) {
      setLensflareToggle(true);
    }

    // Change Atmosphere scaleFactor lerp alpha to look the best while frame rate drops while camera is animating in
    if (position >= 4.26 && !atmosphereScaleToggleAlpha) {
      setAtmosphereScaleToggleAlpha(true);
    }
  });

  return (
    <LoginCanvasContext.Provider
      value={{
        demoSheet,
        maxDistanceFactor,
        minDistance,
        sunLightRef,
        setSunLightRef,
        lensflareToggle,
        earthRadius,
        sceneIsLoaded,
        setSceneIsLoaded,
        introAnimationDone,
        atmosphereScaleToggleAlpha,
      }}
    >
      {children}
    </LoginCanvasContext.Provider>
  );
};
