import {
  BaseQueryFn,
  fetchBaseQuery,
  FetchArgs,
  FetchBaseQueryError,
  BaseQueryApi,
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { RootState } from "../../app/store";

// Redux Actions
import { logOut, setAccessToken } from "../slices/auth/authSlice";
import { resetEvents } from "../slices/events/eventsSlice";
import { resetEvents as resetCurScenarioEvents } from "../slices/scenarios/currentScenario/events/eventsSlice";
import { resetTLEs } from "../slices/scenarios/currentScenario/objects/preEventTLEs";
import { resetSVSlice } from "../slices/scenarios/currentScenario/objects/postEventSVs";
import { resetScenarioDetails } from "../slices/scenarios/currentScenario/details/scenarioDetailsSlice";
import Common from "../../utils/common";

import { trogdorApi, acmeApi } from "./api";

const baseUrl = process.env.REACT_APP_BASE_URL || "";
const mutex = new Mutex();

// TODO: Check for combining into acmeBaseQuery
export const refreshTokenQuery = fetchBaseQuery({
  baseUrl,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.tokens.refresh.token;
    // If we have a token set in state pass it.
    if (token) {
      headers.set("X-CSRF-TOKEN", token);
    }
    return headers;
  },
});

export const acmeBaseQuery = fetchBaseQuery({
  baseUrl,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.tokens.access.token;
    // If we have a token set in state pass it.
    if (token) {
      headers.set("X-CSRF-TOKEN", token);
    }
    return headers;
  },
});

export const trogdorBaseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}/proxy`,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.tokens.access.token;
    // If we have a token set in state pass it.
    if (token) {
      headers.set("X-CSRF-TOKEN", token);
    }
    return headers;
  },
});

export const trogdorQuery =
  (): BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it.
    // forces any further requests to wait for refresh process
    await mutex.waitForUnlock();

    const newArgs = {
      url: "/",
      params: {
        api: "trogdor",
        url: args.url,
        ...args.params,
      },
      body: args.body,
      method: args.method,
    };

    let result = await trogdorBaseQuery(newArgs, api, extraOptions);
    if (result.error && result.error.status === 401) {
      // checking for locked mutex
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          // try to get a new token
          const refreshResult = await refreshTokenQuery(
            { url: "/auth/refresh", method: "post" },
            api,
            extraOptions
          );
          if (refreshResult?.data) {
            // store the new token
            api.dispatch(setAccessToken(refreshResult.data));
            // retry the initial query
            result = await trogdorBaseQuery(newArgs, api, extraOptions);
          } else {
            await acmeBaseQuery(
              { url: "/auth/logout", method: "post" },
              api,
              {}
            );
            clearStateAndNavigateToLogin(api);
          }
        } finally {
          // release gets called once request is complete
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await trogdorBaseQuery(args, api, extraOptions);
      }
    }
    return result;
  };

export const acmeQuery =
  (): BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it.
    // forces any further requests to wait for refresh process
    await mutex.waitForUnlock();

    // Request formatting
    if (args.body) {
      args.body = Common.snakeize(args.body);
    }

    let result = await acmeBaseQuery(args, api, extraOptions);
    if (
      result.error &&
      result.error.status === 401 &&
      args.url !== "/auth/login"
    ) {
      // checking for locked mutex
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          // try to get a new token
          const refreshResult = await refreshTokenQuery(
            { url: "/auth/refresh", method: "post" },
            api,
            extraOptions
          );
          if (refreshResult?.data) {
            // store the new token
            api.dispatch(setAccessToken(refreshResult.data));
            // retry the initial query
            result = await acmeBaseQuery(args, api, extraOptions);
          } else {
            await acmeBaseQuery(
              { url: "/auth/logout", method: "post" },
              api,
              {}
            );
            clearStateAndNavigateToLogin(api);
          }
        } finally {
          // release gets called once request is complete
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await acmeBaseQuery(args, api, extraOptions);
      }
    }
    return result;
  };

// const tokenErrorMessageCheck = (message: string) =>
//   message === "CSRF double submit tokens do not match";

// const isRefreshExpired = (state: any) => {
//   const refreshExpiration = state?.tokens?.refresh?.expires;
//   if (refreshExpiration) {
//     const expiresDate = new Date(refreshExpiration);
//     return expiresDate < new Date();
//   } else {
//     return true;
//   }
// };

const clearStateAndNavigateToLogin = async (api: BaseQueryApi) => {
  const host = window.location.host;
  await logOff(api).then(() => {
    window.location.replace(`http://${host}/login`);
  });
  // TODO: display warning notification that refresh token was expired
};

/* 
 ! IDEA: Add state machine or flag to auth that can be triggered here
 ! somewhere else we can listen for that flag to be tripped and call the logout thunk process
*/

const logOff = async (api: BaseQueryApi) => {
  api.dispatch(trogdorApi.util.resetApiState());
  api.dispatch(acmeApi.util.resetApiState());
  // Reset Current Scenario Slices
  api.dispatch(resetCurScenarioEvents());
  api.dispatch(resetTLEs());
  api.dispatch(resetSVSlice());
  api.dispatch(resetScenarioDetails());
  // Reset events and scenario lists
  api.dispatch(resetEvents());
  // Reset auth slice
  api.dispatch(logOut());
};
