import { useState, createContext, useContext } from "react";

type PropTypes = {
  editScenarioOpen: boolean;
  createEventOpen: boolean;
  editEventOpen: boolean;
  addObjectOpen: boolean;
  scenarioId: number;
  eventId: string;
  openEditScenario: (scenarioId: number) => void;
  closeEditScenario: () => void;
  openEditEvent: (eventId: string) => void;
  closeEditEvent: () => void;
  toggleCreateEvent: () => void;
  toggleAddObject: () => void;
};

const DrawerContext = createContext<PropTypes>({
  editScenarioOpen: false,
  createEventOpen: false,
  editEventOpen: false,
  addObjectOpen: false,
  scenarioId: 0,
  eventId: "0",
  openEditScenario: () => null,
  closeEditScenario: () => null,
  openEditEvent: () => null,
  closeEditEvent: () => null,
  toggleCreateEvent: () => null,
  toggleAddObject: () => null,
});

export function useDrawerContext() {
  return useContext(DrawerContext);
}

export function DrawerContextProvider({ children }: Children) {
  const [editScenarioOpen, setEditScenarioOpen] = useState(false);
  const [createEventOpen, setCreateEventOpen] = useState(false);
  const [editEventOpen, setEditEventOpen] = useState(false);
  const [addObjectOpen, setAddObjectOpen] = useState(false);
  const [scenarioId, setScenarioId] = useState(0);
  const [eventId, setEventId] = useState("0");

  const openEditScenario = (scenarioId: number) => {
    setScenarioId(scenarioId);
    setEditScenarioOpen(true);
  };

  const closeEditScenario = () => {
    setEditScenarioOpen(false);
  };

  const openEditEvent = (eventId: string) => {
    setEventId(eventId);
    setEditEventOpen(true);
  };

  const closeEditEvent = () => {
    setEditEventOpen(false);
  };

  const toggleCreateEvent = () => {
    setCreateEventOpen(!createEventOpen);
  };

  const toggleAddObject = () => {
    setAddObjectOpen(!addObjectOpen);
  };

  return (
    <DrawerContext.Provider
      value={{
        editScenarioOpen,
        createEventOpen,
        editEventOpen,
        addObjectOpen,
        scenarioId,
        eventId,
        openEditScenario,
        closeEditScenario,
        openEditEvent,
        closeEditEvent,
        toggleCreateEvent,
        toggleAddObject,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}
