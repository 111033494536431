import AcmeAxios from "./acmeAxios";

export const login = (username: string, password: string) => {
  const data = {
    username,
    password,
  };
  return AcmeAxios.post("/auth/login", data);
};

export const renewAccessToken = (refreshToken: string) => {
  return AcmeAxios.post("/auth/refresh", null, null, {
    "X-CSRF-TOKEN": refreshToken,
  });
};

export const logoff = () => {
  return AcmeAxios.post("/auth/logout");
};
