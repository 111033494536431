import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { acmeApi } from "../api";
import { formatSpaceTrackSatResponse } from "../formatting/responseFormatting";
import { SpaceTrackSat } from "../types/internalTypes";
import { SpaceTrackSatResponse } from "../types/responseTypes";
import { formatGetFilteredSpaceTrackSatRequest } from "../formatting/requestFormatting";
import { ObjectSearchFormValues } from "../../../Components/Objects/AddObjectPanel/ObjectSearchForm";

export const satelliteSearchAdapter = createEntityAdapter<SpaceTrackSat>({
  sortComparer: (a, b) => Number(a.id) - Number(b.id),
});

export const satelliteSearchInitialState =
  satelliteSearchAdapter.getInitialState();

export const satelliteSearchEndpoints = acmeApi.injectEndpoints({
  endpoints: (builder) => ({
    getSpaceTrackSats: builder.query<
      EntityState<SpaceTrackSat>,
      ObjectSearchFormValues
    >({
      query: (formData) => ({
        url: "/stsatellites/filter",
        method: "post",
        body: { filters: formatGetFilteredSpaceTrackSatRequest(formData) },
      }),
      keepUnusedDataFor: Infinity,
      transformResponse(response: SpaceTrackSatResponse[]) {
        const filteredSats = response.filter((sat) => {
          if (!sat.DECAY_DATE) return sat;
        });
        const satellites = filteredSats.map((sat) =>
          formatSpaceTrackSatResponse(sat)
        );
        return satelliteSearchAdapter.setAll(
          satelliteSearchInitialState,
          satellites
        );
      },
    }),
  }),
});

export const { useLazyGetSpaceTrackSatsQuery } = satelliteSearchEndpoints;
