import {
  createEntityAdapter,
  createSelector,
  EntityState,
  EntityId,
  Dictionary,
} from "@reduxjs/toolkit";
import { createCachedSelector } from "re-reselect";
import { trogdorApi } from "../api";
import {
  formatCreateEventRequest,
  formatUpdateEventRequest,
} from "../formatting/requestFormatting";
import { formatEventResponse } from "../formatting/responseFormatting";
import { Event } from "../types/internalTypes";
import { MutationResponse, EventResponse } from "../types/responseTypes";
import { RootState } from "../../../app/store";
import { getScenarioIds } from "./scenario";
import _ from "lodash";

const eventURLs = {
  1: "/db/create_event",
  4: "/maneuver/change_altitude",
  5: "/maneuver/change_inclination",
  6: "/maneuver/change_period",
  7: "/maneuver/close_approach",
  // 12: "/maneuver/rpo",
  // 12: "/maneuver/rpo-scenario",
  13: "/breakup/breakup",
  14: "/breakup/shedding",
};

export const eventsAdapter = createEntityAdapter<Event>({
  sortComparer: (a, b) => a.scenarioId - b.scenarioId,
});

export const eventsInitialState = eventsAdapter.getInitialState();

export const eventsEndpoints = trogdorApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEvents: builder.query<EntityState<Event>, void>({
      query: () => ({
        url: "/db/get_all_events",
        method: "get",
      }),
      keepUnusedDataFor: Infinity,
      transformResponse(response: { body: EventResponse[] }) {
        const events = response.body.map((event) => {
          return formatEventResponse(event);
        });

        return eventsAdapter.setAll(eventsInitialState, events);
      },
      providesTags: (entityResponse: EntityState<Event> | undefined) => {
        const entities = entityResponse?.entities;
        const arrayOfEntities =
          entities && (Object.values(entities) as Event[]);
        return arrayOfEntities
          ? [
              ...arrayOfEntities.map(({ id }) => ({
                type: "Event" as const,
                id,
              })),
              "Event",
            ]
          : ["Event"];
      },
    }),
    getScenarioEvents: builder.query<EntityState<Event>, number>({
      async queryFn(scenarioId, _queryApi, _extraOptions, baseQuery) {
        const scenarioEvents: any = await baseQuery({
          url: "/db/get_scenario_events",
          method: "get",
          params: { scenario_id: scenarioId },
        });
        const formattedEvents =
          scenarioEvents?.data?.body?.message ===
          "There are no events for the given Scenario."
            ? []
            : scenarioEvents?.data?.body?.map((event: EventResponse) => {
                return formatEventResponse(event);
              });
        const eventIds = formattedEvents.map((event: any) => event.id);
        const scenarioEventsArray = [] as any;
        const eventRequests = eventIds.map(async (eventId: number) => {
          const eventResponse: any = await baseQuery({
            url: "/db/get_event",
            method: "get",
            params: { event_id: eventId },
          });
          scenarioEventsArray.push(
            formatEventResponse(eventResponse.data.body)
          );
        });
        await Promise.all(eventRequests);

        const eventEntity = eventsAdapter.setAll(
          eventsInitialState,
          scenarioEventsArray
        );
        return eventEntity
          ? { data: eventEntity }
          : {
              status: 400,
              data: {} as EntityState<Event>,
            };
      },
      providesTags: (entityResponse: EntityState<Event> | undefined) => {
        const entities = entityResponse?.entities;
        const arrayOfEntities =
          entities && (Object.values(entities) as Event[]);
        return arrayOfEntities
          ? [
              ...arrayOfEntities.map(({ id }) => ({
                type: "Event" as const,
                id,
              })),
              "Event",
            ]
          : ["Event"];
      },
    }),
    getEvent: builder.query<Event, number>({
      query: (id) => ({
        url: "/db/get_event",
        method: "get",
        params: { event_id: id },
      }),
      transformResponse(response: { body: EventResponse }) {
        return formatEventResponse(response.body);
      },
      providesTags: (_result, _error, id) => [{ type: "Event", id }],
    }),
    createEvent: builder.mutation<Event, Event>({
      query: (eventData) => {
        const eventTypeId = eventData.eventTypeId;
        const params = formatCreateEventRequest(eventData);
        return {
          url: eventURLs[eventTypeId as keyof typeof eventURLs],
          method: "post",
          params: params,
        };
      },
      transformResponse(response: { body: EventResponse }) {
        return formatEventResponse(response.body);
      },
      invalidatesTags: ["Event"],
    }),
    updateEvent: builder.mutation<MutationResponse, Event>({
      query: (formData) => {
        const params = formatUpdateEventRequest(formData);
        return {
          url: "/db/update_event",
          method: "post",
          params: params,
        };
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: "Event", id: arg.id },
      ],
    }),
    deleteEvent: builder.mutation<MutationResponse, number>({
      query: (id) => {
        const params = { event_id: id };
        return {
          url: "/db/delete_event",
          method: "delete",
          params: params,
        };
      },
      invalidatesTags: (_result, _error, id) => [{ type: "Event", id }],
    }),
  }),
});

export const {
  useGetAllEventsQuery,
  useGetScenarioEventsQuery,
  useCreateEventMutation,
  useDeleteEventMutation,
}: any = eventsEndpoints;

// Build and export selectors
const selectEventsResult = eventsEndpoints.endpoints.getAllEvents.select();

export const selectEventsData = createSelector(
  selectEventsResult,
  (eventsResult) => eventsResult.data
);

// Export selectors
export const {
  selectIds: getEventIds,
  selectEntities: getEventsById,
  selectAll: getEvents,
  selectById: getEventById,
} = eventsAdapter.getSelectors(
  (state: RootState) => selectEventsData(state) ?? eventsInitialState
);

export const getEventsByScenarioId: any = createSelector(
  [selectEventsData, getScenarioIds],
  (selectEventsData, scenarioIds) => {
    if (selectEventsData && !_.isEmpty(scenarioIds)) {
      return buildEventsByScenarioId(selectEventsData.entities, scenarioIds);
    } else {
      return {};
    }
  }
);

const scenarioId = (state: RootState, scenarioId: number) => scenarioId;

export const getEventIdsByScenarioId = createCachedSelector(
  [getEventsByScenarioId, scenarioId],
  (selectorResult: any, scenarioId: number) => {
    const scenarioEvents =
      selectorResult?.eventsByScenarioId?.[scenarioId] || [];
    return scenarioEvents.map((event: Event) => event.id);
  }
)(scenarioId);

export const buildEventsByScenarioId = (
  eventEntities: Dictionary<Event>,
  scenarioIds: EntityId[]
) => {
  const allEvents = Object.values<Event>(
    eventEntities as Record<EntityId, Event>
  );
  const eventsByScenarioId: { [key: EntityId]: Event[] } = {};
  scenarioIds.map((scenarioId) => {
    const matchingEvents = allEvents.filter(
      (event) => scenarioId === event?.scenarioId
    );
    eventsByScenarioId[scenarioId] = matchingEvents;
  });
  return {
    eventsByScenarioId,
  };
};
