import { Suspense } from "react";
import { CanvasContextProvider } from "../../utils/Hooks/contextHooks/useCanvasContext";
import { ZoomContextProvider } from "../../utils/Hooks/contextHooks/useZoomContext";

import Setup from "./setup/Setup";
import Loading from "./setup/Loading";
import Stars from "./setup/Stars";
import Sun from "./objects/Sun";
import Earth from "./objects/Earth";
import NightLights from "./aux/NightLights";
import Atmosphere from "./aux/Atmosphere";

const Engine = () => {
  return (
    <>
      <ZoomContextProvider>
        <CanvasContextProvider>
          <Setup />
          <Suspense fallback={<Loading />}>
            <Stars />
            <Sun />
            <Earth />
            <NightLights />
            <Atmosphere />
          </Suspense>
        </CanvasContextProvider>
      </ZoomContextProvider>
    </>
  );
};

export default Engine;
