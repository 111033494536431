import { combineReducers } from "redux";
import { currentScenarioEventsSlice } from "./events/eventsSlice";
import { preEventTLEsSlice } from "./objects/preEventTLEs";
import { currentScenarioDetailsSlice } from "./details/scenarioDetailsSlice";
import { postEventSVsSlice } from "./objects/postEventSVs";

const currentScenarioReducer = combineReducers({
  details: currentScenarioDetailsSlice.reducer,
  events: currentScenarioEventsSlice.reducer,
  preEventTLEs: preEventTLEsSlice.reducer,
  postEventSVs: postEventSVsSlice.reducer,
});

export default currentScenarioReducer;
