import AcmeAxios from "./acmeAxios";
import { formatEventObjectResponse } from "./formattingFunctions";

export const getEvent = (eventId) => {
  return AcmeAxios.get(`/scenarios/events/${eventId}`);
};

export const createEvent = (data) => {
  return AcmeAxios.post(`/scenarios/events/`, data).then((response) => {
    return response;
  });
};

export const editEvent = (data, eventId) => {
  return AcmeAxios.put(`/scenarios/events/${eventId}`, data).then(
    (response) => {
      return response;
    }
  );
};

export const deleteEvent = (id) => {
  return AcmeAxios.delete(`/scenarios/events/${id}`);
};

export const createEventObject = (data, eventId) => {
  return AcmeAxios.post(`/scenarios/events/objects/`, {
    ...data,
    eventId,
  }).then((response) => {
    response.data = formatEventObjectResponse(response.data);
    return response;
  });
};

export const deleteEventObject = (objectId) => {
  return AcmeAxios.delete(`/scenarios/events/objects/${objectId}`).then(
    (response) => {
      response.data = formatEventObjectResponse(response.data);
      return response;
    }
  );
};

export const editEventObject = (data, objectId) => {
  return AcmeAxios.put(`/scenarios/events/objects/${objectId}`, data).then(
    (response) => {
      response.data = formatEventObjectResponse(response.data);
      return response;
    }
  );
};
