import { useCallback } from "react";
import { useAppSelector } from "./reduxHooks";
import { useLocation } from "react-router-dom";
import { isAfter, isEqual, parseISO } from "date-fns";
import {
  getTimelineEventIds,
  getTimelineEventsById,
  getTimelineTLEsByEventId,
  getTimelineSVsByEventId,
} from "../../redux/selectors/timelineSelector";
import { eventTypeDefinitions } from "../api/trogdor/eventTypeDefinitions";
import {
  findEventParamValueByDefId,
  findTleIdBySatNo,
  findEventTypeIdByName,
} from "../common";
import {
  EventStateVectorResponse,
  SatTypes,
} from "../../redux/rtk/types/trogdorApiTypes";
import { EventSat } from "../../redux/rtk/types/ThreeJSCommonTypes";
import { Event } from "../../redux/rtk/types/internalTypes";

const useEventTimeline = () => {
  const location = useLocation();
  const timelineEventIds = useAppSelector(getTimelineEventIds);
  const timelineEventsById = useAppSelector(getTimelineEventsById);
  const timelineTLEsByEventId = useAppSelector(getTimelineTLEsByEventId);
  const timelineSVsByEventId = useAppSelector(getTimelineSVsByEventId);

  // Takes a time and returns the current satellites for visualization
  const getCurrentSats = useCallback(
    (
      engineDateTime?: Date | any,
      timelineTLEs?: EventSat[],
      timelineSVs?: EventStateVectorResponse[]
    ) => {
      // prevents satellites from being shown on ScenarioList screen
      if (location.pathname === "/scenarios") return [];

      if (!engineDateTime || !timelineTLEs || !timelineSVs) return [];
      const activeEvents = getActiveEvents(
        engineDateTime,
        timelineEventIds,
        timelineEventsById
      );

      let satellites = addVisualizationProp(timelineTLEs, timelineSVs);

      // * Events are already sorted by event start
      //id is 'unknown' and unknown can only be itself or 'any'
      activeEvents.forEach((id: any) => {
        satellites = updateSatellitesByEvent(
          timelineEventsById[id],
          satellites,
          timelineTLEsByEventId,
          timelineSVsByEventId
        );
      });

      return satellites;
    },
    [
      getActiveEvents,
      timelineEventsById,
      updateSatellitesByEvent,
      timelineTLEsByEventId,
      timelineSVsByEventId,
    ]
  );

  return {
    getCurrentSats,
  };
};

export default useEventTimeline;

export const addVisualizationProp = (
  TLEs: EventSat[],
  SVs: EventStateVectorResponse[]
) => {
  const visualizationPropTLEs = TLEs.map((sat) => {
    return {
      ...sat,
      visualizationState: "visible",
    };
  });
  const visualizationPropSVs = SVs.map((sat) => {
    return {
      ...sat,
      visualizationState: "hidden",
    };
  });
  return [...visualizationPropTLEs, ...visualizationPropSVs];
};

// Builds a set of active event IDs
export const getActiveEvents = (
  engineDateTime: Date,
  eventIds: string[],
  eventsById: { [id: string]: Event }
) => {
  const newActiveEvents = new Set();
  eventIds.forEach((id) => {
    const event = eventsById[id];
    const eventTypeId = findEventTypeIdByName(event?.eventType);
    const eventTypeDef: { [key: typeof eventTypeId]: any } =
      eventTypeDefinitions;
    const triggerValue = findEventParamValueByDefId(
      event,
      eventTypeDef[eventTypeId].trigger
    );

    const triggerTime = parseISO(triggerValue ? triggerValue : event.startTime);

    if (
      isAfter(engineDateTime, triggerTime) ||
      isEqual(engineDateTime, triggerTime)
    ) {
      newActiveEvents.add(id);
    }
  });
  return newActiveEvents;
};

// Takes event and current satellites and returns an updated satellite list
export const updateSatellitesByEvent = (
  event: Event,
  currentSats: SatTypes[] | any[],
  tlesByEventId: { [id: string]: EventSat[] },
  svsByEventId: { [id: string]: EventStateVectorResponse[] }
) => {
  const eventTLEArray = tlesByEventId[event.id];
  const eventSVArray = svsByEventId[event.id];
  const eventTypeId = findEventTypeIdByName(event?.eventType);
  const eventTypeDef: { [key: typeof eventTypeId]: any } = eventTypeDefinitions;
  // TODO: Split events further into each type and provider
  const categories = {
    0: () => {
      const satNo = findEventParamValueByDefId(
        event,
        eventTypeDef[eventTypeId].target
      );
      const targetSatId = findTleIdBySatNo(eventTLEArray, satNo);
      if (!targetSatId) return currentSats;

      return currentSats.map((sat) => {
        if (sat.id === targetSatId) sat.visualizationState = "hidden";
        return sat;
      });
    },
    1: () => {
      // TODO remove hardcoded references manevuered sats once Trogdor resolves duplication issue
      const satNo = findEventParamValueByDefId(
        event,
        eventTypeDef[eventTypeId].target
      );

      const targetSatId = findTleIdBySatNo(eventTLEArray, satNo);
      const maneuveredSat = eventSVArray?.length && eventSVArray[0];
      if (!targetSatId || !maneuveredSat) return currentSats;

      return currentSats.map((sat) => {
        if (sat.type === "TLE" && sat.id === targetSatId) {
          sat.visualizationState = "hidden";
        }

        if (
          sat.type === "SV" &&
          sat.idStateVector === maneuveredSat.idStateVector
        ) {
          sat.visualizationState = "visible";
        }

        return sat;
      });
    },
  };

  const eventTypeIdCategory: number = eventTypeDef[eventTypeId].category;
  const category: { [key: typeof eventTypeIdCategory]: () => number[] } =
    categories;
  if (category[eventTypeIdCategory]) {
    return category[eventTypeIdCategory]();
  } else return currentSats;
};
