/* eslint-disable react/no-unknown-property */
import { useEffect, useState } from "react";
import { subSeconds, addSeconds } from "date-fns";
import { Line } from "@react-three/drei";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import { getPositionFromSV } from "../../../utils/propagation";
import SVPointHelper from "./SVPointHelper";
import { Ephemeris } from "../../../redux/rtk/types/internalTypes";
import { Vector3 } from "@react-three/fiber";
import { PathPointsHelper } from "../../../redux/rtk/types/ThreeJSCommonTypes";

type PropTypes = {
  ephemerisPoints: Ephemeris;
  startDate: Date;
  pathPoints: THREE.Vector3[];
  range: number;
};

const SVPathHelper = ({
  ephemerisPoints,
  startDate,
  pathPoints,
  range,
}: PropTypes) => {
  const { timelineIsScrubbing } = useCanvasContext();
  const [pathPointsHelper, setPathPointsHelper] = useState<PathPointsHelper[]>(
    []
  );

  useEffect(() => {
    createHelperPath(startDate);
  }, [startDate]);

  const createHelperPath = (start: Date) => {
    const getEphemerisPathLookup = [];
    const stepsBefore = subSeconds(
      start,
      parseInt(ephemerisPoints.step) * range
    );

    for (let i = 0; i < range * 2; i++) {
      const date = addSeconds(stepsBefore, i * Number(ephemerisPoints.step));
      const ephemData = getPositionFromSV(date, ephemerisPoints);

      if (!ephemData?.position) continue;

      const lookup = {
        point: {
          date: ephemData.beforeTime,
          position: ephemData.position,
        },
      };
      getEphemerisPathLookup.push(lookup);
    }
    if (getEphemerisPathLookup.length > 1) {
      setPathPointsHelper(getEphemerisPathLookup);
    }
  };

  return (
    <>
      {pathPointsHelper.length
        ? pathPointsHelper.map((point: any, i) => {
            //any-point doesn't exist on point, can't find right type here
            return (
              <mesh
                position={point.point.position}
                key={i}
                name="crurvePoint"
                visible={!timelineIsScrubbing}
              >
                <sphereGeometry args={[40, 16, 16]} />
                <meshBasicMaterial color={"red"} />
                <SVPointHelper date={point.point.date} />
              </mesh>
            );
          })
        : null}

      {pathPoints.length ? (
        <>
          <Line
            name={"Interpolation Helper Line"}
            linewidth={3}
            points={pathPoints}
            color={"green"}
            visible={!timelineIsScrubbing}
          />

          {pathPoints.map((point: Vector3, i: number) => {
            return (
              <mesh
                name="Interpolation Helper Points"
                position={point}
                key={i}
                visible={!timelineIsScrubbing}
              >
                <sphereGeometry args={[50, 16, 16]} />
                <meshBasicMaterial color={i === 0 ? "#ffd500" : "green"} />
              </mesh>
            );
          })}
        </>
      ) : null}
    </>
  );
};

export default SVPathHelper;
