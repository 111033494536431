import { useState, useEffect, useRef } from "react";
import useKeyPress from "../../../../utils/Hooks/useKeyPress";
import { Stats } from "@react-three/drei";
import { Box } from "@mui/material";

const styles = {
  performanceTool: {
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.5s ease-in-out",
  },
  key: {
    fontSize: "14px",
    color: "#a0a0a0",
    width: "max-content",
    ul: {
      paddingLeft: ".10rem",
      margin: "10px 12px",
    },
  },
  statsContainer: {
    display: "flex",
    flexDirection: "column",
    "> .stats": {
      position: "relative !important",
      display: "flex",
      flexDirection: "column",
      cursor: "auto !important",
      "> canvas": {
        display: "block !important",
        position: "relative",
        marginBottom: ".75rem",
        border: "1px solid #1b2d3e96",
      },
    },
  },
};

const PerformanceTool = () => {
  const statsContainerRef = useRef<any>();

  const [displayToggle, setDisplayToggle] = useState(false);

  const env = process.env.NODE_ENV;

  useEffect(() => {
    if (env === "development") {
      setDisplayToggle(true);
    } else {
      setDisplayToggle(false);
    }
  }, [env]);

  // Store usable keys
  const keys = {
    toggleDisplay: "x",
  };

  const keyFunctionalPaths = {
    [keys.toggleDisplay]: () => setDisplayToggle(!displayToggle),
  };

  const onKeyPress = (key: string | number) => {
    const grabCorrectKeyFunction = keyFunctionalPaths[key];
    grabCorrectKeyFunction();
  };

  useKeyPress(keyFunctionalPaths, onKeyPress);

  return (
    <Box
      component={"div"}
      sx={styles.performanceTool}
      style={
        displayToggle
          ? { transform: "translateX(0px)" }
          : { transform: "translateX(-500px)" }
      }
    >
      <Box component={"div"} sx={styles.statsContainer} ref={statsContainerRef}>
        <Stats parent={statsContainerRef} className="stats" />
      </Box>
      <Box component={"div"} className="key" sx={styles.key}>
        <ul>
          <li>Ctrl X: Toggle Stats</li>
        </ul>
      </Box>
    </Box>
  );
};

export default PerformanceTool;
