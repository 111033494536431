import { useEffect, useRef } from "react";
import { earthRadius } from "satellite.js/lib/constants";
import { useTexture, Detailed } from "@react-three/drei";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";

import EarthTemplate from "../aux/EarthTemplate";
import Satellites from "./Satellites";
import SelectedSats from "./SelectedSats";
import HoveredSats from "./HoveredSats";

const Earth = () => {
  const { setEarthRef } = useCanvasContext();
  const earth = useRef();

  useEffect(() => {
    if (earth.current) {
      setEarthRef(earth);
    }
  }, [earth, setEarthRef]);

  const [
    earthTexture1k,
    earthBumpTexture1k,
    earthTexture2k,
    earthBumpTexture2k,
    earthTexture4k,
    earthBumpTexture4k,
    earthTexture10k,
    earthBumpTexture10k,
    earthSpecularTexture,
  ] = useTexture([
    process.env.PUBLIC_URL + "/earth-assets/1k/8081_earthmap1k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/1k/8081_earthbump1k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/2k/8081_earthmap2k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/2k/8081_earthbump2k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/4k/8081_earthmap4k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/4k/8081_earthbump4k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/10k/8081_earthmap10k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/10k/8081_earthbump10k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/earthspec1k.jpg",
  ]);

  return (
    <group>
      <Detailed
        distances={[0, earthRadius * 2.3, earthRadius * 7, earthRadius * 17]}
      >
        <EarthTemplate
          earthRef={earth}
          quality={"Highest"}
          segments={128}
          map={earthTexture10k}
          bumpMap={earthBumpTexture10k}
          displacementMap={earthBumpTexture1k}
          displacementScale={100}
          specularMap={earthSpecularTexture}
          color={"white"}
        />

        <EarthTemplate
          earthRef={earth}
          quality={"High"}
          segments={64}
          map={earthTexture4k}
          bumpMap={earthBumpTexture4k}
          displacementMap={earthBumpTexture1k}
          displacementScale={170}
          specularMap={earthSpecularTexture}
          color={"white"}
        />

        <EarthTemplate
          earthRef={earth}
          quality={"Medium"}
          segments={32}
          map={earthTexture2k}
          bumpMap={earthBumpTexture2k}
          specularMap={earthSpecularTexture}
          color={"white"}
        />

        <EarthTemplate
          earthRef={earth}
          quality={"Low"}
          segments={16}
          map={earthTexture1k}
          bumpMap={earthBumpTexture1k}
          specularMap={earthSpecularTexture}
          color={"white"}
        />
      </Detailed>
      <Satellites />
      <SelectedSats />
      <HoveredSats />
    </group>
  );
};

export default Earth;
