// Redux Actions
import * as liveEventSlice from "../slices/liveEvent/liveEventSlice";

// API functions
import { getEphemFromSV } from "../../utils/api/acme/propagation";
import * as trogdorEvents from "../../utils/api/trogdor/events";
import { getUDLTLE } from "../../utils/api/udl/udl";

// Selectors
import { getLiveEventParameters } from "../selectors/liveEventSelector";

// Utilities
import { haveParamValuesChanged } from "../../utils/common";

export const updateLiveEvent = (
  newEventDetails,
  newEventParameters,
  editedEventId
) => {
  return async (dispatch, getState) => {
    const curState = await getState();
    const existingEventParams = getLiveEventParameters(curState);
    const liveEventId = crypto.randomUUID();

    // Check for changed data
    if (haveParamValuesChanged(newEventParameters, existingEventParams)) {
      try {
        await dispatch(liveEventSlice.startPosting());
        const tleResponse = await dispatch(
          fetchUdlTleForLive(newEventParameters[0].paramValue)
        );

        // If a suppression event, no SV or ephem needed
        if (newEventDetails.eventType === "Suppression") {
          dispatch(
            liveEventSlice.addPreEventTLESuccess({
              ...tleResponse.data[0],
              liveEventId,
            })
          );
          dispatch(
            liveEventSlice.setEventDetails({
              ...newEventDetails,
              id: liveEventId,
              editedEventId,
            })
          );
          dispatch(liveEventSlice.setEventParameters(newEventParameters));
          dispatch(liveEventSlice.endPosting());
        }
        // All events that require SV and ephemeris
        else {
          const svResponse = await dispatch(
            fetchSVForLive(newEventDetails, newEventParameters, liveEventId)
          );
          if (svResponse.data.body.postManeuverStateVector) {
            const postManeuverSV = svResponse.data.body.postManeuverStateVector;
            const ephemRequest = {
              ...postManeuverSV,
              start: newEventDetails.startTime,
              end: newEventDetails.endTime,
              step: 60,
            };
            const ephemResponse = await dispatch(
              fetchEphemForLive(ephemRequest)
            );

            dispatch(
              liveEventSlice.addPreEventTLESuccess({
                ...tleResponse.data[0],
                liveEventId,
              })
            );
            dispatch(
              liveEventSlice.addPostEventSVSuccess({
                ...postManeuverSV,
                idStateVector: postManeuverSV.id,
                eventId: liveEventId,
              })
            );
            dispatch(
              liveEventSlice.addEphemerisSuccess({
                id: postManeuverSV.id,
                ephemerisPoints: { ...ephemResponse.data },
              })
            );
            dispatch(
              liveEventSlice.setEventDetails({
                ...newEventDetails,
                id: liveEventId,
                editedEventId,
              })
            );
            dispatch(liveEventSlice.setEventParameters(newEventParameters));
            dispatch(liveEventSlice.endPosting());
          } else {
            console.log("Server failed");
            await dispatch(liveEventSlice.updateLiveEventFail("Server failed"));
            dispatch(liveEventSlice.endPosting());
          }
        }
      } catch (error) {
        console.log("error", error);
        await dispatch(liveEventSlice.updateLiveEventFail(error.response));
        dispatch(liveEventSlice.resetLiveEvent());
      }
    }

    return;
  };
};

export const fetchUdlTleForLive = (satNumber) => {
  return async (dispatch) => {
    const onSuccess = async (response) => {
      return response;
    };

    const onError = async (error) => {
      console.error(error);
      await dispatch(liveEventSlice.addPreEventTLEFail(error.response));
      return error;
    };

    try {
      dispatch(liveEventSlice.addPreEventTLEStart());
      const response = await getUDLTLE(satNumber);

      if (response.data.length === 0) {
        alert("No Satellite Found in UDL");
        return onError(
          Promise.reject({ response: "no satellite found in UDL" })
        );
      }
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
};

export const fetchSVForLive = (eventDetails, eventParameters, liveEventId) => {
  return async (dispatch) => {
    const onSuccess = async (response) => {
      if (response.data.body.message)
        throw new Error(response.data.body.message);
      return response;
    };

    const onError = async (error) => {
      await dispatch(liveEventSlice.addPostEventSVFail(error));
      return error;
    };

    try {
      dispatch(liveEventSlice.addPostEventSVStart());
      const response = await trogdorEvents.createEvent(
        {
          ...eventDetails,
          parameters: { parameters: eventParameters },
          eventId: liveEventId,
        },
        true
      );
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
};

export const fetchEphemForLive = (stateVector) => {
  return async (dispatch) => {
    const onSuccess = async (response) => {
      return response;
    };
    const onError = async (error) => {
      await dispatch(liveEventSlice.addEphemerisFail(error));
      console.error(error);
      return error;
    };

    try {
      await dispatch(liveEventSlice.addEphemerisStart());
      const response = await getEphemFromSV(stateVector);
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
};
