import { combineReducers } from "@reduxjs/toolkit";
import { authSlice as auth } from "./auth/authSlice";
import { eventsSlice } from "./events/eventsSlice";
import { liveEventSlice } from "./liveEvent/liveEventSlice";
import currentScenarioReducer from "./scenarios/currentScenario/currentScenarioReducer";
import { trogdorApi, acmeApi } from "../rtk/api";

const rootReducer = combineReducers({
  auth: auth.reducer,
  currentScenario: currentScenarioReducer,
  events: eventsSlice.reducer,
  liveEvent: liveEventSlice.reducer,
  [acmeApi.reducerPath]: acmeApi.reducer,
  [trogdorApi.reducerPath]: trogdorApi.reducer,
});

export default rootReducer;
export type RootReducer = ReturnType<typeof rootReducer>;
