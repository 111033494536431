import { Suspense } from "react";
import Setup from "./setup/Setup";
import Stars from "./setup/Stars";
import Sun from "./objects/Sun";
import Earth from "./objects/Earth";
import Atmosphere from "./aux/Atmosphere";
import Loading from "./setup/Loading";
import NightLights from "./aux/NightLights";
import Clouds from "./aux/Clouds";

const Engine = () => {
  return (
    <>
      <Setup />
      <Suspense fallback={<Loading />}>
        <Stars />
        <Sun />
        <Earth />
        <Atmosphere />
        <NightLights />
        <Clouds />
      </Suspense>
    </>
  );
};

export default Engine;
