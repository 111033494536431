import { useState, KeyboardEvent, FormEvent } from "react";
import { RuxButton, RuxInput } from "@astrouxds/react";
import { useAppSelector } from "../../utils/Hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getAuthError } from "../../redux/selectors/authSelector";
import { useLoginMutation } from "../../redux/rtk/api";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [login, { isLoading }] = useLoginMutation();
  const authError = useAppSelector(getAuthError);
  const navigate = useNavigate();

  const loginChange = async (e: KeyboardEvent | FormEvent) => {
    e.preventDefault();

    login({ username, password })
      .unwrap()
      .then(() => {
        navigate("/scenarios");
      })
      .catch((error) => console.log("error", error));
  };

  const onKeyDownHandler = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      loginChange(e);
    }
  };

  return (
    <Stack
      spacing="1.5rem"
      component="form"
      data-test="auth-form"
      onSubmit={loginChange}
      onKeyDown={onKeyDownHandler}
      width="25rem"
    >
      <Typography variant="h1" textAlign="center">
        Sign In
      </Typography>

      <RuxInput
        id="auth-username-input"
        label="Username"
        value={username}
        onRuxinput={(e) => setUsername(e.target.value)}
      />

      <Box component="span" position="relative">
        <RuxInput
          id="auth-password-input"
          label="Password"
          type="password"
          value={password}
          onRuxinput={(e) => setPassword(e.target.value)}
        />

        {authError && (
          <Typography
            fontWeight={500}
            position="absolute"
            bottom={-32}
            color={({ astro }) => astro.color.text.error}
          >
            {authError.message}
          </Typography>
        )}
      </Box>

      <Stack
        pt="0.5rem"
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing="1.25rem"
      >
        {/* <Stack direction="row" alignItems="center" spacing="0.5rem">
          <Typography>Remember me</Typography>
          <RuxCheckbox />
        </Stack> */}
        <RuxButton type="submit" data-test="btn-login" disabled={isLoading}>
          Sign In
        </RuxButton>
      </Stack>
    </Stack>
  );
};

export default LoginForm;
