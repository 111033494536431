import { useAppSelector } from "../../utils/Hooks/reduxHooks";
import { RuxSelect, RuxOption } from "@astrouxds/react";
import { getEventTypes } from "../../redux/rtk/endpoints/initialization";
import { RuxSelectCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";
import Common from "../../utils/common";
import { Grid } from "@mui/material";
import { EventType } from "../../redux/rtk/types/internalTypes";

const styles = {
  eventType: {
    marginLeft: "1.25rem",
  },
};

type EventTypeSelect = {
  eventTypeId: string | number | undefined;
  handleChange: (e: RuxSelectCustomEvent<void>) => void;
};

const EventTypeSelect = ({ eventTypeId, handleChange }: EventTypeSelect) => {
  const eventTypes: EventType[] = useAppSelector(getEventTypes);

  return (
    <Grid item sm={4.25} sx={styles.eventType}>
      <RuxSelect
        required
        label="Event type"
        name="eventTypeId"
        value={eventTypeId + ""}
        onRuxchange={(e) => {
          handleChange(e);
        }}
        size="small"
        data-test="event-type-select"
      >
        <RuxOption aria-selected value="" label="Select an event" />
        {eventTypes.map((eventType) => {
          if (!eventType) return null;
          return (
            <RuxOption
              key={eventType.id}
              value={eventType.id.toString()}
              label={eventType.name}
              disabled={!Common.isEventTypeSupported(eventType.id)}
            />
          );
        })}
      </RuxSelect>
    </Grid>
  );
};

export default EventTypeSelect;
