import TrogdorAxios from "./trogdorAxios";
import {
  formatSTPreEventTLERequest,
  formatUdlPreEventTLERequest,
  formatStateVectorRequest,
  formatPostEventSVArray,
  // formatMultiEventResponse,
  formatSinglePreEventTLEResponse,
  formatMultiPreEventTLEResponse,
  formatMultiCreateStateVectorResponse,
} from "./formattingFunctions";
import {
  EventSatsResponseParams,
  UDLRequestParams,
} from "../../../redux/rtk/types/trogdorApiTypes";
import { StateVectorRequestParams } from "../../../redux/rtk/types/requestTypes";

// ! Trogdor does not return a satId. This function is disabled until we can use and test
// export const getPreEventTLE = (satId) => {
//   const params = { sat_id: satId };
//   return TrogdorAxios.get("/db/get_sat", params).then((response) => {
//     response.data.body = formatPreEventTLEResponse(response.data.body[0].UDLTle);
//     return response;
//   });
// };

export const getAllPreEventTLEs = (eventId: number) => {
  const params = { event_id: eventId };
  return TrogdorAxios.get("/db/get_event_tles", params).then((response) =>
    formatMultiPreEventTLEResponse(response, eventId)
  );
};

//TODO handle type union error to remove 'any'
export const createPreEventTLE = (
  data: UDLRequestParams | EventSatsResponseParams | any,
  eventId: number
) => {
  // Check if coming from Spacetrack or UDL and format for Trogdor
  if (data.tleLine1) {
    data = formatSTPreEventTLERequest(data);
  } else data = formatUdlPreEventTLERequest(data);
  const params = { event_id: eventId };
  return TrogdorAxios.post("/db/create_sat", data, params).then((response) =>
    //@ts-expect-error eventId is expected to be passed based on formatting but they can't be combined due to different types
    formatSinglePreEventTLEResponse(response)
  );
};

// ! Trogdor does not return a satId. This function is disabled until we can use and test
// export const editPreEventTLE = (data, eventId, satId) => {
//   if (data.tleLine1) {
//     data = formatSTPreEventTLERequest(data);
//   } else data = formatUdlPreEventTLERequest(data);
//   const params = { event_id: eventId, sat_id: satId };
//   return TrogdorAxios.post("/db/update_sat", data, params).then((response) => {
//     response.data.body = formatPreEventTLEResponse(response.data.body);
//     return response;
//   });
// };

export const deletePreEventTLE = (satId: number) => {
  const params = { sat_id: satId };
  return TrogdorAxios.post("/db/delete_sat", params);
};

// ! Trogdor does not return a satId. This function is disabled until we can use and test
// export const getPostEventSV = (satId) => {
//   const params = { sat_id: satId };
//   return TrogdorAxios.get("/db/get_state_vector", params).then((response) => {
//     response.data.body = response.data.body[0].UDLTle;
//     return response;
//   });
// };

export const getAllPostEventSVs = (eventId: number) => {
  const params = { event_id: eventId };
  return TrogdorAxios.get("/db/get_event_state_vectors", params).then(
    (response) => formatPostEventSVArray(response, eventId)
  );
};

export const createPostEventSV = (
  data: StateVectorRequestParams,
  eventId: number
) => {
  data = formatStateVectorRequest(data);
  const params = { event_id: eventId };
  return TrogdorAxios.post("/db/create_state_vector", data, params).then(
    (response) => formatMultiCreateStateVectorResponse(response)
  );
};

// ! Trogdor does not return a stateVectorId. This function is disabled until we can use and test
// export const editPostEventSV = (data, stateVectorId, eventId) => {
//   data = formatStateVectorRequest(data);
//   const params = { event_id: eventId, state_vector_id: stateVectorId };
//   return TrogdorAxios.post("/db/update_state_vector", data, params).then(
//     (response) => {
//       response.data = response.data.body;
//       return response;
//     }
//   );
// };

// ! Trogdor does not return a stateVectorId. This function is disabled until we can use and test
// export const deletePostEventSV = (stateVectorId) => {
//   const params = { state_vector_id: stateVectorId };
//   return TrogdorAxios.post("/db/delete_state_vector", params).then(
//     (response) => {
//       return response;
//     }
//   );
// };
