import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../utils/Hooks/reduxHooks";
import { Grid, Paper, List, Box, Typography } from "@mui/material";
import { RuxButton } from "@astrouxds/react";
import { RuxIcon } from "@astrouxds/react";
import { AcmeTheme } from "../../../themes/acmeTheme";
import ObjectsSearchForm from "./ObjectSearchForm";
import ObjectResultItem from "./ObjectResultItem";
import { useSatelliteContext } from "../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useSatStateContext } from "../../../utils/Hooks/contextHooks/useSatStateContext";
import { saveAllLocalTLEs } from "../../../redux/slices/scenarios/currentScenario/objects/preEventTLEs.js";
import { formatSTPreEventTLERequest } from "../../../utils/api/trogdor/formattingFunctions";
import { useScrollToListItem } from "../../../utils/Hooks/useScrollToListItem";
import PanelTitleBar from "../../Common/PanelTitleBar";
import LeftPanelListHeaders from "../../Common/Layout/LeftPanel/LeftPanelListHeaders/LeftPanelListHeaders";
import {
  useLazyGetSpaceTrackSatsQuery,
  satelliteSearchInitialState,
} from "../../../redux/rtk/endpoints/satelliteSearch";
import useForm from "../../../utils/Hooks/useForm";
import { EventSatsResponseParams } from "../../../redux/rtk/types/trogdorApiTypes";
import { EntityId } from "@reduxjs/toolkit";

const styles = {
  footerContainer: {
    padding: (theme: AcmeTheme) => theme.astro.spacing(4.5, 4.5, 4, 4.25),
    borderTop: "1px solid #101923",
  },
  addObjectPaper: {
    backgroundColor: "#1b2d3e",
    height: "100%",
  },
  scenarioTitleBar: {
    height: "4.875rem",
  },
  objectPanelWrapper: {
    height: "100%",
  },
  resultsContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    flexGrow: 1,
    height: "calc(100% - 2.75rem - 17.625rem - 6.25rem)",
  },
  addObjectList: {
    flexGrow: 1,
    overFlowY: "auto",
  },
  objectTitle: {
    marginLeft: "7.125rem",
  },
  placeholderText: {
    color: "#9FA3A7",
    marginInline: "auto",
  },
  footerText: {
    color: "#9FA3A7",
  },
  footerResults: {
    width: "fit-content",
  },
};

type PropTypes = {
  onClose: () => void;
};

const ObjectPanelSearch = ({ onClose }: PropTypes) => {
  const { setToCurrentSatellites, setThreeSatellites } = useSatelliteContext();
  const { selectedSatIds } = useSatStateContext();
  const { listItemRefs } = useScrollToListItem(selectedSatIds);
  const dispatch = useAppDispatch();

  const [
    satelliteSearchTrigger,
    {
      data: {
        ids: satelliteIds,
        entities: satellitesById,
      } = satelliteSearchInitialState,
      isFetching,
      isSuccess,
    },
  ] = useLazyGetSpaceTrackSatsQuery();

  const { values, handleChange, handleSubmit, setInitialValues } = useForm(
    satelliteSearchTrigger
  );

  const [searchedSatIds, setSearchedSatIds] = useState<EntityId[] | null>(
    satelliteIds
  );

  useEffect(() => {
    if (satellitesById) setThreeSatellites(Object.values(satellitesById));
    setSearchedSatIds(satelliteIds);
  }, [satellitesById]);

  useEffect(() => {
    return () => {
      // clear search list when navigating away from component
      setToCurrentSatellites();
    };
  }, []);

  window.onbeforeunload = (event) => {
    const e = event || window.event;
    e.preventDefault();

    // clear search list on refresh
    setToCurrentSatellites();
  };

  const addAllHandler = () => {
    const formattedSats = satelliteIds?.map((id) =>
      formatSTPreEventTLERequest(satellitesById[id] as EventSatsResponseParams)
    );
    dispatch(saveAllLocalTLEs(formattedSats));
  };

  return (
    <Paper elevation={2} sx={styles.addObjectPaper}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        sx={styles.objectPanelWrapper}
        wrap="nowrap"
      >
        <PanelTitleBar
          leftBtnText="Back"
          titleText="Add object"
          rightBtnText="Cancel"
          rightBtnType="button"
          rightBtnFunction={onClose}
          leftBtnFunction={onClose}
          postingStatus={isFetching}
        />
        <ObjectsSearchForm
          values={values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setInitialValues={setInitialValues}
          setSearchedSatIds={setSearchedSatIds}
        />
        <LeftPanelListHeaders items={[{ name: "Object" }]} />
        <Box component={"div"} sx={styles.resultsContainer}>
          {!searchedSatIds?.length || !satellitesById ? (
            <Grid justifyContent="center" sx={styles.resultsContainer}>
              <Box component={"div"} sx={styles.placeholderText}>
                <RuxIcon
                  icon="search"
                  size="135.75px"
                  style={{ color: "#1C3F5E" }}
                />
              </Box>
              <Typography sx={styles.placeholderText} variant="h4">
                Search for live satellites
              </Typography>
            </Grid>
          ) : (
            <List
              disablePadding
              sx={styles.addObjectList}
              data-test="search-result-list"
            >
              {searchedSatIds.map((satelliteId) => {
                const object = satellitesById[satelliteId];
                if (!object) return null;
                return (
                  <ObjectResultItem
                    listItemRefs={listItemRefs}
                    object={object}
                    key={object.id}
                  />
                );
              })}
            </List>
          )}
          ;
          <Grid
            container
            flexWrap="nowrap"
            justifyContent="space-between"
            sx={styles.footerContainer}
          >
            <Grid item alignItems="center" display="flex">
              <Typography
                variant="h4"
                sx={isSuccess ? null : styles.footerText}
              >
                {isSuccess ? satelliteIds?.length : "--"} Results
              </Typography>
            </Grid>
            <Grid container item sx={styles.footerResults}>
              <RuxButton
                data-test="add-all-button"
                onClick={addAllHandler}
                secondary
                size="medium"
                disabled={satelliteIds?.length === 0 ? true : false}
              >
                Add All
              </RuxButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
};

export default ObjectPanelSearch;
