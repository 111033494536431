import { createSelector } from "@reduxjs/toolkit";

const username = (state) => state.auth.username;
const authError = (state) => state.auth.error;
const tokens = (state) => state.auth.tokens;

export const getAuthError = createSelector(
  [authError],
  (authError) => authError
);

export const getUsername = createSelector([username], (username) => username);

export const getTokens = createSelector([tokens], (tokens) => tokens);
