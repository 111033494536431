/* eslint-disable react/no-unknown-property */
import * as THREE from "three";
import { Sphere, useTexture } from "@react-three/drei";
import { earthRadius } from "satellite.js/lib/constants";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";

const Stars = () => {
  const { maxDistanceFactor } = useCanvasContext();

  const stars = useTexture(process.env.PUBLIC_URL + "/galaxy_starfield.png");

  return (
    <Sphere name="Stars" args={[earthRadius * maxDistanceFactor, 32, 32]}>
      <meshBasicMaterial
        name="Stars Material"
        side={THREE.BackSide}
        map={stars}
        color={"#a8a8a8"}
        depthTest={false}
      />
    </Sphere>
  );
};

export default Stars;
