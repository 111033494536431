import {
  createEntityAdapter,
  createSelector,
  EntityState,
} from "@reduxjs/toolkit";
import { trogdorApi } from "../api";
import { formatEventTypeResponse } from "../formatting/responseFormatting";
import { MultiEventTypeResponse } from "../types/responseTypes";
import { EventType } from "../types/internalTypes";
import { RootState } from "../../../app/store";

export const eventTypesAdapter = createEntityAdapter<EventType>({
  sortComparer: (a, b) => a.id - b.id,
});

export const eventTypesInitialState = eventTypesAdapter.getInitialState();

export const initializationEndpoints = trogdorApi.injectEndpoints({
  endpoints: (builder) => ({
    getEventTypes: builder.query<EntityState<EventType>, void>({
      query: () => ({
        url: "/db/get_all_event_types",
        method: "get",
      }),
      transformResponse(response: MultiEventTypeResponse) {
        const eventTypes = response.body.map((eventType) => {
          return formatEventTypeResponse(eventType);
        });

        return eventTypesAdapter.setAll(eventTypesInitialState, eventTypes);
      },
    }),
  }),
});

export const { useLazyGetEventTypesQuery }: any = initializationEndpoints;

// Build and export selectors
const selectEventTypesResult: any =
  initializationEndpoints.endpoints.getEventTypes.select();
const selectEventTypesData = createSelector(
  selectEventTypesResult,
  (eventTypesResult) => eventTypesResult.data
);

// Export selectors
export const {
  selectIds: getEventTypeIds,
  selectEntities: getEventTypesById,
  selectAll: getEventTypes,
} = eventTypesAdapter.getSelectors(
  (state: RootState) => selectEventTypesData(state) ?? eventTypesInitialState
);
