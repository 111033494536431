/* eslint-disable react/no-unknown-property */
import { useRef, useCallback, useEffect } from "react";
import * as THREE from "three";
import { useTexture } from "@react-three/drei";
import { earthRadius } from "satellite.js/lib/constants";
import { useThree } from "@react-three/fiber";
import { useZoomContext } from "../../../utils/Hooks/contextHooks/useZoomContext";

export const scaleFactorEq = (x: number) => {
  return (
    9.63 +
    -1.29e-3 * x +
    8.81e-8 * Math.pow(x, 2) +
    -2.64e-12 * Math.pow(x, 3) +
    2.89e-17 * Math.pow(x, 4)
  );
};

export const opacityEq = (x: number) => {
  return (
    -4.3 +
    8.64e-4 * x +
    -5.29e-8 * Math.pow(x, 2) +
    1.34e-12 * Math.pow(x, 3) +
    -1.22e-17 * Math.pow(x, 4)
  );
};

const Atmosphere = () => {
  const { zoomValue, sliderZoomActive } = useZoomContext();
  const { gl, camera } = useThree();
  const earthAuxRef = useRef<any>();
  const earthAux = earthAuxRef.current;
  const camPos = camera.position;
  const storedCameraDis = useRef(0);

  const [earthAtmosphere] = useTexture([
    process.env.PUBLIC_URL + "/earth-assets/earth-atmosphere-glow.png",
  ]);

  const earthAuxGlowHandler = useCallback(
    (isSliderZoomActive: boolean | WheelEvent) => {
      let camDistFromEarth: number | undefined;

      if (isSliderZoomActive) {
        camDistFromEarth = -zoomValue;
      } else {
        const cameraDis = Math.floor(camPos.distanceTo(earthAux.position));
        if (cameraDis !== storedCameraDis.current) {
          camDistFromEarth = cameraDis;
          storedCameraDis.current = camDistFromEarth;
        }
      }

      if (
        camDistFromEarth &&
        camDistFromEarth > 8200 &&
        camDistFromEarth < 26000
      ) {
        earthAux.scale.set(
          earthRadius * scaleFactorEq(camDistFromEarth),
          earthRadius * scaleFactorEq(camDistFromEarth),
          1
        );
        earthAux.material.opacity = opacityEq(camDistFromEarth);
      }
    },
    [earthAux, zoomValue, camPos]
  );

  useEffect(() => {
    if (!earthAux) return;
    if (sliderZoomActive) {
      earthAuxGlowHandler(true);
    } else if (!sliderZoomActive) {
      gl.domElement.addEventListener("wheel", () => earthAuxGlowHandler(false));
      return () => {
        gl.domElement.removeEventListener("wheel", earthAuxGlowHandler);
      };
    }
  }, [earthAuxGlowHandler, gl.domElement, sliderZoomActive, earthAux]);

  return (
    <sprite
      name="Atmosphere"
      ref={earthAuxRef}
      scale={[earthRadius * 2.56, earthRadius * 2.56, 1]}
    >
      <spriteMaterial
        name="Atmosphere Mat"
        map={earthAtmosphere}
        side={THREE.FrontSide}
        sizeAttenuation={true}
        depthTest={false}
        blending={THREE.AdditiveBlending}
        opacity={0.5}
      />
    </sprite>
  );
};

export default Atmosphere;
