import store from "../../app/store";

export const getAuth = () => {
  const state = store.getState();
  return state.auth && state.auth.username ? state.auth : null;
};

export const getAccessToken = () => {
  const auth = getAuth();
  return auth && auth.tokens.access.token ? auth.tokens.access.token : null;
};

export const getRefreshToken = () => {
  const auth = getAuth();
  return auth && auth.tokens.refresh.token ? auth.tokens.refresh.token : null;
};
