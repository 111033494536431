import { earthRadius } from "satellite.js/lib/constants";
import { useTexture, Detailed } from "@react-three/drei";

import EarthTemplate from "../aux/EarthTemplate";
import SatelliteTrails from "./SatelliteTrails";

const Earth = () => {
  const [
    earthTexture4k,
    earthBumpTexture4k,
    earthTexture10k,
    earthBumpTexture10k,
    earthSpecularTexture,
  ] = useTexture([
    process.env.PUBLIC_URL + "/earth-assets/4k/8081_earthmap4k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/4k/8081_earthbump4k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/10k/8081_earthmap10k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/10k/8081_earthbump10k.jpg",
    process.env.PUBLIC_URL + "/earth-assets/earthspec1k.jpg",
  ]);

  return (
    <group>
      <Detailed distances={[0, earthRadius * 2.3]}>
        <EarthTemplate
          quality={"Highest"}
          segments={128}
          map={earthTexture10k}
          bumpMap={earthBumpTexture10k}
          displacementMap={earthBumpTexture4k}
          displacementScale={150}
          specularMap={earthSpecularTexture}
        />
        <EarthTemplate
          quality={"High"}
          segments={64}
          map={earthTexture4k}
          bumpMap={earthBumpTexture4k}
          displacementMap={earthBumpTexture4k}
          displacementScale={170}
          specularMap={earthSpecularTexture}
        />
      </Detailed>
      <SatelliteTrails />
    </group>
  );
};

export default Earth;
