import { useEffect } from "react";
import { useAppSelector } from "../../../../utils/Hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import { RuxButton } from "@astrouxds/react";
import { Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import { AcmeTheme } from "../../../../themes/acmeTheme";
import {
  getCurrentScenarioEventsChanged,
  getCurrentScenarioHasChanged,
} from "../../../../redux/selectors/scenarioSelector";
// import { saveAllScenarioEvents } from "../../../../redux/thunks/eventThunks";
import { useSaveCurrentScenario } from "../../../../utils/Hooks/useSaveCurrentScenario";
import { useDialog } from "../../../../utils/Hooks/useDialog";
import DeleteScenarioDialog from "../../../Dialogs/DeleteScenarioDialog";
import DiscardChangesDialog from "../../../Dialogs/DiscardChangesDialog";
import { useAnimationContext } from "../../../../utils/Hooks/contextHooks/useAnimationContext";
import { RuxIndeterminateProgress } from "@astrouxds/react";

const styles = {
  saveFooterContainerSX: {
    height: "4.5rem",
    padding: (theme: AcmeTheme) => theme.astro.spacing(4.25, 4.25, 4, 4.25),
    borderTop: "2px solid #101923",
    position: "absolute",
    bottom: 0,
    backgroundColor: "#1B2D3E",
  },
  modalDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const SaveFooter = () => {
  const navigate = useNavigate();
  const { saveCurrentScenario, inProgress } = useSaveCurrentScenario();
  // const postingStatus = useAppSelector(getCurrentScenarioPosting);
  const postingStatus = inProgress;
  const hasPendingScenarioChanges = useAppSelector(
    getCurrentScenarioHasChanged
  );
  const hasPendingEventChanges = useAppSelector(
    getCurrentScenarioEventsChanged
  );

  const { openDialog, WithCurrentDialog } = useDialog();

  /*
   * The ScenarioDetailsPanel component has multiple sliding behaviors
   * which are desired. This context works to ensure the correct behavior
   * is achieved.
   */
  const { setIsScenarioDetailsAnimation } = useAnimationContext();
  // set flag to false on component load to ensure default behavior
  useEffect(() => setIsScenarioDetailsAnimation(false), []);

  const saveHandler = async () => {
    // Run all the fun save magic
    await saveCurrentScenario();

    navigate("/scenarios");

    // set flag to true to achieve alternate behavior then default
    setIsScenarioDetailsAnimation(true);
    // dispatch(saveAllScenarioEvents()).then(() => {
    //   navigate("/scenarios");

    //   // set flag to true to achieve alternate behavior then default
    //   setIsScenarioDetailsAnimation(true);
  };
  // // TODO Remove manual tag invalidation when full RTK Query set up

  const cancelHandler = () => {
    if (hasPendingScenarioChanges || hasPendingEventChanges) {
      openDialog(<DiscardChangesDialog />);
    } else {
      navigate("/scenarios");
      // set flag to true to achieve alternate behavior then default
      setIsScenarioDetailsAnimation(true);
    }
  };

  const deleteHandler = () => {
    openDialog(<DeleteScenarioDialog />);
  };

  return WithCurrentDialog(
    <Grid
      container
      flexWrap="nowrap"
      justifyContent="space-between"
      sx={styles.saveFooterContainerSX}
    >
      <Modal open={postingStatus} sx={styles.modalDisplay}>
        <RuxIndeterminateProgress data-test="save-all-spinner" />
      </Modal>
      <Grid item>
        <RuxButton
          borderless
          onClick={() => deleteHandler()}
          data-test="footer-scenario-delete-button"
        >
          Delete
        </RuxButton>
      </Grid>
      <Grid container item sx={{ width: "fit-content" }}>
        <Grid item>
          <RuxButton
            data-test="footer-cancel-button"
            secondary
            onClick={() => cancelHandler()}
          >
            Cancel
          </RuxButton>
        </Grid>
        <Grid item sx={{ marginLeft: (theme) => theme.astro.spacing(5) }}>
          <RuxButton
            data-test="footer-save-button"
            onClick={() => saveHandler()}
          >
            Save
          </RuxButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SaveFooter;
