import { useState, useEffect, useCallback } from "react";
import {
  defaultSatelliteOptions,
  generateNewTLE,
} from "../../tleGenerationFunctions";
import { useSatelliteContext } from "../contextHooks/useSatelliteContext";
import { useSatStateContext } from "../contextHooks/useSatStateContext";
import Common from "../../common";

const useManualOrbit = (currentEvent?: any, setInitialValuesCallback?: any) => {
  const [realSatelliteReference, setRealSatelliteReference] = useState(null);
  const {
    threeSatellitesById,
    manualOrbitIds,
    setManualOrbitIds,
    manualOrbitsById,
    setManualOrbitsById,
    manualOrbitTarget,
    setManualOrbitTarget,
  } = useSatelliteContext();
  const { addSelected, clearSatState } = useSatStateContext();
  // Creating and Updating Manual Orbits
  const updateManualOrbit = (
    satellite: {
      tleLine2: string;
      orbitDatetime: string;
      objectName: string;
      noradCatId: number;
      tleLine1: string;
    },
    satelliteOptions: any
  ) => {
    // Updating Manual Orbit
    if (manualOrbitTarget.id === -1) {
      const newSatellite = {
        ...manualOrbitTarget,
        tleLine2: satellite.tleLine2,
        orbitDatetime: satellite.orbitDatetime,
      };

      setManualOrbitTarget(newSatellite);
    } else {
      // New Manual Orbit
      const options = {
        ...defaultSatelliteOptions,
        ...satelliteOptions,
      };

      // Setting ID to -1 for currently editing satellite
      const current = {
        id: -1,
        name: satellite.objectName,
        noradCatId: satellite.noradCatId,
        tleLine1: satellite.tleLine1,
        tleLine2: satellite.tleLine2,
        orbitDatetime: satellite.orbitDatetime,
        ...options,
      };
      const normalizedSatellite = Object.assign(satellite, current);

      setManualOrbitTarget(normalizedSatellite);
    }
  };

  const removeAllManualOrbits = useCallback(() => {
    setManualOrbitIds([]);
    setManualOrbitsById({});
    setManualOrbitTarget(null);
  }, [setManualOrbitIds, setManualOrbitTarget, setManualOrbitsById]);

  const addManualOrbitByIds = (newSelectionID: number) => {
    const newManualOrbitIds = [...manualOrbitIds, newSelectionID];
    const normalizedManualOrbits: any[] = [];

    newManualOrbitIds.forEach((orbitId) => {
      let newManualOrbit;

      if (manualOrbitIds.includes(orbitId)) {
        // for re-selecting manual orbit
        newManualOrbit =
          manualOrbitsById[orbitId as keyof typeof manualOrbitsById];
      } else {
        newManualOrbit = threeSatellitesById[orbitId];
      }

      normalizedManualOrbits.push(newManualOrbit);
    });

    const newManualOrbitsById: any = { ...manualOrbitsById };

    normalizedManualOrbits.forEach((manualOrbit) => {
      newManualOrbitsById[manualOrbit.noradCatId.toString()] = {
        ...newManualOrbitsById[manualOrbit.noradCatId.toString()],
        ...manualOrbit,
      };
    });

    setManualOrbitsById(newManualOrbitsById);
  };

  const addManualOrbit = (orbitId: number) => {
    setManualOrbitIds([...manualOrbitIds, orbitId]);
  };

  const handleManualOrbitChange = (
    newValue: string | number,
    property: string | number
  ) => {
    switch (property) {
      case "inclination":
        newValue = Number(newValue);
        break;
      case "raOfAscNode":
        newValue = Number(newValue);
        break;
      case "meanMotion":
        newValue = Number(newValue);
        break;
      case "tleLine2":
        newValue = String(newValue);
        break;
      default:
        console.log("Unkown satellite property");
    }

    const newManualOrbitTarget = { ...manualOrbitTarget };
    newManualOrbitTarget[property] = newValue;

    // Set new values
    setManualOrbitTarget(newManualOrbitTarget);

    Object.entries(manualOrbitsById).forEach(([key]) => {
      if (key === newManualOrbitTarget.noradCatId.toString()) {
        // normalize ManualOrbit
        const manualOrbitsByIdObj = { ...(manualOrbitsById as any) };
        const thisManualOrbit = {
          [key]: {
            ...manualOrbitsByIdObj[key],
            ...newManualOrbitTarget,
          },
        };

        // update manualOrbitsById with updated value
        setManualOrbitsById({
          ...manualOrbitsById,
          ...thisManualOrbit,
        });
      }
    });

    generateNewTLE(manualOrbitTarget, updateManualOrbit);
  };

  const selectManualOrbit = (newSelectionID: number) => {
    addManualOrbit(newSelectionID);
    addManualOrbitByIds(newSelectionID);
    // sets current event object satellite as chosen target object
    let newManualOrbit;
    if (manualOrbitIds.includes(newSelectionID)) {
      // for re-selecting manual orbit
      newManualOrbit =
        manualOrbitsById[newSelectionID as keyof typeof manualOrbitsById];
    } else {
      newManualOrbit = threeSatellitesById[newSelectionID];
    }
    return newManualOrbit;
  };

  const handleOrbitalElementChange = (e: any) => {
    if (!manualOrbitTarget) return;
    handleManualOrbitChange(e.target.value, e.target.name);
  };

  // Form related functions

  useEffect(() => {
    if (Common.trogdor && currentEvent?.eventType !== "Manual Orbit") return;
    const setCurrentValuesForEdit = (currentEvent: any) => {
      const eventObject = currentEvent?.eventObjects[0];

      if (eventObject) {
        const { inclination, raan, meanMotion } = eventObject;
        setInitialValuesCallback({
          satelliteId: currentEvent.stTargetObjectId,
          inclination: inclination,
          raOfAscNode: raan,
          meanMotion: meanMotion,
        });
        addSelected(eventObject.idElset);
      }
    };

    setCurrentValuesForEdit(currentEvent);
  }, [addSelected, currentEvent, setInitialValuesCallback]);

  const handleSelectTarget = (e: { target: { value: number | string } }) => {
    // early return if "Select an available Object ID" is selected after initial selection
    // resets built inputs
    removeAllManualOrbits();
    clearSatState();
    if (e.target.value === "") {
      setRealSatelliteReference(null);
      setInitialValuesCallback({
        satelliteId: e.target.value,
        inclination: null,
        raOfAscNode: null,
        meanMotion: null,
      });
      return;
    }
    Promise.resolve(selectManualOrbit(e.target.value as number)).then(
      (satellite) => {
        setInitialValuesCallback({
          satelliteId: e.target.value,
          inclination: satellite.inclination,
          raOfAscNode: satellite.raOfAscNode,
          meanMotion: satellite.meanMotion,
        });
        setManualOrbitTarget(satellite);
        setRealSatelliteReference(satellite);
        return;
      }
    );
  };

  const formReference = {
    formTitle: "Manual Orbit",
    handlerFunctions: {
      101: handleSelectTarget,
      102: handleOrbitalElementChange,
      103: handleOrbitalElementChange,
      104: handleOrbitalElementChange,
    },
    targetObject: manualOrbitTarget,
    cleanUpFunction: removeAllManualOrbits,
  };

  return {
    formReference,
    realSatelliteReference,
    manualOrbitIds,
    manualOrbitTarget,
    manualOrbitsById,
    removeAllManualOrbits,
    selectManualOrbit,
    handleManualOrbitChange,
  };
};

export default useManualOrbit;
