import store from "../app/store";
import isAfter from "date-fns/isAfter";

export const isAuthenticatedUser = () => {
  const state = store.getState();
  const auth = state.auth;
  const tokenExp = auth.tokens.refresh.expires as string;
  if (auth.username && isAfter(new Date(tokenExp), Date.now())) {
    return true;
  } else return false;
};
