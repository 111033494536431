// Redux Actions
import * as curScenarioEvents from "../slices/scenarios/currentScenario/events/eventsSlice";
import * as curScenarioTLEs from "../slices/scenarios/currentScenario/objects/preEventTLEs";
// import * as eventsSlice from "../slices/events/eventsSlice";
import * as curScenarioSVs from "../slices/scenarios/currentScenario/objects/postEventSVs";
import * as curScenarioDetails from "../slices/scenarios/currentScenario/details/scenarioDetailsSlice";
import * as liveEvents from "../slices/liveEvent/liveEventSlice";
import * as eventObjectsThunks from "./eventObjectsThunks";

// API functions
import * as acmeEvents from "../../utils/api/acme/events";
import * as trogdorEvents from "../../utils/api/trogdor/events";
import { getUDLTLE } from "../../utils/api/udl/udl";

// Selectors
// import { getEventById } from "../rtk/endpoints/events";
import {
  getCurrentScenarioEvents,
  // getCurrentScenarioEventIds,
  getCurrentScenarioTLEsByEventId,
  // getCurrentScenarioSVsByEventId,
  // getCurrentScenario,
} from "../selectors/scenarioSelector";
import { getLiveEvent } from "../selectors/liveEventSelector";
// import { eventsEndpoints } from "../rtk/endpoints/events";

// Utilities
import Common, {
  currentScenarioStatusEnums as statusEnums,
} from "../../utils/common";
import { trogdorPromiseAllErrorCheck } from "../../utils/api/trogdor/utils";
import { getPromiseAllData } from "../../utils/api/trogdor/formattingFunctions";
import _ from "lodash";

export const fetchEvent = (eventId) => {
  return async (dispatch) => {
    const onSuccess = async (response) => {
      await dispatch(curScenarioEvents.fetchEventSuccess(response.data.body));
      return response;
    };

    const onError = async (error) => {
      console.error(error);
      await dispatch(curScenarioEvents.fetchEventFail(error));
      return error;
    };

    try {
      dispatch(curScenarioEvents.fetchEventStart());
      if (Common.trogdor) {
        const response = await trogdorEvents.getEvent(eventId);
        if (response.data.errorMessage)
          return onError(response.data.errorMessage);
        return onSuccess(response);
      } else {
        const response = await acmeEvents.getEvent(eventId);
        return onSuccess(response);
      }
    } catch (error) {
      return onError(error);
    }
  };
};

export const fetchUdlTle = (satNumber, eventId, addSat = false) => {
  return async (dispatch, getState) => {
    const curState = await getState();
    const preEventTLEs = getCurrentScenarioTLEsByEventId(curState);

    const onSuccess = async (response) => {
      if (response.data.length === 0)
        throw new Error("no satellite found in UDL");

      const [udlTLE] = response.data;
      if (!addSat) {
        await dispatch(
          curScenarioTLEs.replaceEventUdlTLEsSuccess({
            ...udlTLE,
            eventId: eventId,
          })
        );
      } else {
        let replaceIndex = preEventTLEs[eventId]?.findIndex((sat) => {
          return sat.satNo === satNumber;
        });

        await dispatch(
          curScenarioTLEs.fetchUdlTLESuccess({
            ...udlTLE,
            eventId: eventId,
            replaceIndex,
          })
        );
      }

      return response;
    };

    const onError = async (error) => {
      console.error(error);
      await dispatch(curScenarioTLEs.fetchUdlTLEFail(error.response));
      return error;
    };

    try {
      dispatch(curScenarioTLEs.fetchUdlTLEStart());
      const response = await getUDLTLE(satNumber);
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
};

export const replaceUdlTles = (satNumber, eventId) => {
  return async (dispatch) => {
    const onSuccess = async (response) => {
      if (response.data.length === 0)
        throw new Error("no satellite found in UDL");

      dispatch(
        curScenarioTLEs.replaceEventUdlTLEsSuccess({
          ...response.data,
          eventId: eventId,
        })
      );
      return response;
    };

    const onError = async (error) => {
      console.error(error);
      await dispatch(curScenarioTLEs.fetchUdlTLEFail(error.response));
      return error;
    };

    try {
      dispatch(curScenarioTLEs.fetchUdlTLEStart());
      const response = await getUDLTLE(satNumber);
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
};

export const replaceSV = (eventDetails, eventParameters, eventId) => {
  return async (dispatch) => {
    const onSuccess = async (response) => {
      if (response.data.body.message)
        throw new Error("response did not return satellite data");
      const postManeuverSV = response.data.body.postManeuverStateVector;

      // remove all event state vectors
      await dispatch(curScenarioSVs.removeEventSVsSuccess(eventId));

      // Add Post Event SV to redux
      await dispatch(
        curScenarioSVs.addVisualizedSVs({
          eventId,
          stateVector: [
            {
              ...postManeuverSV,
              idStateVector: postManeuverSV.id,
              type: "SV",
            },
          ],
        })
      );

      // Get Ephemeris from SV
      await dispatch(
        eventObjectsThunks.getEphem({
          ...postManeuverSV,
          start: eventDetails.startTime,
          end: eventDetails.endTime,
          // Hard-coding step to 1 minute. TODO: figure out best adjustment mechanism
          step: 60,
        })
      );

      return response;
    };

    const onError = async (error) => {
      await dispatch(curScenarioEvents.createEventFail(error));
      return error;
    };

    try {
      if (Common.trogdor) {
        await dispatch(curScenarioEvents.createEventStart());
        const response = await trogdorEvents.createEvent(
          { ...eventDetails, parameters: { parameters: eventParameters } },
          true
        );
        return onSuccess(response);
      }
    } catch (error) {
      return onError(error);
    }
  };
};

export const visualizeManeuverEvent = (eventDetails, eventParameters) => {
  return async (dispatch) => {
    const onSuccess = async (response) => {
      // create temp eventId and attach to event data and response data
      const eventId = crypto.randomUUID();
      response.data.body.id = eventId;
      const postManeuverSV = response.data.body.postManeuverStateVector;
      const SVArray = [{ ...postManeuverSV, idStateVector: postManeuverSV.id }];

      // Create Event in redux, handles adding tle and SV as well
      await dispatch(
        persistNewEvent(eventDetails, eventParameters, eventId, SVArray)
      );

      // Get Ephemeris from SV
      await dispatch(
        eventObjectsThunks.getEphem({
          ...postManeuverSV,
          start: eventDetails.startTime,
          end: eventDetails.endTime,
          // Hard-coding step to 1 minute.TODO: figure out best adjustment mechanism
          step: 60,
        })
      );
      await dispatch(liveEvents.endPosting());
      return response;
    };

    const onError = async (error) => {
      await dispatch(liveEvents.endPosting());
      await dispatch(curScenarioEvents.createEventFail(error));
      return error;
    };

    try {
      if (Common.trogdor) {
        await dispatch(liveEvents.startPosting());
        await dispatch(curScenarioEvents.createEventStart());
        const response = await trogdorEvents.createEvent(
          // Ugh to redundant Trogdor formatting
          { ...eventDetails, parameters: { parameters: eventParameters } },
          true
        );
        if (response.data.errorMessage)
          return onError(response.data.errorMessage);
        if (response.data.body.message) {
          return onError("response did not return satellite data");
        }
        return onSuccess(response);
      }
    } catch (error) {
      return onError(error.response);
    }
  };
};

export const persistNewEvent = (
  eventDetails,
  eventParameters,
  eventId = crypto.randomUUID(),
  SVArray = []
) => {
  return async (dispatch) => {
    // Add Pre Event TLE to redux
    const fetchTLEPromise = await dispatch(
      //! hard coded for suppression event parameter
      fetchUdlTle(eventParameters[0].paramValue, eventId)
    );
    await dispatch(
      curScenarioSVs.addVisualizedSVs({
        eventId,
        stateVector: SVArray,
      })
    );
    const createEventPromise = await dispatch(
      curScenarioEvents.createEventSuccess({
        ...eventDetails,
        id: eventId,
        parameters: { parameters: eventParameters },
        changed: true,
        shouldUpdate: true,
      })
    );
    await dispatch(curScenarioDetails.updateStatus(statusEnums.unsaved));
    return Promise.all([fetchTLEPromise, createEventPromise]);
  };
};

export const copyLiveEventToCurrentScenario = () => {
  return async (dispatch, getState) => {
    const curState = await getState();
    const liveEvent = getLiveEvent(curState);
    const liveEventWithUpdate = {
      ...liveEvent.eventDetails,
      shouldUpdate: true,
    };
    await dispatch(liveEvents.resetLiveEvent());
    await dispatch(curScenarioEvents.createEventSuccess(liveEventWithUpdate));
    await dispatch(curScenarioTLEs.copyTLEsSuccess(liveEvent.preEventTLEs));
    // will be empty if a suppression event
    if (!_.isEmpty(liveEvent.postEventSVs)) {
      await dispatch(curScenarioSVs.copySVsSuccess(liveEvent.postEventSVs));
      await dispatch(curScenarioSVs.fetchEphemSuccess(liveEvent.ephemeris));
    }

    await dispatch(curScenarioDetails.updateStatus(statusEnums.unsaved));
    return Promise.resolve("Copy Successful");
  };
};

const getParamValueByDefId = (paramsArray, defId) =>
  paramsArray.find((param) => param.paramDefID === defId)?.paramValue;

const checkForChangesByDefIds = async (
  defIds,
  eventId,
  getState,
  newParameters
) => {
  const curState = await getState();
  const existingEvent = getCurrentScenarioEvents(curState)[eventId];
  const existingEventParams = existingEvent.parameters.parameters;

  let hasChanges = false;

  defIds.forEach((id) => {
    if (hasChanges) return;
    const existingValue = getParamValueByDefId(existingEventParams, id);
    const newValue = getParamValueByDefId(newParameters, id);

    if (newValue && existingValue) hasChanges = newValue !== existingValue;
  });

  return Promise.resolve(hasChanges);
};

export const editPersistedEvent = (
  newEventDetails,
  newEventParameters,
  eventId
) => {
  return async (dispatch, getState) => {
    const satTargetDefIds = [1, 13, 6, 9];
    const maneuverParmeterDefIds = [11, 14, 5, 7, 8, 10];
    const newSatTargetParamValue = newEventParameters[0].paramValue;

    let replaceTLEPromise = Promise.resolve(true);
    let replaceSVPromise = Promise.resolve(true);

    await dispatch(liveEvents.startPosting());
    // Replace Pre Event TLE to redux if target sat parameter has changed
    if (
      await checkForChangesByDefIds(
        satTargetDefIds,
        eventId,
        getState,
        newEventParameters
      )
    ) {
      replaceTLEPromise = await dispatch(
        replaceUdlTles(newSatTargetParamValue, eventId)
      );
    }

    switch (newEventDetails.eventTypeId) {
      //suppression
      case "1":
        break;
      case "4":
      case "5":
      case "6":
        // Replace Post event SV in redux if maneuver parameters have changed
        if (
          await checkForChangesByDefIds(
            maneuverParmeterDefIds,
            eventId,
            getState,
            newEventParameters
          )
        ) {
          replaceSVPromise = await dispatch(
            replaceSV(newEventDetails, newEventParameters, eventId)
          );
        }
        break;
      default:
        console.log("unsupported event type in edited event");
    }

    const editEventPromise = await dispatch(
      curScenarioEvents.editEventSuccess({
        ...newEventDetails,
        id: eventId,
        parameters: { parameters: newEventParameters },
        changed: true,
      })
    );

    return Promise.all([
      replaceTLEPromise,
      replaceSVPromise,
      editEventPromise,
    ]).then(() => {
      dispatch(liveEvents.endPosting());
    });
  };
};

export const createEvent = (values, shouldUpdate = true) => {
  return async (dispatch) => {
    const onSuccess = async (response) => {
      await dispatch(
        curScenarioEvents.createEventSuccess({
          ...response.data.body,
          shouldUpdate,
        })
      );
      return response;
    };

    const onError = async (error) => {
      await dispatch(curScenarioEvents.createEventFail(error));
      return error;
    };

    try {
      dispatch(curScenarioEvents.createEventStart());
      if (Common.trogdor) {
        const response = await trogdorEvents.createEvent(values);
        if (response.data.errorMessage)
          return onError(response.data.errorMessage);
        return onSuccess(response);
      } else {
        const response = await acmeEvents.createEvent(values);
        return onSuccess(response);
      }
    } catch (error) {
      return onError(error.response);
    }
  };
};

// ! Trogdor does not return a satId or SVId. This function is disabled until we can use and test
// export const editEvent = (values, eventId) => {
//   return async (dispatch) => {
//     dispatch(curScenarioEvents.editEventStart());
//     const onSuccess = async (response) => {
//       await dispatch(curScenarioEvents.editEventSuccess(response.data.item));
//       return response;
//     };
//     const onError = async (error) => {
//       await dispatch(curScenarioEvents.editEventFail(error));
//       return error;
//     };

//     try {
//       if (Common.trogdor) {
//         const response = await trogdorEvents.editEvent(values, eventId);
//         return onSuccess(response);
//       } else {
//         const response = await acmeEvents.editEvent(values, eventId);
//         return onSuccess(response);
//       }
//     } catch (error) {
//       return onError(error.response);
//     }
//   };
// };

// ! Deletes from ACME, removes from curScenarioSlice for Trogdor
export const deleteEvent = (eventId) => {
  return async (dispatch) => {
    const onTrogdorSuccess = async () => {
      //* Set Current Scenario Status to "unsaved"
      await dispatch(curScenarioDetails.updateStatus(statusEnums.unsaved));
      // Delete event, preEventTLEs and postEventSVs from redux
      await dispatch(curScenarioEvents.deleteEventSuccess(eventId));
      await dispatch(curScenarioTLEs.removeEventPreEventTLEs(eventId));
      await dispatch(curScenarioSVs.removeEventSVsSuccess(eventId));
      return Promise.resolve("Successfully Deleted");
    };

    const onAcmeSuccess = async (response) => {
      await dispatch(curScenarioEvents.deleteEventSuccess(eventId));
      await dispatch(curScenarioTLEs.removeEventPreEventTLEs(eventId));
      await dispatch(curScenarioSVs.removeEventSVsSuccess(eventId));
      return response;
    };

    const onError = async (error) => {
      console.error(error);
      await dispatch(curScenarioEvents.deleteEventFail(error.response.data));
      return error;
    };

    try {
      await dispatch(curScenarioEvents.deleteEventStart());
      if (Common.trogdor) {
        return onTrogdorSuccess();
      } else {
        const response = await acmeEvents.deleteEvent(eventId);
        return onAcmeSuccess(response);
      }
    } catch (error) {
      return onError(error);
    }
  };
};

// // ! Will need to cleanup and separate API thunks from local thunks when possible
// export const deleteTrogdorEvent = (eventId) => {
//   return async (dispatch) => {
//     const onSuccess = async (response) => {
//       await dispatch(eventsSlice.deleteEventSuccess());
//       return response;
//     };
//     const onError = async (error) => {
//       await dispatch(eventsSlice.deleteEventFail(error));
//       return error;
//     };

//     try {
//       await dispatch(eventsSlice.deleteEventStart());
//       const response = await trogdorEvents.deleteEvent(eventId);
//       if (response.data.errorMessage)
//         return onError(response.data.errorMessage);
//       return onSuccess(response);
//     } catch (error) {
//       return onError(error.response);
//     }
//   };
// };

// fetches event details and params for every event ID in the incoming array
// then saves that data to the events list and current scenario events
export const fetchScenarioEventsDetails = (eventIds) => {
  return async (dispatch, getState) => {
    const onSuccess = async (events) => {
      // ! Not updating events list because it will be overwritten by fetchAllEvents
      // update event list
      // await dispatch(eventsSlice.fetchScenarioEventsDetailsSuccess(events));

      // check for equality and update currentScenario events
      const curState = await getState();
      const eventsObj = {};
      events.forEach((event) => (eventsObj[event.id] = event));
      if (!_.isEqual(curState.currentScenario.events.events, eventsObj)) {
        await dispatch(curScenarioEvents.fetchEventsDetailsSuccess(eventsObj));
      }
      return Promise.resolve(events);
    };
    const onError = async (error) => {
      console.error(error);
      await dispatch(curScenarioEvents.fetchEventsDetailsFail(error));
      return Promise.reject(error);
    };

    try {
      if (Common.trogdor) {
        await dispatch(curScenarioEvents.fetchEventsDetailsStart());
        const eventRequests = eventIds.map((id) => trogdorEvents.getEvent(id));
        const responses = await Promise.all(eventRequests);

        // Check for errors
        const errorCheck = trogdorPromiseAllErrorCheck(responses);
        if (errorCheck.error) return onError(errorCheck.errorMessage);

        return onSuccess(getPromiseAllData(responses));
      }
    } catch (error) {
      return onError("Unable to fetch all event details");
    }
  };
};

export const copyScenarioEventsToCurrent = (eventIds) => {
  return async (dispatch, getState) => {
    const curState = await getState();
    const events = {};
    // const eventObjects = {};
    eventIds.forEach((id) => {
      const event =
        curState.trogdorApi.queries["getAllEvents(undefined)"].data.entities[
          id
        ];

      events[id] = {
        ...event,
        changed: false,
      };
      // if (!Common.trogdor) {
      //   eventObjects[id] =
      //     curState.trogdorApi.queries["getAllEvents(undefined)"].data.entities[
      //       id
      //     ].eventObjects;
      // }
    });

    await dispatch(curScenarioEvents.copyScenarioEventsSuccess(events));
    //TODO resolve comment below
    // if (!Common.trogdor) {
    //   await dispatch(curScenarioEvents.fetchEventObjectsSuccess(eventObjects));
    // }
  };
};

// export const fetchEventParams = (eventId) => {
//   return async (dispatch, getState) => {
//     const onSuccess = async (response) => {
//       const curState = await getState();
//       const fetchedParams = response.data.body;
//       const curScenarioEvent = curState.currentScenario.events.events[eventId];
//       if (
//         curScenarioEvent &&
//         !_.isEqual(curScenarioEvent.parameters.parameters, fetchedParams)
//       ) {
//         const newEvent = {
//           ...curScenarioEvent,
//           parameters: { parameters: fetchedParams },
//         };
//         await dispatch(eventsSlice.fetchEventParamsSuccess(newEvent));
//       }
//       return response;
//     };

//     const onError = async (error) => {
//       console.error(error);
//       await dispatch(eventsSlice.fetchEventParamsFail(error));
//       return error;
//     };

//     try {
//       if (Common.trogdor) {
//         await dispatch(eventsSlice.fetchEventParamsStart());
//         const response = await trogdorEvents.getEventParams(eventId);
//         if (response.data.errorMessage)
//           return onError(response.data.errorMessage);
//         return onSuccess(response);
//       }
//     } catch (error) {
//       return onError(error);
//     }
//   };
// };

// export const editEventParam = (paramValue, eventParamId, eventId) => {
//   return async (dispatch) => {
//     const onSuccess = async (response) => {
//       await dispatch(fetchEvent(eventId));
//       return response;
//     };
//     const onError = async (error) => {
//       await dispatch(curScenarioEvents.editEventParamFail(error));
//       return error;
//     };

//     try {
//       if (Common.trogdor) {
//         const response = await trogdorEvents.editEventParam(
//           paramValue,
//           eventParamId
//         );
//         return onSuccess(response);
//       }
//     } catch (error) {
//       return onError(error);
//     }
//   };
// };

// export const createEventParam = (values, eventId) => {
//   return async (dispatch) => {
//     const onSuccess = async (response) => {
//       await dispatch(
//         curScenarioEvents.createEventParamSuccess({ shouldUpdate: false })
//       );
//       await dispatch(fetchEvent(eventId));
//       return response;
//     };
//     const onError = async (error) => {
//       await dispatch(curScenarioEvents.createEventParamFail(error));
//       return error;
//     };

//     try {
//       if (Common.trogdor) {
//         await dispatch(curScenarioEvents.createEventParamStart());
//         const response = await trogdorEvents.createEventParam(values, eventId);
//         return onSuccess(response);
//       }
//     } catch (error) {
//       return onError(error);
//     }
//   };
// };

// export const saveAllScenarioEvents = () => {
//   return async (dispatch, getState) => {
//     await dispatch(curScenarioDetails.saveToTrogdorStart());
//     const curState = await getState();
//     const currentScenario = getCurrentScenario(curState);
//     const trogdorScenarioEventIds =
//       curState.trogdorApi.queries[`getScenarioEvents("${currentScenario.id}")`]
//         .data.ids;
//     const currentEvents = getCurrentScenarioEvents(curState);
//     const currentEventIds = getCurrentScenarioEventIds(curState);
//     const currentTLEs = getCurrentScenarioTLEsByEventId(curState);
//     const currentSVs = getCurrentScenarioSVsByEventId(curState);

//     try {
//       const eventPromises = [];
//       // Start with deletions
//       console.log(trogdorScenarioEventIds);
//       trogdorScenarioEventIds.forEach(async (eventId) => {
//         if (!(eventId.toString() in currentEvents)) {
//           console.log("deleting", eventId, currentEvents);
//           eventPromises.push(
//             dispatch(eventsEndpoints.endpoints.deleteEvent.initiate(eventId))
//           );
//           // eventPromises.push(dispatch(deleteTrogdorEvent(eventId)));
//         }
//       });

//       currentEventIds.forEach(async (eventId) => {
//         if (currentEvents[eventId].changed) {
//           const eventInList = getEventById(curState, eventId);
//           if (!eventInList) {
//             // Create event
//             console.log("creating", currentEvents[eventId]);
//             eventPromises.push(
//               // dispatch(createEvent(currentEvents[eventId], false))
//               dispatch(
//                 eventsEndpoints.endpoints.createEvent.initiate(
//                   currentEvents[eventId]
//                 )
//               ).then(async (response) => {
//                 console.log("create event response", response);
//                 response = response.data;
//                 const newEventId = response.id;
//                 //New event param only needed for suppression events
//                 if (response.eventType === "Suppression") {
//                   const [newParam] = response.parameters.parameters;
//                   console.log("creating new param", newParam, newEventId);
//                   await dispatch(createEventParam(newParam, newEventId));
//                 }
//                 // If sat, create satq
//                 if (currentTLEs[eventId].length) {
//                   currentTLEs[eventId].forEach(async (tle) => {
//                     await dispatch(
//                       eventObjectsThunks.createPreEventTLE(tle, newEventId)
//                     );
//                   });
//                 }
//                 // If SV, create SV
//                 if (currentSVs[eventId].length) {
//                   currentSVs[eventId].forEach(async (sv) => {
//                     await dispatch(
//                       eventObjectsThunks.createPostEventSV(sv, newEventId)
//                     );
//                   });
//                 }
//               })
//             );
//           } else {
//             // ! Commenting out until we can edit/delete Sats/SVs
//             console.log(`edit event fired, eventId: ${eventId}`);
//             // const trogdorEvent = await trogdorEvents
//             //   .getEvent(eventId)
//             //   .then((response) => response.data);
//             // const currentEvent = currentEvents[eventId];

//             // // Check for event detail changes
//             // // TODO: Move Array Definition
//             // let detailChanges = false;
//             // const eventProps = ["name", "startTime", "endTime", "status"];
//             // eventProps.forEach((property) => {
//             //   if (currentEvent[property] !== trogdorEvent[property])
//             //     detailChanges = true;
//             // });
//             // if (detailChanges)
//             //   await trogdorEvents.editEvent(currentEvent, eventId);

//             // // Check for param changes
//             // // ? Params are only changed, not created or deleted if type does not change
//             // const currentEventParams = currentEvent.parameters.parameters;
//             // const trogdorEventParams = trogdorEvent.parameters.parameters;
//             // currentEventParams.forEach(async (param) => {
//             //   const trogdorParam = trogdorEventParams.find((trogdorParam) => {
//             //     return param.paramID === trogdorParam.paramID;
//             //   });
//             //   if (
//             //     trogdorParam &&
//             //     param.paramValue !== trogdorParam.paramValue
//             //   ) {
//             //     await trogdorEvents.updateEventParam(param.paramValue, eventId);
//             //   }
//             // });

//             // Check for Sats
//             // ! Trogdor does not return ID values of Sats or SVs
//             // ! No Editing or deleting can occur without an ID value
//           }
//         }
//       });

//       await Promise.all(eventPromises);
//       //* Fetching all events again after saving so the app is updated.
//       //* Resolves a race condition where the fetch happens before save-all resolution
//       await dispatch(curScenarioDetails.saveToTrogdorEnd());
//     } catch (error) {
//       await dispatch(curScenarioDetails.saveToTrogdorEnd());
//       return Promise.reject("Unable to save all Scenario Events");
//     }
//   };
// };

// Will it format the event properly?
// const editCurrentEvent = (formValues, eventId) => {
//   return async (dispatch, getState) => {
//     const curState = getState();
//     const curEvent = getEventById(curState, eventId);

//     // get current event type? Can't change event type?

//     // Build replacement event
//     const { startTime, startDate, endTime, endDate, name, eventType } =
//       formValues;

//     const newEvent = {
//       ...curEvent,
//       name,
//       eventType,
//       startTime: Common.convertToISOJSONDate(startDate, startTime),
//       endTime: Common.convertToISOJSONDate(endDate, endTime),
//     };

//     // Potentially rebuild event params
//     if (newEvent.eventType === curEvent.eventType) {
//     }
//   };
// };
