import { ParamFieldLookupPropTypes } from "../../../redux/rtk/types/parameterTypes";

export const parameterFieldLookup: ParamFieldLookupPropTypes = {
  1: {
    name: "satellite_id",
    label: "Satellite ID",
    type: "text",
    max: "",
    min: "",
    step: "",
    placeholder: "Choose a satellite",
  },
  5: {
    name: "change_in_inclination",
    label: "Change Amount (deg)",
    type: "number",
    max: "",
    min: "",
    step: "",
  },
  6: {
    name: "inclination_sat",
    label: "Satellite ID",
    type: "text",
    max: "",
    min: "",
    step: "",
    placeholder: "Add satellite id",
  },
  7: {
    name: "inclination_maneuver_time",
    label: "Burn",
    type: "datetime",
    max: "",
    min: "",
    step: "",
  },
  8: {
    name: "change_in_period",
    label: "Change Amount (min)",
    type: "number",
    max: "",
    min: "",
    step: "",
  },
  9: {
    name: "period_sat",
    label: "Satellite ID",
    type: "text",
    max: "",
    min: "",
    step: "",
    placeholder: "Add satellite id",
  },
  10: {
    name: "period_maneuver_time",
    label: "Burn",
    type: "datetime",
    max: "",
    min: "",
    step: "",
  },
  11: {
    name: "change_in_altitude",
    label: "Change amount (km)",
    type: "number",
    max: "",
    min: "",
    step: "",
  },
  12: {
    name: "alt_time_of_flight",
    label: "Time of flight (sec)",
    type: "number",
    max: "",
    min: "",
    step: "",
  },
  13: {
    name: "altitude_sat",
    label: "Satellite ID",
    type: "text",
    max: "",
    min: "",
    step: "",
    placeholder: "Add satellite id",
  },
  14: {
    name: "altitude_maneuver_time",
    label: "Burn",
    type: "datetime",
    max: "",
    min: "",
    step: "",
  },
  101: {
    name: "satelliteId",
    label: "Satellite ID",
    type: "select",
    max: "",
    min: "",
    step: "",
    placeholder: "Choose a satellite",
  },
  102: {
    name: "inclination",
    label: "Inclination",
    type: "number",
    max: "10",
    min: "10",
    step: "0.1",
    placeholder: "Add inclination",
  },
  103: {
    name: "raOfAscNode",
    label: "RA of ascending node",
    type: "number",
    max: "10",
    min: "10",
    step: "0.1",
    placeholder: "Add right ascention of ascending node",
  },
  104: {
    name: "meanMotion",
    label: "Mean motion",
    type: "number",
    max: "1",
    min: "1",
    step: "0.01",
    placeholder: "Add mean motion",
  },
  105: {
    name: "inclination_time_of_flight",
    label: "Time of flight (sec)",
    type: "number",
    max: "",
    min: "",
    step: "",
  },
  106: {
    name: "period_time_of_flight",
    label: "Time of flight (sec)",
    type: "number",
    max: "",
    min: "",
    step: "",
  },
};
