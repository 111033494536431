import { AxiosResponse } from "axios";
import {
  EphemerisRequest,
  EventObjectResponse,
  MultiEventResponse,
  MultiScenarioResponse,
  MultiTLEResponse,
  SpaceTrackFormattedTLE,
} from "../../../redux/rtk/types/acmeApiTypes";
import { getObjByIdAndArrayOfIds } from "../../common";

// _________________ Requests ___________________
export const formatEphemRequest = (stateVector: EphemerisRequest) => {
  const { xpos, ypos, zpos, xvel, yvel, zvel, start, end, step, epoch } =
    stateVector;
  return {
    ...(Object.hasOwn(stateVector, "xpos") && { positionX: xpos }),
    ...(Object.hasOwn(stateVector, "ypos") && { positionY: ypos }),
    ...(Object.hasOwn(stateVector, "zpos") && { positionZ: zpos }),
    ...(Object.hasOwn(stateVector, "xvel") && { velocityX: xvel }),
    ...(Object.hasOwn(stateVector, "yvel") && { velocityY: yvel }),
    ...(Object.hasOwn(stateVector, "zvel") && { velocityZ: zvel }),
    ...(Object.hasOwn(stateVector, "start") && { start: start }),
    ...(Object.hasOwn(stateVector, "end") && { end: end }),
    ...(Object.hasOwn(stateVector, "step") && { step: step }),
    ...(Object.hasOwn(stateVector, "epoch") && { epoch: epoch }),
  };
};

// _________________ Responses __________________
export const formatMultiTLEResponse = (response: MultiTLEResponse) => {
  // @ts-expect-error there is an issue with target requirements
  response.data = response.data.map((tle) => {
    return formatTLEResponse(tle);
  });
  return response;
};

export const formatTLEResponse = (satellite: SpaceTrackFormattedTLE) => {
  if (!satellite.decayDate)
    return {
      ...satellite,
      id: satellite.noradCatId.toString(),
      satNo: satellite.noradCatId,
      line1: satellite.tleLine1,
      line2: satellite.tleLine2,
      type: "TLE",
      eventId: null,
      visualizationState: "visible",
    };
};

export const formatEventObjectResponse = (eventObject: EventObjectResponse) => {
  return {
    ...eventObject,
    id: eventObject.idElset,
  };
};

export const formatMultiScenarioResponse = (
  response: AxiosResponse<MultiScenarioResponse>
) => {
  response.data = getObjByIdAndArrayOfIds(response.data).objectById;
  return response;
};

export const formatMultiEventResponse = (response: MultiEventResponse) => {
  response.data = getObjByIdAndArrayOfIds(response.data).objectById;
  return response;
};
