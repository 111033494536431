import { useAppDispatch, useAppSelector } from "../../utils/Hooks/reduxHooks";
import {
  RuxGlobalStatusBar,
  RuxClock,
  RuxIcon,
  RuxMenuItem,
  RuxMenu,
  RuxPopUp,
} from "@astrouxds/react";
import { logOff } from "../../redux/thunks/authThunks";
import { useNavigate } from "react-router-dom";
//import { NavLink } from "react-router-dom";
import { isAuthenticatedUser } from "../../utils/authenticatedUser";
import { getUsername } from "../../redux/selectors/authSelector";

const GlobalStatusBar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    dispatch(logOff());
    navigate("/login");
  };

  const username = useAppSelector(getUsername);

  return (
    <RuxGlobalStatusBar appDomain="Space" appName="ACME" username={username}>
      <RuxPopUp placement="top-start" slot="left-side">
        <RuxIcon
          slot="trigger"
          className="menu-icon"
          size="small"
          icon="apps"
          data-test="statusbar-btn-menu"
        />
        <RuxMenu>
          {!isAuthenticatedUser() ? (
            //TODO component={Navlink} cannot be used on RuxMenuItem, but is needed for login here. Find solution and update when we have landing page
            <RuxMenu>
              <RuxMenuItem href={"/login"}>Log In</RuxMenuItem>
            </RuxMenu>
          ) : (
            <RuxMenu>
              <RuxMenuItem
                data-test="navdrawer-btn-logout"
                onClick={() => logOut()}
              >
                Log Out
              </RuxMenuItem>
            </RuxMenu>
          )}
        </RuxMenu>
      </RuxPopUp>
      <RuxClock />
    </RuxGlobalStatusBar>
  );
};

export default GlobalStatusBar;
