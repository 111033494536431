/* eslint-disable react/no-unknown-property */
import { useRef } from "react";
import { earthRadius } from "satellite.js/lib/constants";
import { useTexture } from "@react-three/drei";
import { ThreeEvent, useFrame } from "@react-three/fiber";

const Clouds = () => {
  const cloudsRef = useRef<any>();

  const [cloudsTexture] = useTexture([
    process.env.PUBLIC_URL + "/earth-assets/earth-clouds-contrast.jpg",
  ]);

  const cloudsPointerEventsOcclusionHandler = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
  };

  useFrame(() => {
    if (!cloudsRef.current) return;
    cloudsRef.current.rotation.z += 0.0002;
    cloudsRef.current.rotation.y += 0.00031;
  });

  return (
    <mesh
      ref={cloudsRef}
      name={"Clouds Mesh"}
      onPointerOver={(e) => cloudsPointerEventsOcclusionHandler(e)}
      onClick={(e) => cloudsPointerEventsOcclusionHandler(e)}
    >
      <sphereGeometry args={[earthRadius + 20, 64, 64]} name={"Clouds Geo"} />
      <meshPhongMaterial
        name={"Clouds Material"}
        map={cloudsTexture}
        alphaMap={cloudsTexture}
        shininess={5}
        transparent={true}
        opacity={1}
      />
    </mesh>
  );
};

export default Clouds;
