import { createSlice } from "@reduxjs/toolkit";
import { acmeApi } from "../../rtk/api";

interface AuthState {
  error: unknown | null;
  username: null | string;
  tokens: {
    access: {
      token: string;
      expires: string | null;
    };
    refresh: {
      token: string;
      expires: string | null;
    };
  };
}

const initialState = {
  error: null,
  username: null,
  tokens: {
    access: {
      token: "",
      expires: null,
    },
    refresh: {
      token: "",
      expires: null,
    },
  },
} as AuthState;

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthForCypress: (state, { payload }) => {
      state.error = null;
      state.username = payload.username;
      state.tokens.access = payload.accessToken;
      state.tokens.refresh = payload.refreshToken;
    },
    setAccessToken: (state, { payload }) => {
      state.tokens.access = payload.accessToken;
    },
    logOut: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(acmeApi.endpoints.login.matchPending, (state) => {
      state.username = "";
      state.error = null;
    });
    builder.addMatcher(
      acmeApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.error = null;
        state.username = payload.username;
        state.tokens.access = payload.accessToken;
        state.tokens.refresh = payload.refreshToken;
      }
    );
    builder.addMatcher(
      acmeApi.endpoints.login.matchRejected,
      (state, { payload }) => {
        state.username = "";
        state.error = payload?.data;
      }
    );
  },
});

export const { logOut, setAccessToken } = authSlice.actions;
