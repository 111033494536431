import { useAppDispatch } from "../../utils/Hooks/reduxHooks";
import { RuxDialog } from "@astrouxds/react";
import { removeScenarioTLE } from "../../redux/thunks/scenarioThunks";
import { RuxDialogCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";

type SatForRemovalType = {
  id: string;
  scenarioId: number;
  type: string;
};

type PropTypes = {
  isOpen?: boolean;
  item: SatForRemovalType | null;
  resetDialog?: () => void;
};

const RemoveObjectDialog = ({
  isOpen = false,
  item = null,
  resetDialog,
}: PropTypes) => {
  const dispatch = useAppDispatch();

  const handleDialogClose = (e: RuxDialogCustomEvent<boolean | null>) => {
    const { id, scenarioId, type } = item || {};
    if (e.detail)
      if (type === "TLE") {
        dispatch(removeScenarioTLE(id, scenarioId)).then(() => {
          resetDialog?.();
        });
      } else {
        console.log("removeSV thunk needed");
        resetDialog?.();
        //TODO create thunk to remove SV
        // dispatch(removeScenarioSV(satForRemoval)(item.id)).then(() => {
        //   dispatch(fetchScenarios());
        //   dispatch(resetDialog());
        // });
      }
    else {
      resetDialog?.();
    }
  };

  return (
    <RuxDialog
      open={isOpen}
      message="Removing this object will break the associated event"
      header="Delete Object Confirmation"
      onRuxdialogclosed={handleDialogClose}
      data-test="remove-object-dialog"
    />
  );
};

export default RemoveObjectDialog;
