import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utils/Hooks/reduxHooks";
import { ListItemButton, ListItemText, Box, Typography } from "@mui/material";
import { RuxButton } from "@astrouxds/react";
import { AcmeTheme } from "../../../themes/acmeTheme";
import {
  addScenarioTLE,
  removeScenarioTLE,
} from "../../../redux/thunks/scenarioThunks";
import { isSatNoInCurrentScenario } from "../../../redux/selectors/scenarioSelector";
import { useSatStateContext } from "../../../utils/Hooks/contextHooks/useSatStateContext";
import { MutableRefObject } from "react";
import { SpaceTrackFormattedTLE } from "../../../redux/rtk/types/acmeApiTypes";

type PropTypes = {
  object: SpaceTrackFormattedTLE;
  listItemRefs: MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>;
};

const ObjectResultItem = ({ object, listItemRefs }: PropTypes) => {
  const {
    selectedSatIds,
    addSelected,
    removeSelected,
    hoveredSatId,
    setHovered,
    hoveredSelectedSatId,
    setHoveredSelected,
  } = useSatStateContext();
  const { scenarioId } = useParams();
  const dispatch = useAppDispatch();
  const noradCatId = object.noradCatId;
  const isSatNoInScenarioObjList: (
    arg1: any,
    arg2: string | number
  ) => boolean = isSatNoInCurrentScenario;
  const isSatNoAdded = useAppSelector((state) =>
    isSatNoInScenarioObjList(state, noradCatId)
  );

  const objectType = object.type;
  const { objectId, countryCode, objectName, id } = object;
  const isHovered = hoveredSatId === id;

  const addObjHandler = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await dispatch(addScenarioTLE(object, scenarioId));
  };

  const removeObjHandler = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await dispatch(removeScenarioTLE(id, scenarioId));
  };

  const onClickHandler = () => {
    if (selectedSatIds.includes(id)) {
      removeSelected(id);
    } else {
      addSelected(id);
    }
  };

  const onPointerEnterHandler = () => {
    if (selectedSatIds.includes(id)) {
      setHoveredSelected(id);
    } else {
      setHovered(id);
    }
  };

  const onPointerLeaveHandler = () => {
    if (!selectedSatIds.includes(id)) setHovered(null);
    if (hoveredSelectedSatId) setHoveredSelected(id);
  };

  const styles = {
    objectResultItem: (theme: AcmeTheme) => ({
      ...(isHovered && {
        backgroundColor: theme.astro.color.background.surface.selected,
      }),
      borderLeft: "3px solid transparent",
      ...(selectedSatIds.includes(id) && {
        backgroundColor: "#1C3F5E",
        borderLeft: "3px solid #4DACFF",
      }),
      ...(hoveredSelectedSatId === id && { backgroundColor: "#214B6F" }),
    }),
    objectID: {
      color: (theme: AcmeTheme) => theme.astro.palette.grey["500"],
      display: "inline-block",
    },
    objectTitle: {
      display: "inline-block",
    },
  };

  const str = objectName.toString().toLowerCase();
  const strUpper = str.charAt(0).toUpperCase();
  const strConcat = strUpper.concat(str);
  const objectNameSentenceCase = strConcat.slice(0, 1) + strConcat.slice(2);

  return (
    <ListItemButton
      data-test="object-search-list-item"
      sx={styles.objectResultItem}
      ref={(el) => (listItemRefs.current[object.id] = el)}
      id={`Object-${objectId}`}
      onPointerEnter={onPointerEnterHandler}
      onClick={onClickHandler}
      onPointerLeave={onPointerLeaveHandler}
    >
      <ListItemText
        primary={
          <>
            <Box component="span">
              <Typography variant="h3" sx={styles.objectTitle}>
                {objectNameSentenceCase}&nbsp;
              </Typography>
            </Box>
            <Box component="span" sx={styles.objectID}>
              <Typography variant="body1">{`ID# ${object.id}`}</Typography>
            </Box>
          </>
        }
        secondary={`${objectType} \u2022 ${countryCode}`}
      />
      {isSatNoAdded ? (
        <RuxButton
          data-test="remove-object-button"
          secondary
          size="small"
          onClick={(e) => removeObjHandler(e)}
        >
          Remove
        </RuxButton>
      ) : (
        <>
          <RuxButton
            data-test="add-object-button"
            className="addButtons"
            secondary
            size="small"
            onClick={(e) => addObjHandler(e)}
          >
            Add
          </RuxButton>
        </>
      )}
    </ListItemButton>
  );
};

export default ObjectResultItem;
