import { Instances, useGLTF } from "@react-three/drei";
import Satellite from "./Satellite";

const SatelliteTrails = () => {
  const { nodes, materials } = useGLTF(
    process.env.PUBLIC_URL +
      "/satellite-model/low-poly-satellite-baked-light-with-hitbox-transformed.glb"
  );

  const nodesObj = nodes["baked-v2001"] as THREE.Mesh;

  return (
    <Instances
      geometry={nodesObj.geometry}
      material={materials["Material_0.001"]}
      material-transparent={true}
    >
      <Satellite
        position={[0, 0, 0.5]}
        rotation={[Math.PI / 3, 0, Math.PI / 2.5]}
        speed={0.5}
        color={"#12f3ff"}
        radius={1.5}
        name={"middle"}
      />
      <Satellite
        position={[0, 0, 0.5]}
        speed={0.3}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        color={"#12f3ff"}
        radius={2}
        name={"left"}
      />
      <Satellite
        position={[0, 0, 0.5]}
        rotation={[-Math.PI / 3, -Math.PI / 3, Math.PI / 3]}
        speed={0.4}
        color={"#12f3ff"}
        radius={1.8}
        name={"right"}
      />
    </Instances>
  );
};

export default SatelliteTrails;
