import { useState, cloneElement, ReactElement } from "react";
import { createPortal } from "react-dom";

export function useDialog() {
  const [currentDialog, setCurrentDialog] = useState<ReactElement | null>(null);

  const resetDialog = () => {
    setCurrentDialog(null);
  };

  const defaultDialogProps = {
    isOpen: true,
    resetDialog: resetDialog,
  };

  const openDialog = (dialogComponent: ReactElement) => {
    setCurrentDialog(cloneElement(dialogComponent, { ...defaultDialogProps }));
  };

  // use this HOC to wrap rendered component housing the dialog trigger
  const WithCurrentDialog = (anchorComponent: ReactElement) => {
    return (
      <>
        {anchorComponent}
        {createPortal(currentDialog, document.body)}
      </>
    );
  };

  return { openDialog, resetDialog, WithCurrentDialog };
}
