import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { trogdorApi } from "../api";
import {
  formatTLESatResponse,
  formatEventResponse,
} from "../formatting/responseFormatting";
import { TLESatResponse, EventResponse } from "../types/responseTypes";
import { TLESat } from "../types/internalTypes";
import { formatCreateTLESatRequest } from "../formatting/requestFormatting";

export const tleSatsAdapter = createEntityAdapter<TLESat>();
export const tleSatsInitialState = tleSatsAdapter.getInitialState();

export const tleSatsByEventIdAdapter = createEntityAdapter<TLESat>({
  selectId: (tleSat) => tleSat.eventId,
  sortComparer: (a, b) => a.eventId - b.eventId,
});
export const tleSatsByEventIdInitialState =
  tleSatsByEventIdAdapter.getInitialState();

export const tleSatsEndpoints = trogdorApi.injectEndpoints({
  endpoints: (builder) => ({
    getScenarioTLEs: builder.query<any, number>({
      async queryFn(scenarioId, { dispatch }, _extraOptions, baseQuery) {
        const scenarioEvents: any = await baseQuery({
          url: "/db/get_scenario_events",
          method: "get",
          params: { scenario_id: scenarioId },
        });
        const formattedEvents =
          scenarioEvents?.data?.body?.message ===
          "There are no events for the given Scenario."
            ? []
            : scenarioEvents?.data?.body?.map((event: EventResponse) => {
                return formatEventResponse(event);
              });

        const eventIds = formattedEvents.map((event: any) => event.id);
        const tlesByEventId = {} as any;

        try {
          const tleRequests = eventIds.map(async (eventId: number) => {
            await dispatch(
              tleSatsEndpoints.endpoints.getEventTLEs.initiate(eventId)
            ).then((response) => {
              tlesByEventId[eventId] =
                response?.data?.entities &&
                Object.values(response.data.entities);
            });
          });
          await Promise.all(tleRequests);
          return { data: tlesByEventId };
        } catch (error) {
          return { error: error as FetchBaseQueryError };
        }
      },
      providesTags: (_result, _error, scenarioId) => [
        { type: "Scenario", id: scenarioId },
      ],
    }),
    getEventTLEs: builder.query<EntityState<TLESat>, number>({
      query: (eventId) => ({
        url: "/db/get_event_tles",
        method: "get",
        params: { event_id: eventId },
      }),
      keepUnusedDataFor: Infinity,
      transformResponse(response: { body: TLESatResponse[] }, _meta, eventId) {
        const eventTLEs = response.body.map((tleSat) => {
          return formatTLESatResponse(tleSat, eventId);
        });

        return tleSatsAdapter.setAll(tleSatsInitialState, eventTLEs);
      },
      providesTags: (entityResponse: EntityState<TLESat> | undefined) => {
        const entities = entityResponse?.entities;
        const arrayOfEntities =
          entities && (Object.values(entities) as TLESat[]);
        return arrayOfEntities
          ? [
              ...arrayOfEntities.map(({ id }) => ({
                type: "TLESat" as const,
                id,
              })),
              "TLESat",
            ]
          : ["TLESat"];
      },
    }),
    createEventTLE: builder.mutation<
      TLESat,
      { eventId: number; tleData: TLESat }
    >({
      query: ({ eventId, tleData }) => {
        return {
          url: "/db/create_sat",
          method: "post",
          params: { event_id: eventId },
          body: formatCreateTLESatRequest(tleData),
        };
      },
      transformResponse(
        response: { body: TLESatResponse[] },
        _meta,
        { eventId }
      ) {
        return formatTLESatResponse(response.body[0], eventId);
      },
      invalidatesTags: (result) =>
        result ? [{ type: "TLESat", id: result.id }] : ["TLESat"],
    }),
  }),
});

export const {
  useGetEventTLEsQuery,
  useGetScenarioTLEsQuery,
  useCreateEventTLEMutation,
}: any = tleSatsEndpoints;
