// Redux Actions & Thunks
// import * as curScenarioEvents from "../slices/scenarios/currentScenario/events/eventsSlice";
import * as curScenarioSVs from "../slices/scenarios/currentScenario/objects/postEventSVs";
// import * as curScenarioTLEs from "../slices/scenarios/currentScenario/objects/preEventTLEs";
// import { fetchEvent } from "./eventThunks";

// Redux Selectors
import {
  getCurrentScenarioSVsByEventId,
  getCurrentScenarioEvents,
} from "../selectors/scenarioSelector";

// API Functions
// import * as acmeEvents from "../../utils/api/acme/events";
import * as trogdorEventSats from "../../utils/api/trogdor/eventSats";
import { getEphemFromSV } from "../../utils/api/acme/propagation";

// Utilities
import Common from "../../utils/common";
// import { getPromiseAllData } from "../../utils/api/trogdor/formattingFunctions";
// import { trogdorPromiseAllErrorCheck } from "../../utils/api/trogdor/utils";

// import _ from "lodash";

// export const editEventObject = (values, objectId, eventId) => {
//   return async (dispatch) => {
//     dispatch(curScenarioEvents.editEventObjectStart());
//     const onSuccess = async (response) => {
//       await dispatch(
//         curScenarioEvents.editEventObjectSuccess({
//           data: response.data,
//           eventId: eventId,
//         })
//       );
//       await dispatch(fetchEvent(eventId));
//       return response;
//     };

//     const onError = async (error) => {
//       console.error(error);
//       await dispatch(curScenarioEvents.editEventObjectFail(error.response));
//       return error;
//     };

//     try {
//       if (Common.trogdor) {
//         const response = await trogdorEventSats.createPostEventSV(
//           values,
//           objectId, // should be a state vector id
//           eventId
//         );
//         return onSuccess(response);
//       } else {
//         const response = await acmeEvents.editEventObject(values, objectId);
//         onSuccess(response);
//       }
//     } catch (error) {
//       onError(error);
//     }
//   };
// };

// export const deleteEventObject = (objectId, eventId) => {
//   return async (dispatch) => {
//     dispatch(curScenarioEvents.deleteEventObjectStart());
//     const onSuccess = async (response) => {
//       await dispatch(
//         curScenarioEvents.deleteEventObjectSuccess({
//           data: response.data,
//           eventId: eventId,
//         })
//       );
//       await dispatch(fetchEvent(eventId));
//       return response;
//     };

//     const onError = async (error) => {
//       console.error(error);
//       await dispatch(curScenarioEvents.deleteEventObjectFail(error.response));
//       return error;
//     };

//     try {
//       if (Common.trogdor) {
//         const response = await trogdorEventSats.deletePostEventSV(
//           objectId // should be a state vector id
//         );
//         return onSuccess(response);
//       } else {
//         const response = await acmeEvents.editEventObject(objectId);
//         onSuccess(response);
//       }
//     } catch (error) {
//       onError(error);
//     }
//   };
// };

// // ! Only needed for Trogdor
// // Get all state vectors for a scenario by querying each event for state vectors
// export const getScenarioEventSvs = (eventIds) => {
//   return async (dispatch) => {
//     const onSuccess = async (responses) => {
//       const eventObjects = {};
//       eventIds.forEach((id, index) => {
//         eventObjects[id] = responses[index];
//       });
//       await dispatch(curScenarioSVs.fetchSVsSuccess(eventObjects));
//       return Promise.resolve(responses);
//     };

//     const onError = async (error) => {
//       console.error(error);
//       await dispatch(curScenarioSVs.fetchSVsFail(error));
//       return Promise.reject(error);
//     };

//     try {
//       // If going into a scenario for the first time
//       if (Common.trogdor) {
//         await dispatch(curScenarioSVs.fetchSVsStart());
//         const objectRequests = eventIds.map((eventId) =>
//           trogdorEventSats.getAllPostEventSVs(eventId)
//         );
//         const responses = await Promise.all(objectRequests);

//         // Check for errors
//         const errorCheck = trogdorPromiseAllErrorCheck(responses);
//         if (errorCheck.error) return onError(errorCheck.errorMessage);

//         // Remove excess response data
//         return onSuccess(getPromiseAllData(responses));
//       }
//     } catch (error) {
//       return onError("Unable to fetch objects for all Scenario Events");
//     }
//   };
// };

export const getEphem = (stateVector) => {
  return async (dispatch) => {
    const onSuccess = async (response) => {
      await dispatch(
        curScenarioSVs.fetchEphemSuccess({
          id: stateVector.id,
          ephemerisPoints: { ...response.data },
        })
      );
      return response;
    };
    const onError = async (error) => {
      await dispatch(curScenarioSVs.fetchEphemFail(error));
      console.error(error);
      return error;
    };

    try {
      await dispatch(curScenarioSVs.fetchEphemStart());
      const response = await getEphemFromSV(stateVector);
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
};

export const getScenarioSVEphemerides = (eventIds) => {
  return async (dispatch, getState) => {
    const curState = getState();
    const stateVectorsByEventId = getCurrentScenarioSVsByEventId(curState);
    const curScenarioEvents = getCurrentScenarioEvents(curState);

    const onSuccess = async (response) => {
      const ephemByStateVectorId = {};
      eventIds.forEach((eventId) => {
        if (stateVectorsByEventId[eventId].length) {
          stateVectorsByEventId[eventId].forEach((sv) => {
            ephemByStateVectorId[sv.idStateVector] = response.shift();
          });
        }
      });
      await dispatch(
        curScenarioSVs.fetchEphemeridesSuccess(ephemByStateVectorId)
      );
      return Promise.resolve(ephemByStateVectorId);
    };
    const onError = async (error) => {
      console.error(error);
      await dispatch(curScenarioSVs.fetchEphemeridesFail(error));
      return error;
    };

    try {
      await dispatch(curScenarioSVs.fetchEphemeridesStart());
      const ephemRequests = [];
      eventIds.forEach((eventId) => {
        if (stateVectorsByEventId[eventId].length) {
          stateVectorsByEventId[eventId].forEach((sv) => {
            ephemRequests.push(
              getEphemFromSV({
                ...sv,
                start: curScenarioEvents[eventId].startTime,
                end: curScenarioEvents[eventId].endTime,
                // TODO: Hard-coding step to 1 minute. Figure out best adjustment mechanism
                step: 60,
              }).then((response) => response.data)
            );
          });
        }
      });
      const response = await Promise.all(ephemRequests);
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
};

export const getPlotScenarioSVs = (eventIds) => async (dispatch) => {
  try {
    // const SVResponse = await dispatch(getScenarioEventSvs(eventIds));
    const EphemResponse = await dispatch(getScenarioSVEphemerides(eventIds));
    return Promise.all([EphemResponse]);
  } catch (error) {
    console.error("Get and Plot Error", error);
  }
};

// export const createPreEventTLE = (tle, eventId) => {
//   return async (dispatch) => {
//     const onSuccess = async (response) => {
//       await dispatch(curScenarioTLEs.createTrogdorTLESuccess());
//       return response;
//     };
//     const onError = async (error) => {
//       await dispatch(curScenarioTLEs.createTrogdorTLEFail(error));
//       return error;
//     };

//     try {
//       if (Common.trogdor) {
//         await dispatch(curScenarioTLEs.createTrogdorTLEStart());
//         const response = await trogdorEventSats.createPreEventTLE(tle, eventId);
//         if (response.data.errorMessage)
//           return onError(response.data.errorMessage);
//         return onSuccess(response);
//       }
//     } catch (error) {
//       return onError(error);
//     }
//   };
// };

// For saving to Trogdor during the Save-all process
export const createPostEventSV = (sv, eventId) => {
  return async (dispatch) => {
    const onSuccess = async (response) => {
      await dispatch(curScenarioSVs.createTrogdorSVSuccess());
      return response;
    };

    const onError = async (error) => {
      console.error(error);
      await dispatch(curScenarioSVs.createTrogdorSVFail(error));
      return error;
    };

    try {
      if (Common.trogdor) {
        await dispatch(curScenarioSVs.createTrogdorSVStart());
        const response = await trogdorEventSats.createPostEventSV(sv, eventId);
        if (response.data.errorMessage)
          return onError(response.data.errorMessage);

        return onSuccess(response);
      }
    } catch (error) {
      // return onError(error);

      //! Skipping error handling as a correct request returns a 200 but no response, tripping this catch block
      await dispatch(curScenarioSVs.createTrogdorSVSuccess());
      return Promise.resolve("Created Successfully");
    }
  };
};
