/* eslint-disable react/no-unknown-property */
import { useRef } from "react";
import * as THREE from "three";
import { Sphere, useTexture } from "@react-three/drei";
import { earthRadius } from "satellite.js/lib/constants";
import { useLoginCanvasContext } from "../../../utils/Hooks/contextHooks/useLoginCanvasContext";
import { useFrame } from "@react-three/fiber";

const Stars = () => {
  const { maxDistanceFactor, sceneIsLoaded } = useLoginCanvasContext();

  const stars = useTexture(process.env.PUBLIC_URL + "/galaxy_starfield.png");
  const starColor = new THREE.Color();

  const starMaterialRef = useRef<any>();
  const alphaValue = useRef(0);

  useFrame(() => {
    if (!sceneIsLoaded) return;
    if (!starMaterialRef.current) return;
    if (alphaValue.current >= 1) return;

    starColor.lerpColors(
      new THREE.Color(0xffffff),
      new THREE.Color(0xa8a8a8),
      alphaValue.current
    );
    starMaterialRef.current.color.set(starColor);

    alphaValue.current += 0.01;
  });

  return (
    <Sphere name="Stars" args={[earthRadius * maxDistanceFactor, 32, 32]}>
      <meshBasicMaterial
        ref={starMaterialRef}
        name="Stars Material"
        side={THREE.BackSide}
        map={stars}
        depthTest={false}
      />
    </Sphere>
  );
};

export default Stars;
