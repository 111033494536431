import { isBefore, isAfter } from "date-fns/esm";
import store from "../app/store";
import type { Scenario } from "../redux/rtk/types/internalTypes";
import type {
  ScenarioFormValues,
  EventFormValues,
} from "../redux/rtk/types/formTypes";
import Common from "./common";

type FormValues = Partial<ScenarioFormValues> | Partial<EventFormValues>;

export const validateEventStartDateTime = (
  event: CustomEvent<any>,
  values: Partial<EventFormValues>,
  errors: Partial<EventFormValues>
) => {
  const currentScenario: Scenario = store.getState().currentScenario.details
    .details as Scenario;
  const newErrorMessages: Partial<EventFormValues> = {};
  const { startDateTime, endDateTime } = getDatesFromValues(event, values);

  if (startDateTime && endDateTime) {
    if (isBefore(startDateTime, new Date(currentScenario.startTime))) {
      newErrorMessages.startDate =
        "Event start date is before the start of the scenario";
    } else if (isAfter(startDateTime, new Date(currentScenario.endTime))) {
      newErrorMessages.startDate =
        "Event start date is after the end of the scenario";
    } else if (isAfter(startDateTime, endDateTime)) {
      newErrorMessages.startDate = "Event start date must be before end date";
    } else {
      delete errors["endDate"];
      delete errors["startDate"];
    }
  }

  return newErrorMessages;
};

export const validateEventEndDateTime = (
  event: CustomEvent,
  values: Partial<EventFormValues>,
  errors: Partial<EventFormValues>
) => {
  const currentScenario: Scenario = store.getState().currentScenario.details
    .details as Scenario;
  const newErrorMessages: Partial<EventFormValues> = {};
  const { startDateTime, endDateTime } = getDatesFromValues(event, values);

  if (startDateTime && endDateTime) {
    if (isBefore(endDateTime, new Date(currentScenario.startTime))) {
      newErrorMessages.endDate =
        "Event end date is before the start of the scenario";
    } else if (isAfter(endDateTime, new Date(currentScenario.endTime))) {
      newErrorMessages.endDate =
        "Event end date is after the end of the scenario";
    } else if (isAfter(startDateTime, endDateTime)) {
      newErrorMessages.endDate = "Event end date must be after start date";
    } else {
      delete errors["endDate"];
      delete errors["startDate"];
    }
  }

  return newErrorMessages;
};

export const validateScenarioStartDateTime = (
  event: CustomEvent<any>,
  values: Partial<ScenarioFormValues>,
  errors: Partial<ScenarioFormValues>
) => {
  const newErrorMessages: Partial<EventFormValues> = {};
  const { startDateTime, endDateTime } = getDatesFromValues(event, values);

  if (startDateTime && endDateTime) {
    if (isAfter(startDateTime, endDateTime)) {
      newErrorMessages.startDate = "Event start date must be before end date";
    } else {
      delete errors["endDate"];
      delete errors["startDate"];
    }
  }

  return newErrorMessages;
};

export const validateScenarioEndDateTime = (
  event: CustomEvent,
  values: Partial<ScenarioFormValues>,
  errors: Partial<ScenarioFormValues>
) => {
  const newErrorMessages: Partial<EventFormValues> = {};
  const { startDateTime, endDateTime } = getDatesFromValues(event, values);

  if (startDateTime && endDateTime) {
    if (isAfter(startDateTime, endDateTime)) {
      newErrorMessages.endDate = "Event end date must be after start date";
    } else {
      delete errors["endDate"];
      delete errors["startDate"];
    }
  }

  return newErrorMessages;
};

export const validateRequiredField = (event: CustomEvent) => {
  const newErrorMessages: any = {};
  const eventTarget = event.target as HTMLInputElement;
  const name = eventTarget.name;

  if (!eventTarget.value.length) {
    newErrorMessages[name] = "This field cannot be blank";
  }

  return newErrorMessages;
};

export const validateFormRequiredFieldsOnSubmit = (form: HTMLFormElement) => {
  if (!form) return;

  const newErrorMessages: any = {};
  const inputs = [...form.getElementsByTagName("rux-input")];
  const selects = [...form.getElementsByTagName("rux-select")];
  const textareas = [...form.getElementsByTagName("rux-textarea")];
  const formFields = [...inputs, ...selects, ...textareas];

  formFields.forEach((field) => {
    if (!field.value && field.required) {
      newErrorMessages[field.name] = "This field cannot be blank";
    }
  });

  return newErrorMessages;
};

const getDatesFromValues = (event: CustomEvent, values: FormValues) => {
  const eventTarget = event.target as HTMLInputElement;
  const endDateValue =
    eventTarget.name === "endDate" ? eventTarget.value : values.endDate;
  const endTimeValue =
    eventTarget.name === "endTime" ? eventTarget.value : values.endTime;
  const startDateValue =
    eventTarget.name === "startDate" ? eventTarget.value : values.startDate;
  const startTimeValue =
    eventTarget.name === "startTime" ? eventTarget.value : values.startTime;

  if (endDateValue && endTimeValue && startDateValue && startTimeValue) {
    return {
      endDateTime: new Date(
        Common.convertToISOJSONDate(endDateValue, endTimeValue)
      ),
      startDateTime: new Date(
        Common.convertToISOJSONDate(startDateValue, startTimeValue)
      ),
    };
  } else {
    return { startDateTime: null, endDateTime: null };
  }
};
