import { toDate as julianToDateObj } from "julian";
import { eventTypeDefinitions } from "./eventTypeDefinitions";
import Common, {
  getObjByIdAndArrayOfIds,
  findEventTypeIdByName,
} from "../../common";
import {
  GetEventParameterResponse,
  EventDataForPost,
  EventSatsResponseParams,
  GetEventStateVectorResponse,
  GetMultiEventParameterResponse,
  GetSat,
  GetSatResponse,
  UDLRequestParams,
  VisualizeMitreManeuverResponse,
  VisualizeStateVectorResponse,
  VisualizeStateVector,
  MitreStateVector,
  EventParams,
  StateVectorResponse,
  GetMultiStateVectorResponse,
  GetSingleSatResponse,
} from "../../../redux/rtk/types/trogdorApiTypes";
import { Scenario, Event } from "../../../redux/rtk/types/internalTypes";
import {
  ParamValueResponse,
  EventResponse,
  MultiEventsResponse,
} from "../../../redux/rtk/types/responseTypes";
import {
  UpdateScenarioRequestParams,
  CreateScenarioRequestParams,
  EventRequestParams,
  EventParamsRequestIntersection,
  StateVectorRequestParams,
  ChangeManeuverEventRequestParams,
} from "../../../redux/rtk/types/requestTypes";
import { AxiosResponse } from "axios";

// ______________ Utilities ____________________________

const eventCreationDataResponseKeys = {
  1: "Event",
  4: "ManeuverEvent",
  5: "ManeuverEvent",
  6: "ManeuverEvent",
};

// ______________ Request Formatting ___________________

export const formatSTPreEventTLERequest = (
  preEventSatData: EventSatsResponseParams
) => {
  const {
    apoapsis,
    argOfPericenter,
    bstar,
    classificationType,
    eccentricity,
    epoch,
    inclination,
    meanAnomaly,
    meanElementTheory,
    meanMotion,
    meanMotionDdot,
    meanMotionDot,
    noradCatId,
    originator,
    period,
    periapsis,
    raOfAscNode,
    revAtEpoch,
    semimajorAxis,
    tleLine1,
    tleLine2,
  } = preEventSatData;
  return {
    inclination,
    period,
    meanMotion,
    meanMotionDdot,
    meanMotionDot,
    eccentricity,
    meanAnomaly,
    type: "TLE",
    dataMode: "REAL",
    ...(Object.hasOwn(preEventSatData, "bstar") && { bStar: bstar }),
    ...(Object.hasOwn(preEventSatData, "apoapsis") && { apogee: apoapsis }),
    ...(Object.hasOwn(preEventSatData, "noradCatId") && {
      //! Using Sat Number for now
      id: noradCatId.toString(),
      name: `Sat No: ${noradCatId}`,
      satNo: noradCatId,
      idOnOrbit: noradCatId.toString(),
    }),
    ...(Object.hasOwn(preEventSatData, "argOfPericenter") && {
      argOfPerigee: argOfPericenter,
    }),
    ...(Object.hasOwn(preEventSatData, "periapsis") && { perigee: periapsis }),
    ...(Object.hasOwn(preEventSatData, "raOfAscNode") && { raan: raOfAscNode }),
    ...(Object.hasOwn(preEventSatData, "epoch") && {
      epoch: Common.formatTrogdorDateTime(epoch),
    }),
    ...(Object.hasOwn(preEventSatData, "originator") && {
      source: originator,
      origNetwork: "OPS1",
    }),
    ...(Object.hasOwn(preEventSatData, "semimajorAxis") && {
      semiMajorAxis: semimajorAxis,
    }),
    ...(Object.hasOwn(preEventSatData, "revAtEpoch") && { revNo: revAtEpoch }),
    ...(Object.hasOwn(preEventSatData, "classificationType") && {
      classificationMarking: classificationType,
    }),
    ...(Object.hasOwn(preEventSatData, "tleLine1") && { line1: tleLine1 }),
    ...(Object.hasOwn(preEventSatData, "tleLine2") && { line2: tleLine2 }),
    ...(Object.hasOwn(preEventSatData, "meanElementTheory") && {
      algorithm: meanElementTheory,
    }),
  };
};

export const formatUdlPreEventTLERequest = (
  preEventSatData: UDLRequestParams
) => {
  const newSat = { ...preEventSatData };
  delete newSat.id;
  delete newSat.eventId;
  delete newSat.name;
  return newSat;
};

export const eventParamRequestFormatFunction = (
  eventTypeId: number,
  paramsArray: ParamValueResponse[]
): EventParamsRequestIntersection => {
  const paramValuesByDefId: { [key: string]: string } = {};
  paramsArray.forEach((param) => {
    paramValuesByDefId[param.paramDefID] = param.paramValue;
  });
  const eventParamsByEventTypeId = {
    1: {
      satellite_id: paramValuesByDefId[1],
    },
    4: {
      change_amount: paramValuesByDefId[11],
      burn_time: `${paramValuesByDefId[14]}`,
      sat_number: paramValuesByDefId[13],
      time_of_flight: paramValuesByDefId[12],
    },
    5: {
      change_amount: paramValuesByDefId[5],
      burn_time: `${paramValuesByDefId[7]}`,
      sat_number: paramValuesByDefId[6],
      time_of_flight: paramValuesByDefId[105],
    },
    6: {
      change_amount: paramValuesByDefId[8],
      burn_time: `${paramValuesByDefId[10]}`,
      sat_number: paramValuesByDefId[9],
      time_of_flight: paramValuesByDefId[106],
    },
  };

  return eventParamsByEventTypeId[
    eventTypeId as keyof typeof eventParamsByEventTypeId
  ];
};

export const formatEventParamCreationRequest = (
  values: Partial<ParamValueResponse>,
  eventId: number
) => {
  const { paramDefID, paramValue } = values;
  return {
    event_id: eventId,
    param_def_id: paramDefID,
    param_value: paramValue,
  };
};

// Conditionally reformats data object for query param submission on POST
export const formatEventRequest = (
  eventRequestData: EventDataForPost
): EventRequestParams => {
  const { eventTypeId, scenarioId, startTime, endTime, name, id, mitreMode } =
    eventRequestData;
  return {
    event_type:
      eventTypeDefinitions[eventTypeId as keyof typeof eventTypeDefinitions]
        .eventName,
    // hard coded event status value for new events
    event_status: "Queued",
    scenario_id: scenarioId,
    event_start: `${startTime}`,
    event_end: `${endTime}`,
    event_name: name,
    event_id: id,
    mitreMode,
    // adds additional request formatting for specific event type parameters
    ...eventParamRequestFormatFunction(
      eventTypeId,
      eventRequestData.parameters.parameters
    ),
  };
};

export const formatScenarioRequest = (
  scenarioData: Scenario
): UpdateScenarioRequestParams => {
  const { name, active, description, endTime, startTime, id } = scenarioData;
  return {
    scenario_name: name,
    scenario_active: active,
    scenario_end: Common.formatTrogdorDateTime(endTime),
    scenario_start: Common.formatTrogdorDateTime(startTime),
    scenario_desc: description,
    scenario_id: id,
    scenario_status: "Deactivated",
  };
};

export const formatCreateScenarioRequest = (
  scenarioData: Scenario
): CreateScenarioRequestParams => {
  const { name, description, endTime, startTime } = scenarioData;
  return {
    scenario_name: name,
    scenario_active: false,
    scenario_end: Common.formatTrogdorDateTime(endTime),
    scenario_start: Common.formatTrogdorDateTime(startTime),
    scenario_desc: description,
    scenario_status: "Deactivated",
  };
};

export const formatUpdateScenarioRequest = (
  scenarioData: Scenario
): UpdateScenarioRequestParams => {
  const { name, active, description, endTime, startTime, id } = scenarioData;
  return {
    scenario_name: name,
    scenario_active: active,
    scenario_end: Common.formatTrogdorDateTime(endTime),
    scenario_start: Common.formatTrogdorDateTime(startTime),
    scenario_desc: description,
    scenario_id: id,
    scenario_status: "Deactivated",
  };
};

//TODO create when needed
export const formatStateVectorRequest = (data: StateVectorRequestParams) => {
  const newData = structuredClone(data);
  delete newData.id;
  delete newData.endTime;
  delete newData.startTime;
  delete newData.descriptor;
  delete newData.mass;

  return newData;
};

// ______________ Response Formatting _______________________
export const formatMulitItemResponse = (
  itemList: Array<object>,
  formatting: any
) => {
  return getObjByIdAndArrayOfIds(itemList.map((item) => formatting(item)));
};

export const formatEventResponse = (eventData: EventResponse): Event => {
  // Optionally remove class object layer.
  if ("Event" in eventData) eventData = eventData.Event;
  if ("ManeuverEvent" in eventData) eventData = eventData.ManeuverEvent;

  return {
    id: eventData.eventID,
    name: eventData.eventName,
    startTime: eventData.startTime,
    endTime: eventData.endTime,
    scenarioId: eventData.scenarioID,
    active: eventData.active,
    status: eventData.status,
    eventType: eventData.eventType,
    eventTypeId: findEventTypeIdByName(eventData.eventType),
    provider: eventData.provider,
    parameters: eventData.parameters,
    changed: false,
  };
};

export const formatMultiEventResponse = (
  response: AxiosResponse<MultiEventsResponse>
) => {
  const events = formatMulitItemResponse(
    response.data.body,
    formatEventResponse
  );
  response.data.body = events.objectById;
  return response;
};

export const formatSingleEventResponse = (
  response: AxiosResponse<{ body: EventResponse }>
) => {
  const newResponse = { data: { body: {} } };
  const eventFormatted = formatEventResponse(response.data.body);
  newResponse.data.body = eventFormatted;
  return newResponse;
};

export const formatVisualizeStateVectorResponse = (
  stateVector: VisualizeStateVectorResponse,
  eventId: number
): VisualizeStateVector => {
  const {
    spacecraftId,
    inverseBallisticCoeff,
    mass,
    description,
    agom,
    epoch,
    velocityX,
    velocityY,
    velocityZ,
    positionX,
    positionY,
    positionZ,
    startTime,
    id,
    endTime,
    frame,
  } = stateVector;
  const constructedName = `Post Manevuer SV for Sat: ${spacecraftId}`;
  return {
    id,
    epoch,
    mass,
    startTime,
    endTime,
    name: constructedName,
    dragCoeff: Number(
      inverseBallisticCoeff.substring(0, inverseBallisticCoeff.length - 6)
    ),
    // idStateVector,
    xpos: Number(positionX.substring(0, positionX.length - 3)), // in km
    ypos: Number(positionY.substring(0, positionY.length - 3)), // in km
    zpos: Number(positionZ.substring(0, positionZ.length - 3)), // in km
    xvel: Number(velocityX.substring(0, velocityX.length - 5)), // in km/s
    yvel: Number(velocityY.substring(0, velocityY.length - 5)), // in km/s
    zvel: Number(velocityZ.substring(0, velocityZ.length - 5)), // in km/s
    satNo: Number(spacecraftId),
    idOnOrbit: spacecraftId,
    referenceFrame: frame.substring(6),
    descriptor: description,
    solarRadPressCoeff: Number(agom.substring(0, agom.length - 6)),
    type: "SV",
    eventId: eventId,
  };
};

export const formatVisualizeMitreStateVectorResponse = (
  mitreModeResponse: VisualizeMitreManeuverResponse,
  eventId: number
): MitreStateVector => {
  const satNo = mitreModeResponse.satNo;
  const endState = mitreModeResponse.endState;
  const sat = mitreModeResponse.sat;
  const elSet = "elSet" in sat ? sat.elSet : sat.a;

  const position = "position" in endState ? endState.position : endState.a;
  const velocity = "velocity" in endState ? endState.velocity : endState.b;
  const time = "time" in endState ? endState.time : endState.c;
  const julianTime = "julian" in time ? time.julian : time.b;

  const constructedName = `Post Manevuer SV for Sat: ${satNo}`;

  return {
    id: crypto.randomUUID(),
    epoch: Common.formatDateTimeToISO(julianToDateObj(julianTime)),
    // mass,
    // startTime,
    // endTime,
    name: constructedName,
    // dragCoeff: Number(
    //   inverseBallisticCoeff.substring(0, inverseBallisticCoeff.length - 6)
    // ),
    // idStateVector,
    xpos: position.X, // in km
    ypos: position.Y, // in km
    zpos: position.Z, // in km
    xvel: velocity.X, // in km/s
    yvel: velocity.Y, // in km/s
    zvel: velocity.Z, // in km/s
    satNo: satNo,
    idOnOrbit: satNo.toString(),
    // referenceFrame: frame.substring(6),
    descriptor: mitreModeResponse.objective,
    solarRadPressCoeff: elSet.solarRadiationPressureCoefficient,
    type: "SV",
    eventId: eventId,
  };
};

export const formatCreatedEventResponse = (
  response: AxiosResponse,
  eventId: number,
  visualize: boolean,
  requestData: EventDataForPost
) => {
  // If trogdor responds with an AI-Solutions error
  if (response.data.body.message) return response;

  if (visualize) {
    // response.data = response.data.body;
    if (requestData.mitreMode) {
      response.data.body.postManeuverStateVector =
        formatVisualizeMitreStateVectorResponse(
          response.data.body.maneuver,
          eventId
        );
    } else {
      response.data.body.postManeuverStateVector =
        formatVisualizeStateVectorResponse(
          response.data.body.postManeuverStateVector,
          eventId
        );
    }
  } else {
    response.data.body = formatEventResponse(
      response.data.body[
        eventCreationDataResponseKeys[
          requestData.eventTypeId as keyof typeof eventCreationDataResponseKeys
        ]
      ]
    );
  }
  return response;
};

export const eventParamResponseFormatFunction = {
  4: (responseData: ChangeManeuverEventRequestParams): EventParams => {
    const { change_amount, burn_time, sat_number } = responseData;
    return {
      changeAmount: change_amount,
      burnTime: burn_time,
      satNumber: sat_number,
    };
  },
};

const formatPreEventTLEResponse = (
  eventSatData: GetSat | { UDLTle: GetSat },
  eventId: number
) => {
  // Remove class Object Wrapper
  if ("UDLTle" in eventSatData) eventSatData = eventSatData.UDLTle;
  const satNo = eventSatData.satNo || Number(eventSatData.origObjectId);
  const constructedName = `Sat No: ${satNo}`;
  return {
    ...eventSatData,
    name: constructedName,
    id: eventSatData.idElset,
    type: "TLE",
    eventId: eventId,
    satNo: satNo,
  };
};

export const formatMultiPreEventTLEResponse = (
  response: AxiosResponse<GetSatResponse>,
  eventId: number
) => {
  response.data.body = response.data.body.map((tle) => {
    return formatPreEventTLEResponse(tle, eventId);
  });
  return response;
};

// Pulls the first item out of the returned array
export const formatSinglePreEventTLEResponse = (
  response: AxiosResponse<GetSingleSatResponse>,
  eventId: number
) => {
  const newResponse = { data: { body: {} } };
  const tleFormatted = formatPreEventTLEResponse(
    response.data.body[0],
    eventId
  );
  newResponse.data.body = tleFormatted;
  return response;
};

export const formatPostEventSVArray = (
  response: AxiosResponse<GetEventStateVectorResponse>,
  eventId: number
) => {
  response.data.body.map((sat) => {
    sat.type = "SV";
    sat.id = sat.idStateVector;
    sat.eventId = eventId;
    return sat;
  });
  return response;
};

export const formatMultiEventParamResponse = (
  response: AxiosResponse<GetMultiEventParameterResponse>
) => {
  const newResponse = { data: { body: {} } };
  newResponse.data.body = response.data.body.map(
    (param) => param.EventParameter
  );
  return newResponse;
};

export const formatSingleEventParamResponse = (
  response: AxiosResponse<GetEventParameterResponse>,
  eventId: number
) => {
  const newResponse = { data: { body: {} } };
  const eventParam = { ...response.data.body.EventParameter, eventId };
  newResponse.data.body = eventParam;
  return newResponse;
};

export const getPromiseAllData = (
  responseArr: Array<{ data: { body: { [key: string]: any } } }>
) => {
  return responseArr.map((response) => {
    return response.data.body;
  });
};

export const formatCreateStateVectorResponse = (
  stateVectorData: StateVectorResponse | { UDLStateVector: StateVectorResponse }
) => {
  // Optionally remove class object layer.
  if ("UDLStateVector" in stateVectorData)
    stateVectorData = stateVectorData.UDLStateVector;

  return {
    ...stateVectorData,
    id: stateVectorData.dbId,
    type: "SV",
  };
};

export const formatMultiCreateStateVectorResponse = (response: {
  data: GetMultiStateVectorResponse;
}) => {
  response.data.body = response.data.body.map((sv) =>
    formatCreateStateVectorResponse(sv)
  );
  return response;
};
