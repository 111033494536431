import Common from "../../common";
import universalAxios, { Url, Data, Params, Headers, Method } from "../axios";

class AcmeAxios {
  static get(url: Url, params?: Params) {
    return this.axios("get", url, null, params);
  }

  static delete(url: Url, data?: Data, params?: Params) {
    if (data) data = Common.snakeize(data);
    return this.axios("delete", url, data, params);
  }

  static post(url: Url, data?: Data, params?: Params, headers?: Headers) {
    if (data) data = Common.snakeize(data);

    return this.axios("post", url, data, params, headers);
  }

  static put(url: Url, data?: Data, params?: Params) {
    if (data) data = Common.snakeize(data);
    return this.axios("put", url, data, params);
  }

  static patch(url: Url, data?: Data, params?: Params) {
    if (data) data = Common.snakeize(data);
    const headers = {
      "Content-Type": "application/merge-patch+json",
    };
    return this.axios("patch", url, data, params, headers);
  }

  static axios(
    method: Method,
    url: Url,
    data?: Data,
    params?: Params,
    headers?: Headers
  ) {
    return universalAxios(method, url, params, data, headers)
      .then((response) => {
        return Common.camelizeResponseData(response);
      })
      .catch((error) => {
        // Throw error for later handling
        throw error;
      });
  }
}

export default AcmeAxios;
