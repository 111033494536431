import { useRef, useState, useEffect, useMemo } from "react";
import * as THREE from "three";
import { Line } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { isAfter, isBefore, addMinutes, subMinutes } from "date-fns";
import { isBetween } from "../../../utils/common";
import {
  calculateMeanMotion,
  getPositionFromSV,
} from "../../../utils/propagation";
import { useSatelliteContext } from "../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useSatStateContext } from "../../../utils/Hooks/contextHooks/useSatStateContext";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import { useDateTimeContext } from "../../../utils/Hooks/contextHooks/useDateTimeContext";
import TLEGhostOrbit from "./TLEGhostOrbit";
import { EventStateVector } from "../../../redux/rtk/types/trogdorApiTypes";
import { Ephemeris } from "../../../redux/rtk/types/internalTypes";
import { EventDataForObjects } from "../../../redux/rtk/types/ThreeJSCommonTypes";

type PropTypes = {
  isSelected: boolean;
  isHovered: boolean;
  hasManualOrbit?: boolean;
  satellite: EventStateVector;
  eventData: EventDataForObjects;
  ephemerisPoints: Ephemeris;
};

const SVOrbit = ({
  isSelected,
  isHovered,
  hasManualOrbit,
  satellite,
  eventData,
  ephemerisPoints,
}: PropTypes) => {
  const { threeSatellitesById } = useSatelliteContext();
  const {
    removeSelected,
    hoveredSelectedSatId,
    setHoveredSelected,
    clearHoveredSelected,
  } = useSatStateContext();
  const { orbitIsDragging, timelineIsScrubbing, ghostTLEToggle } =
    useCanvasContext();
  const { dateTime } = useDateTimeContext();

  const [startDate, setStartDate] = useState<Date>(dateTime);
  const [endDate, setEndDate] = useState<Date>();
  const [SVOrbitPointsArr, setSVOrbitPointsArr] = useState<THREE.Vector3[]>([]);

  const lineSegmentInterval = useRef(isHovered ? 2 : 1);
  const ghostPathInterval = useRef(0);

  const revsPerDay = useMemo(() => calculateMeanMotion(satellite), [satellite]);
  const minutesPerRev = useMemo(() => 1440 / Number(revsPerDay), [revsPerDay]);
  const firstOrbitOfSVStart = addMinutes(eventData.eventTrigger, minutesPerRev);

  const satId = satellite.id;
  const isHoveredActive = hoveredSelectedSatId === satId;

  // __ Create Path _______________________________________________
  useEffect(() => {
    const SVOrbitPoints = [];

    for (let i = 0; i <= minutesPerRev; i += lineSegmentInterval.current) {
      const date = addMinutes(startDate, i);
      const getPos = getPositionFromSV(date, ephemerisPoints)?.position;

      if (!getPos) continue;

      SVOrbitPoints.push(getPos);
      if (i + lineSegmentInterval.current >= minutesPerRev) {
        setEndDate(date);
      }
    }

    setSVOrbitPointsArr(SVOrbitPoints);
  }, [startDate, satId]);

  // __ Update Path ________________________________________
  useFrame(() => {
    if (typeof endDate === "undefined" || (isFirstSVOrbit && ghostTLEToggle))
      return;

    let timeReset = false;
    // set orbit only once per revolution
    const timeIsBefore = isBefore(dateTime, startDate);
    const timeIsAfter = isAfter(dateTime, endDate);

    // if timeIsBefore set the start of previous orbit revolution as new startDate
    if (timeIsBefore && !timeReset) {
      const startOfPreviousOrbitDate = subMinutes(startDate, minutesPerRev);
      const isTimeJump = isAfter(startOfPreviousOrbitDate, dateTime);
      if (isTimeJump) {
        setStartDate(dateTime);
        timeReset = true;
        return;
      }
      setStartDate(startOfPreviousOrbitDate);
      timeReset = true;
    } else if (!timeIsBefore && timeReset) {
      timeReset = false;
    }

    // if timeIsAfter set the current revolution dateTime as the new startDate
    if (timeIsAfter && !timeReset) {
      const startOfNextOrbitDate = addMinutes(endDate, minutesPerRev);
      const isTimeJump = isBefore(startOfNextOrbitDate, dateTime);
      if (isTimeJump) {
        setStartDate(dateTime);
        timeReset = true;
        return;
      }
      setStartDate(endDate);
      timeReset = true;
    } else if (!timeIsAfter && timeReset) {
      timeReset = false;
    }
  });

  // __ Update Ghost Orbit Path _____________________________
  useEffect(() => {
    if (!timelineIsScrubbing) return;
    if (isFirstSVOrbit && ghostTLEToggle && ghostPathInterval.current % 2) {
      setStartDate(dateTime);
    }
    ghostPathInterval.current++;
  }, [dateTime, timelineIsScrubbing, ghostTLEToggle]);

  useEffect(() => {
    if (isFirstSVOrbit && ghostTLEToggle) {
      setStartDate(dateTime);
    }
  }, [ghostTLEToggle]);

  // __ Internal ____________________________________________
  const SVColorHandler = useMemo(
    () => (isHovered ? "#40ff40" : hasManualOrbit ? "grey" : "#9fff9f"),
    [isHovered, hasManualOrbit]
  );

  const isFirstSVOrbit = useMemo(() => {
    return isBetween(dateTime, eventData.eventTrigger, firstOrbitOfSVStart);
  }, [dateTime]);

  // _ Remove Selected by clicking on orbit _________________________
  const onClickHandler = () => {
    if (isSelected && !hasManualOrbit) {
      removeSelected(satId);
      clearHoveredSelected();
    }
  };

  const onPointerOverHandler = () => {
    if (orbitIsDragging) return;
    if (isSelected && !hasManualOrbit) {
      setHoveredSelected(satId);
    }
  };

  const onPointerOutHandler = () => {
    if (isSelected && !hasManualOrbit) {
      clearHoveredSelected();
    }
  };

  return (
    <>
      {SVOrbitPointsArr.length ? (
        <>
          {/* This first Line is not visible but acts as a hitbox for the visible line */}
          <Line
            name={"SVOrbit Hitbox"}
            onClick={onClickHandler}
            onPointerOver={onPointerOverHandler}
            onPointerOut={onPointerOutHandler}
            linewidth={30}
            points={SVOrbitPointsArr}
            visible={false}
            depthFunc={THREE.LessDepth}
          />
          <Line
            name={"SVOrbit"}
            points={SVOrbitPointsArr}
            color={SVColorHandler}
            opacity={isHovered || hasManualOrbit ? 0.6 : 1.0}
            transparent
            linewidth={isHoveredActive ? 5 : 3} // px
            blending={isHovered ? THREE.AdditiveBlending : THREE.NormalBlending}
            dashed={isHovered || hasManualOrbit}
            depthFunc={THREE.LessDepth}
            dashSize={300}
            gapSize={150}
            dashOffset={40}
          />

          {ghostTLEToggle &&
          isFirstSVOrbit &&
          threeSatellitesById[eventData?.tleId] ? (
            <TLEGhostOrbit
              isSelected={isSelected}
              isHovered={isHovered}
              eventData={eventData}
              isHoveredActive={isHoveredActive}
              satellite={threeSatellitesById[eventData.tleId]}
              endOfRev={startDate}
              eventStart={eventData.eventTrigger}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default SVOrbit;
