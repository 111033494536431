import { useEffect } from "react";
import { useAppSelector } from "../../utils/Hooks/reduxHooks";
import { RuxSelect, RuxOption } from "@astrouxds/react";
import { getEventTypesById } from "../../redux/rtk/endpoints/initialization";
import { RuxSelectCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";
import Common, {
  supportedProviderTypesByEventTypeId,
} from "../../utils/common";
import { Grid } from "@mui/material";
import _ from "lodash";
import type { EventFormValues } from "../../redux/rtk/types/formTypes";

const styles = {
  eventProvider: {
    marginRight: "1.25rem",
  },
};

type EventProviderProps = {
  values: Partial<EventFormValues>;
  handleChange: (e: RuxSelectCustomEvent<void>) => void;
  setInitialValues: (key: object) => void;
};

const EventProviderSelect = ({
  values,
  handleChange,
  setInitialValues,
}: EventProviderProps) => {
  const { eventTypeId, providerId } = values || {};
  const eventTypesById = useAppSelector(getEventTypesById);
  const allProviders = eventTypeId && eventTypesById[eventTypeId]?.providers;
  const supportedProviderIds =
    supportedProviderTypesByEventTypeId[
      eventTypeId as keyof typeof supportedProviderTypesByEventTypeId
    ];

  const getDefaultProvider = () => {
    if (!eventTypeId) return null;
    if (!supportedProviderIds.includes(Number(providerId)))
      setInitialValues({ providerId: supportedProviderIds[0].toString() });
  };

  // sets value of select when eventType changes
  useEffect(() => {
    getDefaultProvider();
  }, [eventTypeId]);

  return (
    <Grid item sm={6} sx={styles.eventProvider}>
      <RuxSelect
        required
        label="Provider"
        name="providerId"
        value={providerId + ""}
        onRuxchange={(e) => {
          // next line is work aroudn for possible bug in rux-select that returns empty array instead of options values
          if (_.isEmpty(e.target.value)) return;
          return handleChange(e);
        }}
        size="small"
        data-test="provider-select"
      >
        {allProviders ? (
          <>
            {allProviders.map((provider) => {
              return (
                <RuxOption
                  data-test="event-provider-options"
                  key={provider.providerID}
                  value={provider.providerID.toString()}
                  label={provider.providerName}
                  disabled={
                    !Common.isProviderSupported(
                      eventTypeId,
                      provider.providerID
                    )
                  }
                />
              );
            })}
          </>
        ) : (
          <RuxOption value="" label="Select provider" />
        )}
      </RuxSelect>
    </Grid>
  );
};

export default EventProviderSelect;
