import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import * as THREE from "three";
import { Instance } from "@react-three/drei";
import { ThreeEvent, useFrame } from "@react-three/fiber";
import { addSeconds, isBefore, secondsToMilliseconds } from "date-fns";
import { useSatStateContext } from "../../../utils/Hooks/contextHooks/useSatStateContext";
import { useCanvasContext } from "../../../utils/Hooks/contextHooks/useCanvasContext";
import { useZoomContext } from "../../../utils/Hooks/contextHooks/useZoomContext";
import { getPositionFromSV } from "../../../utils/propagation";
import { dateToStringWithMilliseconds } from "../../../utils/common";
import { useDateTimeContext } from "../../../utils/Hooks/contextHooks/useDateTimeContext";
import AltitudeLine from "./AltitudeLine";
import SatelliteLabel from "./SatelliteLabel";
import SVPointHelper from "../aux/SVPointHelper";
import SVPathHelper from "../aux/SVPathHelper";
import { SatTypes } from "../../../redux/rtk/types/trogdorApiTypes";
import { Ephemeris } from "../../../redux/rtk/types/internalTypes";
import { EventDataForObjects } from "../../../redux/rtk/types/ThreeJSCommonTypes";
// import useManualOrbit from "../../../utils/Hooks/eventTypeHooks/useManualOrbit";

type PropTypes = {
  center?: THREE.Vector3;
  storedPos?: THREE.Vector3;
  satellite: SatTypes;
  eventData: EventDataForObjects;
  ephemerisPoints: Ephemeris;
};

const SVSatelliteInstance = ({
  center = new THREE.Vector3(0, 0, 0),
  storedPos = new THREE.Vector3(0, 0, 0),
  satellite,
  eventData,
  ephemerisPoints,
}: PropTypes) => {
  const {
    selectedSatIds,
    addSelected,
    removeSelected,
    hoveredSatId,
    setHovered,
    hoveredSelectedSatId,
    setHoveredSelected,
    clearHoveredSelected,
  } = useSatStateContext();
  const {
    orbitIsDragging,
    timelineIsScrubbing,
    altitudeLinesToggle,
    satLabelToggle,
    ephemHelperToggle,
  } = useCanvasContext();
  const { instanceScale } = useZoomContext();
  const { dateTime } = useDateTimeContext();
  // const { manualOrbitTarget } = useManualOrbit();
  // const sameIdAsManualOrbitTarget =
  //   manualOrbitTarget?.noradCatId &&
  //   satId === manualOrbitTarget.noradCatId.toString();

  const satId = satellite.id;
  const [startDate, setStartDate] = useState<Date>(dateTime);
  const [pathPoints, setPathPoints] = useState<THREE.Vector3[]>([]);
  const initialPosSet = useRef(false);
  const instanceRef = useRef<any>();
  const percentDiff = useRef(0);
  const storedPercentDiff = useRef(0);

  const isHovered = hoveredSatId === satId;
  const isSelected = selectedSatIds.includes(satId);
  const isHoveredActive = hoveredSelectedSatId === satId;

  // __ Internal ________________________________________________________________
  const forceUpdatePosition = useCallback(
    (date: Date) => {
      const getPos = getPositionFromSV(date, ephemerisPoints)?.position;
      if (!getPos) return;

      instanceRef.current.position.set(getPos.x, getPos.y, getPos.z);
      instanceRef.current.lookAt(center);
      storedPos.set(getPos.x, getPos.y, getPos.z);

      if (!initialPosSet.current) {
        initialPosSet.current = true;
      }
    },
    [center, ephemerisPoints]
  );

  const updateIfDiff = useCallback(
    (position: {
      equals: (arg0: THREE.Vector3) => boolean;
      x: number;
      y: number;
      z: number;
    }) => {
      // check to make sure pos is only updated when value is diff then last
      if (!position.equals(storedPos)) {
        instanceRef.current.lookAt(center);
        instanceRef.current.position.set(position.x, position.y, position.z);
        storedPos.set(position.x, position.y, position.z);
      }
    },
    [center]
  );

  const createPath = (start: string) => {
    const startPathPos = getPositionFromSV(start, ephemerisPoints)?.position;
    const endPathDate = new Date(
      Date.parse(start) + secondsToMilliseconds(Number(ephemerisPoints.step))
    );
    const endPathPos = getPositionFromSV(
      endPathDate,
      ephemerisPoints
    )?.position;

    // error check for jumping to end of event time which would only produce 1 point
    // and at least 2 points are needed to create the line. If only one point is imputed
    // it throws an error and breaks the app.
    const pathPoints =
      startPathPos && endPathPos ? [startPathPos, endPathPos] : [];

    setPathPoints(pathPoints);
  };

  const satPath = useMemo(() => {
    if (pathPoints.length) {
      const curve = new THREE.CatmullRomCurve3(pathPoints);
      curve.type = "chordal";
      curve.arcLengthDivisions = 5000;
      curve.updateArcLengths();

      return curve;
    }
  }, [pathPoints]);

  useEffect(() => {
    if (!initialPosSet.current) {
      forceUpdatePosition(dateTime);
    }
    // This should be the only place createPath is called for simplicity's sake. To create new path a new startDate has to be set.
    createPath(dateToStringWithMilliseconds(startDate));
  }, [startDate]);

  // __ Update satellites Position - 2 ways - explained________
  // 1.) When NOT scrubbing the timeline a curvepath is created to interpolate between the gathered ephemeris points that have a step of 1 min between those real points
  // 2.) When timeline IS being scrubbed the real epheme points are called 1 at a time like how the positioning is updated for TLESatelliteInstance. This is because timeline updates dateTime. Real Ephem points are gathered with a step of 1 min between points so real points can be passed in without need for interpolation.

  // Scrubbing the timeline creates an exponential amount of calls because so many dates are passed. It's' difficult for pathfinding animation to keep up with the amount of calls scrubbing produces and would likely become an inaccurate representation of where that sat should be positioned at that dateTime.

  const percentageComplete = useMemo(() => {
    if (!instanceRef.current) return;

    return getPositionFromSV(dateTime, ephemerisPoints)?.percentageComplete;
  }, [dateTime]);

  // 1.) When NOT scrubbing the timeline
  useFrame(() => {
    if (!instanceRef.current) return;
    if (!initialPosSet.current) {
      // TODO: This will not be needed when "Ephemeris Loading Optimization" task is completed. Its a temporary fix to make sure SV's show when jumping to a time in it's lifespan
      const timeIsBefore = isBefore(dateTime, eventData.endTime);

      if (timeIsBefore) {
        setStartDate(dateTime);
      }
    }

    if (!pathPoints.length) {
      // TODO: The return here will not be needed when "Ephemeris Loading Optimization" task is completed.
      // Sats are "visible" but pathPoints can not be generated because ephem is not gathered after the event endTime.
      return;
    }

    if (timelineIsScrubbing) return;
    percentDiff.current = Math.abs(Number(percentageComplete) / 100);

    const isDiffPercentCheck =
      percentDiff.current !== storedPercentDiff.current;

    if (isDiffPercentCheck) {
      if (percentDiff.current === 1) {
        // Hacky way to make sure new start time is after next end of current step end
        setStartDate(addSeconds(dateTime, 1));
        return;
      }

      const getCurveVec =
        percentDiff.current > 0 &&
        percentDiff.current < 1 &&
        satPath?.getPointAt(percentDiff.current);

      if (!getCurveVec) return;
      updateIfDiff(getCurveVec);
      storedPercentDiff.current = percentDiff.current;
    }
  });

  // 2.) When timeline IS being scrubbed
  useEffect(() => {
    if (!instanceRef.current || !timelineIsScrubbing) return;
    if (!pathPoints.length) {
      // TODO: The return here will not be needed when "Ephemeris Loading Optimization" task is completed.
      return;
    }

    const getPos = getPositionFromSV(dateTime, ephemerisPoints);

    if (!getPos?.position) return;

    if (getPos.positionAfter) {
      const percentMultiplier = Math.abs(getPos.percentageComplete / 100);

      // Create a vector between past a future positions
      // ! Since this is a straight line, the object will still jump slightly but should not be perceptable
      const newPos = getPos.position.lerp(
        getPos.positionAfter,
        percentMultiplier
      );
      updateIfDiff(newPos);
    } else {
      updateIfDiff(getPos.position);
    }
  }, [dateTime, timelineIsScrubbing]);

  useEffect(() => {
    if (!timelineIsScrubbing) {
      setStartDate(dateTime);
    }
  }, [timelineIsScrubbing]);

  // __ Update Instance Color __________________________
  useEffect(() => {
    if (!instanceRef.current) return;
    const color = new THREE.Color("yellow");
    instanceRef.current.color.set(color);
  }, [instanceRef]);

  // __ Instance Pointer Interaction ________________________
  const onClickHandler = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();

    if (selectedSatIds.includes(satId)) {
      removeSelected(satId);
      setHovered(null);
    } else if (!selectedSatIds.includes(satId)) {
      addSelected(satId);
      setHovered(null);
      setHoveredSelected(satId);
    }
  };

  const onPointerOverHandler = (e: ThreeEvent<PointerEvent>) => {
    if (orbitIsDragging || timelineIsScrubbing) return;
    e.stopPropagation();

    selectedSatIds.includes(satId)
      ? setHoveredSelected(satId)
      : setHovered(satId);
  };

  const onPointerOutHandler = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    if (hoveredSelectedSatId) clearHoveredSelected();
    setHovered(null);
  };

  return (
    <group name={satId}>
      <Instance
        ref={instanceRef}
        onClick={(e) => onClickHandler(e)}
        onPointerOver={(e) => onPointerOverHandler(e)}
        onPointerOut={(e) => onPointerOutHandler(e)}
        scale={instanceScale}
      >
        {initialPosSet.current && satLabelToggle ? (
          <SatelliteLabel
            isSelected={isSelected}
            isStateVector={true}
            isHoveredActive={isHoveredActive}
            satellite={satellite}
          />
        ) : null}
        {initialPosSet.current && ephemHelperToggle ? (
          <SVPointHelper
            date={getPositionFromSV(dateTime, ephemerisPoints)?.currentTime}
            isSatellite={true}
          />
        ) : null}
      </Instance>

      {(isSelected || isHovered) && altitudeLinesToggle ? (
        <AltitudeLine
          isSelected={isSelected}
          satPos={storedPos}
          isStateVector={true}
        />
      ) : null}

      {ephemHelperToggle ? (
        <SVPathHelper
          ephemerisPoints={ephemerisPoints}
          startDate={startDate}
          pathPoints={pathPoints}
          range={6}
        />
      ) : null}
    </group>
  );
};

export default SVSatelliteInstance;

// Curve path  -- R3F
// RESOURCE: https://codesandbox.io/s/animations-curve-path-forked-yy42m?file=/src/App.js:1867-1877
// RESOURCE: https://codesandbox.io/s/animations-curve-path-w9vfs?file=/src/App.js:2142-2236
// Curve path + Transform Controls -- plain Three.js
// RESOURCE: https://github.com/mrdoob/three.js/blob/master/examples/webgl_modifier_curve.html
