import { useAppDispatch } from "../../utils/Hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import { RuxDialog } from "@astrouxds/react";
import { RuxDialogCustomEvent } from "@astrouxds/astro-web-components/dist/types/components";
import { updateStatus } from "../../redux/slices/scenarios/currentScenario/details/scenarioDetailsSlice";
import { currentScenarioStatusEnums as statusEnums } from "../../utils/common";

type PropTypes = {
  isOpen?: boolean;
  resetDialog?: () => void;
};

const DiscardChangesDialog = ({ isOpen = false, resetDialog }: PropTypes) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDialogClose = (e: RuxDialogCustomEvent<boolean | null>) => {
    if (e.detail) {
      dispatch(updateStatus(statusEnums.initialState));
      navigate("/scenarios");
    }
    resetDialog?.();
  };

  return (
    <RuxDialog
      data-test="discard-changes-dialog"
      open={isOpen}
      header="Discard Changes"
      message="Are you sure you want to discard all pending changes?"
      onRuxdialogclosed={handleDialogClose}
    />
  );
};

export default DiscardChangesDialog;
