import React, { useState, useEffect } from "react";
import { Html } from "@react-three/drei";
import { RuxIcon } from "@astrouxds/react";
import Box from "@mui/material/Box";
import TimeRuler from "./TimeRuler/TimeRuler";
import { add, addMilliseconds } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import Common from "../../../../../utils/common";
import { useCanvasContext } from "../../../../../utils/Hooks/contextHooks/useCanvasContext";
import { useSatelliteContext } from "../../../../../utils/Hooks/contextHooks/useSatelliteContext";
import { useDateTimeContext } from "../../../../../utils/Hooks/contextHooks/useDateTimeContext";
import { ConditionalWrapper } from "../../../../Misc/ConditionalWrapper";

const styles = {
  icon: {
    color: "white",
  },
  playbackControlsContainer: {
    position: "absolute",
    bottom: "3.125rem",
    left: "calc(50% - 20.25rem)",
    height: "3.75rem",
    backgroundColor: "transparent",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    borderRadius: ".5rem",
    width: "40.625rem",
  },
  timeDisplay: {
    fontFamily: "Roboto Mono",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: ".3125rem",
    marginLeft: "2%",
  },
  controlsContainer: {
    marginTop: ".3125rem",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  buttonDiv: {
    flexBasis: "7%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  timelineDiv: {
    display: "inline-flex",
    flexDirection: "column",
  },
  playhead: {
    backgroundColor: "var(--color-background-interactive-default, #4dacff)",
    position: "absolute",
    minHeight: "1.875rem",
    minWidth: ".125rem",
    bottom: "-.125rem",
    left: "20.625rem",
    zIndex: "10",
    pointerEvents: "none",
    "&.playhead::after": {
      width: 0,
      height: 0,
      borderLeft: ".75rem solid transparent",
      borderRight: ".75rem solid transparent",
      borderTop:
        ".75rem solid var(--color-background-interactive-default, #4dacff)",
      position: "absolute",
      transform: "scale(0.6, 0.75)",
      left: "-.6875rem",
      top: "-.625rem",
      content: '""',
    },
  },
  html: {
    height: "calc(50vh - 2.5rem)",
  },
};

const PlaybackControls = () => {
  const { dateTime, setDateTime } = useDateTimeContext();
  const { timelineIsScrubbing, setTimelineIsScrubbing } = useCanvasContext();
  const { renderWindowPortal } = useSatelliteContext();
  const isCypress = process.env.REACT_APP_CYPRESS;

  // State for showing and pausing live satellite positions
  const [isPaused, setIsPaused] = useState(false);

  // State to track if play is paused before scrubbing
  const [pauseButtonState, setPauseButtonState] = useState("playing");

  const handleTogglePlayPause = () => {
    setIsPaused(!isPaused);
    pauseButtonState === "playing"
      ? setPauseButtonState("paused")
      : setPauseButtonState("playing");
  };

  // TODO: Time is currently synced to the units in the ruler, the ruler segments are broken into 60 units representing minutes in time. When introducing time intervals we will need a method of changing these scroll units into the appropriate time duration.
  const handleDisplayDateTimeChange = (scrollMovement: number) => {
    setDateTime((prevTime) => add(prevTime, { minutes: scrollMovement }));
  };

  useEffect(() => {
    if (isPaused) return;
    const updateSatellitePositions = setInterval(() => {
      setDateTime((prevTime) => addMilliseconds(prevTime, 500));
    }, 500);

    return () => clearInterval(updateSatellitePositions);
  }, [isPaused, setDateTime]);

  return (
    <ConditionalWrapper
      condition={!isCypress}
      wrapper={(children: React.ReactPortal) => (
        <Html style={styles.html} portal={renderWindowPortal}>
          {children}
        </Html>
      )}
    >
      <Box component={"div"} sx={styles.playbackControlsContainer}>
        <Box component={"div"} sx={styles.timeDisplay}>
          <Box component={"div"} data-test="playback-controls-time-display">
            {formatInTimeZone(dateTime, "UTC", Common.PLAYBACK_CONTROLS_FORMAT)}
          </Box>
        </Box>
        <Box component={"div"} sx={styles.controlsContainer}>
          <Box component={"div"} sx={styles.buttonDiv}>
            <Box
              component={RuxIcon}
              icon="restore"
              size="small"
              onClick={() => setDateTime(new Date())}
              sx={styles.icon}
            />
          </Box>
          <Box component={"div"} sx={styles.buttonDiv}>
            {isPaused ? (
              <Box
                component={RuxIcon}
                icon="play-arrow"
                size="small"
                onClick={handleTogglePlayPause}
                sx={styles.icon}
              />
            ) : (
              <Box
                component={RuxIcon}
                icon="pause"
                size="small"
                onClick={handleTogglePlayPause}
                sx={styles.icon}
              />
            )}
          </Box>
          <Box component={"div"} sx={styles.timelineDiv}>
            <TimeRuler
              setIsPaused={setIsPaused}
              pauseButtonState={pauseButtonState}
              displayDateTime={dateTime}
              handleDisplayDateTimeChange={handleDisplayDateTimeChange}
              timelineIsScrubbing={timelineIsScrubbing}
              setTimelineIsScrubbing={setTimelineIsScrubbing}
            />
            <Box component={"div"} sx={styles.playhead} />
          </Box>
        </Box>
      </Box>
    </ConditionalWrapper>
  );
};

export default PlaybackControls;
